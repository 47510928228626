import Selectors from "redux/selectors/generic.selector"

export * from "redux/selectors/user.selector"
export * from "redux/selectors/data.selector"
export * from "redux/selectors/dashboard.selector";
export * from "redux/selectors/tasks.selector";

export {CustomerSelectors, SalesOrderSelectors, ProductSelectors, SyncSelectors} from "redux/selectors/generic.selector"

export default Selectors
