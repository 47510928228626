
import FetchAction from "./FetchAction";
import UpdateAction from "./UpdateAction";
import * as API from "../rest/API"
import AppEntities from './AppEntities'
import reduxStore from "./ReduxStore";

const GenericAction = (entityName, redux) => {

    const entityFetch = () => new FetchAction(AppEntities[entityName], API.fetcher(entityName));
    const entityUpdateAll = () => new UpdateAction(AppEntities[entityName], API.updater(entityName));
    const entityDelete = () => new UpdateAction(AppEntities[entityName], API.deleteAll(entityName));

    const entityFetchCurrent = () => new FetchAction(`CURRENT_${AppEntities[entityName]}`, API.fetcherCurrent(entityName));
    const entityUpdate = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.updater(entityName));
    const entityCreate = () => new UpdateAction(`CURRENT_${AppEntities[entityName]}`, API.creator(entityName));
    const entityFetchCurrentTask = () => new FetchAction(`CURRENT_TASK_${AppEntities[entityName]}`, API.fetcherCurrentTask(entityName));
    const entityUpdateCurrentTask = (apiAction) => new UpdateAction(`CURRENT_TASK_${AppEntities[entityName]}`, apiAction);
    const entityUpdateTakeAction = (apiAction) => new UpdateAction(`CURRENT_TASK_${AppEntities[entityName]}`, apiAction);

    return class Actions {

        static fetch(state, stateFilter, ...args) {
            return entityFetch().doFetch(state, stateFilter, ...args)
        }

        static fetchCurrent(state, stateFilter, currentId) {
            let toReturn = entityFetchCurrent().doFetch(state, stateFilter, currentId);
            return toReturn;
        }

        static fetchCurrentTask(state, stateFilter, ...args) {
            let toReturn = entityFetchCurrentTask().doFetch(state, stateFilter, ...args);
            return toReturn;
        }

        static assignCurrentTask(callback, params) {
            entityUpdateCurrentTask(API.assignCurrentTask(entityName)).doUpdate(true, callback, params);
        }

        static onEntityTakeAction(callback, params, action, invalidate = true) {
            entityUpdateTakeAction(API.onEntityAction(entityName)).doUpdate(invalidate, callback, action, params)
        }

        static setCurrentId(currentInstance, encoded = false) {
            reduxStore.dispatch({
                type: "SET_DATA",
                name: `${entityName}_currentId`,
                value: currentInstance
            })
        };

        static setCurrentPage(page) {
            reduxStore.dispatch({
                type: "SET_DATA",
                name: `${entityName}_currentPage`,
                value: page
            })
        };

        static invalidateEntity(entityName) {
            reduxStore.dispatch({
                type: `${entityName}`,
                status: 'invalid'
            })
        }


        static setMaxPerPage(maxPerPage) {
            reduxStore.dispatch({
                type: "SET_DATA",
                name: `${entityName}_maxPerPage`,
                value: maxPerPage
            })

            Actions.invalidate();

        };

        static setSearchParams(params) {
            reduxStore.dispatch({
                type: "SET_DATA",
                name: `${entityName}_searchParams`,
                value: params
            })

            Actions.invalidate({ searchParams: false });
        };

        static invalidate(options) {
            options = Object.assign({
                searchParams: true
            }, options);

            let { searchParams } = options;

            reduxStore.dispatch({
                type: "UNSET",
                name: `${entityName}_currentPage`
            })

            reduxStore.dispatch({
                type: "UNSET",
                name: `${entityName}_currentId`
            })

            searchParams && reduxStore.dispatch({
                type: "UNSET",
                name: `${entityName}_searchParams`
            })

            reduxStore.dispatch({
                type: `CURRENT_TASK_${AppEntities[entityName]}`,
                status: 'invalid'
            })

            reduxStore.dispatch({
                type: `CURRENT_${AppEntities[entityName]}`,
                status: 'invalid'
            })

            reduxStore.dispatch({
                type: AppEntities[entityName],
                status: 'invalid'
            })
        }

        static create(callback, entity, invalidate = true) {
            entityCreate().doUpdate(invalidate, callback, entity)
        }

        static update(callback, entity, invalidate = true) {
            entityUpdate().doUpdate(invalidate, callback, [entity])
        }

        static updateAll(callback, entities, invalidate = true) {
            entityUpdateAll().doUpdate(invalidate, callback, entities)
        }

        static delete(callback, ids, invalidate = true) {
            entityDelete().doUpdate(invalidate, callback, ids)
        }
    }
}

export default GenericAction;
