import React from "react";
import {A} from "../../components/ImgIcon";
import logoImage from 'assets/images/chargebee-icon-orange.png'
function Title(props) {
    const {msg, src = logoImage} = props;

    return (<div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <img src={src} style={{width: 30, marginRight: 8}}/>{msg}</div>)

}

export const allTours = {
    basicTour: {
        steps: [
            {
                content: 'This is our sidebar, menu. Quickly get to sales, customers, revenue plans or reporting',
                placement: 'right',
                spotlightPadding: 0,
                spotlightClicks: true,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#menu',
                title: <Title msg={'The Menu'}></Title>,
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: false,
            },
            {
                content: (<div>
                    Your current accounting period drives which sales are recorded in which closing period. Read <A
                    href="https://www.chargebee.com/docs/revrec-results.html#managing-revenue-subledger_managing-account-closing">more</A>
                </div>),
                placement: 'right',
                spotlightClicks: true,
                spotlightPadding: 12,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#actg_period',
                title: <Title msg={'Accounting Period'}></Title>
            },
            {
                content: (<div>
                    You get multiple environments with Chargebee RevRec. Your test environment is your play area to try things
                    out. Read <A href="https://www.chargebee.com/docs/revrec/understanding-environments.html">more</A>
                </div>),
                placement: 'right',
                spotlightClicks: true,
                spotlightPadding: 0,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#environments',
                title: <Title msg={'Your Environments'}></Title>
            },
            {
                content: (
                    <div>
                        Get help whenever you need it.
                        <br/>
                        Documentation, videos or live help.
                    </div>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: false,
                placement: 'bottom',
                spotlightClicks: true,
                spotlightPadding: 8,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#help',
                title: <Title msg={'Get help...'}></Title>,
            },
            {
                content: (<div>
                    Keep your account secure. Manage your credentials (password, MFA, phone etc).
                </div>),
                placement: 'top',
                spotlightClicks: true,
                spotlightPadding: 8,
                styles: {
                    options: {
                        zIndex: 10000,
                    },
                },
                target: '#my-account',
                title: <Title msg={'Your Account'}></Title>
            }
        ]
    }
}
