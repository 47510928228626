import {createSelector} from 'reselect';
import {currentOrganization, currentOrganizationState} from './selectors/user.selector'

import Actions from './actions'
import {AppEntityConfig} from './AppEntities'
import {Utils} from 'revlock-webutils'

const GenericSelectors = (entityName) => {

    const EntityActions = Actions[entityName];
    const config = AppEntityConfig(entityName);
    const entityState = (state) => state && state[entityName];

    // private selectors
    const currentState = (state) => state && state[`${entityName}_current`];
    const currentId = (state) => state.data && state.data[`${entityName}_currentId`];
    const currentPage = (state) => state.data && state.data[`${entityName}_currentPage`] || 0;
    const maxPerPage = (state) => state.data && state.data[`${entityName}_maxPerPage`] || config.maxPerPage;
    const searchParams = (state, props) => {
        let searchParams = state.data && state.data[`${entityName}_searchParams`] || {};

        if (props && props.searchParams) {
            searchParams = Object.assign(searchParams, props.searchParams)
        }

        return searchParams;
    }
    const currentTaskState = (state) => state && state[`${entityName}_current_task`];

    const selectAllNoPaging = createSelector(
        [currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams],
        (currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams) => {
            searchParams.end = Number.MAX_SAFE_INTEGER

            if (entityName === 'salesOrders' || !currentOrganization)
                return undefined

            if (!EntityActions.fetch(entityState, () => {

                let uptodate = entityState.lastUpdated != undefined &&
                    entityState.lastUpdated >= currentOrganizationState.lastUpdated

                if (uptodate && config.paging) {
                    uptodate = entityState[entityName] && entityState[entityName][currentPage]
                }

                return uptodate

            }, {currentPage, maxPerPage, ...searchParams}))
                return undefined;

            return config.paging ? entityState[entityName][currentPage] : entityState[entityName]
        }
    )

    const selectAll = createSelector(
        [currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams],
        (currentOrganization, currentOrganizationState, entityState, currentPage, maxPerPage, searchParams) => {
            if (!currentOrganization)
                return undefined

            if (!EntityActions.fetch(entityState, () => {

                let uptodate = entityState.lastUpdated != undefined &&
                    entityState.lastUpdated >= currentOrganizationState.lastUpdated

                if (uptodate && config.paging) {
                    uptodate = entityState[entityName] && entityState[entityName][currentPage]
                }

                return uptodate

            }, {currentPage, maxPerPage, ...searchParams}))
                return undefined;

            return config.paging ? entityState[entityName][currentPage] : entityState[entityName]
        }
    )

    const current = createSelector(
        [currentState, selectAll, currentId],
        (currentState, allEntities, currentId) => {
            if (config.loadCurrentFromRedux) {
                return allEntities && allEntities.find(entity => entity.id == currentId);
            } else {
                if (!currentId || currentState.isFetching)
                    return undefined
                const decodedId  = Utils.decodeId(currentId)
                if (!EntityActions.fetchCurrent(currentState, () => {
                    return currentState.lastUpdated != undefined && currentState[entityName] && currentState[entityName].id == decodedId;
                }, currentId))
                    return currentState[entityName];

                let currentEntity = currentState[entityName];
                return currentEntity;
            }
        }
    )

    return {
        selectAllNoPaging,
        selectAll,
        current,
        currentPage,
        maxPerPage,
        searchParams,
        currentId
    }
}

export default GenericSelectors
