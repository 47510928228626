import {
    UPDATE_LAYOUT,
    TOGGLE_SIDENAV,
    SET_SIDENAV_OPEN,
    TOGGLE_SIDENAV_VARIANT,
    TOGGLE_NOTIFICATIONS,
    TOGGLE_ORGANIZATIONS,
    TOGGLE_ADD_ORG
} from '../actions/layout.actions';

const defaultLayout = {
    currentLayout: 'compact',
    notificationsOpen: false,
    organizationsOpen: false,
    sidenavOpen: false
}

const layoutReducer = (layout = defaultLayout, action) => {
    switch (action.type) {
        case TOGGLE_SIDENAV:
            return {
                ...layout,
                sidenavOpen: !layout.sidenavOpen
            };

        case SET_SIDENAV_OPEN:
            return {
                ...layout,
                sidenavOpen: action.payload
            };

        case TOGGLE_SIDENAV_VARIANT:
            return {
                ...layout,
                sidenavVariant: action.payload
            };

        case TOGGLE_NOTIFICATIONS:
            return {
                ...layout,
                notificationsOpen: !layout.notificationsOpen
            };

        case TOGGLE_ORGANIZATIONS:
            return {
                ...layout,
                organizationsOpen: !layout.organizationsOpen
            };
        case TOGGLE_ADD_ORG:
            return {
                ...layout,
                addOrgOpen: !layout.addOrgOpen
            };

        case UPDATE_LAYOUT:
            return {
                ...layout,
                currentLayout: action.payload
            };

        default:
            return layout;
    }
};

export default layoutReducer;
