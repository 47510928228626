const { config } = require("../../../package.json");

const alias = (() => {
    return config.serviceProfile ? `/${config.serviceProfile}` : "";
})();

function getApiEndpoint() {
    return (
        (config["isCloudDeployment"] &&
            `https://qa-west-api.rev-lock.com${alias}`) ||
        "http://localhost:3001"
    );
}

export default {
    microstrategy: {
        mstrWebUrl:
            "https://qa-west-reports.rev-lock.com/MicroStrategy/servlet/",
        project: "QA_RL",
        server: "qa-west-mstr.cluster.rev-lock.local"
    },
    hotGlue: {
        api_key: "PnNOjM3Yqw6m9jnadfyQx3e7YsRdnvw8aiolDYSk",
        env_id: "qa.hotglue.rev-lock.com"
    },
    AWS: {
        API: {
            endpoints: [
                {
                    name: "revlock_api",
                    endpoint: getApiEndpoint()
                },
                {
                    name: "revlock_public_api",
                    endpoint: getApiEndpoint(),
                    custom_header: async () => {
                        return {
                            "x-api-key":
                                "u1BqIUHDCY4yZN4GcilVT15qI31rNzSY9pXMCTNE"
                        };
                    }
                }
            ]
        },
        Auth: {
            identityPoolId: "us-west-2:bd607732-8872-4964-a36b-f271325b2a1b",
            region: "us-west-2",
            userPoolId: "us-west-2_NJhh5sExi",
            userPoolWebClientId: "3c61e2uppi2jnrp5aiqlqn217b",
            mandatorySignIn: true
        },
        Storage: {
            bucket: "qa-west-userfiles",
            region: "us-west-2",
            hotglueBucket: "qa-hotgluefiles"
        },
        Analytics: {
            disabled: true
        },
        oauth: {
            domain: "qa-west.auth.rev-lock.com",
            clientId:
                "1092057117597-naiv1sargrqsilc5n3l6pbef2nclsk16.apps.googleusercontent.com",
            redirectSignout: "https://qa-west.rev-lock.com/signout",
            redirectSignIn: "https://qa-west.rev-lock.com/",
            scope: ["email", "profile", "openid"],
            responseType: "code"
        }
    },
    analytics: {
        trackingId: "UA-127158128-3",
        debug: true
    },
    keycloakConfig: {
        url: "https://idp-broker.devcb.in/",
        redirectUri: `https://qa-west-${config.serviceProfile}-sso.stagingcb.com/`,
        realm: "master",
        clientId: "revrec-app",
        idpHint: "revrec-idp"
    }
};
