export default {
    RETROSPECTIVE: "R",
    PROSPECTIVE: "P",
    parse(modificationType) {
        let modType;
        if (typeof modificationType === "string") {
            modificationType = modificationType.toLowerCase();
        }
        if (modificationType == "prospective") {
            modType = this.PROSPECTIVE;
        } else if (
            modificationType == null ||
            modificationType == "" ||
            modificationType == "retrospective"
        ) {
            modType = this.RETROSPECTIVE;
        }
        return modType;
    }
};
