import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as WebUtils from "utils/WebUtils";
import { compose } from "utils/WebUtils";
import { withStyles } from "@material-ui/core/styles";
import { withCookies } from "react-cookie";
import withWidth, { isWidthDown, isWidthUp } from "@material-ui/core/withWidth";
import { LinearProgress, Typography, Button, Divider } from "@material-ui/core";
import Sidenav from "layouts/components/sidenav/sidenav.component";
import MstrLogout from "containers/reports/MstrLogout";
import {
    currentOrganization,
    currentUser,
    userOrganizations
} from "redux/selectors/user.selector";

import EntitySelectors from "redux/selectors";

// Actions
import {
    setSidenavOpen,
    toggleSidenav,
    toggleSidenavVariant
} from "redux/actions/layout.actions";

import scss from "./layout-compact.module.scss";
import styles from "./layout-compact.style";
import TourWindow from "../../components/Tour/TourWindow";
import WelcomeTour from "../../components/Tour/WelcomeTour";
import Notification from "../../containers/Notification";
import UpgradeSplashDialog from "../../components/UpgradeSplash";
import {
    autoSync,
    getClientSSPFields,
    joyRide,
    showImportDialog,
    showQuickViewBanner,
    showUpgradeDialog,
    showSuspendedBanner,
    triggerTour
} from "redux/selectors/data.selector";

import { setData2, unset2 } from "redux/actions/data.actions";
import Joyride from "react-joyride";

import { allTours } from "../../components/Tour/JoyRideConfig";
import Alert from "@material-ui/lab/Alert/Alert";
import { getPlan, getTrial } from "utils/auth";
import IntegrationSyncForm from "containers/sync/sync-search/IntegrationSyncForm";
import { withRouter } from "react-router-dom";
import {
    updateOrganizationAttribute,
    getIncorrectJournalAccountMappings
} from "rest/API";

import { RateReview, Settings } from "@material-ui/icons";
import PLG from "./PLG";
import { SecurityUtils } from "revlock-webutils";
import ExtendTenantExpire from "../../components/ExtendTenantExpire";
import MigrateToChargebeeSignIn from "../../components/MigrateToChargebeeSignIn";

const notificationSelectors = EntitySelectors.notification;

class CompactLayout extends React.Component {
    // Set the initial layout state when the layout is initialised
    constructor(props) {
        super(props);
        const variant = isWidthDown("sm", props.width)
            ? "temporary"
            : "persistent";
        props.toggleSidenavVariant(variant);
        props.setSidenavOpen(variant === "persistent");
        this.state = {
            showSuspenededBanner: false,
            showIncorrectJAMBanner: false
        };
    }

    setJoyrideCallback = (callback) => {
        const { type, status } = callback;

        if (type == "tour:end" && status == "finished")
            setData2("currentJoyRide", null);
    };

    setJoyrideHelpers = (helpers) => {
        this.joyRideHelpers = helpers;
    };

    async fetchIncorrectJournalAccountMappings() {
        const { currentOrganization, checkIncorrectJAMMappings } = this.props;
        const { alreadyMadeIncorrectJAMCall } = this.state;

        if (
            currentOrganization &&
            (!alreadyMadeIncorrectJAMCall || checkIncorrectJAMMappings)
        ) {
            this.setState(
                {
                    alreadyMadeIncorrectJAMCall: true
                },
                () => {
                    unset2("checkIncorrectJAMMappings");
                }
            );
            const res = await getIncorrectJournalAccountMappings(
                currentOrganization.id
            );

            this.setState({
                showIncorrectJAMBanner: (res && res.result?.length) || false
            });
        }
    }

    // Update the layout state when a going from mobile to desktop and vice versa
    componentWillReceiveProps(nextProps) {
        if (
            isWidthDown("sm", this.props.width) &&
            isWidthUp("md", nextProps.width)
        ) {
            this.props.toggleSidenavVariant("persistent");
            this.props.setSidenavOpen(true);
        } else if (
            isWidthDown("sm", nextProps.width) &&
            isWidthUp("md", this.props.width)
        ) {
            this.props.toggleSidenavVariant("temporary");
            this.props.setSidenavOpen(false);
        }

        if (this.props.joyRide !== nextProps.joyRide)
            this.joyRideHelpers &&
                this.joyRideHelpers.reset &&
                this.joyRideHelpers.reset(true);

        if (this.props.currentOrganization != nextProps.currentOrganization) {
            this.setState({
                showSuspenededBanner:
                    nextProps.currentOrganization?.suspended || false
            });
        }

        if (this.props.currentOrganization) {
            this.fetchIncorrectJournalAccountMappings();
        }
    }

    handleHideAlert = async (e) => {
        e.stopPropagation();
        const { currentOrganization, patchOrganization, classes } = this.props;
        if (
            (currentOrganization.currentPeriod == undefined || null) &&
            currentOrganization.setupIncomplete
        ) {
            WebUtils.showAlert({
                content: (
                    <Typography variant={"body1"}>
                        {
                            "To get out of setup / restatement mode you must close your first accounting period. "
                        }
                        <a
                            href="https://www.chargebee.com/docs/revrec/journal-account-mapping.html"
                            target="_blank"
                            className={classes.linkCss}
                        >
                            {"Learn How"}
                        </a>
                    </Typography>
                ),
                title: "",
                icon: "warning"
            });
        } else {
            setData2("showUpgradeDialog", null);
            const org = await updateOrganizationAttribute(
                currentOrganization.id,
                { setupIncomplete: false }
            );
            patchOrganization(org);
        }
    };

    handleAlertClick = async (e, isTrial) => {
        e.stopPropagation();
        if (isTrial) setData2("showUpgradeDialog", "plan");
    };

    render() {
        const {
            children,
            classes,
            showUpgradeDialog,
            showQuickViewBanner,
            showSuspendedBanner,
            showImportDialog,
            autoSync,
            sspFields,
            history,
            cookies,
            ...rest
        } = this.props;
        const {
            triggerTour,
            joyRide,
            userOrganizations,
            currentOrganization,
            steps,
            currentUser
        } = rest;
        const appModeResponse = cookies.get("appModeResponse");
        if (
            !userOrganizations ||
            !userOrganizations.length ||
            !currentOrganization
        )
            return <LinearProgress />;

        const activePlan = getPlan();
        const { isTrial, daysRemaining } = activePlan;
        const setupIncomplete =
            currentOrganization && currentOrganization.setupIncomplete;

        const revEssential =
            (currentOrganization &&
                currentOrganization.license &&
                currentOrganization.license.name === "rev-essential") ||
            false;

        const superAdminLevel = SecurityUtils.getSuperAdminLevel(currentUser);
        const disableActionsForQuickView =
            revEssential && superAdminLevel < SecurityUtils.SUPER_ADMIN_LEVEL_1;

        // Show dialog and banner if no tour being displayed and no upgrade popup is being displayed.
        const showPlg =
            !(!!showUpgradeDialog || showQuickViewBanner) && !triggerTour;

        const showExtendTenantDialog =
            (currentOrganization &&
                currentOrganization.license &&
                currentOrganization.license.showExtendTrialDailog) ||
            false;

        const migrateToChargebeeSignIn = currentUser.migrateToChargebeeSignIn;

        return (
            <div
                className={classNames(
                    scss["layout-compact-wrapper"],
                    classes.wrapper
                )}
            >
                <Sidenav {...rest}></Sidenav>
                <main id="main-layout" className={scss["layout-compact-main"]}>
                    {isTrial && (
                        <Alert
                            classes={{
                                root: classes.warning,
                                message: classes.alertMessage
                            }}
                            severity="warning"
                            onClick={(e) => this.handleAlertClick(e, isTrial)}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                {isTrial && (
                                    <span>
                                        You have {daysRemaining} days left in
                                        your trial
                                    </span>
                                )}
                            </div>
                        </Alert>
                    )}
                    {this.state.showIncorrectJAMBanner &&
                        this.props.productSpace === "revrec" && (
                            <>
                                {isTrial && <Divider />}
                                <Alert
                                    onClick={(e) => {}}
                                    icon={
                                        <RateReview
                                            style={{ color: "grey" }}
                                            onClick={() =>
                                                this.props?.history.push(
                                                    `/${currentOrganization.id}/journalaccounts?incorrectmappings=true`
                                                )
                                            }
                                        />
                                    }
                                    severity="warning"
                                    classes={{ root: classes.warning }}
                                >
                                    We have found incorrect Journal Account
                                    mappings in the system. Please review and
                                    correct them to facilitate a smooth
                                    accounting close process.
                                </Alert>
                            </>
                        )}
                    {appModeResponse && appModeResponse.mode == "r" && (
                        <Alert
                            icon={<Settings style={{ color: "grey" }} />}
                            severity="info"
                            classes={{ root: classes.info }}
                        >
                            The system is currently undergoing maintenance.
                        </Alert>
                    )}

                    {showPlg && (
                        <PLG
                            currentOrganization={currentOrganization}
                            currentUser={currentUser}
                        />
                    )}

                    <div className={scss["layout-compact-content-wrapper"]}>
                        <div className={scss["layout-compact-content"]}>
                            <Notification />
                            {children}
                        </div>
                    </div>
                </main>
                {disableActionsForQuickView ? (
                    <WelcomeTour open={triggerTour} />
                ) : (
                    <TourWindow open={triggerTour} />
                )}
                <UpgradeSplashDialog
                    open={
                        !!showUpgradeDialog ||
                        showQuickViewBanner ||
                        showSuspendedBanner
                    }
                    type={showUpgradeDialog}
                    onClose={() => {
                        setData2("showUpgradeDialog", null);
                        setData2("showQuickViewBanner", null);
                        setData2("showSuspendedBanner", null);
                    }}
                />

                {showExtendTenantDialog && (
                    <ExtendTenantExpire
                        currentOrganization={currentOrganization}
                    />
                )}
                {migrateToChargebeeSignIn && (
                    <MigrateToChargebeeSignIn
                        currentOrganization={currentOrganization}
                    />
                )}

                <Joyride
                    callback={this.setJoyrideCallback}
                    getHelpers={this.setJoyrideHelpers}
                    continuous={true}
                    run={joyRide}
                    steps={steps}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    styles={{
                        options: {
                            primaryColor: "#012a38"
                        }
                    }}
                />
                <IntegrationSyncForm
                    visible={showImportDialog}
                    autoSync={autoSync}
                    onClose={() => setData2("showImportDialog", null)}
                    user={currentUser}
                />
                <MstrLogout />
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    const currentRide = joyRide(state);

    const toReturn = {
        layout: {
            sidenavOpen: state.layout.sidenavOpen
        },
        triggerTour: triggerTour(state),
        organizations: userOrganizations(state),
        currentOrganization: currentOrganization(state),
        userOrganizations: userOrganizations(state),
        currentUser: currentUser(state),
        notification: notificationSelectors.selectAll(state),
        showUpgradeDialog: showUpgradeDialog(state),
        showQuickViewBanner: showQuickViewBanner(state),
        showSuspendedBanner: showSuspendedBanner(state),
        showImportDialog: showImportDialog(state),
        autoSync: autoSync(state),
        joyRide: !!currentRide,
        sspFields: getClientSSPFields(state),
        checkIncorrectJAMMappings: state.data?.checkIncorrectJAMMappings,
        productSpace: state.data.productSpace || "revrec"
    };

    if (currentRide) {
        const tour = allTours[currentRide];
        if (tour && tour.steps) {
            toReturn.steps = tour.steps;
            toReturn.currentRide = currentRide;
        }
    }

    return toReturn;
}

CompactLayout.propTypes = {
    classes: PropTypes.shape({}).isRequired,
    children: PropTypes.shape({}).isRequired,
    width: PropTypes.string.isRequired,
    toggleSidenavVariant: PropTypes.func.isRequired,
    setSidenavOpen: PropTypes.func.isRequired
};

export default compose(
    withRouter,
    withCookies,
    withWidth(),
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, {
        toggleSidenav,
        toggleSidenavVariant,
        setSidenavOpen,
        patchOrganization: (org) => ({
            type: "CURRENT_ORGANIZATION",
            response: org,
            status: "success"
        })
    })
)(CompactLayout);
