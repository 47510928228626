import { Utils, Period } from "revlock-webutils";

let { randomFixedInteger } = Utils;
var util = require("util");

let maxLength = 7;
export let prodIdPrefix = "1";
export let testIdPrefix = "9";
export let consolidatedIdPrefix = "7";
export let consolidatedTestIdPrefix = "6";

export function isDiscountProduct(product) {
    if (product) {
        if (product.isDiscount == 1 || product.isDiscount == true) {
            return true;
        } else if (product.isDiscount == 0 || product.isDiscount == false) {
            return false;
        } else if (product.code) {
            return product.code.toLowerCase().startsWith("discount");
        }
    }

    return false;
}

export function isMeteredProduct(product) {
    if (!product) {
        return false;
    }

    return product.isMetered == 1 || product.isMetered == true;
}

export function newProdId() {
    return prodIdPrefix + randomFixedInteger(maxLength).toString();
}

export function toProdId(id) {
    if (typeof id === "number") id = `${id}`;

    return prodIdPrefix + id.substring(1);
}

export function toTestId(id) {
    if (typeof id === "number") id = `${id}`;

    return testIdPrefix + id.substring(1);
}

export function toConsolidatedId(id) {
    if (typeof id === "number") id = `${id}`;

    return consolidatedIdPrefix + id.substring(1);
}

export function toConsolidatedTestId(id) {
    if (typeof id === "number") id = `${id}`;

    return consolidatedTestIdPrefix + id.substring(1);
}

export function isProdId(id) {
    if (typeof id === "number") id = `${id}`;

    return id.toString().startsWith(prodIdPrefix);
}

export function isTestId(id) {
    if (typeof id === "number") id = `${id}`;

    return id.toString().startsWith(testIdPrefix);
}

export function isConsolidated(orgId) {
    return isConsolidatedId(orgId) || isConsolidatedTestId(orgId);
}

export function isConsolidatedId(id) {
    if (typeof id === "number") id = `${id}`;

    return id.toString().startsWith(consolidatedIdPrefix);
}

export function isConsolidatedTestId(id) {
    if (typeof id === "number") id = `${id}`;

    return id.toString().startsWith(consolidatedTestIdPrefix);
}

export function getTerm(start, end) {
    return Utils.getYearDiff(start, end, true);
}

export function evaluateExpression(expression, objs) {
    let expr = Object.keys(objs).reduce(
        (expr, name) => (expr += `var ${name}=objs["${name}"];`),
        ""
    );
    expr += expression;
    try {
        return eval(expr);
    } catch (e) {
        throw Utils.error(
            `Error while evaulation expression ${expression}, params : ${util.inspect(
                objs
            )}`,
            e
        );
    }
}

// On the fly upgrade for sales order, this is used only in two places.
// Hydrator and Restore sales order snapshot.
export function ensureCompatibility(salesOrder) {
    // If we are processing a 'ref' copy, there can't be any compability issues.
    if (!salesOrder.salesOrderItem) return salesOrder;

    salesOrder.salesOrderItem.forEach((soi) => {
        // intruduced in v2.8
        if (
            soi.dereferencedStartDate &&
            soi.dereferencedStartDate == "Order Start Date"
        )
            soi.dereferencedStartDate = "Order Date";

        // intruduced in v2.8
        if (!soi.dereferencedEndDate && !soi.term && soi.endDate)
            soi.dereferencedEndDate = "manualDate";

        // change the name of attr in v2.8
        if (soi.dereferenceSource) {
            soi.dereferencedStartDateSource = soi.dereferenceSource;
            delete soi.dereferenceSource;
        }

        // change the name of attr in v2.8
        if (soi.term && !soi.termSource) {
            soi.termSource = "salesOrder";
            delete soi.dereferenceSource;
        }
    });

    return salesOrder;
}

export function itemHasReasonCode(rai, orgConfig) {
    const reasonCodeConfig =
        orgConfig &&
        orgConfig.find((cfg) => cfg.id == "properties/expense/reasoncodes");
    const reasonCodeValues = (reasonCodeConfig && reasonCodeConfig.value) || [];

    if (reasonCodeValues.length == 0) {
        return false;
    }

    const salesOrderItem = rai.salesOrderItem;
    const reasonCodeValue = salesOrderItem.attributes?.reasonCode;
    if (reasonCodeValues.includes(reasonCodeValue)) {
        return true;
    }
}

export function computeListPrice(product, serviceStartDate, serviceEndDate) {
    if(!product){
        return 0
    }
    
    let productListPrice =  product.listPriceAmount || 0;
    if (!product.recurring || !serviceEndDate) return productListPrice;
    let term = product.listPricePeriod || 1;
    let monthsMultiplyFactor = 1;

    if (term) {
        productListPrice = productListPrice / term;
    }

    if (product.listPricePeriodUnit == "months") {
        monthsMultiplyFactor = 1;
    } else if (product.listPricePeriodUnit == "quarters") {
        monthsMultiplyFactor = 3;
    } else {
        monthsMultiplyFactor = 12;
    }
    let proRatedStartDate = Period.addMonths(
        serviceStartDate,
        monthsMultiplyFactor
    );
    const firstMonthDays = Period.daysBetween(
        serviceStartDate,
        proRatedStartDate,
        true,
        false
    );

    const fullDays = Period.daysBetween(
        serviceStartDate,
        serviceEndDate,
        true,
        true
    );

    let serviceTerm = fullDays / firstMonthDays;

    const listPrice = productListPrice * serviceTerm;
    return listPrice;
};
