import FetchAction from "../FetchAction";
import * as API from "../../rest/API"
import store from '../ReduxStore'

const searchFetcher = () => new FetchAction("SALES_SEARCH", API.getSalesOrders);


export default class SalesActions {
  /**
   * Task Actions
   */
  static doSearch(state, stateFilter, ...args) {
    return searchFetcher().doFetch(state, stateFilter, ...args)
  }

}
