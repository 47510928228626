import AppEntities, { newEntity as newAppEntity } from "./AppEntities"
import selectors from "./selectors"
import actions from "./actions"
import reduxStore from './ReduxStore'
import GenericReducer from './GenericReducer';
import { pagedEntity } from './reducers/data.reducer';

export const newEntity = (entityName, type) => {
    newAppEntity(entityName, type)
    actions.newEntity(entityName);
    selectors.newEntity(entityName);
    reduxStore.newEntity(entityName, GenericReducer(entityName, AppEntities[entityName]));
    pagedEntity[AppEntities[entityName]] = entityName;
}