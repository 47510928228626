import { Utils } from 'revlock-webutils'

const AppEntities = {
    product: 'PRODUCT',
    customer: 'CUSTOMER',
    professionalServicesLabor: 'PROFESSIONAL_SERVICES_LABOR',
    journalAccount: 'JOURNAL_ACCOUNTS',
    salesPerson: 'SALES_PERSON',
    recognitionRule: 'RECOGNITION_RULES',
    commissionRecognitionRule: 'COMMISSION_RECOGNITION_RULES',
    salesOrder: 'SALES_ORDER',
    revenueSummary: 'REVENUE_SUMMARY',
    customerRevenueSummary: 'CUSTOMER_REVENUE_SUMMARY',
    commissionSummary: 'COMMISSION_SUMMARY',
    standaloneSellingPrice: 'STANDALONE_SELLING_PRICE',
    expenseAccounting: 'EXPENSE_ACCOUNTING',
    directExpenses: 'DIRECT_EXPENSES',
    inDirectExpenses: 'INDIRECT_EXPENSES',
    missingStandaloneSellingPrice: 'MISSING_STANDALONE_SELLING_PRICE',
    clientAttribute: 'CLIENT_ATTRIBUTE',
    sspSelector: 'SSP_TEMPLATE',
    orgConfig: 'ORG_CONFIG',
    sync: 'SYNC',
    progress: 'PROGRESS',
    professionalServiceDelivery: "PROFESSIONAL_SERVICE_DELIVERY",
    billingSchedule: "BILLING_SCHEDULE",
    recon: "RECON",
    customerContracts: "CUSTOMER_CONTRACTS",
    customerInvoices: "CUSTOMER_INVOICES",
    notification: 'NOTIFICATION',
    journalAccountMappings: 'JOURNAL_ACCOUNT_MAPPING',
    forexExchange: 'FOREX_EXCHANGE'
}

const DEFAULT_CONFIG = entityName => ({
    getAllPath: `${entityName}s`,
    getPath: `${entityName}`,
    updatePath: `${entityName}`,
    loadCurrentFromRedux: true // Load current item from redux instead of making a remote fetch call.
})

const DEFAULT_PAGING_CONFIG = entityName => (
    Object.assign({}, DEFAULT_CONFIG(entityName), {
        paging: true,
        maxPerPage: (entityName == 'forexExchange' || entityName == 'journalAccountMappings') ? 50 : 10,
        loadCurrentFromRedux: false
    })
)

const CLIENT_TABLES_PAGING_CONFIG = entityName => (
    Object.assign({}, DEFAULT_PAGING_CONFIG(entityName), {
        getAllPath: `clientTable/${entityName}`,
        getPath: `clientTable/${entityName}`,
        updatePath: `clientTable/${entityName}`,
        loadCurrentFromRedux: true
    })
)

const SINGLE_ENTITY_CONFIG = entityName => ({
    getAllPath: `${entityName}`,
    getPath: `${entityName}`,
    updatePath: `${entityName}`
})

const entityTypes = {
    single: ['revenueSummary', 'commissionSummary', 'notification', 'customerRevenueSummary'],
    paged: ['salesOrder', 'inDirectExpenses', 'directExpenses', 'sync', 'standaloneSellingPrice', 'expenseAccounting', 'missingStandaloneSellingPrice',
        'customerContracts', 'customerInvoices', 'customer', "product", 'forexExchange', 'journalAccountMappings'],
    clientTable: []
}

export const AppEntityConfig = (entityName) => {
    if (entityTypes['single'].includes(entityName)) {
        return SINGLE_ENTITY_CONFIG(entityName);
    } else if (entityTypes['paged'].includes(entityName)) {

        let entityConfig = DEFAULT_PAGING_CONFIG(entityName);

        if (entityName === "customerInvoices") {
            entityConfig.getAllPath = `${entityName}`
        }

        return entityConfig;
    } else if (entityTypes['clientTable'].includes(entityName)) {
        return CLIENT_TABLES_PAGING_CONFIG(entityName);
    } else {
        let entityConfig = DEFAULT_CONFIG(entityName);

        if (entityName === "customer") {
            entityConfig['loadCurrentFromRedux'] = false;
        }

        if (entityName === "product") {
            entityConfig['loadCurrentFromRedux'] = false;
        }

        return entityConfig
    }
}

export const newEntity = (entityName, type = 'paged') => {
    AppEntities[entityName] = Utils.convertToAllCapsFromCamelCase(entityName);
    entityTypes[type].push(entityName);
}

export default AppEntities;
