
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { setData, unset } from '../../redux/actions/data.actions';
import { confirmDialogParams } from '../../redux/selectors/data.selector';
import { compose } from 'utils/WebUtils';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    title: {
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1.3125rem',
        fontWeight: '500',
        fontFamily: 'Roboto',
        lineHeight: ' 1.16667em'
    }, paper: {
        maxWidth: '80%',
        margin: 0,
        padding: 0,
        borderRadius: theme.dialog && theme.dialog.borderRadius || '10px',
        overflow: 'hidden'
    },
    titleContainer: {
        flex: '0,0, auto',
        margin: 0,
        padding: '24px, 24px, 20px, 24px',

    }
});

class ConfirmDialog extends React.Component {

    onClose = (confirmed) => {
        let { onClose } = this.props.dialogParams;
        this.props.autoClose && this.props.setData("confirmDialogParams", Object.assign({}, this.props.dialogParams, { visible: false }));
        onClose && onClose(confirmed);
    }

    render() {

        let { dialogParams, classes, dialogContent, zIndex } = this.props;
        if (!dialogParams) {
            dialogParams = {
                visible: false
            }
        }

        zIndex = 1200 + (zIndex || 0)

        let { title, message, visible, cancel, ok, disableActions, disableCancel, disableOkOrDelete, disableCloseButton, fullWidth } = dialogParams;

        return (
            <Dialog
                disableEnforceFocus={true}
                open={visible}
                onClose={() => this.onClose(false)}
                style={{ zIndex: zIndex || 0 }}
            >
                {disableCloseButton || <div style={{ position: 'absolute', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer', width: '98%', height: 30, top: 10 }} >
                    <Avatar onClick={() => this.onClose(false)} className={classes.avatar} style={{ width: 30, height: 30 }} >
                        <CloseIcon style={{ fontSize: 20 }} />
                    </Avatar>
                </div>}
                {title && <DialogTitle >
                    {title}
                </DialogTitle>}
                <DialogContent>
                    {dialogContent ||
                        message && <DialogContentText style={{ fontSize: '16px', color: '#000000', minWidth: '300px' }}>
                            <div style={{ display: 'flex' }} >{message}</div>
                        </DialogContentText>
                    }
                </DialogContent>
                {disableActions || <DialogActions>
                    {disableCancel || <Button onClick={() => this.onClose(false)} color="primary">
                        {cancel || 'Cancel'}
                    </Button>}
                    {disableOkOrDelete || <Button onClick={() => this.onClose(true)} color="primary" autoFocus>
                        {ok || 'Delete'}
                    </Button>}
                </DialogActions>}
            </Dialog>
        );
    }
}

ConfirmDialog.defaultProps = {
    message: null
};

function mapStateToProps(state, props) {
    return {
        dialogParams: props.dialogParams || confirmDialogParams(state),
        autoClose: (props.dialogParams) ? false : true
    };
}

export default compose(withStyles(styles), connect(mapStateToProps, { setData }))(ConfirmDialog);
