import { Utils, moment } from "revlock-webutils";
import { Currency } from "revlock-accounting";
import AppEntities from "../AppEntities";

let { CurrencyType } = Currency;

const initialState = {
    sync: 0,
    day: 0,
    doMstrLogout: true,
    showProductToursDialog: false,
    showWelcomeTour: null,
    showSSPBuilderDialog: false,
    salesOrderGridColumns: [
        "orderType",
        "id",
        "customer",
        "orderDate",
        "salesPerson"
    ],
    customerGridColumns: ["name"],
    sspGridColumns: ["standalonePrice", "recognitionRule"],
    _sspGridColumns: ["standalonePrice", "recognitionRule"],
    expenseGridColumns: [
        "expenseAmortizationMethod",
        "expenseCode",
        "expenseTracksRevenue"
    ],
    salesOrderItemGridColumns: [
        "product",
        "quantity",
        "salePrice",
        "extendedSalePrice"
    ],
    revenueArrangementItemGridColumns: [
        "product",
        "extendedSellingPrice",
        "estimatedSalePrice",
        "extendedEstimatedSalePrice",
        "productRevenue"
    ],
    commissionItemGridColumns: [
        "product",
        "commissionPercent",
        "extendedSellingPrice",
        "extendedEstimatedSalePrice",
        "sspCommissionExpense",
        "commissionExpense",
        "allocatedCommission"
    ]
};

export const pagedEntity = {};

const data = (state = initialState, action) => {
    let newstate = Object.assign({}, state);

    if (Object.keys(pagedEntity).includes(action.type)) {
        const entity = pagedEntity[action.type];
        if (action.status == "success") {
            if (!newstate[`${entity}_searchParams`])
                newstate[`${entity}_searchParams`] = {};

            newstate[`${entity}_searchParams`].cursor =
                action.response && action.response.cursor;
            newstate[`${entity}_searchParams`].count =
                action.response && action.response.count;

            return newstate;
        }

        return state;
    }

    switch (action.type) {
        case "RESET_ORG":
            const entities = Object.keys(AppEntities);

            for (let entity of entities) {
                if (newstate[`${entity}_searchParams`]) {
                    delete newstate[`${entity}_searchParams`];
                    delete newstate[`${entity}_currentId`];
                    delete newstate[`${entity}_currentPage`];
                }
            }
            return newstate;
        case "SET_FLAG":
            newstate[action.name] = true;
            return newstate;
        case "UNSET":
            newstate[action.name] =
                (initialState[action.name] &&
                    Object.assign({}, initialState[action.name])) ||
                undefined;
            return newstate;
        case "SET_DATA":
            newstate[action.name] = action.value;

            return newstate;
        case "UPDATE_SALES_ORDER":
            let removeIndex = newstate.salesOrders.findIndex(
                (salesOrder) => action.salesOrder.id == salesOrder.id
            );
            newstate.salesOrders[removeIndex] = Utils.copy(action.salesOrder);
            newstate.salesOrders = newstate.salesOrders.slice();
            return newstate;
        case "NEW_SALES_ORDER":
            newstate.salesOrders.push({
                id: action.id,
                new: true,
                sync: 0,
                orderType: "New",
                salesOrderItem: [],
                commission: [],
                revenueArrangement: [],
                orderDate: Utils.formatDate(moment().toDate())
            });
            newstate.salesOrders = newstate.salesOrders.slice();
            return newstate;
        case "SELECT_ORGANIZATION":
            newstate = selectOrganization(newstate, action);
            return newstate;
        case "UPDATE_ORGANIZATION":
            newstate = updateOrganization(newstate, action);
            return newstate;

        case "SALES_ORDER":
            if (action.status == "success") {
                if (!newstate["salesOrder_searchParams"])
                    newstate["salesOrder_searchParams"] = {};

                newstate["salesOrder_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["salesOrder_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;

        case "DIRECT_EXPENSES":
            if (action.status == "success") {
                if (!newstate["directExpenses_searchParams"])
                    newstate["directExpenses_searchParams"] = {};

                newstate["directExpenses_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["directExpenses_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;

        case "INDIRECT_EXPENSES":
            if (action.status == "success") {
                if (!newstate["inDirectExpenses_searchParams"])
                    newstate["inDirectExpenses_searchParams"] = {};

                newstate["inDirectExpenses_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["inDirectExpenses_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "CURRENT_SALES_ORDER":
        case "CURRENT_CUSTOMER":
            if (action.status == "success") {
                newstate["currency"] =
                    (action.response && action.response.currency) ||
                    CurrencyType["USD"];

                return newstate;
            }

            return state;
        case "SYNC":
            if (action.status == "success") {
                if (!newstate["sync_searchParams"])
                    newstate["sync_searchParams"] = {};

                newstate["sync_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["sync_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "STANDALONE_SELLING_PRICE":
            if (action.status == "success") {
                if (!newstate["standaloneSellingPrice_searchParams"])
                    newstate["standaloneSellingPrice_searchParams"] = {};

                newstate["standaloneSellingPrice_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["standaloneSellingPrice_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "EXPENSE_ACCOUNTING":
            if (action.status == "success") {
                if (!newstate["expenseAccounting_searchParams"])
                    newstate["expenseAccounting_searchParams"] = {};

                newstate["expenseAccounting_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["expenseAccounting_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "MISSING_STANDALONE_SELLING_PRICE":
            if (action.status == "success") {
                if (!newstate["missingStandaloneSellingPrice_searchParams"])
                    newstate["missingStandaloneSellingPrice_searchParams"] = {};

                newstate["missingStandaloneSellingPrice_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["missingStandaloneSellingPrice_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "PROGRESS":
            if (action.status == "success") {
                if (!newstate["progress_searchParams"])
                    newstate["progress_searchParams"] = {};

                newstate["progress_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["progress_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }
        case "CUSTOMER_CONTRACTS":
            if (action.status == "success") {
                if (!newstate["customerContracts_searchParams"])
                    newstate["customerContracts_searchParams"] = {};

                newstate["customerContracts_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["customerContracts_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;

        case "CUSTOMER":
            if (action.status == "success") {
                if (!newstate["customer_searchParams"])
                    newstate["customer_searchParams"] = {};

                newstate["customer_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["customer_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "FOREX_EXCHANGE":
            if (action.status == "success") {
                if (!newstate["forexExchange_searchParams"])
                    newstate["forexExchange_searchParams"] = {};

                newstate["forexExchange_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["forexExchange_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;

        case "JOURNAL_ACCOUNT_MAPPING":
            if (action.status == "success") {
                if (!newstate["journalAccountMappings_searchParams"])
                    newstate["journalAccountMappings_searchParams"] = {};

                newstate["journalAccountMappings_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["journalAccountMappings_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "PRODUCT":
            if (action.status == "success") {
                if (!newstate["product_searchParams"])
                    newstate["product_searchParams"] = {};

                newstate["product_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["product_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;
        case "CUSTOMER_INVOICES":
            if (action.status == "success") {
                if (!newstate["customerInvoices_searchParams"])
                    newstate["customerInvoices_searchParams"] = {};

                newstate["customerInvoices_searchParams"].cursor =
                    action.response && action.response.cursor;
                newstate["customerInvoices_searchParams"].count =
                    action.response && action.response.count;

                return newstate;
            }

            return state;

        case "ORGANIZATION_USERS_INVALIDATE":
            return Object.assign({}, initialState);
        default:
            return state;
    }

    return state;
};

const selectOrganization = (newstate, action) => {
    let organizations = newstate.organizations;
    organizations = organizations.map((org) => {
        if (org.id == action.value.id)
            org.isSelected = org.isSelected ? false : true;
        else org.isSelected = false;
        return org;
    });
    newstate.organizations = organizations;
    return newstate;
};

const updateOrganization = (newstate, action) => {
    let organizations = newstate.organizations;
    const updatedOrg = action.value;
    organizations = organizations.map((org) => {
        if (org.id == updatedOrg.id) {
            org = Object.assign({}, updatedOrg);
        }
        return org;
    });
    // organizations = organizations.map(org => Object.assign({}, org))
    newstate.organizations = organizations;
    return newstate;
};

export default data;
