import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import WebFont from 'webfontloader';
import store from './redux/ReduxStore';

import App from './app.component';
import {unregister} from './registerServiceWorker';
import GA from './utils/GoogleAnalytics';

import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.common.css';
import './assets/css/dx.material.revrec-scheme.css';

const _theme = {
    container: {
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#212529',
        textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '15px',
        display: 'flex',
        flex: 1,
        height: '100%'
    }
};

WebFont.load({
    google: {
        families: ['Roboto:300,400,400i,500,600,700']
    }
});

ReactDOM.render(
    <Provider store={store}>
        <Router>
            {GA.init() && <GA.RouteTracker/>}
            <App theme={_theme}/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

unregister();
