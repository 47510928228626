import { Period } from "revlock-webutils";
import FullMonthRatablePlan from "./plan/FullMonthRatablePlan";
import Modified30_360Plan from "./plan/Modified30_360Plan";
import FixedMonthRatablePlan from "./plan/FixedMonthRatablePlan";
import DailyRatablePlan from "./plan/DailyRatablePlan";
import ApportionedRatablePlan from "./plan/ApportionedRatablePlan";
import ItemType from "./reference/ItemType";

/**
 * Validate that the given plan is valid
 *
 * @param plan
 */
export function assertValidPlan(plan) {
    for (let p of plan) {
        if (!p || !p.actgPeriod || isNaN(p.planAmount)) {
            throw new Error("Created an invalid plan");
        }
    }
}

/**
 * Validate that the provided revenue arrangement is valid
 *
 * @param ra
 * @param type
 */
export function assertValidArrangement(ra, type) {
    for (let item of ra.revenueArrangementItem) {
        if (item.plan) {
            if (!Array.isArray(item.plan)) {
                throw new Error("No valid plan");
            }
            for (let p of item.plan) {
                if (!p.period && !p.actgPeriod) {
                    throw new Error("Invalid Revenue Arrangement");
                }

                if (type === "w") {
                    if (p.actgPeriod) {
                        throw new Error("Invalid Revenue Arrangement");
                    }

                    if (!p.period) {
                        throw new Error("Invalid Revenue Arrangement");
                    }
                }
                if (type === "r") {
                    if (!p.actgPeriod?.period) {
                        throw new Error("Invalid Revenue Arrangement");
                    }
                }
            }
        }
    }
}

export function getAutoCreateSSP(orgConfig) {
    const autoCreateSSPConfig = orgConfig.find(
        (config) => config.id === "properties/auto-create-ssp"
    );
    const autoCreateSSP =
        (autoCreateSSPConfig && autoCreateSSPConfig.value) || false;

    return autoCreateSSP;
}

export function getEndPeriodUnPlannedAmount(
    plan,
    startDate,
    endDate,
    actualServiceEndDate,
    orgConfig,
    calendarConfig
) {
    const d2 = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive = (d2 && d2.value) || false;

    if (startDate == endDate) {
        // same day, no revenue
        return 0;
    }

    const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig);
    const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig);

    let totalPlanAmount = 0;
    // STEP 1: Calculate total plan amount
    plan.forEach((p) => {
        totalPlanAmount += p.planAmount;
    });

    // STEP 2: Calculate total plan amount with new end date
    if (startActgPeriod.period === endActgPeriod.period) {
        // write off in same month as service start
        const revenueDays = Period.daysBetween(
            startDate,
            actualServiceEndDate,
            true,
            endDateInclusive
        );
        const perDayRevenue = totalPlanAmount / revenueDays;
        const newRevenueDays = Period.daysBetween(
            startDate,
            endDate,
            true,
            endDateInclusive
        );
        const planNewAmount = perDayRevenue * newRevenueDays;

        return totalPlanAmount - planNewAmount;
    }

    let totalNewPlanAmount = 0;
    const actualEndActgPeriod = Period.toActgPeriod(
        actualServiceEndDate,
        calendarConfig
    );
    for (const p of plan) {
        // we will only iterate until last period
        if (p.actgPeriod.period === endActgPeriod.period) {
            let actualEndDays = 0;
            if (actualEndActgPeriod.period == endActgPeriod.period) {
                actualEndDays = Period.daysBetween(
                    actualEndActgPeriod.startDate,
                    actualServiceEndDate,
                    true,
                    endDateInclusive
                );
            } else {
                actualEndDays = p.actgPeriod.numberOfDays(p.actgPeriod.period);
            }

            let perDayRevenue = 0;
            if (actualEndDays !== 0)
                perDayRevenue = p.planAmount / actualEndDays;

            const newRevenueDays = Period.daysBetween(
                endActgPeriod.startDate,
                endDate,
                true,
                endDateInclusive
            );

            totalNewPlanAmount += perDayRevenue * newRevenueDays;

            break;
        } else {
            totalNewPlanAmount += p.planAmount;
        }
    }

    return totalPlanAmount - totalNewPlanAmount;
}

export function buildPlanByType(
    planType,
    orgConfig,
    startDate,
    endDate,
    originalBooking,
    calendarConfig,
    term
) {
    const d2 = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive = (d2 && d2.value) || false;

    let plan;
    if (planType === "monthly_endmonth_exclusive") {
        plan = FullMonthRatablePlan.buildPlan(
            startDate,
            endDate,
            originalBooking,
            calendarConfig
        );
    } else if (planType === "fixed") {
        const d3 = orgConfig.find(
            (config) => config.id === "properties/ratable_start_exact_days"
        );
        const exactDays = (d3 && d3.value) || false;

        if (exactDays) {
            plan = Modified30_360Plan.buildPlan(
                startDate,
                endDate,
                originalBooking,
                endDateInclusive,
                calendarConfig,
                term
            );
        } else {
            plan = FixedMonthRatablePlan.buildPlan(
                startDate,
                endDate,
                originalBooking,
                endDateInclusive,
                exactDays,
                calendarConfig
            );
        }
    } else if (planType === "daily") {
        plan = DailyRatablePlan.buildPlan(
            startDate,
            endDate,
            originalBooking,
            endDateInclusive,
            calendarConfig
        );
    } else {
        plan = ApportionedRatablePlan.buildPlan(
            startDate,
            endDate,
            originalBooking,
            endDateInclusive,
            calendarConfig
        );
    }

    return plan;
}

export function buildPlan(
    revenueArrangementItem,
    salesOrder,
    orgConfig,
    productRevenue,
    calendarConfig,
    customCode
) {
    let startDate = revenueArrangementItem.deliveryStartDate;

    let endDate =
        revenueArrangementItem.extendedDeliveryEndDate ||
        revenueArrangementItem.deliveryEndDate;
    let originalBooking = productRevenue;
    // delayed revenue is true our plan is 0 (in modification this will be adjustment)

    const d1 = orgConfig.find(
        (config) => config.id === "properties/ratable_plan_type"
    );
    const planType = (d1 && d1.value) || "default";

    const customPlanConfig = orgConfig.find(
        (config) => config.id === "properties/custom-code/plan-name"
    );

    const customPlanName = customPlanConfig && customPlanConfig.value;

    const d2 = orgConfig.find(
        (config) => config.id === "properties/ratable_end_date_inclusive"
    );
    const endDateInclusive = (d2 && d2.value) || false;

    const d3 = orgConfig.find(
        (cfg) => cfg.id === "properties/max-contract-years"
    );
    const maxContractYears = (d3 && d3.value && Number(d3.value)) || 20;

    if (calendarConfig && calendarConfig.type === "retail") {
        if (planType !== "daily")
            throw new Error(
                "Retail calendar can only be used with a daily plan!"
            );
    }

    let plan;
    if (planType === "custom") {
        const buildPlan = customCode[customPlanName];
        // let's call custom plans
        plan = buildPlan(
            startDate,
            endDate,
            originalBooking,
            endDateInclusive,
            calendarConfig,
            revenueArrangementItem
        );

        assertValidPlan(plan);
    } else {
        let term;
        if (revenueArrangementItem.salesOrderItem) {
            term = revenueArrangementItem.salesOrderItem.term;
        }
        plan = buildPlanByType(
            planType,
            orgConfig,
            startDate,
            endDate,
            originalBooking,
            calendarConfig,
            term
        );
    }

    plan =
        plan.length > 12 * maxContractYears
            ? plan.slice(0, 12 * maxContractYears)
            : plan;
    //do not support more than maxContractYears years

    assertValidPlan(plan);

    return plan;
}

export function prefillZeroAtStart(plan, salesOrder, calendarConfig) {
    if (plan.length === 0) return;

    const firstPlanActgPeriod = plan[0].actgPeriod;

    const arrangementEffectivePeriod = Period.toActgPeriod(
        salesOrder.revenueArrangement[0].effectiveDate,
        calendarConfig
    );

    if (arrangementEffectivePeriod.period >= firstPlanActgPeriod.period) return;

    const periods = Period.periodsBetween(
        arrangementEffectivePeriod.period,
        firstPlanActgPeriod.period,
        true
    );

    if (periods.length === 0) return;

    let toAdd = [];
    let actgPeriod = firstPlanActgPeriod;
    for (let period of periods) {
        actgPeriod = actgPeriod.get(period);

        toAdd.push({
            actgPeriod,
            planAmount: 0
        });
    }

    plan.unshift(...toAdd);

    assertValidPlan(plan);
}

export function calculatePlanAmount(itemPlan, productRevenue, baseRAI) {
    if (!baseRAI.plan) {
        return [];
    }
    const baseItemProductRevenue = baseRAI.productRevenue;
    let basePlan = {},
        newPlan = [];
    baseRAI.plan.forEach((plan) => {
        basePlan[plan.actgPeriod.period] = plan;
    });
    for (let plan of itemPlan) {
        const { planAmount } = basePlan[plan.actgPeriod.period];
        plan["planAmount"] =
            (planAmount / baseItemProductRevenue) * productRevenue;
        newPlan.push(plan);
    }
    return newPlan;
}
