import ComponentType from './reference/ComponentType';
import _ from 'lodash';

/**
 * Generates journal entries for the list of transactions for the component type
 * and sets them as an attribute using the jeKey on the salesOrder object.
 * @param {*} salesOrder The salesOrder object to generate order transaction entries for.
 * @param {*} journalAccounts List of journal accounts to book to.
 * @param {*} transactions The transactions, output of the postingJob query.
 * @param {*} jeMappingColumns The JE mapping columns for this component type.
 * @param {*} jeKey The name of the attribute to set on the salesOrder object whose
 * value is the list of journal entries generated.
 */
export function generateJournalEntries(salesOrder,
                                        journalAccounts,
                                        transactions,
                                        jeMappingColumns,
                                        jeKey) {
    const journalEntries = [];

    const defaultAccounts = {};
    journalAccounts.forEach(account => {
        if (account.isDefault) {
            defaultAccounts[account.accountType] = account;
        }
    });

    transactions.forEach(transaction => {
        if (transaction.componentType != ComponentType.ACCOUNT_RECEIVABLE &&
            transaction.componentType != ComponentType.UNEARNED_REVENUE &&
            transaction.componentType != ComponentType.EXPENSE &&
            (transaction.IsActive == undefined || transaction.IsActive == true)) {
            // Create and push journal entries from the transaction.
            journalEntries.push(
                ...createEntries(transaction, defaultAccounts, jeMappingColumns));
        }
    });

    salesOrder[jeKey] = journalEntries;
}

function createEntries(reportingTransaction, defaultAccounts, jeMappingColumns) {
    const journalEntries = [];

    if (reportingTransaction.Amount != 0) {
        // Build base journal mapping value.
        const baseJournalMappingValues = [];

        for (const jeMappingColumn of jeMappingColumns) {
            baseJournalMappingValues.push(
                reportingTransaction[jeMappingColumn] || "JEMINVOICE");
        }

        if (baseJournalMappingValues.length == 0) {
            baseJournalMappingValues.push("JEMINVOICE");
        }

        const baseJournalMappingValue = baseJournalMappingValues.join(':=:');

        let amount = reportingTransaction.Amount;
        let inverse = amount < 0;
        amount = Math.abs(amount);

        let creditAccount =
                defaultAccounts["Tax Payable"] || { accountType: 'Tax Payable' };
        let debitAccount =
                defaultAccounts["Cash Tax"] || { accountType: 'Cash Tax' };

        if (inverse) {
            [debitAccount, creditAccount] = [creditAccount, debitAccount];
        }

        let baseEntry = Object.assign({}, _.pick(reportingTransaction, [
            'OrgId',
            'Actg Period',
            'Sales Order Id',
            'Linked Sales Order Id',
            'Sales Order Item Id',
            'Sales Order Item Root Id',
            'Customer Id',
            'Product Id',
            'Job Id',
            'IsActive',
            'Active Link Id',
            'Transaction Date',
            'Currency',
            'Home Currency',
            'IsAdjustment'
        ]), {
            'Transaction_Id': reportingTransaction.Id,
            'Component Type': reportingTransaction.componentType,
            'Transaction Type': reportingTransaction.transactionType,
            'Amount': reportingTransaction.Amount,
            'SOI Version Item Id': reportingTransaction['Sales Order Item Id'],
            'Link Id': '\\N',
            // Ensures that the AdjustmentType is NULL and not empty string
            // if its not specified.
            'AdjustmentType':
                reportingTransaction.isAdjustment == 1
                    ? reportingTransaction['AdjustmentType']
                    : '\\N',
            // These are for expense only but if we don't set them as follows
            // then they end up in JE table as empty strings instead of NULL
            'Reference Number': '\\N',
            'Expense Type': '\\N',
            'Expense Code': '\\N',
            'Class': '\\N',
            'Department': '\\N',
            'Location': '\\N',
            'Sales Person': '\\N'
        });

        const debitEntry = Object.assign({
            "Account Id": debitAccount && debitAccount.id,
            "Account Type": debitAccount && debitAccount.accountType,
            "Debit Amount": amount,
            "Credit Amount": 0,
            "Net Amount": amount
        }, baseEntry, {
            "Journal Mapping Key":
                baseJournalMappingValue.length > 0
                    ? baseJournalMappingValue + ":=:" + debitAccount.accountType
                    : debitAccount.accountType
        });

        const creditEntry = Object.assign({
            "Account Id": creditAccount && creditAccount.id,
            "Account Type": creditAccount && creditAccount.accountType,
            "Debit Amount": 0,
            "Credit Amount": -1 * amount,
            "Net Amount": -1 * amount
        }, baseEntry, {
            "Journal Mapping Key":
                baseJournalMappingValue.length > 0
                    ? baseJournalMappingValue + ":=:" + creditAccount.accountType
                    : creditAccount.accountType
        });

        journalEntries.push(debitEntry, creditEntry);
    }

    return journalEntries;
}
