import {createStore, applyMiddleware} from 'redux';
import logger from 'redux-logger';
import staticReducers from './reducers';

var pkgConfig = require("../../package.json");

const isDevelopment = pkgConfig.config.environment != "prod";


function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers)
    const finalReducers = {}
    for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i]

        if (typeof reducers[key] === 'function') {
            finalReducers[key] = reducers[key]
        }
    }
    const finalReducerKeys = Object.keys(finalReducers)


    return function combination(state = {}, action) {
        let hasChanged = true;
        const nextState = Object.assign({}, state);
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i]
            const reducer = finalReducers[key]
            const previousStateForKey = state[key]

            const nextStateForKey = reducer(previousStateForKey, action, nextState);

            if (typeof nextStateForKey === 'undefined') {
                const errorMessage = "Undefined state returned!";
                throw new Error(errorMessage)
            }

            nextState[key] = nextStateForKey

            hasChanged = hasChanged || nextStateForKey !== previousStateForKey
        }

        return hasChanged ? nextState : state
    }
}

function createReducer(asyncReducers) {
    const appReducer = combineReducers({
        ...staticReducers,
        ...asyncReducers
    })

    const rootReducer = (state, action) => {
        if (action.type === 'SIGN_OUT') {
            state = undefined;
        }
        return appReducer(state, action);
    }

    return rootReducer;
}


const reduxStore = isDevelopment ?
    createStore(createReducer(), applyMiddleware(logger)) :
    createStore(createReducer());

// Add a dictionary to keep track of the registered async reducers
reduxStore.asyncReducers = {}

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
reduxStore.newEntity = (key, asyncReducer) => {
    reduxStore.asyncReducers = Object.assign(reduxStore.asyncReducers, asyncReducer)
    reduxStore.replaceReducer(createReducer(reduxStore.asyncReducers))
}

export default reduxStore;
