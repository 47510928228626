import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import {connect} from 'react-redux';
import { compose } from 'utils/WebUtils';
import {MySnackbarContentWrapper} from '../components/CustomizedSnackbars';
import {withRouter} from 'react-router-dom';
import { setData2, setTabIndex } from 'redux/actions/data.actions'
import { getOrgConfigurations } from 'redux/selectors/data.selector';

import store from "../redux/ReduxStore";
import {POST} from '../rest/API';

import EntitySelectors, { currentOrganization } from 'redux/selectors'
import EntityActions from '../redux/actions';

import {RefreshRounded} from '@material-ui/icons';

import $ from 'jquery';
import * as WebUtils from "utils/WebUtils";

const jQuery = $.noConflict();

const salesOrderActions = EntityActions.salesOrder;
const notificationSelectors = EntitySelectors.notification;
const notificationActions = EntityActions.notification;
const revenueSummaryActions = EntityActions.revenueSummary;
const commissionSummaryActions = EntityActions.commissionSummary;
const missingStandaloneSellingPriceActions = EntityActions.missingStandaloneSellingPrice

const notificationMetadata = {
    SALES_ORDERS_LOADING: {
        severity: () => 'success',
        actions: [
            {
                icon: RefreshRounded,
                label: `Refresh`,
                color: 'inherit',
                onClick: function () {
                    salesOrderActions.setSearchParams({});
                    revenueSummaryActions.invalidate();
                    commissionSummaryActions.invalidate();
                    store.dispatch({
                        type: 'NOTIFICATION',
                        status: 'invalid',
                    });
                }
            }]
    },
    TRIGGER_REPROCESS_SALES_ORDER: {
        severity: () => 'warning',
        actions: [
            {
                label: `PROCESS NOW!`,
                onClick: async function () {

                    const ok = await WebUtils.showAlert({
                        title: 'Warning',
                        text: `Are you sure you want to process orders effected by rule changes?`,
                        buttons: true,
                        icon: 'warning'
                    })
            
                    if (!ok) {
                        return
                    }

                    this.setState({})

                    notificationActions.onEntityTakeAction((res, err) => {

                        if (err) {
                            err.text = err.text || "An internal error has occurred while triggering sales order processing, please contact administrator."
                            err.title = err.title || 'Something broke..';

                            WebUtils.showAlert({
                                title: err.title,
                                text: err.text,
                                icon: "error",
                                buttons: true,
                            });
                        } else {
                            WebUtils.showAlert({
                                title: "success",
                                text: `Sales order processing started!`,
                                icon: "success",
                                timer: 2000
                            });

                            setTimeout(() => {
                                store.dispatch({
                                    type: 'NOTIFICATION',
                                    status: 'invalid',
                                });
                            }, 2000);
                        }

                    }, {}, {url: `reprocess-sales-orders`, method: POST}, false)
                }
            }
        ]
    },
    MISSING_SSP_RULES: {
        severity: () => 'warning',

        actions: [
            {
                label: `Fix Rules`,
                color: 'Red',
                onClick: function () {
                    const {history, expenseFeatureEnabled} = this.props
                    const url = `/${this.props.match.params.orgId}/esp`;
                    missingStandaloneSellingPriceActions.invalidate()
                    history.push(url);

                    const tabIndex = expenseFeatureEnabled ? 2: 1
                    setTabIndex('sspSearchTabs', tabIndex);
                }
            }
        ]
    },
    DEFAULT: {
        severity: notification => notification.status == 'Failed' ? 'error' : 'success'
    }
}

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(2),
        "&:empty": {
            display: 'none'
        },
    }
});

class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}

        this.actions = this.actions.bind(this);
    }

    actions(metadata, notification) {
        let self = this;
        let actions = metadata.actions && metadata.actions.map(action => {
            action.onClick = action.onClick.bind(self)
            return action;
        })

        if (notification.status === 'In Progress') {
            if (!actions) actions = []
            actions.push({
                label: `Refresh`,
                icon: RefreshRounded,
                color: 'inherit',
                onClick: function () {
                    const {props} = self;
                    props && props.invalidateNotification();

                    salesOrderActions.setSearchParams({});
                    revenueSummaryActions.invalidate();
                    commissionSummaryActions.invalidate();
                }
            })
        } else if (notification.status === 'Failed') {
            if (!actions) actions = []

            actions.push({
                label: `Contact Support`,
                color: 'None',
                onClick: function () {
                    window.open('mailto:revrec-support@chargebee.com')
                }
            })

        }

        actions = actions && actions.filter((a)=>a.onStatus? a.onStatus === notification.status: true)
        //filter out actions that are only relevant for a particular status type

        return actions;
    }

    render() {
        const {classes, notification} = this.props;

        var hasNotification = notification && notification.name

        if (!hasNotification) {
            return <div className={classes.root}/>
        }

        const metadata = notificationMetadata[notification.name] || notificationMetadata['DEFAULT'];

        return (
            <div className={classes.root}>
                {<MySnackbarContentWrapper
                    variant={metadata.severity(notification)}
                    message={notification.message}
                    actions={this.actions(metadata, notification)}
                    onClose={() => {

                        notificationActions.delete(undefined, [notification.name], true)
                    }}
                />}
            </div>
        );
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        invalidateNotification: () => dispatch({
            type: 'NOTIFICATION',
            status: 'invalid',
        })
    }
}

function mapStateToProps(state) {
    const orgConfig = getOrgConfigurations(state) | []
    const expenseFeatureConfig = orgConfig && orgConfig.find(cfg => cfg.id == 'properties/expense-enabled')
    const expenseFeatureEnabled = expenseFeatureConfig && expenseFeatureConfig.value || false
    return {
        notification: notificationSelectors.selectAll(state),
        currentOrganization: currentOrganization(state),
        expenseFeatureEnabled
    };
}

export default compose(
    withRouter,
    withStyles(styles, {withTheme: true}),
    connect(mapStateToProps, mapDispatchToProps)
)(Notification);

