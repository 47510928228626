import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import { fade } from '@material-ui/core';
// import { red } from '@material-ui/core/colors';

const defaultContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto',
    fontSize: 14,
    fontWeight: 500,
    subheading: {
      fontSize: '14px'
    },
    heading1: {
      size: 16,
      color: '#012a38',
      weight: 600
    },
    heading2: {
      color: '#6e7a87',
      size: 12,
      weight: 400
    },
  },
  palette: {
    type: 'light',
    primary: {
      // main:'#8e5dcb'
      main: '#012a38'
    },
    
    // secondary: orange,
    secondary: {
      main: '#9B1817'
      // main:'#f7cfbd'
    },
    success: {
      main: '#96d48a'
    },
    background: {
      default: '#FFF',
      box: '#f4f5f7',
      rl: 'rgb(234, 234, 244)',
      error: fade(orange[500], .6),
      button: {
        main: '#012a38',
        light: '#3d2b6a'
      },
      text: {
        main: '#b5def0',
        secondary: '#6e7a87'
      },
      link: {
        main: '#4da8d5',
        light: '#2d6a8a'
      },
      primary: {
        50: '#e5f3fa',
        100: '#b5def0',
        200: '#8ccce6',
        300: '#68badf',
        400: '#4da8d5',
        500: '#3d8cb8',
        600: '#2d6a8a',
        700: '#1d485f',
        800: '#012a38',
        900: '#040f13'
      },
      info: {
        50: '#eff5f5',
        100: '#d0e0e1',
        200: '#b1cbce',
        300: '#92b6ba',
        400: '#73a1a6',
        500: '#59888c',
        600: '#456a6d',
        700: '#314b4e',
        800: '#1e2d2f',
        900: '#0a0f10'
      },
      neutral: {
        50: '#f4f5f7',
        100: '#eaecee',
        200: '#d6dadf',
        300: '#bac1c9',
        400: '#96a0ac',
        500: '#6e7a87',
        600: '#4d5966',
        700: '#374049',
        800: '#21262c',
        900: '#0b0d0f'
      },
      orange: {
        50: '#FEF5E5',
        100: '#FEECCC',
        200: '#FDD499',
        300: '#F9B566',
        400: '#F49640',
        500: '#ED6704',
        600: '#CB4C02',
        700: '#AE4503',
        800: '#8F4203',
        900: '#753101'
      },
      red: {
        50: '#fce9e9',
        100: '#f5bdbc',
        200: '#ee9190',
        300: '#e86564',
        400: '#e13837',
        500: '#c81f1e',
        600: '#9b1817',
        700: '#6f1111',
        800: '#430a0a',
        900: '#160303'
      },
      green: {
        50: '#ecf9ee',
        100: '#c5eccd',
        200: '#9fe0ac',
        300: '#78d38a',
        400: '#52c769',
        500: '#38ad4f',
        600: '#297f3a',
        700: '#1f602c',
        800: '#133a1a',
        900: '#061309'
      },
      dockedMenu: '#012a38'
    },
    info: lightBlue,
    error: {
      50: '#fce9e9',
      100: '#f5bdbc',
      200: '#ee9190',
      300: '#e86564',
      400: '#e13837',
      500: '#c81f1e',
      600: '#9b1817',
      700: '#6f1111',
      800: '#430a0a',
      900: '#160303'
    }
  },
  status: {
    danger: 'orange'
  },
  shape: {
    borderRadius: 5
  },
  overrides: {
    MuiDialog: {
      paper: {
        maxWidth: '80%',
        margin: 0,
        padding: 0,
        borderRadius: 10,
        overflow: 'hidden',
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000'
      }
    },
    MuiTypography: {
      colorTextSecondary:{
        color: '#6e7a87'
      },
      h6: {
        fontSize: '1.15rem',
        fontWeight: 400
      },
      h5: {
        fontSize: 15,
        fontWeight: 500,
      },
      h4: {
        fontSize: 22,
        fontWeight: 600,
        fontFamily: "font-family: basis-bold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif"
      },
      body1: {
        fontSize: 14,
        color: "#4d5966",
        fontWeight: 600,
        fill: '#4d5966',
        outlineColor: '#4d5966',
        textDecorationColor: '#4d5966',
      },
      body2: {
        fontSize: 14,
        color: '#6e7a87',
        fontWeight: 600,
        fill: '#6e7a87',
        outlineColor: '#6e7a87',
        textDecorationColor: '#6e7a87',
      },
      subtitle1: {
        fontSize: 14,
        color: '#6e7a87',
        fontWeight: 500,
        fill: '#6e7a87',
        outlineColor: '#6e7a87',
        textDecorationColor: '#6e7a87'
      },
      subtitle2: {
        fontSize: 12,
        color: '#6e7a87',
        fontWeight: 400,
        fill: '#6e7a87',
        outlineColor: '#6e7a87',
        textDecorationColor: '#6e7a87'
      }
    },
    MuiExpansionPanel: {
      root: {
        margin: '16px 0'
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#f4f5f7'
      }
    },
    MuiExpansionPanelActions: {
      root: {
        padding: '16px 24px'
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: '-apple-system, "system-ui", "Segoe UI", Roboto'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 4,
        '&:hover': {
          borderColor: purple[500]
        }
      },
      input: {
        padding: '12px 12px'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 14px) scale(1)',
        fontSize: '15px'
      }
    },
    MuiGrid: {
      root: {
        padding: '6px'
      }
    },
    MuiButton: {
      root: {
        padding: '4px 14px',
        borderRadius: 4,
        textTransform: "none",
      },
      outlinedPrimary: {
        border: '#012a38 1px solid',
        background: '#eff5f5',
        color: '#456a6d',
        '&:hover': {
          backgroundColor: '#012a38',
          color: 'white'
        }
      },
      containedPrimary: {
        color: 'white'
      },

    },
    MuiIconButton: {
      sizeSmall: {
        padding: 8
      },
      root: {
        '&:hover': {
          backgroundColor: fade('#2d6a8a', .08),
          '& svg': {
          }
        }
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
      },
      rounded: {
        borderRadius: 10
      }
    },
    MuiTableCell: {
      root: {
        fontSize: 12,
        fontWeight: 400
      },
      head: {
        fontWeight: 600
      }
    }
  },
  dialog: {
    borderRadius: '10px'
  }
};



const themes = [
  {
    id: 'classic-revrec',
    name: 'Classic - RevRec',
    theme: {
      contentTheme: defaultContentTheme,
      sidenavTheme: defaultContentTheme
    }
  }
];

export default themes;
