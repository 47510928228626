import React from 'react';
import PropTypes from 'prop-types';

// Material components
import { withStyles } from "@material-ui/core/styles"

import themeStyles from './layout-loader.theme.style';
const LayoutLoader = (props) => {
    const {
        classes, width, height
    } = props;
                 
    const src = "https://assets1.lottiefiles.com/packages/lf20_BYd1ZjQ95w.json" 
    //https://cdn.rev-lock.com/images/graph.json
    // "https://cdn.rev-lock.com/revenue-graph.json"
    //'https://asset6.lottiefiles.com/packages/lf20_3ceaXy.json';

    const w = 300;
    const h = 300;

    return (<div className={classes.loaderWrapper}>
        <lottie-player
            src={src}
            background="transparent"
            speed="1"
            style={{ width: width || w, height: height || h, color: 'red' }}
            loop 
            autoplay
        >
        </lottie-player>
    </div>)
};

LayoutLoader.propTypes = {
    classes: PropTypes.shape({}).isRequired
};


export default withStyles(themeStyles, { withTheme: true })(LayoutLoader);
