import RemoteEntityReducer from "../RemoteEntityReducer";


const entityReducer = new RemoteEntityReducer((state, action) => {
  if (!action.response)
    throw new Error("Cannot reduce with no user");

  state.tasks = action.response;

  return Object.assign({}, state);
});

const main = (state = {}, action) => {
  const newstate = Object.assign({}, state);

  switch (action.type) {
    case "ALL_TASKS":
      return entityReducer.doReduction(newstate, action);
    default:
      return state;
  }
};

export default main;
