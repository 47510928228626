import {Auth} from "aws-amplify";
import revlockConfig from "./config";

const debug = false;


const {AWS, AWS_Exports, development} = revlockConfig;

if (AWS.API && (development || !revlockConfig.isCloudDeployment))
    AWS.API.endpoints[0].custom_header = async () => {
        const user = await Auth.currentAuthenticatedUser();
        return {
            "X-User-Id": user.username
        };
    };

const awsAmplifyConfig = {...AWS, ...AWS_Exports};

if (debug)
    console.log("AWS Amplify Config", JSON.stringify(awsAmplifyConfig));

export default awsAmplifyConfig;
