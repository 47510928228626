const styles = (theme) => ({
    wrapper: {
        color: theme.palette.text.primary,
        backgroundColor: "rgb(234, 234, 244)"
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    fab: {
        position: "absolute",
        top: theme.spacing(20),
        opacity: 0.4,
        right: 0,
        zIndex: 10000,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        "&:hover": {
            opacity: 1
        }
    },
    alertMessage: {
        width: "100%"
    },
    warning: {
        color: "#856404",
        backgroundColor: "#fff3cd",
        borderColor: "#ffeeba",
        alignItems: "center"
    },
    info: {
        color: "#004085",
        backgroundColor: "#cce5ff",
        borderColor: "#b8daff"
    },
    linkCss: {
        fontSize: 13,
        color: "#000",
        textDecoration: "underline"
    }
});

export default styles;
