import RemoteEntityReducer from "../RemoteEntityReducer";

const salesOrderSearchEntityReducer = new RemoteEntityReducer((state, action) => {
  if (!action.response)
    throw new Error("Cannot reduce with no data");

  // console.log(action.response);

  state.data = Array.isArray(action.response) ? action.response : [action.response];

  return Object.assign({}, state);
});

const main = (state = {}, action) => {
  const newstate = Object.assign({}, state);

  switch (action.type) {
    case "SET_DATA":
      if (action.name == "salesOrderSearchParams") {
        newstate.data = undefined;
        return newstate;
      }

      return state;


    case "SALES_SEARCH":
      return salesOrderSearchEntityReducer.doReduction(newstate, action);

    default:
      return state;
  }
};


export default main;
