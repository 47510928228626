import connect from "react-redux/es/connect/connect";
import { currentOrganization } from "redux/selectors";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Slide,
    Typography,
    Grid
} from "@material-ui/core";
import { CloseRounded as CloseIcon } from "@material-ui/icons";
import React from "react";
import * as WebUtils from "../utils/WebUtils";
import { extendTrial } from "../rest/API";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ExtendTenantExpire(props) {
    const { currentOrganization } = props;
    const [open, setOpen] = React.useState(true);
    const [working, setWorking] = React.useState(false);

    const handleClose = async () => {
        setOpen(false);
    };

    const extendTrialHandler = async () => {
        try {
            setWorking(true);

            await extendTrial(
                currentOrganization.id,
                currentOrganization.merchantId
            );

            setWorking(false);

            await WebUtils.showAlert({
                title: "Success",
                text: `Trial Extended Successfully`,
                icon: "info",
                timeout: 3000
            });
            window.location.reload();
        } catch (e) {
            setWorking(false);
            WebUtils.showError(e);
        }
    };

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={open}
                TransitionComponent={Transition}
                maxWidth={"md"}
            >
                <DialogTitle
                    style={{
                        padding: "24px 16px 0px 16px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            variant={"h4"}
                            color={"primary"}
                            style={{ paddingLeft: "10px" }}
                        >
                            Extend your trial period!!
                        </Typography>
                        <IconButton size={30} onClick={handleClose}>
                            <CloseIcon size={"small"} />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent
                    style={{
                        overflowY: "auto",
                        padding: "0px 16px 24px 16px"
                    }}
                >
                    {working && <LinearProgress />}
                    {!working && (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    color={"textSecondary"}
                                >
                                    We hope you've had a chance to explore
                                    RevRec during your trial and discover how it
                                    can streamline revenue recognition for your
                                    business.
                                    <br />
                                    Your RevRec trial is going to expire in{" "}
                                    {
                                        currentOrganization.license
                                            .daysRemaining
                                    }{" "}
                                    days. You are eligible for a one-time
                                    extension of your trial for an additional
                                    month. Click ‘Extend My Trial’ to activate
                                    your extension.”
                                    <br />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <Button
                                    id={"extendMyTrial"}
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    onClick={extendTrialHandler}
                                >
                                    Extend My Trial
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        currentOrganization: currentOrganization(state)
    };
};

export default connect(mapStateToProps)(ExtendTenantExpire);
