import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { setData2 } from "redux/actions";
import { getPlan, getUser } from "../utils/auth";
import { SecurityUtils } from "revlock-webutils";
import { currentOrganization } from "redux/selectors";
import { connect } from "react-redux";
import { compose } from "utils/WebUtils";

const useStyles = makeStyles((theme) => ({
    hidden: {
        opacity: 0.4,
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        zIndex: 10
    },
    main: {
        position: "relative",
        pointerEvents: "all",
        opacity: 1.0,
        zIndex: 20
    }
}));

function Overlay(props) {
    const { licenseLevel, currentOrganization } = props;
    const classes = useStyles();

    const plan = getPlan();

    const noPermission = React.useMemo(() => {
        if (
            SecurityUtils.getSuperAdminLevel(getUser()) >=
            SecurityUtils.SUPER_ADMIN_LEVEL_3
        ) {
            // Super admins have no restrictions.
            return false;
        } else if (currentOrganization?.suspended) {
            setData2("showSuspendedBanner", "true");
            return true;
        } else if (licenseLevel && plan) {
            const planLevel = plan && plan.level;
            return planLevel < licenseLevel;
        }
    }, [licenseLevel, plan, currentOrganization]);

    React.useEffect(() => {
        if (noPermission) {
            setData2("showUpgradeDialog", "plan");
        }
    }, [noPermission]);

    return noPermission ? (
        <div id={"overlay"} className={classes.hidden}>
            {props.children}
        </div>
    ) : (
        props.children
    );
}

function mapStateToProps(state) {
    const toReturn = {
        currentOrganization: currentOrganization(state)
    };
    return toReturn;
}

export default compose(connect(mapStateToProps))(Overlay);
