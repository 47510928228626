import React from 'react';
import { connect } from 'react-redux';
import { currentOrganization, userOrganizations } from './selectors/user.selector';
import { currentSalesOrderId } from './selectors/data.selector';
import EntityActions from './actions';
import EntitySelectors from './selectors';
import User from './actions/user.actions';
import UserActions from '../redux/actions/user.actions';
import {Utils} from 'revlock-webutils'

let salesOrderActions = EntityActions.salesOrder;
let syncActions = EntityActions.sync;
let syncSelectors = EntitySelectors.sync;


export function withUrlSync(ComposedComponent) {
    class ReduxUrlSync extends React.Component {
        render() {
            return <>
                <ComposedComponent {...this.props} />
            </>;
        }
    };

    function mapStateToProps(state, props) {
        const { orgId, salesOrderId, syncId } = props.match.params;
        const userOrgs = userOrganizations(state);

        if (!orgId || !userOrgs) return {};

        if (userOrgs && userOrgs.length == 0) {
            UserActions.signOut();
        }

        let org = currentOrganization(state);
        const isSalesOrderIdEncoded = Utils.isEncoded(salesOrderId);

        if (!isNaN(orgId) && (!org || org.id != orgId)) {
            org = userOrgs.find(o => o.id == orgId) || userOrgs[0]
            if (org) {
                User.setCurrentOrganization(org);

                if (orgId && org.id != orgId) {
                    // this should never happen unless someone tries to give a orgId in the url
                    // which he doesn't have access to.
                    // Redirecting them to the org on which they have access.

                    if (window.location.pathname != `/${org.id}`){
                        props.history.push('/');
                    }
                    //note that make sure you dont get into a loop here
                }
            } else {
                // no org loaded for user yet.
                UserActions.signOut();
            }
        }

        if (salesOrderId) {
            let _currentSalesOrderId = currentSalesOrderId(state);
            if (!_currentSalesOrderId || salesOrderId != _currentSalesOrderId) {
                salesOrderActions.setCurrentId(salesOrderId);
            }
        }

        if (syncId) {
            let _currentSyncId = syncSelectors.currentId(state);
            if (!_currentSyncId || syncId != _currentSyncId) {
                syncActions.setCurrentId(syncId);
            }
        }

        return {}

    }

    return connect(mapStateToProps)(ReduxUrlSync)
}
