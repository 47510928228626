import {
  REPORTS_LOGIN_AUTH
} from '../actions/reports.actions';


const reportsReducer = (reports = {}, action) => {
  switch (action.type) {
    case REPORTS_LOGIN_AUTH:
      return {
        authenticationToken: action.payload
      };

    default:
      return reports;
  }
};

export default reportsReducer;
