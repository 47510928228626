import {
    Grid,
    Box,
    Button,
    Typography,
    IconButton,
    Paper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import SSPProductDetails from "./SSPProductDetails";
import React from "react";
import { connect } from "react-redux";
import { compose, showAlert } from "utils/WebUtils";
import { getPriceAnalysis, runSSPAnalyzerJob } from "rest/API";
import { Utils } from "revlock-webutils";
import LoadingIndicator from "../../layouts/components/layout-loader/layout-loader.component";
import Process from "@material-ui/icons/PlayArrow";

import {
    currentOrganization,
    getOrgConfigurations,
    sspAnalyzerRefresh
} from "redux/selectors";

import {
    DataGrid,
    MasterDetail,
    Column,
    Pager,
    Paging,
    Sorting
} from "devextreme-react/ui/data-grid";

import { getCurrency } from "redux/selectors/data.selector";
import noDataImg from "./images/SSP.svg";
import { Refresh } from "@material-ui/icons";
import { isImpersonatedUser } from "utils/auth";
const { formatCurrency } = Utils;

const columnData = [
    {
        visible: true,
        dataField: "Product_Name",
        caption: "Products",
        renderer: (item, formatCurrency) => {
            return item.data["Product_Name"];
        }
    },
    {
        visible: true,
        dataField: "Count",
        caption: "Count",
        renderer: (item, formatCurrency) => {
            return item.data["Count"];
        }
    },
    {
        visible: true,
        dataField: "Normal_Min",
        caption: "Minimum Price",
        renderer: (item, formatCurrency) => {
            return formatCurrency(item.data["Normal_Min"] || 0.0);
        }
    },
    {
        visible: true,
        dataField: "Normal_Max",
        caption: "Maximum Price",
        renderer: (item, formatCurrency) => {
            return formatCurrency(item.data["Normal_Max"] || 0.0);
        }
    },
    {
        visible: true,
        dataField: "Normal_Avg",
        caption: "Average Price",
        renderer: (item, formatCurrency) => {
            return formatCurrency(item.data["Normal_Avg"] || 0.0);
        }
    },
    {
        visible: true,
        dataField: "Normal_Median",
        caption: "Median",
        renderer: (item, formatCurrency) => {
            return formatCurrency(item.data["Normal_Median"] || 0.0);
        }
    },
    {
        visible: true,
        dataField: "Normal_Stddev",
        caption: "Standard Deviation",
        renderer: (item, formatCurrency) => {
            return formatCurrency(item.data["Normal_Stddev"] || 0.0);
        }
    }
];

function SSPAnalyzer(props) {
    const { currentOrg, analyzerRefresh } = props;
    const [productsData, setProductsData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        async function loadPriceAnalysis() {
            setLoading(true);

            try {
                const data = await getPriceAnalysis(currentOrg.id);
                setProductsData(data);
            } catch (e) {
                showAlert({
                    title: "Error",
                    text: "Error in fetching SSP Analyzer data.",
                    icon: "error",
                    buttons: "OK"
                });
            } finally {
                setLoading(false);
            }
        }

        if (currentOrg) {
            loadPriceAnalysis();
        }
    }, [currentOrg, refresh, analyzerRefresh]);

    async function handleSSPDataUpdate() {
        try {
            setLoading(true);
            await runSSPAnalyzerJob(currentOrg.id);
        } catch (e) {
            showAlert({
                title: "Error",
                text: "Error in fetching SSP Analyzer data.",
                icon: "error",
                buttons: "OK"
            });
        } finally {
            showAlert({
                title: "SSP Population",
                text: "Started analysis.. give us a moment",
                buttons: "OK"
            });
            setLoading(false);
        }
    }

    if (!currentOrg) {
        return null;
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container spacing={2} justify={"center"} alignItems={"center"}>
            <Grid item xs={12}>
                <Paper elevation={1} className={"page-heading-container"}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        <Box className="page-heading">SSP Analysis</Box>
                    </div>
                    <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        className="w-auto"
                    >
                        <Grid item>
                            <IconButton
                                role="analysis-refresh"
                                onClick={() => setRefresh(!refresh)}
                            >
                                <Refresh />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Box>
                    {!productsData ||
                        (!productsData.length && (
                            <Grid
                                container
                                spacing={4}
                                direction="column"
                                alignItems="center"
                                justify="center"
                                style={{ minHeight: "50vh" }}
                            >
                                <Grid item lg={5} md={5} sm={5} />
                                <Grid
                                    item
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        src={noDataImg}
                                        style={{ maxWidth: 200 }}
                                    />
                                    <Typography
                                        variant="h5"
                                        color={"textSecondary"}
                                        style={{ paddingTop: "1pc" }}
                                    >
                                        SSP Analyzer helps analyze your
                                        historical sales to help <br />
                                        you setup or adjust your SSP library
                                        quickly.
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        color={"textSecondary"}
                                        style={{
                                            paddingTop: "1pc",
                                            paddingBottom: "1pc"
                                        }}
                                    >
                                        To get started
                                        <br />
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<Process />}
                                        onClick={handleSSPDataUpdate}
                                    >
                                        Prepare SSP Analysis
                                    </Button>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        style={{ marginTop: "30px" }}
                                    ></Grid>
                                </Grid>
                            </Grid>
                        ))}
                    {productsData.length > 0 && (
                        <DataGrid
                            id="grid-container"
                            dataSource={productsData}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            // keyExpr="ID"
                            showBorders={true}
                            onRowExpanding={(e) => {
                                e.component.collapseAll(-1);
                            }}
                        >
                            {columnData.map((col, index) => {
                                return (
                                    <Column
                                        key={index}
                                        caption={col.caption}
                                        dataField={col.dataField}
                                        visible={col.visible}
                                        cellRender={(item) =>
                                            col.renderer(item, formatCurrency)
                                        }
                                    />
                                );
                            })}
                            <Sorting mode="single" />
                            <MasterDetail
                                enabled={true}
                                component={SSPProductDetails}
                            />
                            <Pager
                                visible={true}
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 25, 50]}
                                showNavigationButtons={true}
                            />
                            <Paging visible={true} defaultPageSize={5} />
                        </DataGrid>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}

function mapStateToProps(state, props) {
    const orgConfig = getOrgConfigurations(state) || [];

    return {
        orgConfig,
        analyzerRefresh: sspAnalyzerRefresh(state),
        currentOrg: currentOrganization(state),
        currency: getCurrency(state)
    };
}

const styles = (theme) => ({
    portalDashboardPageWrapper: {
        padding: "24px",
        minHeight: "100%",
        boxSizing: "border-box"
    },
    toolBar: {
        alignItems: "center",
        color: "white",
        minHeight: "48px",
        background: theme.palette.background.neutral[50],
        borderRadius: "10px 10px 0 0",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
        padding: "8px 16px"
    },
    link: {
        margin: 0,
        padding: 0,
        minWidth: 0
    },
    linkLabel: {
        textDecoration: "none",
        color: theme.palette.secondary.main,
        textAlign: "left",
        alignSelf: "flex-start",
        textTransform: "none",
        margin: 0,
        padding: 0,
        minWidth: 0,
        fontSize: "0.75rem",
        fontWeight: "400"
    },
    list: {
        padding: 0,
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,.1)"
    },
    listHeader: {
        backgroundColor: "rgba(0, 0, 0, 0.08)" // padding: '12px 16px'
    },
    buttonRoot: {
        display: "flex",
        justifyContent: "space-between",
        "& > *": {
            margin: theme.spacing(1)
        }
    }
});

export default compose(
    withStyles(styles),
    connect(mapStateToProps)
)(SSPAnalyzer);
