import RemoteEntityReducer from "../RemoteEntityReducer";


const entityReducer = new RemoteEntityReducer((state, action) => {
    
    if (!action.response)
        throw new Error("Cannot reduce with no user");
    
    state.userLicenses = action.response.userLicenses;
    state.users = action.response.users;
    state.orgId = action.params
    
    return Object.assign({}, state);
});

const main = (state = {}, action) => {
    var newstate = Object.assign({}, state);
    
    switch (action.type) {
        case 'ORGANIZATION_USERS_INVALIDATE':
            return {};
        case 'ORGANIZATIONS_USERS':
            return entityReducer.doReduction(newstate, action);
        case 'ORGANIZATIONS_USERS_REMOVE':
            newstate.users = newstate.users.filter(user => user.email != action.data.email)
            return Object.assign({}, newstate)
        
        default:
            return state;
    }
}


export default main
