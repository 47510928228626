import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose, ensureOrgProfile } from "utils/WebUtils";
import { AccountingUtils } from "revlock-accounting";
import { useHistory } from "react-router-dom";
import { isImpersonatedUser } from "utils/auth";

// Material UI
import {
    Drawer,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    MenuList,
    MenuItem,
    Grid,
    CircularProgress,
    Avatar,
    Badge,
    Popover,
    List,
    ListItemAvatar,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    Chip
} from "@material-ui/core/";

import {
    ChevronLeftRounded as ChevronLeftIcon,
    DeleteForeverSharp,
    Settings as SettingsIcon,
    MoreVertRounded as MoreIcon,
    FileCopyRounded as CopyIcon,
    Group as MembersIcon,
    LibraryBooks as RulesIcon
} from "@material-ui/icons";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

// Styles
import styles from "./organization-sidenave.style";
// Actions && Selectors
import { toggleOrganizations } from "redux/actions/layout.actions";

import {
    getStateStatus,
    getClientTablesConfig
} from "redux/selectors/data.selector";
import { isReadOnlyOrganization } from "redux/selectors/user.selector";
import UserActions from "redux/actions/user.actions";
import { invalidateAll } from "redux/actions";
import { orgId, hasRole } from "utils/auth";
import FlaskImage from "../../../assets/images/flask.svg";
import swal from "sweetalert";

// Components
import {
    addTestTransactions,
    deleteEnvironment,
    setAccountingPeriod
} from "rest/API";
import { resetOrg } from "redux/actions";

class OrganizationSidenav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doMstrLogout: false
        };
        this.setOrg = this.setOrg.bind(this);
    }

    static propTypes = {
        layout: PropTypes.shape({
            organizationsOpen: PropTypes.bool
        }),
        toggleOrganizations: PropTypes.func
    };

    static defaultProps = {
        layout: {
            organizationsOpen: false
        },
        toggleOrganizations: null
    };

    state = {
        currentTabIndex: 0
    };

    navigateTo = (e, path) => {
        e.stopPropagation();
        this.props.toggleOrganizations();
        this.props.history.replace(`/${orgId() + path}`);
    };

    render() {
        const {
            layout,
            currentOrganization,
            organizations,
            currentUser
        } = this.props;

        if (!organizations || !currentOrganization) return null;

        return <>{this.renderOrganizations()}</>;
    }

    renderOrganizations() {
        const {
            organizations,
            currentOrganization,
            isReadOnlyOrg,
            currentUser,
            clientTables,
            classes
        } = this.props;
        let self = this;

        if (!organizations) return null;

        const orgSort = (a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        };

        const selectedOrgSort = (a, b) => {
            return a.id == currentOrganization.id
                ? -1
                : b.id == currentOrganization.id
                ? 1
                : a.name == currentOrganization.name
                ? -1
                : b.name == currentOrganization.name
                ? 1
                : 0;
        };

        let _orgs = organizations.sort(orgSort);

        if (currentOrganization) {
            _orgs = _orgs.sort(selectedOrgSort);
        }

        return (
            <List className={classes.root}>
                {_orgs.map((org, index) => (
                    <Tile
                        fetchNotifications={this.props.fetchNotifications}
                        classes={classes}
                        style={{ color: "black" }}
                        index={index}
                        key={index}
                        selected={
                            currentOrganization &&
                            org.id == currentOrganization.id
                        }
                        image={`https://logo.clearbit.com/${org.domain}`}
                        org={org}
                        isReadOnlyOrg={isReadOnlyOrg}
                        patchOrganization={this.props.patchOrganization}
                        onSettingsOrMembers={(e, url) => {
                            if (currentOrganization.id !== org.id) {
                                self.setOrg(org);
                            }

                            self.navigateTo(e, url);
                        }}
                        onClick={() => self.setOrg(org, true)}
                        clientTables={clientTables}
                        history={history}
                    />
                ))}
                {this.renderLoadingTile()}
            </List>
        );
    }

    renderLoadingTile() {
        const { organizationStatus } = this.props;
        if (!organizationStatus.loading) return null;

        return (
            <Grid item xs={12} sm={6} md={4} lg={2}>
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <CircularProgress />
                </div>
            </Grid>
        );
    }

    setOrg(org, navigateToDashboard) {
        const self = this;
        const targetUrl = `${window.location.origin}/${org.id}/dashboards`;

        ensureOrgProfile(
            org.id,
            { url: targetUrl, orgProfile: org.profile },
            () => {
                const { history, currentOrganization } = self.props;
                const isAlreadySelected = org.id == currentOrganization.id;

                if (!isAlreadySelected) {
                    UserActions.setCurrentOrganization(org, false);
                }

                if (navigateToDashboard) {
                    self.props.toggleOrganizations();
                    const _url = `/${org.id}/dashboards`;
                    history.push(_url);
                    self.props.invalidateOrganization();
                }
            }
        );
    }
}

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`
    }
}))(Avatar);

function Tile(props) {
    const {
        toggleDropDown,
        image,
        org,
        disabled,
        onClick,
        selected,
        onSettingsOrMembers,
        index,
        fetchNotifications,
        isReadOnlyOrg,
        patchOrganization,
        classes,
        clientTables
    } = props;

    const [showOptions, setShowOptions] = React.useState(false);
    const history = useHistory();

    const adminUser = hasRole("Admin");

    const isTestOrg = AccountingUtils.isTestId(org.id);
    const isConsolidateOrg = AccountingUtils.isConsolidatedId(org.id);
    const isConsolidateTestOrg = AccountingUtils.isConsolidatedTestId(org.id);

    const isInSetup = (org && !org.currentPeriod) || false;

    // const isCypressOrg = org && org.domain && org.domain.includes("cypress.com");
    const testId = isTestOrg ? "cypress-test-org" : "cypress-prod-org";
    const envName = isTestOrg
        ? "Test"
        : isConsolidateOrg
        ? "Consolidated Prod"
        : isConsolidateTestOrg
        ? "Consolidated Test"
        : "Production";
    const title = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography variant={"subtitle"}>{org.name}</Typography>
            <Typography variant={"caption"}>
                {isTestOrg
                    ? "Test"
                    : isConsolidateOrg
                    ? "Consolidated"
                    : isConsolidateTestOrg
                    ? "Consolidated Test"
                    : "Production"}
            </Typography>
        </div>
    );

    const tileStyle = disabled
        ? { filter: "grayscale(100%)", cursor: "pointer" }
        : { cursor: "pointer" };

    const badgeContent = isTestOrg ? (
        <SmallAvatar src={FlaskImage} style={{ backgroundColor: "white" }} />
    ) : null;

    const canPerformAction = () => {
        if (isReadOnlyOrg) {
            swal({
                title: "Error",
                text: `You dont have permission to perform this operation`,
                icon: "error",
                dangerMode: true,
                buttons: "ok"
            });
            return false;
        }

        return true;
    };

    const onDelete = async (evt) => {
        evt.stopPropagation();
        setShowOptions(null);

        if (!canPerformAction()) {
            return;
        }

        if (!adminUser) {
            return;
        }

        let message = {
            title: "Are you sure?",
            text: "This will delete all the orders in your environment",
            icon: "warning",
            dangerMode: true,
            buttons: true
        };

        const isTestOrg = AccountingUtils.isTestId(org.id);

        if (!isTestOrg) {
            message = Object.assign(message, {
                title: `!! YOU ARE CONNECTED TO ${org.name} LIVE SITE !!`,
                text: "You are about to DELETE all the data in this site.",
                buttons: ["Cancel", `Yes! clear live site.`]
            });
        }

        const willDelete = await swal(message);

        if (willDelete) {
            try {
                toggleDropDown && toggleDropDown();
                setTimeout(
                    () => fetchNotifications && fetchNotifications(),
                    2 * 1000
                );
                await deleteEnvironment(org.id);
                fetchNotifications && fetchNotifications();
                let _org = Object.assign({}, org);
                _org.currentPeriod = "";
                patchOrganization && patchOrganization(_org);

                resetOrg();

                history.push("/");

                invalidateAll();
            } catch (e) {
                swal(
                    "Error",
                    e.errorMessage ||
                        e.text ||
                        "Error occured while clearing environment",
                    "error"
                );
            }
        }
    };

    const onCopy = async (evt) => {
        evt.stopPropagation();
        setShowOptions(null);

        if (!canPerformAction()) {
            return;
        }

        const agreeToCreate = await swal({
            title: "Are you sure?",
            text: "This will copy some test orders in your test environment",
            icon: "warning",
            dangerMode: true,
            buttons: true
        });

        if (agreeToCreate) {
            toggleDropDown && toggleDropDown();

            setTimeout(
                () => fetchNotifications && fetchNotifications(),
                2 * 1000
            );

            addTestTransactions(org.id)
                .then(() => {
                    fetchNotifications && fetchNotifications();
                    let _org = Object.assign({}, org);
                    _org.currentPeriod = "";
                    patchOrganization && patchOrganization(_org);
                })
                .catch((err) => {
                    if (err.errorMessage) {
                        swal(
                            "error",
                            `Something didn't quite work ${err.errorMessage}`,
                            "error"
                        );
                    }
                });
        }
    };

    const onGoSettingsOrMembers = async (evt, url) => {
        evt.stopPropagation();
        // toggleDropDown()
        setShowOptions(null);
        onSettingsOrMembers(evt, url);
    };

    const show = (evt) => {
        evt.stopPropagation();
        setShowOptions(evt.currentTarget);
    };

    const hide = (evt) => {
        evt.stopPropagation();
        setShowOptions(null);
    };

    let menuItems = [
        <MenuItem
            onClick={(e) => onGoSettingsOrMembers(e, `/organization?tab=1`)}
            id={"tenant-settings"}
        >
            <ListItemIcon className={`${classes.menuColor}`}>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <Typography
                variant="inherit"
                className={`${classes.menuItemTitle}`}
            >
                Settings
            </Typography>
        </MenuItem>
    ];

    if (clientTables && clientTables.length > 0) {
        menuItems.push(
            <MenuItem
                id={"accounting-rules"}
                onClick={(e) => onGoSettingsOrMembers(e, `/esp`)}
            >
                <ListItemIcon className={`${classes.menuColor}`}>
                    <RulesIcon fontSize="small" />
                </ListItemIcon>
                <Typography
                    variant="inherit"
                    className={`${classes.menuItemTitle}`}
                >
                    Accounting Rules
                </Typography>
            </MenuItem>
        );
        menuItems.push(
            <MenuItem
                id={"team-members"}
                onClick={(e) => onGoSettingsOrMembers(e, `/organization?tab=0`)}
            >
                <ListItemIcon className={`${classes.menuColor}`}>
                    <MembersIcon fontSize="small" />
                </ListItemIcon>
                <Typography
                    variant="inherit"
                    className={`${classes.menuItemTitle}`}
                >
                    Team Members
                </Typography>
            </MenuItem>
        );
    }

    if (
        adminUser &&
        (isTestOrg || isInSetup) &&
        !org.isSystem &&
        !isImpersonatedUser()
    ) {
        menuItems.unshift(
            <MenuItem
                key={"clear-tenant"}
                id={"clear-tenant"}
                onClick={onDelete}
                style={{ width: "200px" }}
            >
                <ListItemIcon className={`${classes.menuColor}`}>
                    <DeleteForeverSharp fontSize="small" />
                </ListItemIcon>
                <Typography
                    variant="inherit"
                    className={`${classes.menuItemTitle}`}
                >
                    Clear
                </Typography>
            </MenuItem>
        );
    }

    if (isTestOrg && !isImpersonatedUser())
        menuItems.push(
            <MenuItem id={"load-sample-data"} onClick={onCopy}>
                <ListItemIcon className={`${classes.menuColor}`}>
                    <CopyIcon fontSize="small" />
                </ListItemIcon>
                <Typography
                    variant="inherit"
                    className={`${classes.menuItemTitle}`}
                >
                    Load Sample Data
                </Typography>
            </MenuItem>
        );

    return (
        <>
            <ListItem style={tileStyle} onClick={onClick} key={index}>
                <ListItemAvatar className={`${classes.orgListAvatar}`}>
                    <Badge
                        overlap="circle"
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        badgeContent={badgeContent}
                    >
                        <Avatar
                            alt={title}
                            src={image}
                            className={`${classes.orgAvatar}`}
                        />
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Tooltip title={org.name}>
                            <Typography
                                type="body2"
                                className={`${classes.ddTitle}`}
                            >
                                {org.name}
                            </Typography>
                        </Tooltip>
                    }
                    // primaryTypographyProps={selected ? { color: 'primary' } : { color: 'secondary' }}
                    secondary={
                        <Chip
                            id="env"
                            className={
                                envName == "Production"
                                    ? classes.activeChip
                                    : envName == "Test"
                                    ? classes.errorChip
                                    : classes.warningChip
                            }
                            label={envName}
                        />
                    }
                    secondaryTypographyProps={{ color: "primary" }}
                />
                {!isConsolidateTestOrg && !isConsolidateOrg && (
                    <ListItemSecondaryAction>
                        <IconButton
                            id={testId}
                            className={`${classes.orgIcon}`}
                            size="small"
                            onClick={show}
                            style={{ color: "#4d5266" }}
                        >
                            <MoreIcon size="small" />
                        </IconButton>
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            <Popover
                style={{ left: 55, marginLeft: 8 }}
                open={Boolean(showOptions)}
                anchorEl={showOptions}
                onClose={hide}
            >
                <MenuList
                    style={{
                        backgroundColor: "white",
                        color: "#4d5266",
                        cursor: "pointer",
                        opacity: "1"
                    }}
                >
                    {menuItems}
                </MenuList>
            </Popover>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        fetchNotifications: () =>
            dispatch({
                type: "NOTIFICATION",
                status: "invalid"
            }),
        invalidateOrganization: (args) => {
            dispatch({
                type: "ORGANIZATION_INVALIDATE"
            });

            dispatch({
                type: "ORGANIZATION_USERS_INVALIDATE"
            });
        },

        toggleOrganizations: () => dispatch(toggleOrganizations()),
        setCurrentOrganization: () =>
            dispatch(UserActions.setCurrentOrganization()),
        patchOrganization: (org) =>
            dispatch({
                type: "CURRENT_ORGANIZATION",
                response: org,
                status: "success"
            })
    };
}

function mapStateToProps(state) {
    return {
        layout: {
            organizationsOpen: state.layout.organizationsOpen
        },
        organizationStatus: getStateStatus(state, { name: "organizations" }),
        isReadOnlyOrg: isReadOnlyOrganization(state),
        clientTables: getClientTablesConfig(state)
    };
}

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(OrganizationSidenav);
