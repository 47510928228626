import { setCookie, getCookie, invokeApi, GET, PUT, POST } from './API'

/***************************
 * PLG API invoke
 *************************** */

// Set and get schedule
export async function plgUserAction(clientId, action, features) {
    if (action === 'close-banner' || action === 'contact-sales') {
        disablePlg()
    }

    return invokeApi(PUT, `/private/${clientId}/plg/user-action/${action}`, { body: { features } });
}

export async function getMessageForUser(clientId) {

    // PLG is disabled for now.
    if (getCookie('disablePlg')) {
        return null
    }
    
    return invokeApi(GET, `/private/${clientId}/plg/message-for-user`);
}

export function disablePlg(callback) {
    // No PLG dialog or banner for 24 hours.

    // const expiry = .5 
    const expiry = 24 * 60

    setCookie('disablePlg', 'disablePlg', '/', expiry) 

    callback && callback()
} 

