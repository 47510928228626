import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { withRouter } from 'react-router-dom'

class ErrorView extends React.Component {

	componentDidUpdate (prevProps) {
		//only called when location get changed
		if (this.props.location !== prevProps.location) {
			this.props.clearError()
			this.props.history.push(this.props.location)
		}
	}

	// This component will be displayed when an error boundary catches an error
	render () {
		return (<>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					style={{ minHeight: '80vh' }}
				>
					<Grid item lg={4} md={4} sm={4}/>
					<Grid item lg={4} md={4} sm={4}>

						<ErrorOutlineIcon style={{
							color: 'rgb(128,128,128)',
							fontSize: '40px',
						}}/>
						<Typography variant="h5" color={'textSecondary'}>
							Oops! Something went wrong on our end!
						</Typography>
						<small> We track these errors automatically, but if the problem
							persists feel free to contact us.</small>
						<Grid
							container
							spacing={0}
							direction="column"
							alignItems="left"
							justify="center"
							style={{ marginTop: '30px' }}
						>
						</Grid>
					</Grid>
				</Grid>
			</>)
	}
}

export default withRouter(ErrorView)
