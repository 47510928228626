import Period from "./Period";

function getComparator(sortCols, sortTypes, reverse) {
    return function (a, b) {
        JSON.stringify(sortCols + ", " + sortTypes);

        let toReturn = sortCols.reduce((result, sortCol, i) => {
            if (result != 0) return result;

            let aValue = typeof sortCol === "function" ? sortCol(a) : a[sortCol];
            let bValue = typeof sortCol === "function" ? sortCol(b) : b[sortCol];

            return sortTypes[i](
                aValue, bValue
            )
        }, 0)

        return reverse ? toReturn * -1 : toReturn;
    }
}

const forType = (type) => {
    switch (type) {
        case 'period':
            return (a, b) => {
                return Period.isSame(a, b) ? 0 : Period.isBefore(b, a) ? 1 : -1
            }
        case 'date':
            return (a, b) => {
                if (a instanceof Date)
                    a = a.toISOString().substring(0, 10);
                if (b instanceof Date)
                    b = b.toISOString().substring(0, 10);
                if (typeof (a) === "string" && a.length === 24)
                    a = a.substring(0, 10);
                if (typeof (b) === "string" && b.length === 24)
                    b = b.substring(0, 10);
                return Period.isSame(a, b) ? 0 : Period.isBefore(b, a) ? 1 : -1
            }
        case 'timestamp':
            return (a, b) => {
                a = new Date(a)
                b = new Date(b)
                return a - b
            }
        case 'number':
            return (a, b) => a == b ? 0 : (a < b ? -1 : 1);
        case 'string':
            return (a, b) => a == b ? 0 : (a < b ? -1 : 1)
    }
}

export {
    forType,
    getComparator
}

