import * as API from "../rest/API";
import User from "../redux/actions/user.actions";

export const ChargebeeUiSession = "cbrevrecUiSessionId";

export function setChargebeeUISession(uiSessionId) {
    localStorage.setItem(ChargebeeUiSession, uiSessionId);
}

export function getChargebeeUISession() {
    return localStorage.getItem(ChargebeeUiSession);
}

export function removeChargebeeUiSession() {
    localStorage.removeItem(ChargebeeUiSession);
}

export function isChargebeeUser() {
    if (getChargebeeUISession()) {
        return true;
    }
    return false;
}

export async function validateChargebeeUiSession() {
    let {
        isValidToken,
        uiSession,
        nextRefresh
    } = await API.validChargebeeToken(getChargebeeUISession());
    if (isValidToken) {
        setChargebeeUISession(uiSession);
        return {
            isValid: true,
            nextRefresh
        };
    } else {
        removeChargebeeUiSession();
    }
    return {
        isValid: false
    };
}
