import React from 'react';
import store from "./ReduxStore";
import { orgId } from "../utils/auth";
import * as WebUtils from 'utils/WebUtils';
import UserActions from "redux/actions/user.actions"

const isDebugEnabled = false;
export const RETRY_WAIT = 500;
export const MAX_ERROR_TIMEOUT = 1000 * 5;

const expiredTokenHandler = async () => {
    console.log("Handle expired token here.");
};

export default class FetchAction {

    constructor(actionType, remoteAction, errorHandler, expirationTime = 60 * 60 * 1000) {
        this.actionType = actionType;
        this.remoteAction = remoteAction;
        this.errorHandler = errorHandler;
        this.expirationTime = expirationTime;
    }


    /**
     * Determine if I need to do a fetch, and if I do perform the fetch
     *
     * @param fetchParams
     * @return
     * TRUE if the current state is valid and should be returned to the user
     * 1) can't be performed (not logged in or currently busy fetching..
     * 2) should not be performed or has already been performed and there is nothing new to do
     *
     * FALSE if the current state is invalid and you should let the user know that he cant use
     * any item in the state (most likely by returning an undefined or empty response)
     *
     */
    doFetch(reduxState, dataFilter, ...fetchParams) {
        const hasError = reduxState && reduxState.error != undefined;
        const retryCount = reduxState && reduxState.retryCount;
        const neverFetched = reduxState == undefined || reduxState.lastUpdated == undefined;
        const isFetching = reduxState && reduxState.isFetching;
        const isInvalidated = reduxState && reduxState.didInvalidate;
        const isExpired = reduxState && this.cacheExpired(reduxState.lastUpdated);
        const hasData = dataFilter(reduxState);
        const finalErrorAttempt = (retryCount == 0);

        let performedFetch = false;

        if (!isFetching && (isInvalidated || neverFetched || isExpired || !hasData)) {
            const wait = (hasError) ? (finalErrorAttempt ? MAX_ERROR_TIMEOUT : RETRY_WAIT) : (isInvalidated ? RETRY_WAIT : 0);
            if (!hasError || (hasError && retryCount >= 0) || (hasError && finalErrorAttempt)) {
                performedFetch = true;

                if (isDebugEnabled)
                    console.log("Fetching [" + this.actionType + "] (isStateValidToUse, performedFetch, hasError, neverFetched, isFetching, isInvalidated, isExpired, hasData, retryCount, wait) (" +
                        hasData + "," + performedFetch + "," + hasError + "," + neverFetched + "," + isFetching + "," + isInvalidated + "," + isExpired + "," + hasData + "," + retryCount + "," + wait + ") with params [" + JSON.stringify(fetchParams) + "]");

                this.performAsyncFetch(wait, finalErrorAttempt, ...fetchParams);
                //go ahead and asynchronously fetch this data..
            }
        }

        return hasData;
    }

    async performAsyncFetch(wait, finalErrorAttempt, ...fetchParams) {
        try {
            store.dispatch(this.requestAction(...fetchParams));
            //dispatch request

            var response =
                await this.doTimedRemoteCall(wait, ...fetchParams);

            store.dispatch(this.successAction(response, ...fetchParams));
        } catch (error) {
            if (error && error.code === 500) {
                await WebUtils.showError({
                    title: error.title || 'Error',
                    text: error.errorMessage,
                    icon: 'error',
                    error: true,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    details: error.details
                });
            } else if (error.code == 403) {
                try {
                    await WebUtils.showError({
                        title: 'Logged out',
                        text: "You were logged out for security reasons. Please login again.",
                        icon: "error",
                        buttons: true
                    });
                    return await UserActions.signOut();
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }

            } else if (error.code == 404) {
                window.location = `/${orgId()}/dashboard`;
            } else if (this.errorHandler) {
                try {
                    await this.errorHandler(error, ...fetchParams);
                }
                catch (error) {
                    store.dispatch(this.errorAction(error));
                }
            } else
                store.dispatch(this.errorAction(error));

            if (finalErrorAttempt) {
                const message = error.errorMessage || error.message || "Something went wrong on our servers."
                await WebUtils.showError({
                    title: error.title || 'Error',
                    text: message,
                    icon: 'error',
                    error: true,
                    buttons: 'Close',
                    details: error.details || ''
                });

            }
        }
    }

    async doTimedRemoteCall(wait, ...fetchParams) {
        return new Promise((resolve, reject) =>
            setTimeout(() => {
                try {
                    resolve(this.remoteAction(store.getState(), ...fetchParams))
                } catch (err) {
                    reject(err);
                }
            }, wait));
    }

    requestAction(...fetchParams) {
        const params = (fetchParams) ? fetchParams[0] : undefined;
        return {
            type: this.actionType,
            status: "request",
            params
        }
    }

    successAction(response, ...fetchParams) {
        const params = (fetchParams) ? fetchParams[0] : undefined;
        return {
            type: this.actionType,
            status: "success",
            params,
            response,
            orgId: orgId()
        }
    }

    errorAction(error) {
        return {
            type: this.actionType,
            status: "error",
            error
        }
    }

    cacheExpired(lastUpdatedOn) {
        const hasExpired = (lastUpdatedOn) ? (Date.now() - lastUpdatedOn) > this.expirationTime : false;

        if (isDebugEnabled)
            console.log("Fetcher: Has Expired:" + hasExpired + ":" + (Date.now() - lastUpdatedOn) + ":" + this.expirationTime);

        return hasExpired;
    }

    showErrorNotification(callback) {
        callback(undefined, { message: "Something bad happened!" })
    }
}
