import { Storage } from 'aws-amplify';
import { LinearProgress, Typography, Box } from '@material-ui/core/';
import React from 'react';
import { connect } from 'react-redux';
import { moment } from 'revlock-webutils';

import FileUploader from '../../../components/fileUploader/FileUploader';
import { currentUser, currentOrganization } from '../../../redux/selectors/user.selector';
import ConfirmDialog from '../../shared/ConfirmDialog';
import CancelIcon from '@material-ui/icons/Cancel';

class S3FileUploader extends React.Component {

    constructor(props) {
        super(props);
        this.onUploadComplete = this.onUploadComplete.bind(this);
        this.onUploadDialogClose = this.onUploadDialogClose.bind(this);
        this.state = this.init(props, {})
    }

    init(props, state) {
        let self = this;

        state.dialogParams = {
            visible: true,
            title: props.title || 'Upload Data',
            ok: props.ok || 'Done',
            cancel: props.cancel || 'Cancel',
            onClose: self.onUploadDialogClose,
            disableCloseButton: true
        }

        return state;
    }


    showComponent() {
        const { help, hideMessage = false, fileTypes = [".xlsx", ".xls", ".zip"], fileTypeId, removeFile } = this.props

        const { uploadError, fileMetadata, uploading } = this.state
        return (<div style={{ minWidth: '400px' }}>
            <FileUploader
                fileTypeId={fileTypeId}
                fileTypes={fileTypes}
                label={"Drag File Here"}
                btnLable={(fileMetadata && "Replace") || "Upload"}
                onFileReceive={this.onUploadComplete}
            />
            {(this.props.uploading || uploading) && <LinearProgress />}

            {fileMetadata && !hideMessage &&
                <Box p={1} mt={2} style={{ border: 1, borderStyle: 'dashed', maxHeight: '200px', overflowY: 'auto' }}>

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <CancelIcon
                            onClick={() => {
                                this.setState({
                                    fileMetadata: null
                                })
                                if(removeFile){
                                    removeFile()
                                }
                            }}
                            color='disabled'
                            style={{ marginRight: 1, marginTop: 1, fontSize: '15px' }}
                        />
                        <span> <Typography variant={'caption'}> {fileMetadata.name}</Typography><br /></span>

                    </div>
                </Box>
            }
            {!hideMessage && uploadError &&
                <Typography align="left" color="red" variant="body1">{uploadError}</Typography>
            }
            {help && help}
        </div>)
    }

    renderAsDialog() {
        const { dialogParams } = this.state

        return (dialogParams.visible && <ConfirmDialog
            dialogParams={dialogParams}
            dialogContent={this.showComponent()}
        /> || null);
    }

    render() {
        const { type = 'dialog' } = this.props

        return type == 'component' ? this.showComponent() :
            this.renderAsDialog();
    }

    onUploadComplete(file, fileCount) {

        const { hasError, currentOrganization, onFileUpload } = this.props;

        let getS3FileKey = this.props.getS3FileKey || (fileName => `${currentOrganization.id}/user-uploads/${fileName}`)
        // By default upload to currentOrg's user-uploads folder.

        if (file.hasError) {
            this.setState({
                fileMetadata: undefined,
                uploadError: file.message
            })

            onFileUpload && onFileUpload(file, fileCount);

        } else {
            const uploader = () => {
                const { user } = this.props;
                const { fileMetadata } = this.state;
                const fileName = file.src.name;

                if (hasError && hasError(fileMetadata)) {
                    this.setState({
                        fileMetadata: undefined,
                        uploadError: `File with name [${fileName}] already exist. `,
                        uploading: false
                    })
                    return;
                }

                const fileKey = getS3FileKey(fileName);
                const filePath = `public/${fileKey}`;

                Storage.put(fileKey, file.src, {
                    level: "public",
                    contentType: file.src.type,
                    identityId: user.id
                }).then(result => {
                    this.setState({
                        uploading: true
                    })
                    //upload the file
                    const resultParams = {
                        id: fileMetadata && fileMetadata.id,
                        name: fileName,
                        key: fileKey,
                        s3filename: filePath,
                        lastUpdated: moment().toISOString()
                    };

                    this.setState({
                        fileMetadata: resultParams,
                    });

                    const reset =
                        onFileUpload && onFileUpload(resultParams, fileCount) || false;

                    this.setState({
                        fileMetadata: reset ? null : resultParams,
                        uploading: false
                    })

                    this.props.externalClose && this.props.externalClose(resultParams)

                }).catch(err => {
                    this.setState({
                        uploadError: err.message,
                        uploading: false
                    })
                });
            }

            this.setState({
                uploadError: undefined,
                uploading: true
            }, uploader)
        }
    }

    onUploadDialogClose(confirmed) {
        let { onClose } = this.props;
        let { fileMetadata } = this.state;

        if (confirmed && fileMetadata) {
            onClose(fileMetadata);
        } else {
            onClose();
        }

        this.setState({
            dialogParams: Object.assign(this.state.dialogParams, {
                visible: false,
            }),
            fileMetadata: undefined
        })
    }

}

function mapStateToProps(state) {
    return {
        user: currentUser(state),
        currentOrganization: currentOrganization(state)
    };
}

export default connect(mapStateToProps)(S3FileUploader);

