import { Period, Utils } from 'revlock-webutils'
import { assertValidPlan } from '../ArrangementUtils'

export default {
	buildPlan: function buildPlan (startDate, endDate, originalBooking,
		endDateInclusive = false, calendarConfig) {
		if (Period.isBefore(endDate, startDate)) {
			console.warn(`Plan starts ${startDate} before it ends ${endDate}`)
			return []
		}

		const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig)
		const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig)

		let firstPeriodDays
		if (startActgPeriod.period === endActgPeriod.period) {
			firstPeriodDays = Period.daysBetween(startDate, endDate, true,
				endDateInclusive)
		} else {
			firstPeriodDays = Period.daysBetween(startDate, startActgPeriod.endDate,
				true, true)
		}

		//if the start and end date are the same period then all revenue goes to the first period

		const lastPeriodDays = (startActgPeriod.period === endActgPeriod.period)
			? 0
			: Period.daysBetween(endActgPeriod.startDate, endDate, true,
				endDateInclusive)

		let periods = (startActgPeriod.period === endActgPeriod.period)
			? [startActgPeriod.period]
			: Period.periodsBetween(startActgPeriod.period, endActgPeriod.period,
				true, true)
		//between periods

		const totalContractRevenueDays = (startDate === endDate)
			? 1
			: Period.daysBetween(startDate, endDate, true, endDateInclusive)

		if (firstPeriodDays === 0 && lastPeriodDays === 0 &&
			totalContractRevenueDays === 1) {
			firstPeriodDays = 1
		}

		const totalPeriods = (startDate === endDate) ? 1 : periods.length

		let firstPeriodPercent = (firstPeriodDays < 28) ? firstPeriodDays /
			totalContractRevenueDays : null
		let lastPeriodPercent = (lastPeriodDays < 28) ? lastPeriodDays /
			totalContractRevenueDays : null

		const remainingPercent = 1 - firstPeriodPercent - lastPeriodPercent
		let remainingDuration = totalPeriods
		if (lastPeriodPercent != null && totalPeriods > 1) {
			remainingDuration--
		}
		if (firstPeriodPercent != null) {
			remainingDuration--
		}

		const monthlyBookingPercent = remainingPercent / remainingDuration
		//if the percent for first and last period are known already then exclude from duration calcs for remaining

		if (firstPeriodDays >= 28) firstPeriodPercent = monthlyBookingPercent
		if (lastPeriodDays >= 28) lastPeriodPercent = monthlyBookingPercent
		//use a full month if more than 28 days

		const plan = []
		let cumulativeTotal = 0
		let actgPeriod = startActgPeriod
		let percentRecognized = 0.0
		for (let period of periods) {
			let postingDate
			let planAmount = 0
			if (period === startActgPeriod.period) {
				actgPeriod = startActgPeriod
				percentRecognized = firstPeriodPercent
				planAmount = firstPeriodPercent * originalBooking

			} else if (period === endActgPeriod.period) {
				actgPeriod = endActgPeriod
				percentRecognized = lastPeriodPercent
				planAmount = lastPeriodPercent * originalBooking

			} else {
				actgPeriod = actgPeriod.get(period)
				planAmount = monthlyBookingPercent * originalBooking
				percentRecognized = monthlyBookingPercent
			}

			postingDate = actgPeriod.endDate

			//calculate the amount and dates for the period.

			cumulativeTotal += planAmount

			plan.push({
				actgPeriod,
				planAmount,
			})
		}

		assertValidPlan(plan)

		return plan
	},
	updateRatablePlan
}

export function updateRatablePlan (plan, originalBooking) {

	// Zero product revenue.
	if (originalBooking === 0) {
		const zeroElement = {
			planAmount: 0,
			percentRecognized: 0,
		}
		plan.forEach(element => {
			Object.assign(element, zeroElement)
		})
		return
	}

	const _originalBooking = originalBooking
	let remainingAmount = _originalBooking

	plan.forEach((element, index) => {
		if (isNaN(element.percentRecognized)) {
			throw new Error(`Invalid percent recognized in plan ${element.planAmount}`)
		}

		element.planAmount = element.percentRecognized * _originalBooking

		const _planAmount = originalBooking > 0 ? element.planAmount : Math.abs(element.planAmount)
		const _remainingAmount = originalBooking > 0 ? remainingAmount : Math.abs(remainingAmount)

		if (_planAmount > _remainingAmount) {
			element.planAmount = remainingAmount
			element.percentRecognized = (element.planAmount / _originalBooking) || 0
		}

		remainingAmount -= element.planAmount

		// on last element push all remaining amount.
		if (index === plan.length - 1 && !Utils.approxeq(remainingAmount, 0)) {
			element.planAmount += remainingAmount
			element.percentRecognized = (element.planAmount / _originalBooking) || 0
			remainingAmount = 0
		}

		if (isNaN(element.planAmount)) {
			throw new Error(`Invalid plan amount found ${element.planAmount}`);
		}
	});
}