import React from 'react';

import {Button, Dialog, Grid, IconButton, Slide, Typography} from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import {withRouter} from 'react-router-dom';
import {compose} from 'utils/WebUtils';
import {markSeenTour} from "utils/Crisp";

import {setData2} from 'redux/actions';

import {connect} from 'react-redux';
import {currentOrganization, currentUser} from "redux/selectors";

import WelcomeImage from './images/quickview_welcome.svg'
import * as PlgAPI from "../../rest/PlgAPI";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tourFooter: {
        display: 'initial',
        padding: '16px 20px',
    },
    btn: {
        main: theme.palette.background.primary['600']
    }
});

const Transition = React.forwardRef(function Transistepstion(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    },
}))(MuiDialogActions);

function WelcomeTour(props) {
    const {currentOrganization, currentUser, open, history, classes} = props;

    async function handleClose() {
        setTimeout(() => {
            // User just completed tour, let's give 'em a break. No PLG for a short time.
            PlgAPI.disablePlg(async () => {
                await markSeenTour(currentOrganization.id, undefined, history)
                setData2('showWelcomeTour', false);
            })
        }, 100);
    };

    if (!open || !currentOrganization)
        return null;

    return (<Dialog
            onClose={handleClose}
            open={open}
            TransitionComponent={Transition}
            maxWidth={false}
            fullWidth={false}>
            <div style={{maxWidth: '800px'}}>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant={'h4'} style={{ fontSize:" 22.78px",padding: '0px 15px 20px 15px'}}>
                                Welcome to Chargebee RevRec
                            </Typography>
                            <Typography variant={'subtitle1'} style={{ fontSize:" 14.22px", padding: '0px 15px 0px 15px'}}>
                                Chargebee RevRec simplifies accounting for your finance team by automating revenue
                                recognition and ensuring GAAP compliance.
                            </Typography>
                            <br/>
                            <Typography variant={'subtitle1'} style={{fontSize:" 14.22px",padding: '0px 15px 20px 15px'}}>
                                <b>You'll find Quick View reports in Reports on left-side menu.</b>
                            </Typography>
                            <div>
                                <Button variant="contained" color="primary" size="normal" style={{margin: '10px'}}
                                        onClick={async () => {
                                            await handleClose()
                                        }}>
                                    {'Access Quick View Report'}
                                </Button>
                                <Button variant="outlined" color="primary" size="normal" style={{margin: '10px'}}
                                        onClick={async () => {
                                            window.open('https://www.chargebee.com/docs/revrec/revrec-reporting-new.html', '_blank');
                                        }}>
                                    {'Learn More About RevRec'}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <img src={WelcomeImage}/>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
}


function mapStateToProps(state) {
    const toReturn = {
        currentOrganization: currentOrganization(state),
        currentUser: currentUser(state)
    };
    return toReturn;
}

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps)
)(WelcomeTour);
