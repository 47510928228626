import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'utils/WebUtils';
import { snackbarParams } from '../redux/selectors/data.selector'
import { toastDone } from '../redux/actions/data.actions'
import { withDispatch } from '../redux/Redux';


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    note: InfoIcon,
};

const styles1 = theme => ({
    spacing: {
        margin: 5
    },
    success: {
        color: '#155724',
        backgroundColor: '#d4edda',
        borderColor: '#c3e6cb'
    },
    note: {
        color: theme.palette.primary.dark,
        backgroundColor: '#fff',
        borderColor: theme.palette.primary.dark,
        marginTop:0,
        marginBottom:0,
        paddingTop:0,
        paddingBottom:0
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        color: '#856404',
        backgroundColor: '#fff3cd',
        borderColor: '#ffeeba'
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

function MySnackbarContent(props) {
    const { classes, className, message, onClick, onClose, variant, actions, ...other } = props;
    const Icon = variantIcon[variant];

    let _actions = [
    ]

    if (variant != 'note') {
        _actions.push(<IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={e => {
                onClose && onClose()
                e.stopPropagation()
            }}
        >
            <CloseIcon className={classes.icon} />
        </IconButton>)
    }

    if (actions) {
        actions.forEach((action, index) => {

            if (action.icon) {
                const ActionIcon = action.icon
                _actions.unshift(<IconButton
                    size="small"
                    color={action.color || 'secondary'}
                    className={classes.close}
                    onClick={action.onClick}
                >
                    <ActionIcon />
                </IconButton>)
            } else {
                _actions.unshift(<Button
                    key={index}
                    variant={'outlined'}
                    size="small"
                    color={action.color || 'secondary'}
                    onClick={action.onClick}
                >
                    {action.label}
                </Button>)
            }
        })

    }

    return (
        <SnackbarContent
            className={classNames(classes.spacing, classes[variant], className)}
            onClick={e => {
                e && e.stopPropagation();
                onClick && onClick()
            }}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={_actions}            
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
    margin: {
        margin: theme.spacing(1),
    },
});

class CustomizedSnackbars extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: new Date()
        }

    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        const { snackbar } = this.props

        if (snackbar && snackbar.directCall) {
            this.props.snackbar.open = false
            this.setState({
                date: new Date()
            })
        } else {
            toastDone();
        }
    };

    render() {
        const { classes, snackbar } = this.props;

        if (!snackbar || (snackbar && Object.keys(snackbar).length == 0)) return null;

        const { type, message, open, autoHideDuration, anchorOrigin } = snackbar

        return (
            <div  >
                <Snackbar
                    style={{ zIndex: 1500 }}
                    anchorOrigin={anchorOrigin || {
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    autoHideDuration={autoHideDuration || 6000}
                    onClose={this.handleClose}
                >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={type}
                        message={message}
                    />
                </Snackbar>
            </div>
        );
    }
}

CustomizedSnackbars.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
    let snackbar = snackbarParams(state) || {};

    if (props.snackBarMessage) {
        snackbar.message = props.snackBarMessage
        snackbar.directCall = true
    }

    if (props.snackBarType) {
        snackbar.type = props.snackBarType
    }

    if (props.snackBarOpen) {
        snackbar.open = props.snackBarOpen
    }

    if (props.snackBarAutoHideDuration) {
        snackbar.autoHideDuration = props.snackBarAutoHideDuration
    }

    if (props.snackbarAnchorOrigin) {
        snackbar.anchorOrigin = props.snackbarAnchorOrigin
    }

    return {
        snackbar
    };
}

export default compose(
    withStyles(styles2, { withTheme: true }),
    withDispatch,
    connect(mapStateToProps),
)(CustomizedSnackbars);

