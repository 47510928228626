import React from 'react';
import { connect } from 'react-redux';

export function withDispatch(ComposedComponent) {
  class Data extends React.Component {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  };

  const mapDispatchToProps = (dispatch) => ({ dispatch })

  return connect(undefined, mapDispatchToProps)(Data)
}
