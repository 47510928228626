import { Utils } from "revlock-webutils";

const fromRevenueHeaderAliases = {
    "Standalone Price Policy": "Standalone Price Policy",
    Amount: "Fixed Amount",
    "Minimum Amount": "Minimum Amount",
    "Maximum Amount": "Maximum Amount",
    "Minimum Percentage": "Minimum Percentage",
    "Maximum Percentage": "Maximum Percentage",
    "SSP Value Method": "SSP Value Method",
    "Recognition Method": "Recognition Rule",
    "Recognized On": "Recognized On",
    "Revenue Start Date": "Delivery Start Date",
    "Revenue End Date": "Delivery End Date",
    Term: "Term",
    "Rule Type": "SSPType"
};

const toExcelRowRevenueHeaderAliases = {
    "Standalone Price Policy": "Standalone Price Policy",
    "Fixed Amount": "Amount",
    "Minimum Amount": "Minimum Amount",
    "Maximum Amount": "Maximum Amount",
    "Minimum Percentage": "Minimum Percentage",
    "Maximum Percentage": "Maximum Percentage",
    "SSP Value Method": "SSP Value Method",
    "Recognition Rule": "Recognition Method",
    "Recognized On": "Recognized On",
    "Delivery Start Date": "Revenue Start Date",
    "Delivery End Date": "Revenue End Date",
    Term: "Term",
    SSPType: "Rule Type"
};

const fromRevenueValuesAliases = {
    Maximum: "max",
    Minimum: "min",

    // Standalone Price Policy
    SALE_PRICE: "AS_IS",
    DISCOUNT: "DISCOUNT_RANGE",
    SIMPLE_PERCENT_NET: "SIMPLE_PERCENT_NET_RANGE",
    APPORTIONED_PERCENT_NET: "PERCENT_NET_RANGE"
};

const revenueUIValueAliases = {
    AS_IS: "Sale Price",
    LIST_PRICE: "List Price",
    STANDALONE: "Standalone",
    DISCOUNT_RANGE: "Discount",
    RANGE: "Dollar Amount",
    NUMERIC: "Dollar Amount",
    RESIDUAL: "Residual",
    SIMPLE_PERCENT_NET_RANGE: "Simple Percent Net",
    PERCENT_NET_RANGE: "Apportioned Percent Net",
    boundary: "Boundary",
    midpoint: "Midpoint",
    max: "Maximum",
    min: "Minimum"
};

const toExcelRowRevenueValueAliases = {
    max: "Maximum",
    min: "Minimum",

    // Standalone Price Policy
    AS_IS: "SALE_PRICE",
    DISCOUNT_RANGE: "DISCOUNT",
    SIMPLE_PERCENT_NET_RANGE: "SIMPLE_PERCENT_NET",
    PERCENT_NET_RANGE: "APPORTIONED_PERCENT_NET"
};

const toExpenseHeaderAliases = {
    "Expense Code": "Expense Code",
    "Follow Revenue Rules": "Expense Tracks Revenue",
    "Reallocate Expense": "Reallocate Expense",
    "Expense Amortization Method": "Expense Amortization Method",
    "Amortization Start Date": "Amortization Start Date",
    "Amortization End Date": "Amortization End Date",
    "Amortization Term": "Amortization Term",
    "Rule Type": "SSPType"
};

const toExcelRowExpenseHeaderAliases = {
    "Expense Code": "Expense Code",
    "Expense Tracks Revenue": "Follow Revenue Rules",
    "Reallocate Expense": "Reallocate Expense",
    "Expense Amortization Method": "Expense Amortization Method",
    "Amortization Start Date": "Amortization Start Date",
    "Amortization End Date": "Amortization End Date",
    "Amortization Term": "Amortization Term",
    SSPType: "Rule Type"
};

const toExpenseValuesAliases = {
    CONTRACT_COST: "Direct",
    OTHER_COST: "Indirect"
};

const toExcelRowExpenseValueAliases = {
    Direct: "CONTRACT_COST",
    Indirect: "OTHER_COST"
};

const expenseUIValueAliases = {
    Direct: "Contract Cost",
    Indirect: "Other Cost"
};

// revenue

export function translateRevenueHeaders(headerName) {
    return fromRevenueHeaderAliases[headerName] != undefined
        ? fromRevenueHeaderAliases[headerName]
        : headerName;
}

export function translateRevenueValues(value) {
    return fromRevenueValuesAliases[value] != undefined
        ? fromRevenueValuesAliases[value]
        : value;
}

// expense

export function translateExpenseHeaders(headerName) {
    return toExpenseHeaderAliases[headerName] != undefined
        ? toExpenseHeaderAliases[headerName]
        : headerName;
}

export function translateExpenseValues(value) {
    return toExpenseValuesAliases[value] != undefined
        ? toExpenseValuesAliases[value]
        : value;
}

// general
const toExcelRowHeaderAliases = {
    ...toExcelRowRevenueHeaderAliases,
    ...toExcelRowExpenseHeaderAliases
};
const toExcelRowValuesAliases = {
    ...toExcelRowRevenueValueAliases,
    ...toExcelRowExpenseValueAliases
};
const toUIValueAliases = { ...revenueUIValueAliases, ...expenseUIValueAliases };
export function translateToExcelRowHeaders(headerName) {
    return toExcelRowHeaderAliases[headerName] != undefined
        ? toExcelRowHeaderAliases[headerName]
        : headerName;
}

export function translateToExcelRowValues(value) {
    return toExcelRowValuesAliases[value] != undefined
        ? toExcelRowValuesAliases[value]
        : value;
}

export function translateToUIvalues(value) {
    return toUIValueAliases[value] != undefined
        ? toUIValueAliases[value]
        : value;
}

export function translateXLRow(xlRow) {
    const row = {};

    const ruleType = row["Rule Type"] || row["SSPType"];
    // expense rules translations
    if (
        ["Direct", "Indirect", "CONTRACT_COST", "OTHER_COST"].includes(ruleType)
    ) {
        Object.keys(xlRow).forEach((key) => {
            const updatedKey = translateExpenseHeaders(key);
            const updatedValue = translateExpenseValues(xlRow[key]);

            // updating keys and values
            row[updatedKey] = updatedValue;
        });

        return row;
    }

    // revenue rules translation
    Object.keys(xlRow).forEach((key) => {
        const updatedKey = translateRevenueHeaders(key);
        const updatedValue = translateRevenueValues(xlRow[key]);

        // updating keys and values
        row[updatedKey] = updatedValue;
    });

    if (row["Standalone Price Policy"] == "DOLLAR_AMOUNT") {
        if (
            !Utils.isEmpty(row["Minimum Amount"]) &&
            !Utils.isEmpty("Maximum Amount")
        ) {
            row["Standalone Price Policy"] = "RANGE";
        } else if (!Utils.isEmpty(row["Fixed Amount"])) {
            row["Standalone Price Policy"] = "NUMERIC";
        }
    }

    return row;
}
