import React from "react";
import { compose } from "utils/WebUtils";
import { connect } from "react-redux";

import { A } from "../../../components/ImgIcon";
import { makeStyles } from "@material-ui/styles";
import { setData2 } from "redux/actions";

import {
    Grid,
    Typography,
    CircularProgress,
    Button,
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Box
} from "@material-ui/core";

import Switch from "@material-ui/core/Switch";

import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import EntityActions from "redux/actions";
import {
    currentUser,
    currentOrganization
} from "redux/selectors/user.selector";
import S3FileUploader from "../sync-view/S3FileUploader";
import swal from "sweetalert";
import shortid from "shortid";
import { SyncActions } from "redux/actions";
import GA from "utils/GoogleAnalytics";
import excelIcon from "./excelIcon.png";
import { LinearProgress } from "@material-ui/core/";

const syncActions = EntityActions.sync;

const useStyles = makeStyles((theme) => ({
    media: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        resizeMode: "cover"
    },
    mediaContainer: {
        position: "relative",
        width: "100%",
        height: "70%"
    },
    paper: {
        maxWidth: "100%",
        margin: 0,
        padding: 0
    },
    card: {
        display: "flex",
        flexDirection: "column",
        width: "100px;",
        cursor: "pointer",
        height: "80px",
        margin: "2em"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    cardTopAction: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        top: "-60px"
    },
    actionbutton: {
        margin: theme.spacing(1),
        color: "white"
    },
    leftIcon: {
        marginRight: theme.spacing(1)
    },
    box: {
        paddingTop: "8px",
        paddingLeft: "8px",
        alignItems: "center"
    }
}));

function ImportDialog(props) {
    const { user, onClose, visible, currentOrganization, currentUser } = props;
    const [jobName, setJobName] = React.useState("");
    const [jobId, setJobId] = React.useState(shortid.generate());
    const [dataFiles, setDataFiles] = React.useState({});
    const [valid, setValid] = React.useState(false);
    const [isProcessing, setProcessing] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [manualUploadOverwrite, setManualUploadOverwrite] = React.useState(
        false
    );
    const classes = useStyles();

    React.useEffect(() => {
        const valid =
            Object.keys(dataFiles).length > 0 && jobName && jobName.length > 0;
        setValid(valid);
    }, [dataFiles, jobName]);

    const close = () => {
        setDataFiles({});
        setJobName("");
        setManualUploadOverwrite(false);
        setJobId(shortid.generate());
        onClose && onClose();
    };

    const onSync = () => {
        let autoSync = props?.autoSync ? props.autoSync : "true";
        const syncParams = {
            startedBy: user.id,
            userId: user.attributes.email,
            jobName,
            jobId,
            isAutoLoadSyncData: autoSync == "true" ? true : false
        };

        if (manualUploadOverwrite) {
            // user wants to force update data even though it's auto sync disabled.
            syncParams.forceUpdateManualUpload = manualUploadOverwrite;
        }

        const params = {
            syncParams,
            files: Object.values(dataFiles)
        };

        setProcessing(true);
        const onComplete = (res, err) => {
            setProcessing(false);

            GA.setEvent({
                category: "Accounting",
                action: "Import",
                label: `${err ? "failed" : "success"}`,
                value: err ? -1 : 1,
                dimension1: currentOrganization && currentOrganization.id,
                dimension2: user && user.id,
                dimension3:
                    currentOrganization && currentOrganization.merchantId,
                dimension4: user && user.attributes.email
            });

            if (err) {
                console.log(JSON.stringify(err));
                const msg = err.text ? err.text : "Something went wrong";
                setData2("syncImportComplete", true);
                swal({
                    text: `Our sync back-end was unable to process the import: ${msg}`,
                    title: "Error",
                    icon: "error",
                    buttons: false,
                    timer: 3000
                });
            } else if (res) {
                setData2("syncImportComplete", true);
            }

            close();
        };

        syncActions.create(onComplete, params);
    };

    const onUploadComplete = (fileMetadata, fileCount) => {
        if (fileMetadata.hasError) {
            swal({
                text: `${fileMetadata.message}`,
                title: "Error",
                icon: "error",
                buttons: false,
                timer: 2000
            });
        } else {
            const { name } = fileMetadata;
            dataFiles[name] = fileMetadata;
            setDataFiles(Object.assign({}, dataFiles));
            if (fileCount == Object.keys(dataFiles).length) {
                setUploading(false);
            } else {
                setUploading(true);
            }
        }
        return true;
    };

    const removeFile = (file) => {
        if (dataFiles) delete dataFiles[file.name];
        setDataFiles(Object.assign({}, dataFiles));
    };

    const s3FileKey = (fileName) => {
        return `${currentOrganization.id}/${jobId}/Original Input/${fileName}`;
    };

    return (
        <Dialog open={visible || false}>
            <DialogTitle>Create Import Job</DialogTitle>
            <DialogContent>
                <Grid container alignItems={"center"}>
                    <Grid item xs={3}>
                        <Avatar
                            src={excelIcon}
                            style={{
                                width: "100%",
                                height: "100%",
                                padding: 16
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Job Name"
                            type="text"
                            value={jobName}
                            onChange={(e) => setJobName(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <S3FileUploader
                            uploading={uploading}
                            getS3FileKey={s3FileKey}
                            hideMessage={true}
                            onFileUpload={onUploadComplete}
                            style={{ width: "100%" }}
                            type={"component"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                            className={classes.box}
                        >
                            <Typography variant={"body2"}>
                                Overwrite existing data, including UI updates
                                (sync disabled).
                            </Typography>
                            <span style={{}}>
                                <Switch
                                    id="overrideSyncDisabledSwitch"
                                    size="medium"
                                    style={{ width: "50px !important" }}
                                    checked={manualUploadOverwrite}
                                    onChange={(e) => {
                                        setManualUploadOverwrite(
                                            e.target.checked
                                        );
                                    }}
                                />
                            </span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {dataFiles && Object.keys(dataFiles).length > 0 && (
                            <Box
                                p={1}
                                mt={2}
                                style={{
                                    border: 1,
                                    borderStyle: "dashed",
                                    maxHeight: "200px",
                                    overflowY: "auto"
                                }}
                            >
                                {dataFiles &&
                                    Object.keys(dataFiles).map(
                                        (name, index) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexWrap: "wrap"
                                                }}
                                            >
                                                <CancelIcon
                                                    onClick={() =>
                                                        removeFile(
                                                            dataFiles[name]
                                                        )
                                                    }
                                                    color="disabled"
                                                    style={{
                                                        marginRight: 1,
                                                        marginTop: 1,
                                                        fontSize: "15px"
                                                    }}
                                                />
                                                <span>
                                                    <Typography
                                                        variant={"caption"}
                                                    >
                                                        {name}
                                                    </Typography>
                                                    <br />
                                                </span>
                                            </div>
                                        )
                                    )}
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant={"subtitle1"}
                            color={"textSecondary"}
                        >
                            Allows you to create a job to collect one or more
                            Excel files containing orders, invoices etc.
                            <A
                                href={
                                    "https://www.chargebee.com/docs/revrec/importing-data-in-bulk.html"
                                }
                            >
                                Learn more
                            </A>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={close}
                    color="disabled"
                    className={classes.button}
                >
                    <CancelIcon
                        className={classes.leftIcon}
                        style={{ color: "rgb(128,128,128)" }}
                    />
                    Close
                </Button>
                <Button
                    disabled={isProcessing || !valid || uploading}
                    role={"import"}
                    className={classes.actionbutton}
                    onClick={onSync}
                    color="primary"
                    variant="contained"
                >
                    {isProcessing ? (
                        <>
                            <CircularProgress
                                size="15px"
                                style={{ marginRight: "4px" }}
                            />{" "}
                            Import{" "}
                        </>
                    ) : (
                        "Import"
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function mapStateToProps(state, props) {
    return {
        currentUser: currentUser(state),
        currentOrganization: currentOrganization(state)
    };
}

export default compose(connect(mapStateToProps))(ImportDialog);
