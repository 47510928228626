import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import FileDrop from 'react-file-drop';
import './uploadFile.style.scss'

export default class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {
                srcUrl: undefined,
                file: undefined
            }
        }
    }

    getExtension(filename) {
        const parts = filename.split('.');
        return parts[parts.length - 1];
    }

    verifyFile(file) {
        if (!file) return false;
        const { fileTypes } = this.props
        const ext = `.${this.getExtension(file.name)}`
        return fileTypes.includes(ext)
    }

    handleDrop = (files, event) => {
        for (let i = 0; i < files.length; i++)
            this.readFile(files[i], files.length)
    }

    handleOnBrowse = (e) => {
        const files = e && e.target && e.target.files
        for (let i = 0; i < files.length; i++)
            this.readFile(files[i], files.length)

        e.target.value = null;
    }

    getFileSize(file) {
        if (file) {
            let size = { value: 0, unit: 'byte' }
            if (file.size >= 800000) {
                size.value = (file.size / 100000).toFixed(2)
                size.unit = 'MB'
            } else {
                size.value = (file.size / 1000).toFixed(2)
                size.unit = 'KB'
            }
            return size
        }
    }

    readFile = (file, fileCount) => {
        const { onFileReceive, fileTypes } = this.props

        // Verify if files types are valid
        const isValidFile = (fileTypes && file) ? this.verifyFile(file) : true
        if (file && isValidFile) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.props.onFileReceive(
                    {
                        url: e.target.result,
                        src: file,
                        hasError: false,
                        size: this.getFileSize(file)
                    }, fileCount)
            };
            reader.readAsDataURL(file);
        } else {
            onFileReceive && onFileReceive({
                hasError: true,
                message: 'Invalid file or bad type [Supported types are ' + fileTypes.join(",") + ']'
            })
        }
    }


    render() {
        const { btnLable, label, fileTypes, fileTypeId = "upload" } = this.props
        const accept = fileTypes.join(",");
        return (
            <div id="react-file-drop-demo" className={'upload_container'}>
                <FileDrop onDrop={this.handleDrop}>
                    <Typography align="center" color="inherit" variant="body1">{label || 'Drag File Here'}</Typography>
                    <Typography align="center" color="inherit" variant="body1">{'or'}</Typography>
                    <div className="file-upload">
                        <label htmlFor={fileTypeId} className='file-upload__label'>{btnLable || 'Browse'}</label>
                        <input id={fileTypeId} type="file" className="file-upload__input" name="file-upload" multiple="multiple"
                            onChange={(e) => this.handleOnBrowse(e)} accept={accept} />
                    </div>
                </FileDrop>
            </div>
        )
    }
}

FileUploader.propTypes = {
    onFileRecieve: PropTypes.func,
    label: PropTypes.string, // e.g. Drag file here
    btnLable: PropTypes.string, // e.g. Browse, add
    fileTypes: PropTypes.array  // ['jpg', 'png']
}
