import { Comparator, Period, Utils } from "revlock-webutils";
import ComponentType from "./reference/ComponentType";
import TransactionType from "./reference/TransactionType";
import ModificationType from "./reference/ModificationType";
import shortid from "shortid";

const endOfTransactions = {
    end: true
};

const CONFIG = {
    expense: {
        componentType: ComponentType.EXPENSE,
        originalTransactionType: TransactionType.EXP_DEFERRAL.name,
        ammortizationTransactionType: TransactionType.EXP_RECOGNIZED.name,
        getPlan: (item) => item.plan || [],
        getTotalBooking: (item) => item.allocatedExpense,
        getRecognitionRule: (item) => item.expenseAmortizationMethod,
        getEndDate: (item) => item.deliveryEndDate,
        getJournalTransactions: (O) => O.expenseTransactions,
        addJournalEntries: (O, journalEntries) =>
            (O.expenseJournalEntries = journalEntries),
        createEntries: (
            reportingTransaction,
            salesOrder,
            salesOrderItemId,
            config,
            defaultAccounts,
            jeMappingColumns,
            bookJEExchangeRateSeparately
        ) => {
            let transactions = [];

            if (reportingTransaction["Foreign Currency Change"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.EXPENSE,
                    "Transaction Type": bookJEExchangeRateSeparately
                        ? TransactionType.FX_GAIN_LOSS.name
                        : TransactionType.EXP_RECOGNIZED.name,
                    Amount: reportingTransaction["Foreign Currency Change"],
                    "Link Id": `ExpAmort_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });
            }

            if (reportingTransaction["Revenue"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.EXPENSE,
                    "Transaction Type": TransactionType.EXP_RECOGNIZED.name,
                    Amount: reportingTransaction["Revenue"],
                    "Link Id": `ExpAmort_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });
            }

            if (reportingTransaction["New Billing"] != 0) {
                transactions.push({
                    "Component Type": ComponentType.EXPENSE,
                    "Transaction Type": TransactionType.EXP_DEFERRAL.name,
                    Amount: -1 * reportingTransaction["New Billing"],
                    "Link Id": `ExpDef_${reportingTransaction["Actg Period"]}`,
                    "SOI Version Item Id": salesOrderItemId
                });
            }

            // Build base journal mapping value.
            const baseJournalMappingValues = [];

            for (const jeMappingColumn of jeMappingColumns) {
                baseJournalMappingValues.push(
                    reportingTransaction[jeMappingColumn] || "JEMINVOICE"
                );
            }

            const baseJournalMappingValue = baseJournalMappingValues.join(
                ":=:"
            );

            let journalEntries = [];
            transactions.forEach((transaction) => {
                let transactionDate = Period.toEndOfMonth(
                    reportingTransaction["Actg Period"]
                );
                let amount = transaction["Amount"];

                delete transaction["Amount"];

                if (amount == 0) {
                    return;
                }

                let inverse = amount < 0;
                amount = Math.abs(amount);

                let debitAccount, creditAccount;

                if (
                    transaction["Transaction Type"] ===
                    config.originalTransactionType
                ) {
                    debitAccount = defaultAccounts["Expense Clearing"] || {
                        accountType: "Expense Clearing"
                    };
                    creditAccount = defaultAccounts["Deferred Expense"] || {
                        accountType: "Deferred Expense"
                    };
                } else if (
                    transaction["Transaction Type"] ===
                    TransactionType.FX_GAIN_LOSS.name
                ) {
                    // this is gain loss transaction
                    debitAccount = defaultAccounts["Realized FX Gain Loss"] || {
                        accountType: "Realized FX Gain Loss"
                    };

                    creditAccount = defaultAccounts["Deferred Expense"] || {
                        accountType: "Deferred Expense"
                    };
                } else {
                    debitAccount = defaultAccounts["Expense Recognized"] || {
                        accountType: "Expense Recognized"
                    };
                    creditAccount = defaultAccounts[
                        "Commission Accumulated Amortization"
                    ] ||
                        defaultAccounts["Deferred Expense"] || {
                            accountType: "Deferred Expense"
                        };
                }

                if (inverse) {
                    [debitAccount, creditAccount] = [
                        creditAccount,
                        debitAccount
                    ];
                    // credit will be contra
                    // debit will be deferred
                }

                let baseEntry = Object.assign(
                    {
                        OrgId: reportingTransaction["OrgId"],
                        "Actg Period": reportingTransaction["Actg Period"],
                        "Sales Order Id":
                            reportingTransaction["Sales Order Id"],
                        "Linked Sales Order Id":
                            reportingTransaction["Linked Sales Order Id"],
                        "Sales Order Item Id":
                            reportingTransaction["Sales Order Item Root Id"],
                        "Sales Order Item Root Id":
                            reportingTransaction["Sales Order Item Root Id"],
                        "Product Id": reportingTransaction["Product Id"],
                        "Transaction Date": transactionDate,
                        "Customer Id": reportingTransaction["Customer Id"],
                        "Component Type": reportingTransaction["componentType"],
                        "Job Id": reportingTransaction["Job Id"],
                        IsActive: reportingTransaction["Is Active"],
                        "Reference Number":
                            reportingTransaction["Reference Number"],
                        "Expense Type":
                            reportingTransaction["Expense Type"] || "\\N",
                        "Expense Code":
                            reportingTransaction["Expense Code"] || "\\N",
                        Class: reportingTransaction["Class"] || "\\N",
                        Department: reportingTransaction["Department"] || "\\N",
                        Location: reportingTransaction["Location"] || "\\N",
                        "Sales Person":
                            reportingTransaction["Sales Person"] || "\\N",
                        "Active Link Id":
                            reportingTransaction["Active Link Id"],
                        Currency: reportingTransaction["Currency"],
                        "Home Currency": reportingTransaction["Home Currency"],
                        IsAdjustment: reportingTransaction["IsAdjustment"],
                        Transaction_Id: "\\N"
                    },
                    transaction
                );

                baseEntry["Channel"] = reportingTransaction["Channel"];
                baseEntry["App Name"] = reportingTransaction["App Name"];

                let debitEntry = Object.assign(
                    {
                        "Account Id": debitAccount && debitAccount.id,
                        "Account Type":
                            debitAccount && debitAccount.accountType,
                        "Debit Amount": amount,
                        "Credit Amount": 0,
                        "Net Amount": amount
                    },
                    baseEntry,
                    {
                        "Journal Mapping Key":
                            baseJournalMappingValue.length > 0
                                ? baseJournalMappingValue +
                                  ":=:" +
                                  debitAccount.accountType
                                : debitAccount.accountType
                    }
                );

                let creditEntry = Object.assign(
                    {
                        "Account Id": creditAccount && creditAccount.id,
                        "Account Type":
                            creditAccount && creditAccount.accountType,
                        "Debit Amount": 0,
                        "Credit Amount": amount == 0 ? 0 : -1 * amount,
                        "Net Amount": amount == 0 ? 0 : -1 * amount
                    },
                    baseEntry,
                    {
                        "Journal Mapping Key":
                            baseJournalMappingValue.length > 0
                                ? baseJournalMappingValue +
                                  ":=:" +
                                  creditAccount.accountType
                                : creditAccount.accountType
                    }
                );

                journalEntries.push(debitEntry, creditEntry);
            });

            return journalEntries;
        }
    }
};
export function getTransactionsForExpenseReporting(salesOrder) {
    let transactions = salesOrder.expenseTransactions;

    let expenseArrangementItemById = salesOrder.revenueArrangement.reduce(
        (result, arrangement) => {
            arrangement.revenueArrangementItem.forEach((rai) => {
                rai.expenseItems.forEach((item) => {
                    result[item.id] = item;
                });
            });
            return result;
        },
        {}
    );

    let revenueArrangementItemById = salesOrder.revenueArrangement.reduce(
        (result, arrangement) => {
            arrangement.revenueArrangementItem.forEach(
                (item) => (result[item.id] = item)
            );
            return result;
        },
        {}
    );

    transactions.sort(
        Comparator.getComparator(
            ["salesOrderExpenseItemId", "postingPeriod"],
            [Comparator.forType("string"), Comparator.forType("period")]
        )
    );

    let beginningCommissionPayable = 0;
    let curSalesOrderItemId;
    let curSalesOrderItemExpenseId;
    let currReferenceNumber;
    let currClass;
    let currDepartment;
    let currLocation;
    let curSalesOrderItemRootId;
    let curPostingPeriod;
    let reportingTransaction;
    let reportingTransactions = [];

    // ensure last transaction is posted
    transactions.push(endOfTransactions);
    transactions.forEach((transaction) => {
        // Only report the unearned revenue componement
        if (
            !transaction.end &&
            transaction.componentType != ComponentType.EXPENSE
        ) {
            return;
        }

        if (
            curSalesOrderItemId != transaction.salesOrderItemId ||
            curSalesOrderItemExpenseId != transaction.salesOrderExpenseItemId ||
            currReferenceNumber != transaction.referenceNumber ||
            currClass != transaction.class ||
            currDepartment != transaction.department ||
            currLocation != transaction.location ||
            curPostingPeriod != transaction.postingPeriod
        ) {
            if (reportingTransaction) {
                reportingTransaction["Ending Commission Payable"] =
                    beginningCommissionPayable -
                    reportingTransaction["Actual Commission this month"];
                reportingTransaction["Commission Amount"] =
                    reportingTransaction["Ending Commission Payable"] +
                    reportingTransaction["Commission Expense"] -
                    reportingTransaction["Beginning Commission Payable"];
                reportingTransactions.push(reportingTransaction);

                if (
                    curSalesOrderItemRootId ==
                        transaction.salesOrderItemRootId &&
                    curSalesOrderItemExpenseId ==
                        transaction.salesOrderExpenseItemId
                ) {
                    beginningCommissionPayable =
                        reportingTransaction["Ending Commission Payable"];
                } else {
                    beginningCommissionPayable = 0;
                }
            }

            curSalesOrderItemId = transaction.salesOrderItemId;
            curSalesOrderItemRootId = transaction.salesOrderItemRootId;
            curSalesOrderItemExpenseId = transaction.salesOrderExpenseItemId;
            currReferenceNumber = transaction.referenceNumber;

            currClass = transaction.class;
            currDepartment = transaction.department;
            currLocation = transaction.location;
            curPostingPeriod = transaction.postingPeriod;

            reportingTransaction = undefined;
        }

        // The end
        if (transaction.end) return;

        if (!reportingTransaction) {
            let {
                postingPeriod,
                revenueArrangementItemId,
                expenseArrangementItemId
            } = transaction;

            let revenueArrangementItem =
                revenueArrangementItemById[revenueArrangementItemId];
            const expenseArrangementItem =
                expenseArrangementItemById[expenseArrangementItemId];

            let { salesOrderItem } = revenueArrangementItem;

            let expenseActgPeriod = Utils.formatShortestDateWithYear(
                expenseArrangementItem.expenseDate
            );

            reportingTransaction = {
                "Actg Period": postingPeriod,
                Quarter: Utils.getQuarter(postingPeriod),
                Year: Utils.getYearFromPeriod(postingPeriod),
                "Customer Id": salesOrder.customerId,
                "Sales Order Id": salesOrder.id,
                "Sales Order Item Id": transaction.salesOrderItemRootId,
                "Sales Order Item Root Id": transaction.salesOrderItemRootId,
                "Product Id": salesOrderItem.product.id,
                "Beginning Commission Payable": Utils.parseAmount(
                    beginningCommissionPayable
                ),
                // Commission Amortization
                "Commission Expense": 0,
                "Actual Commission this month": 0,
                // New Deferral
                "Commission Amount":
                    (expenseActgPeriod == postingPeriod &&
                        expenseArrangementItem.allocatedExpense &&
                        Utils.parseAmount(
                            expenseArrangementItem.allocatedExpense
                        )) ||
                    0,
                "Job Id": salesOrder.jobId,
                IsActive: revenueArrangementItem.isActive,
                Class: expenseArrangementItem.class,
                Department: expenseArrangementItem.department,
                Location: expenseArrangementItem.location,
                "Expense Type": expenseArrangementItem.expenseType,
                "Expense Code": expenseArrangementItem.expenseCode,
                "Reference Number": expenseArrangementItem.referenceNumber,
                "Sales Order Expense Item Id":
                    expenseArrangementItem.salesOrderExpenseItemId,
                "Sales Person": expenseArrangementItem.salesPerson?.name,
                Channel: expenseArrangementItem.channel,
                "App Name": expenseArrangementItem.app_name,
                "Expense Transaction Date": expenseArrangementItem.expenseDate
            };
        }

        reportingTransaction["Actual Commission this month"] +=
            transaction.amount;

        if (transaction.transactionType == TransactionType.EXP_RECOGNIZED.name)
            reportingTransaction["Commission Expense"] += transaction.amount;
    });
    transactions.pop();
    salesOrder.expenseReportingTransactions = reportingTransactions;
}

export function generateExpenseTransactions(salesOrder, calendarConfig) {
    let config = CONFIG["expense"];

    let { getEndDate } = config;

    if (!salesOrder.currentRevenueArrangement) return;

    // Order arrangements by effective date.
    let comparator = (ra1, ra2) =>
        ra2.effectiveDate < ra1.effectiveDate ? 1 : -1;
    salesOrder.revenueArrangement.sort(comparator);

    // Collect transactions in following array.
    let transactions = [];
    let needOriginalTransactions = true;
    let firstPeriod = "210001";
    let lastPeriod = "200001";
    let pComp = Comparator.forType("period");

    const lastestVersionItem = {};
    salesOrder.currentRevenueArrangement.revenueArrangementItem.forEach(
        (rai) => {
            rai.expenseItems.forEach((item) => {
                let _itemId =
                    item.salesOrderItemRootId || item.salesOrderItemId;
                lastestVersionItem[_itemId] = rai.salesOrderItem.id;
            });
        }
    );

    // For each arrangement,
    salesOrder.revenueArrangement.forEach((revenueArrangement) => {
        revenueArrangement.revenueArrangementItem.forEach((rai) => {
            rai.expenseItems.forEach((item) => {
                let _itemId =
                    item.salesOrderItemRootId || item.salesOrderItemId;
                if (!lastestVersionItem[_itemId])
                    lastestVersionItem[_itemId] = rai.salesOrderItem.id;
            });
        });

        if (revenueArrangement.state == "New") {
            return;
        }

        let { endDate, modificationType } = revenueArrangement;
        const arrangementFirstActgPeriod = Period.toActgPeriod(
            revenueArrangement.effectiveDate,
            calendarConfig
        );

        // If end date is null i.e. arrangement is active
        if (!endDate) {
            // Set end date to the   deliveryEndDate of the revenueArrangementItem with longest duration
            endDate =
                revenueArrangement.revenueArrangementItem[0].deliveryEndDate;
            revenueArrangement.revenueArrangementItem.forEach((rai) => {
                rai.expenseItems.forEach((item) => {
                    let _endDate = getEndDate(item);
                    if (_endDate > endDate) {
                        endDate = _endDate;
                    }
                });
            });
        }

        const endPeriod = Period.toActgPeriod(endDate, calendarConfig);
        endDate = endPeriod.endDate;

        // Current effective date is arrangement's effective date, this might change if arrangement has retrospective mod.
        const currentActgPeriod = Period.toActgPeriod(
            Period.currentDate(),
            calendarConfig
        );
        const currentPostingPeriod = currentActgPeriod.period;
        const currentPostingPeriodDate = currentActgPeriod.endDate;

        // If retrospective MOD
        if (
            modificationType &&
            (modificationType === ModificationType.RETROSPECTIVE ||
                modificationType === ModificationType.PROSPECTIVE)
        ) {
            // Create reversal transaction for all existing entries
            let reversals = transactions.reduce((result, transaction) => {
                // Only reverse where transaction type is REVENUE.
                if (!transaction.skipReversal) {
                    const actgPeriod = arrangementFirstActgPeriod;
                    const isFutureTransaction = Period.isSameOrAfter(
                        actgPeriod.startDate,
                        currentPostingPeriodDate
                    );

                    const reversal = {
                        id: transaction.id + "_reversal",
                        actgPeriod,
                        revenueArrangementItemId:
                            transaction.revenueArrangementItemId,
                        expenseArrangementItemId:
                            transaction.expenseArrangementItemId,
                        salesOrderItemId:
                            lastestVersionItem[
                                transaction.salesOrderItemRootId
                            ],
                        salesOrderItemRootId: transaction.salesOrderItemRootId,
                        salesOrderExpenseItemId:
                            transaction.salesOrderExpenseItemId,
                        effectiveDate: actgPeriod.endDate,
                        postingDate: actgPeriod.postingDate,
                        period: transaction.period,
                        postingPeriod: actgPeriod.period,
                        postingYear: actgPeriod.strYear,
                        amount: -1 * transaction.amount,
                        transactionType: transaction.transactionType,
                        componentType: transaction.componentType,
                        isFutureTransaction: isFutureTransaction,
                        totalBooking: transaction.totalBooking,
                        processType: "REVERSAL",
                        productId: transaction.productId,
                        expenseProductCode: transaction.expenseProductCode,
                        expenseCode: transaction.expenseCode,
                        expenseType: transaction.expenseType,
                        referenceNumber: transaction.referenceNumber,
                        class: transaction.class,
                        department: transaction.department,
                        location: transaction.location,
                        customerId: transaction.customerId,
                        salesOrderId: salesOrder.id,
                        isActive: transaction.isActive,
                        isModification: true,
                        salesPerson: transaction.salesPerson,
                        channel: transaction.channel,
                        app_name: transaction.app_name,
                        expenseDate: transaction.expenseDate
                    };

                    // No need to reverse again in case there are more mods.
                    transaction.skipReversal = true;
                    reversal.skipReversal = true;

                    /*
                            reversal.cummulativePercentRecognized += (reversal.amount / reversal.totalBooking)
                            */
                    result.push(reversal);
                }
                return result;
            }, []);

            transactions.push(...reversals);

            needOriginalTransactions = true;
        }

        if (needOriginalTransactions) {
            let originActgPeriod = Period.toActgPeriod(
                revenueArrangement.effectiveDate,
                calendarConfig
            );

            let isMod = revenueArrangement.state == "Closed";
            revenueArrangement.revenueArrangementItem.forEach((rai) => {
                let { salesOrderItem } = rai;
                rai.expenseItems.forEach((item) => {
                    const _originActgPeriod = Period.toActgPeriod(
                        item.expenseDate,
                        calendarConfig
                    );

                    if (_originActgPeriod.period > originActgPeriod.period) {
                        // Expense Date is after current revenue arrangement we are
                        // iterating over
                        originActgPeriod = _originActgPeriod;
                    }

                    const isFutureTransaction = Period.isSameOrAfter(
                        originActgPeriod.endDate,
                        currentPostingPeriodDate
                    );

                    if (originActgPeriod.period < firstPeriod)
                        firstPeriod = originActgPeriod.period;
                    if (originActgPeriod.period > lastPeriod)
                        lastPeriod = originActgPeriod.period;

                    let totalBooking = config.getTotalBooking(item);
                    let transaction = {
                        id: shortid.generate(),
                        actgPeriod: originActgPeriod,
                        revenueArrangementItemId: rai.id,
                        expenseArrangementItemId: item.id,
                        salesOrderItemId:
                            lastestVersionItem[
                                item.salesOrderItemRootId ||
                                    item.salesOrderItemId
                            ],
                        salesOrderItemRootId:
                            item.salesOrderItemRootId || item.salesOrderItemId,
                        salesOrderExpenseItemId: item.salesOrderExpenseItemId,
                        effectiveDate: originActgPeriod.endDate,
                        postingDate: originActgPeriod.postingDate,
                        period: originActgPeriod.period,
                        postingPeriod: originActgPeriod.period,
                        postingYear: originActgPeriod.strYear,
                        amount: -1 * totalBooking,
                        transactionType: config.originalTransactionType,
                        componentType: config.componentType,
                        isFutureTransaction: isFutureTransaction,
                        totalBooking: totalBooking,
                        processType: "NORMAL",
                        productId: salesOrderItem.productId,
                        expenseProductCode: item.productCode,
                        expenseCode: item.expenseCode,
                        expenseType: item.expenseType,
                        referenceNumber: item.referenceNumber,
                        class: item.class,
                        department: item.department,
                        location: item.location,
                        customerId: salesOrder.customerId,
                        salesOrderId: salesOrder.id,
                        isActive: item.isActive,
                        isModification: isMod,
                        salesPerson: item.salesPerson?.name,
                        channel: item.channel,
                        app_name: item.app_name,
                        expenseDate: item.expenseDate
                    };
                    transactions.push(transaction);
                });
            });
            needOriginalTransactions = false;
        }

        revenueArrangement.revenueArrangementItem.forEach((rai) => {
            let { salesOrderItem } = rai;
            rai.expenseItems.forEach((item) => {
                let plan = config.getPlan(item);

                let expenseEffectiveDateToCheckAgainstPlanEffectiveDate;
                if (
                    Period.isAfter(
                        item.expenseDate,
                        revenueArrangement.effectiveDate
                    )
                ) {
                    // Expense Date is after current revenue arrangement we are
                    // iterating over
                    expenseEffectiveDateToCheckAgainstPlanEffectiveDate =
                        item.expenseDate;
                } else {
                    expenseEffectiveDateToCheckAgainstPlanEffectiveDate =
                        revenueArrangement.effectiveDate;
                }

                // let's say a closed state revenueArrangement is type mod for now
                let isMod = revenueArrangement.state == "Closed";
                let newTransactions = plan.reduce((result, planElement) => {
                    // Scan period from current effective date to end date
                    // we are comparing endDate (function) which would give us un-expected behavior
                    let _endDate = Utils.formatDate(endDate); // TODO: why do we need this??
                    if (
                        isMod &
                            Period.isBefore(
                                planElement.actgPeriod.effectiveDate,
                                _endDate
                            ) ||
                        (!isMod &&
                            Period.isSameOrBefore(
                                planElement.actgPeriod.effectiveDate,
                                _endDate
                            ))
                    ) {
                        let postingDate = planElement.actgPeriod.postingDate;
                        let postingPeriod = planElement.actgPeriod.period;
                        let effectiveDate =
                            planElement.actgPeriod.effectiveDate;
                        let period = planElement.actgPeriod.period;
                        let postingYear = Period.toYear(effectiveDate);

                        if (isNaN(planElement.planAmount))
                            throw new Error("Bad plan found");

                        if (
                            Period.isSameOrBefore(
                                effectiveDate,
                                expenseEffectiveDateToCheckAgainstPlanEffectiveDate
                            )
                        ) {
                            postingDate =
                                arrangementFirstActgPeriod.postingDate;
                            postingPeriod = arrangementFirstActgPeriod.period;
                            postingYear = arrangementFirstActgPeriod.strYear;
                        }

                        // Create plan transaction.
                        let t = {
                            id: shortid.generate(),
                            actgPeriod: planElement.actgPeriod,
                            revenueArrangementItemId: rai.id,
                            expenseArrangementItemId: item.id,
                            salesOrderItemId:
                                lastestVersionItem[
                                    item.salesOrderItemRootId ||
                                        item.salesOrderItemId
                                ],
                            salesOrderItemRootId:
                                item.salesOrderItemRootId ||
                                item.salesOrderItemId,
                            salesOrderExpenseItemId:
                                item.salesOrderExpenseItemId,
                            effectiveDate: effectiveDate,
                            postingDate: postingDate,
                            period: period,
                            postingPeriod: postingPeriod,
                            postingYear: postingYear,
                            amount: planElement.planAmount,
                            transactionType:
                                config.ammortizationTransactionType,
                            componentType: config.componentType,
                            isFutureTransaction: Period.isSameOrAfter(
                                postingPeriod,
                                currentPostingPeriod
                            ),
                            totalBooking: config.getTotalBooking(item),
                            processType: "NORMAL",
                            productId: salesOrderItem.productId,
                            expenseProductCode: item.productCode,
                            expenseCode: item.expenseCode,
                            expenseType: item.expenseType,
                            referenceNumber: item.referenceNumber,
                            class: item.class,
                            department: item.department,
                            location: item.location,
                            customerId: salesOrder.customerId,
                            salesOrderId: salesOrder.id,
                            jobId: salesOrder.jobId,
                            isActive: item.isActive,
                            isModification: isMod,
                            salesPerson: item.salesPerson?.name,
                            channel: item.channel,
                            app_name: item.app_name,
                            expenseDate: item.expenseDate
                        };

                        result.push(t);

                        if (pComp(postingPeriod, firstPeriod) < 0)
                            firstPeriod = postingPeriod;

                        if (pComp(postingPeriod, lastPeriod) > 0)
                            lastPeriod = postingPeriod;
                    }

                    return result;
                }, []);

                transactions.push(...newTransactions);
            });
        });
    });

    salesOrder["expenseTransactions"] = transactions;
    salesOrder["expenseFirstPeriod"] = firstPeriod;
    salesOrder["expenseLastPeriod"] = lastPeriod;
}

export function generateExpenseWaterFall(salesOrder) {
    let config = CONFIG["expense"];

    let transactions = salesOrder["expenseTransactions"];

    let waterFall = {};

    salesOrder.revenueArrangement.forEach((ra) => {
        ra.revenueArrangementItem.forEach((rai) => {
            rai.expenseItems.forEach((expItem) => {
                const itemId =
                    expItem.salesOrderItemRootId || expItem.salesOrderItemId;
                waterFall[itemId] = {
                    salesOrderItem: expItem,
                    salesOrderItemId: itemId
                };
            });
        });
    });

    let currentYear = Utils.getYear(Utils.currentDate());
    let currentPostingPeriod = Utils.formatShortestDateWithYear(
        Utils.currentDate()
    );
    let totalPeriodWaterFall = { salesOrderItemId: 0 };
    waterFall[0] = totalPeriodWaterFall;

    transactions.forEach((transaction) => {
        if (transaction.transactionType != config.ammortizationTransactionType)
            return;

        // Monthly water fall for current year only.
        let period = transaction.postingPeriod;
        let year = transaction.postingYear;

        let itemWaterFall = waterFall[transaction.salesOrderItemRootId];

        // By monthly period
        let periodAmount = itemWaterFall[period] || 0;
        periodAmount += transaction.amount || 0;
        itemWaterFall[period] = periodAmount;

        // By year
        let totalYearAmount = itemWaterFall[year] || 0;
        totalYearAmount += transaction.amount || 0;
        itemWaterFall[year] = totalYearAmount;

        // Year to date
        if (
            (!transaction.isFutureTransaction && year == currentYear) ||
            (transaction.isFutureTransaction && period == currentPostingPeriod)
        ) {
            let ytdAmount = itemWaterFall["ytd"] || 0;
            ytdAmount += transaction.amount || 0;
            itemWaterFall["ytd"] = ytdAmount;
        }

        // Full total
        let totalPeriodAmount = totalPeriodWaterFall[period] || 0;
        totalPeriodAmount += transaction.amount || 0;
        totalPeriodWaterFall[period] = totalPeriodAmount;
    });

    salesOrder["expenseWaterFall"] = waterFall;
}

export function generateExpenseJournalReportData(
    salesOrder,
    journalAccounts,
    transactions,
    jeMappingColumns,
    orgConfig
) {
    let config = CONFIG["expense"];

    let journalEntries = [];

    let defaultAccounts = {};
    journalAccounts.forEach((account) => {
        if (account.isDefault) defaultAccounts[account.accountType] = account;
    });

    const bookJEExchangeRateSeparatelyConfig = orgConfig.find(
        (cfg) => cfg.id === "properties/journalentries/book-exchange-rate"
    );
    const bookJEExchangeRateSeparately =
        bookJEExchangeRateSeparatelyConfig &&
        bookJEExchangeRateSeparatelyConfig.value;

    transactions.forEach((transaction) => {
        if (
            (transaction.componentType === config.componentType ||
                transaction["Component Type"] === config.componentType) &&
            (transaction.IsActive == undefined || transaction.IsActive == true)
        ) {
            // Only report the expense component
            journalEntries.push(
                ...config.createEntries(
                    transaction,
                    salesOrder,
                    transaction["Sales Order Item Id"],
                    config,
                    defaultAccounts,
                    jeMappingColumns,
                    bookJEExchangeRateSeparately
                )
            );
        }
    });

    config.addJournalEntries(salesOrder, journalEntries);
}
