import { Period } from 'revlock-webutils'
import { assertValidPlan } from '../ArrangementUtils'

export default {
	buildPlan: function buildPlan (startDate, endDate, originalBooking,
		endDateInclusive = false, exactDays, calendarConfig) {
		if (calendarConfig && calendarConfig.type === 'retail') {
			throw new Error(
				`FixedMonthRatable is not supported for calendar ${calendarConfig.type}`)
		}

		if (Period.isBefore(endDate, startDate)) {
			throw new Error(`Plan starts ${startDate} before it ends ${endDate}`)
		}

		const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig)
		const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig)

		let firstPeriodDays = (startDate === endDate) ? 30 : (exactDays
			? startActgPeriod.numberOfDays()
			: 30) - Period.getDays(startDate) + 1
		//if the start and end date are the same then all revenue goes here.

		let lastPeriodDays = (startDate === endDate) ? 0 : Period.getDays(endDate) -
			(endDateInclusive ? 0 : 1)

		if (endDateInclusive && lastPeriodDays != 0 &&
			((endActgPeriod.strMonth !== '02' && lastPeriodDays > 30) ||
				(endActgPeriod.strMonth == '02' && endActgPeriod.endDate == endDate))) {
			// not feb and month end is 31 or feb and month end is (28, 29 days)
			lastPeriodDays = 30
		}

		let periods = (startActgPeriod.period === endActgPeriod.period)
			? [startActgPeriod.period]
			: Period.periodsBetween(startActgPeriod.period, endActgPeriod.period,
				true, true)
		//between periods

		if (startDate !== endDate && startActgPeriod.period ===
			endActgPeriod.period) {
			// Since the last day in last period day in excluded, we want to use last period days.
			firstPeriodDays = lastPeriodDays
			lastPeriodDays = 0
		}

		let totalContractRevenueDays = firstPeriodDays + lastPeriodDays +
			(periods.length > 2 ? 30 * (periods.length - 2) : 0)

		if (totalContractRevenueDays === 0) {
			totalContractRevenueDays = 1
			firstPeriodDays = 1
		}

		const planRate = originalBooking / totalContractRevenueDays

		const plan = []
		let cummulativeTotal = 0

		let actgPeriod = startActgPeriod

		for (let period of periods) {
			actgPeriod = actgPeriod.get(period)

			let planAmount = 0
			if (period === startActgPeriod.period) {
				planAmount = firstPeriodDays * planRate
			} else if (period === endActgPeriod.period) {
				planAmount = lastPeriodDays * planRate
			} else {
				planAmount = 30 * planRate
			}
			//calculate the amount for the period.

			cummulativeTotal += planAmount

			plan.push({
				actgPeriod,
				planAmount,
			})
		}

		assertValidPlan(plan)

		return plan

	},
}