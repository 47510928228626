export default Object.freeze({
    RATABLE: 1,
    QUANTITY_DELIVERED: 2 /* NO LONGER SUPPORTED */,
    POINT_IN_TIME: 3,
    PROPORTIONAL_PERFORMANCE: 4,
    ROYALTY: 5 /* NO LONGER SUPPORTED */,
    PERCENT_OF_COMPLETE: 6,
    EIM: 7,
    TRACK_OTHER_PRODUCT_REVENUE: 8
});
