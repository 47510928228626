import AppEntities from '../AppEntities'
import GenericSelectors from '../GenericSelectors';

const Selectors = {}

Object.keys(AppEntities).forEach(entityName => Selectors[entityName] = GenericSelectors(entityName));

Selectors.newEntity = (entityName) => {
    Selectors[entityName] = GenericSelectors(entityName)
}

export const ProductSelectors = Selectors['product'];
export const CustomerSelectors = Selectors['customer'];
export const SyncSelectors = Selectors['sync'];
export const SalesOrderSelectors = Selectors['salesOrder'];

export default Selectors;
