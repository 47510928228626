import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import {Route} from 'react-router-dom'
import config from "../cfg/config";

const analyticPaths = {
    "/:orgId": "dashboards",
    "/:orgId/mstr-native-report/:reportType/:reportId": "report",
    "/:orgId/reports/:reportType/:reportId": "report",
    "/:orgId/pages/salesorder/:salesOrderId": "salesOrder",
    "/:orgId/pages/salesorder/v/:salesOrderId": "salesOrderVersion",
    "/:orgId/esp/version/:versionId": "espVersion",
    "/:orgId/client-table/:entity": "clientTable",
    "/:orgId/pages/sync/:syncId": "syncStatus"
}

function getTitle(pathname){
    const pathComponents =
      pathname.split("/")
    for(let [path, name] of Object.entries(analyticPaths)){
        const comp = path.split("/");
        if(comp.length === pathComponents.length){
            let i = 1;
            do {
                if(comp[i].startsWith(":") || comp[i] === pathComponents[i]){
                    i++;
                    continue;
                }
                else {
                    break;
                }
            } while ( i < comp.length);

            if (i === comp.length) {
                return name
            }
        }
    }

    return pathname && pathname.split("/").slice(-1);
}

const isAnalyticsEnabled = () => {
    return true; //!config.development
}

class GoogleAnalytics extends Component {
    componentDidMount() {
        this.logPageChange(
            this.props.location.pathname,
            this.props.location.search
        )
    }

    componentDidUpdate({location: prevLocation}) {
        const {location: {pathname, search}, match} = this.props
        const isDifferentPathname = pathname !== prevLocation.pathname
        const isDifferentSearch = search !== prevLocation.search

        if (isDifferentPathname || isDifferentSearch) {
            this.logPageChange(pathname, search)
        }
    }

    logPageChange(pathname, search = '') {
        const page = pathname + search
        const {location} = window
        const title = getTitle(pathname);
        if (isAnalyticsEnabled()) {
            ReactGA.set({
                page,
                title,
                location: `${location.origin}${page}`,
                ...this.props.options
            })
            ReactGA.pageview(page)
        }
    }

    render() {
        return null
    }
}

GoogleAnalytics.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
    }).isRequired,
    options: PropTypes.object
}

const RouteTracker = () =>
    <Route component={GoogleAnalytics}/>


const init = (options = {}) => {
    const { analytics } = config

    if (analytics) {
        const env = window._env_ || {
            REACT_APP_GA_TRACKING_ID: analytics.trackingId,
            REACT_APP_GA_DEBUG: analytics.debug || false,
        }
        if (isAnalyticsEnabled()) {
            ReactGA.initialize(env.REACT_APP_GA_TRACKING_ID, {
                debug: env.REACT_APP_GA_DEBUG, ...options,
            })
        } else {
            console.log('Google Analytics is disabled')
        }
    }

    return isAnalyticsEnabled() && analytics || false;
}

const setField = (params) => {
    if (isAnalyticsEnabled()) {
        const ga = ReactGA.ga();
        for (let key of Object.keys(params)) {
            ga && ga('set', key, params[key]);
        }
    }
}

const setEvent = (eventParams) =>{
    if(isAnalyticsEnabled()){
        ReactGA.event(eventParams);
    }
}

export {ReactGA};

export default {
    GoogleAnalytics,
    RouteTracker,
    init,
    setField,
    setEvent
}
