import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { getPlan } from "utils/auth";
import { A } from "components/ImgIcon";
import {
    Box,
    Button,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Slide,
    Typography
} from "@material-ui/core";
import { getPlansForUpgrade } from "../utils/auth";
import { CloseRounded as CloseIcon } from "@material-ui/icons";
import {
    currentOrganization,
    getOrganizationUserLicenses,
    getOrganizationUsers
} from "redux/selectors";
import {
    quickViewBanner,
    showQuickViewBanner,
    showSuspendedBanner
} from "redux/selectors/data.selector";
import connect from "react-redux/es/connect/connect";
import { contactSales, upgradeLicensing } from "../rest/API";
import swal from "sweetalert";
import GA from "utils/GoogleAnalytics";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    },
    media: {
        height: 100,
        objectFit: "contain"
    },
    title: {
        color: "#6200ea",
        fontSize: "20px"
    },
    mainheading: {
        color: "#6200ea",
        fontSize: "25px"
    },
    selected: {
        border: `2px solid ${fade(theme.palette.primary.main, 0.4)}`
    },
    warning: {
        color: theme.palette.secondary.main
    },
    dialogTitle: {
        backgroundColor: "rgba(0,0,0,0.4)",
        display: "flex",
        justify: "flex-start"
    },
    actionsRoot: {
        justifyContent: "flex-start"
    },
    link: {
        paddingLeft: 2,
        color: theme.palette.background.link.light
    }
}));

const suspendedBanner = {
    heading: "Your account has been temporarily suspended",
    message:
        "To reinstate access to your Chargebee RevRec account please contact <a href='mailto:ar@chargebee.com'>ar@chargebee.com</a>"
};

function PlanSplash(props) {
    const {
        handleInquiry,
        handleUpgrade,
        plans,
        currentPlan,
        nextPlan
    } = props;
    const classes = useStyles();

    const splashOtherMessage = React.useMemo(() => {
        if (currentPlan.name === "rev-essential") {
            return (
                <>
                    <br />
                    {props.showQuickViewBanner && (
                        <>
                            <Typography
                                variant="body1"
                                className={classes.warning}
                            >
                                Quick Note:
                            </Typography>
                            <br />
                            <Typography variant="body1" color={"textSecondary"}>
                                The report you are about to see is not based on
                                your actual RevRec data and is for demo purpose
                                - remember to try out the cool features such as
                                drill down, sort and reformat.
                            </Typography>
                            <br />
                        </>
                    )}

                    <Typography variant="body1" color={"textSecondary"}>
                        Learn more about all the great{" "}
                        <A
                            href={
                                "https://www.chargebee.com/docs/revrec/revrec-reporting.html"
                            }
                        >
                            accounting and control features
                        </A>{" "}
                        available when you upgrade from your current
                        RevRec-QuickView plan.
                    </Typography>
                </>
            );
        }

        return null;
    }, [currentPlan]);
    const toPeriod = (plan) => {
        switch (plan.term) {
            case "monthly":
                return "/mo";
            case "annual":
                return "/yr";
            default:
                return "";
        }
        return "";
    };

    const upgradeToPlan = nextPlan ? nextPlan.name : "rev-up";
    const upgradePlan = plans.find((p) => p.name === upgradeToPlan);

    return (
        <Grid container spacing={0} justify={"center"} alignItems={"center"}>
            <Grid item xs={12}>
                <div
                    style={{
                        paddingBottom: "20px"
                    }}
                >
                    <Typography variant="body1" color={"textSecondary"}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html:
                                    (props.showSuspendedBanner &&
                                        suspendedBanner.message) ||
                                    props.quickViewBanner?.message ||
                                    "RevRec offers a wide range of features that can help you scale"
                            }}
                        ></span>
                        &nbsp;
                        {props.quickViewBanner?.link && (
                            <>
                                <a
                                    className={classes.link}
                                    size="medium"
                                    variant="outlined"
                                    color="primary"
                                    href={
                                        props.quickViewBanner?.link ||
                                        `https://www.chargebee.com/docs/revrec/quick-start.html`
                                    }
                                    target="_black"
                                >
                                    Learn More
                                </a>
                            </>
                        )}
                    </Typography>
                    {/*splashOtherMessage*/}
                </div>
                {props.showSuspendedBanner ? (
                    <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={(e) => window.open("mailto:ar@chargebee.com")}
                    >
                        Contact Us
                    </Button>
                ) : (
                    <Button
                        size="medium"
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleInquiry(upgradePlan, e)}
                    >
                        Talk to an Expert
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UpgradeSplashDialog(props) {
    const { onClose, open, currentOrganization, quickViewBanner } = props;
    const classes = useStyles();

    if (!currentOrganization) return <LinearProgress />;

    const currentPlan = getPlan();

    const isTrial = currentPlan && currentPlan.isTrial;
    const plans = getPlansForUpgrade();
    const nextPlan = plans.nextPlan;

    const handleInquiry = async (option, e) => {
        e.stopPropagation();
        try {
            await contactSales(
                currentOrganization.id,
                option.type,
                option.name
            );

            onClose && onClose();

            await swal({
                title: `Thank you!!`,
                icon: "success",
                text: `Our RevRec teammate will contact you shortly.`
            });
        } catch (err) {
            await swal({
                title: "Error",
                icon: "error",
                text: `Something didn't quite work ${err.errorMessage}`,
                error: true,
                timer: 2000,
                buttons: false
            });
        }
    };

    const handleUpgrade = async (option, e) => {
        e.stopPropagation();

        const { invalidateLicense, quickViewBanner } = props;

        try {
            await upgradeLicensing(
                currentOrganization.id,
                option.type,
                option.name,
                true
            );

            await GA.setEvent({
                category: "Upgrade",
                action: `License - ${nextPlan && nextPlan.name}`,
                value: 1
            });

            invalidateLicense();
            onClose && onClose();

            await swal({
                title: `Congratulations`,
                icon: "success",
                text: `Your trial license has been activated for 30 days.`,
                buttons: true
            });
        } catch (err) {
            await swal({
                title: "Error",
                icon: "error",
                text: `Something didn't quite work ${err.errorMessage}`,
                error: true,
                timer: 2000,
                buttons: false
            });
        }
    };

    return (
        <Dialog
            onClose={onClose}
            open={open}
            TransitionComponent={Transition}
            maxWidth={"sm"}
        >
            <DialogTitle
                style={{
                    padding: "24px 16px 0px 16px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <Typography
                        variant={"h4"}
                        color={"primary"}
                        style={{ paddingLeft: "10px" }}
                    >
                        {(props.showSuspendedBanner &&
                            suspendedBanner.heading) ||
                            quickViewBanner?.heading ||
                            "Access to this feature requires an upgrade"}
                    </Typography>
                    <IconButton size={30} onClick={onClose}>
                        <CloseIcon size={"small"} />
                    </IconButton>
                </div>
            </DialogTitle>
            {/*<Divider />*/}
            <DialogContent
                style={{
                    overflowY: "auto",
                    padding: "0px 16px 24px 16px"
                }}
            >
                <PlanSplash
                    {...props}
                    {...plans}
                    currentPlan={currentPlan}
                    nextPlan={nextPlan}
                    handleUpgrade={handleUpgrade}
                    handleInquiry={handleInquiry}
                />
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        currentOrganization: currentOrganization(state),
        organizationUsers: getOrganizationUsers(state),
        organizationUserLicenses: getOrganizationUserLicenses(state),
        showQuickViewBanner: showQuickViewBanner(state),
        showSuspendedBanner: showSuspendedBanner(state),
        quickViewBanner: quickViewBanner(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        invalidateLicense: () => {
            dispatch({
                type: "ORGANIZATION_INVALIDATE"
            });

            dispatch({
                type: "ORGANIZATION_USERS_INVALIDATE"
            });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpgradeSplashDialog);
