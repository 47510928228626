import cloneDeep from "lodash.clonedeep";
//https://stackoverflow.com/questions/222309/calculate-last-day-of-month
function getDaysInMonth(m, y) {
    return m === 2
        ? y & 3 || (!(y % 25) && y & 15)
            ? 28
            : 29
        : 30 + ((m + (m >> 3)) & 1);
}
const {
    WeekCalculation,
    WeekGrouping,
    LastDayOfWeek,
    LastMonthOfYear,
    LeapYearStrategy,
    RetailCalendarFactory
} = require("retail-calendar");
import Utils from "./Utils";

// validates yyyy-mm-dd
const simpleDateRegExp = new RegExp(
    /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
);

// validates mm/dd/yyyy
const mmddyyyyDateRegExp = new RegExp(
    /(0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])[\/](19|20)\d\d/
);

// validates dd/mm/yyyy
const ddmmyyyyDateRegExp = new RegExp(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
);

const periodRegExp = new RegExp(/^\d{6}$/);
const yearRegExp = new RegExp(/^\d{4}$/);
const isoDateRegExp = new RegExp(
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
);

const isISODate = (date) =>
    typeof date === "string" && isoDateRegExp.test(date);

const isInRequiredDateFormat = (date, dateFormat) => {
    if (typeof date === "string") {
        if (dateFormat && dateFormat === "DD/MM/YYYY") {
            return ddmmyyyyDateRegExp.test(date);
        } else if (dateFormat && dateFormat === "MM/DD/YYYY") {
            return mmddyyyyDateRegExp.test(date);
        }

        return simpleDateRegExp.test(date);
    }
};

const isSimpleDate = (date) =>
    typeof date === "string" && simpleDateRegExp.test(date);

const getDateRegexByDateFormat = (format) => {
    if (format === "DD/MM/YYYY") {
        return mmddyyyyDateRegExp;
    } else if (format === "MM/DD/YYYY") {
        return ddmmyyyyDateRegExp;
    } else {
        return "[0-9]{4}-[0-9]{2}-[0-9]{2}";
    }
};

const isPeriod = (date) => periodRegExp.test(date);
const isYear = (date) => yearRegExp.test(date);

function currentDatePeriod() {
    return toPeriod(currentDate());
}

function currentDate() {
    let currentDate = new Date().toISOString().substring(0, 10);
    if (process.env.CURRENT_DATE && isSimpleDate(process.env.CURRENT_DATE))
        currentDate = process.env.CURRENT_DATE;
    return currentDate;

    // '2023-03-01';
}

function toPreviousDay(daysBack) {
    const somePreviourDay = new Date();
    somePreviourDay.setDate(somePreviourDay.getDate() - daysBack);

    let currentDate = somePreviourDay.toISOString().substring(0, 10);
    if (process.env.CURRENT_DATE && isSimpleDate(process.env.CURRENT_DATE))
        currentDate = process.env.CURRENT_DATE;

    return currentDate;
}

function currentTimestamp() {
    if (process.env.CURRENT_DATE && isSimpleDate(process.env.CURRENT_DATE)) {
        return new Date(process.env.CURRENT_DATE).toISOString();
    }

    return new Date().toISOString();
}

function toEndOfMonth(date) {
    date = date || currentDate();

    if (isSimpleDate(date)) {
        const year = Number(date.substring(0, 4));
        const month = Number(date.substring(5, 7));

        return `${date.substring(0, 7)}-${getDaysInMonth(month, year)}`;
    }

    if (isPeriod(date)) {
        const year = Number(date.substring(0, 4));
        const month = date.substring(4, 6);
        return `${year}-${month}-${getDaysInMonth(Number(month), year)}`;
    }

    throw new Error("Unrecognized date " + date);
}

function toStartOfMonth(date) {
    date = date || currentDate();

    if (isSimpleDate(date)) {
        return `${date.substring(0, 7)}-01`;
    }

    if (isPeriod(date)) {
        const year = Number(date.substring(0, 4));
        const month = date.substring(4, 6);
        return `${year}-${month}-01`;
    }

    throw new Error("Unrecognized date " + date);
}

function toEndDateOfPreviousMonth(date) {
    date = date || currentDate();

    if (isSimpleDate(date)) {
        const _period = toPeriod(date);
        const _priorPeriod = priorPeriod(_period);
        return toEndOfMonth(_priorPeriod);
    }

    if (isPeriod(date)) {
        const _priorPeriod = priorPeriod(date);
        return toEndOfMonth(_priorPeriod);
    }

    throw new Error("Unrecognized date " + date);
}

function toStartDateOfPreviousMonth(date) {
    date = date || currentDate();

    if (isSimpleDate(date)) {
        const _period = toPeriod(date);
        const _priorPeriod = priorPeriod(_period);
        return toStartOfMonth(_priorPeriod);
    }

    if (isPeriod(date)) {
        const _priorPeriod = priorPeriod(date);
        return toStartOfMonth(_priorPeriod);
    }

    throw new Error("Unrecognized date " + date);
}

function toPeriodFromComponent(year, month) {
    if (!year) throw new Error("Invalid year");

    if (!month) throw new Error("Invalid month");

    month = Number(month) < 10 ? "0" + Number(month) : month;

    return `${year}${month}`;
}

function toPeriod(date) {
    date = date || currentDate();

    if (isSimpleDate(date))
        return `${date.substring(0, 4)}${date.substring(5, 7)}`;

    throw new Error("Unrecognized date " + date);
}

function roundedDaysBetween(d1, d2) {
    return Math.round(daysBetween(d1, d2, true, true));
}

function daysBetween(d1, d2, d1Inclusive = true, d2Inclusive = false) {
    if (!isSimpleDate(d1) || !isSimpleDate(d2))
        throw new Error("Unrecognized date " + d1 + ":" + d2);

    let days =
        (new Date(d2).getTime() - new Date(d1).getTime()) / (1000 * 3600 * 24);

    if (!d1Inclusive) days--;
    if (d2Inclusive) days++;

    return days;
}

function monthsBetween(
    d1,
    d2,
    funkyInclusive = true,
    funkyInclusiveLastMonth = false
) {
    const { month: month1, year: year1, day: day1 } = toComponents(d1);
    const { month: month2, year: year2, day: day2 } = toComponents(d2);

    function getMonthDiff(m1, m2) {
        return Number(m2) - Number(m1);
    }

    let months = 0;
    if (year2 !== year1) {
        months = (Number(year2) - Number(year1)) * 12;
    }

    months += getMonthDiff(month1, month2);

    if (funkyInclusive) {
        const monthId = Number(month2);
        const year = Number(year2);
        const lastDays = getDaysInMonth(monthId, year);
        if (
            Number(day2) === lastDays &&
            (funkyInclusiveLastMonth
                ? d1 !== d2
                : day1 !== day2 && month1 !== month2)
        )
            months++;
    } else months += 1;

    return months;
}
function toPostingPeriod(date, incrementMonth = false) {
    date = date || currentDate();

    if (!isSimpleDate(date)) throw new Error("Unrecognized date " + date);

    let { year, month } = toComponents(date);
    if (incrementMonth) {
        month = Number(month);
        year = Number(year);

        let nextMonth, nextYear;
        if (month === 12) {
            nextMonth = "01";
            nextYear = `${year + 1}`;
        } else {
            nextYear = `${year}`;
            nextMonth = month + 1 >= 10 ? `${month + 1}` : `0${month + 1}`;
        }
        return `${nextYear}-${nextMonth}-03`;
    }

    return `${year}-${month}-03`;
}

function numberOfDays(datePeriod) {
    const is_Period = isPeriod(datePeriod);
    const is_Date = isSimpleDate(datePeriod);

    if (!(is_Date || is_Period)) throw new Error("Not a valid period or date");

    let year, month;
    if (is_Period) {
        year = Number(datePeriod.substring(0, 4));
        month = datePeriod.substring(4, 6);
    }

    if (is_Date) {
        const d = toComponents(datePeriod);
        year = d.year;
        month = d.month;
    }

    const d = new Date(year, month, 0);
    return d.getDate();
}

function getDays(date) {
    if (!isSimpleDate(date)) throw new Error("Unrecognized date " + date);

    return new Number(date.substring(8, 10));
}

function toComponents(date) {
    date = date || currentDate();

    if (isSimpleDate(date)) {
        const day = date.substring(8, 10);
        const year = date.substring(0, 4);
        const month = date.substring(5, 7);

        return { day, year, month };
    }

    if (isPeriod(date)) {
        const year = date.substring(0, 4);
        const month = date.substring(4);
        return { year, month };
    }
}

function toQuarter(date) {
    date = date || currentDate();

    if (!(isSimpleDate(date) || isPeriod(date)))
        throw new Error("Unrecognized date " + date);

    const year = date.substring(0, 4);
    const month = isPeriod(date)
        ? Number(date.substring(4, 6))
        : Number(date.substring(5, 7));
    if (month >= 0 && month <= 3) {
        return `${year}1`;
    } else if (month >= 4 && month <= 6) {
        return `${year}2`;
    } else if (month >= 7 && month <= 9) {
        return `${year}3`;
    } else if (month >= 10 && month <= 12) {
        return `${year}4`;
    } else throw new Error("Invalid date");
}

function toYear(date) {
    date = date || currentDate();

    if (isSimpleDate(date) || isPeriod(date)) return date.substring(0, 4);
    else throw new Error("Unrecognized date " + date);
}

function isBefore(d1, d2) {
    if (
        (isSimpleDate(d1) && isSimpleDate(d2)) ||
        (isPeriod(d1) && isPeriod(d2))
    )
        return d1 < d2;

    throw new Error("Unrecognized date " + d1 + ":" + d2);
}

function isAfter(d1, d2) {
    if (
        (isSimpleDate(d1) && isSimpleDate(d2)) ||
        (isPeriod(d1) && isPeriod(d2)) ||
        (isYear(d1) && isYear(d2))
    )
        return d1 > d2;

    throw new Error("Unrecognized date " + d1 + ":" + d2);
}

function isSameOrBefore(d1, d2) {
    if (
        (isSimpleDate(d1) && isSimpleDate(d2)) ||
        (isPeriod(d1) && isPeriod(d2))
    )
        return d1 <= d2;
    throw new Error("Unrecognized date " + d1 + ":" + d2);
}

function isSameOrAfter(d1, d2) {
    if (
        (isSimpleDate(d1) && isSimpleDate(d2)) ||
        (isPeriod(d1) && isPeriod(d2))
    )
        return d1 >= d2;
    throw new Error("Unrecognized date " + d1 + ":" + d2);
}

function inPeriod(date, period) {
    if (!isSimpleDate(date) || !isPeriod(period))
        throw new Error("Unrecognized period or date");

    const periodYr = Number(period.substring(0, 4));
    const periodMonth = period.substring(4, 6);

    const dateYear = Number(date.substring(0, 4));
    const dateMonth = Number(date.substring(5, 7));

    return periodYr === dateYear && dateMonth === periodMonth;
}

function isSame(d1, d2) {
    if (!d1 && !d2) return d1 === d2;
    if ((!d1 && d2) || (!d2 && d1)) return false;

    if (
        (isSimpleDate(d1) && isSimpleDate(d2)) ||
        (isPeriod(d1) && isPeriod(d2))
    )
        return d1 === d2;
    throw new Error("Unrecognized date " + d1 + ":" + d2);
}

function nextYearPeriod(p) {
    if (!isPeriod(p)) throw new Error("Unrecognized period " + p);

    const year = Number(p.substring(0, 4));
    const month = p.substring(4, 6);

    const nextYear = year + 1;
    return `${nextYear}${month}`;
}

function priorYearPeriod(p) {
    if (!isPeriod(p)) throw new Error("Unrecognized period " + p);

    const year = Number(p.substring(0, 4));
    const month = p.substring(4, 6);

    const nextYear = year - 1;
    return `${nextYear}${month}`;
}

function __nextPeriod(p) {
    if (!isPeriod(p)) throw new Error("Unrecognized period " + p);

    const year = Number(p.substring(0, 4));
    const month = Number(p.substring(4, 6));

    let nextMonth, nextYear;
    if (month === 12) {
        nextMonth = "01";
        nextYear = `${year + 1}`;
    } else {
        nextYear = `${year}`;
        nextMonth = month + 1 >= 10 ? `${month + 1}` : `0${month + 1}`;
    }

    return `${nextYear}${nextMonth}`;
}

function priorPeriod(p) {
    if (!isPeriod(p)) throw new Error("Unrecognized period " + p);

    let year = Number(p.substring(0, 4));
    let month = Number(p.substring(4, 6));

    if (month == 1) {
        // we are on jan, we need to go back a year to dec
        month = 12;
        year = year - 1;
    } else {
        month = month - 1;
    }

    const toReturn = "" + `${year}${month < 10 ? "0" + month : month}`;

    return toReturn;
}

function addMonths(date, months) {
    if (!isSimpleDate(date)) throw new Error("Unrecognized date " + date);

    let { year, month, day } = toComponents(date);

    let newMonth = Number(month) + months;
    year = Number(year);

    while (newMonth > 12) {
        year++;
        newMonth -= 12;
    }

    while (newMonth <= 0) {
        year--;
        newMonth += 12;
    }

    const maxDay = getDaysInMonth(newMonth, year);
    day = day <= maxDay ? day : maxDay;

    return `${year}-${newMonth.toString().padStart(2, "0")}-${day}`;
}

function addYear(date) {
    let { year, month, day } = toComponents(date);

    // If adding a year to a leap day.
    if (month == "02" && day == "29") day = "28";

    return `${Number(year) + 1}-${month}-${day}`;
}

function nextYear(p) {
    if (!isYear(p)) throw new Error("Unrecognized period " + p);

    const year = Number(p);

    return year + 1;
}

function yearsBetween(p1, p2, p1inclusive = false, p2inclusive = false) {
    if (!isYear(p1) || !isYear(p2))
        throw new Error("Unrecognized period " + p1 + ":" + p2);

    if (!isAfter(p2, p1)) return [];

    let toReturn = [];

    if (p1 === p2) {
        if (p1inclusive) toReturn.push(Number(p1));
        if (p2inclusive) toReturn.push(Number(p2));
        return toReturn;
    }

    let currentYear = nextYear(p1);

    if (p1inclusive) toReturn.push(Number(p1));

    while (currentYear != p2) {
        toReturn.push(currentYear);
        currentYear = nextYear(currentYear);
    }

    if (p2inclusive) toReturn.push(Number(p2));

    return toReturn;
}

function periodsBetween(p1, p2, p1inclusive = false, p2inclusive = false) {
    if (!isPeriod(p1) || !isPeriod(p2))
        throw new Error("Unrecognized period " + p1 + ":" + p2);

    let toReturn = [];

    if (p1 === p2) {
        if (p1inclusive) toReturn.push(p1);
        if (p2inclusive) toReturn.push(p2);
        return toReturn;
    }

    if (!isAfter(p2, p1)) return [];

    let currentPeriod = __nextPeriod(p1);

    if (p1inclusive) toReturn.push(p1);

    while (currentPeriod != p2) {
        toReturn.push(currentPeriod);
        currentPeriod = __nextPeriod(currentPeriod);
    }

    if (p2inclusive) toReturn.push(p2);

    return toReturn;
}

function toPeriodComponents(period) {
    if (!isPeriod(period)) throw new Error("Not a recognized period" + period);
    const year = period.substring(0, 4);
    const month = period.substring(4, 6);

    const toQuarter = () => {
        const m = Number(month);
        if (m >= 1 && m <= 3) return 1;
        if (m >= 4 && m <= 6) return 2;
        if (m >= 7 && m <= 9) return 3;
        if (m >= 10 && m <= 12) return 4;
    };

    const quarter = `${year}${toQuarter()}`;

    return { year, month, quarter };
}

function toDate(period) {
    const { year, month } = toPeriodComponents(period);
    return `${year}-${month}-01`;
}

function addPeriods(period, periodCount) {
    const years = Math.round(periodCount / 12);
    const remainingCount = periodCount - years * 12;

    let nextPeriod = Number(period) + years * 100;

    const endMonth = Number(String(nextPeriod).slice(-2));
    if (remainingCount + endMonth <= 0) {
        nextPeriod -= 100;
        nextPeriod = nextPeriod + remainingCount + 12;
    } else if (remainingCount + endMonth > 12) {
        nextPeriod += 100;
        nextPeriod = nextPeriod + remainingCount - 12;
    } else {
        nextPeriod = nextPeriod + remainingCount;
    }

    return String(nextPeriod);
}

function toDaysArray(d1, d2, d1Inclusive = true, d2Inclusive = false) {
    if (!isSimpleDate(d1) || !isSimpleDate(d2))
        throw new Error("Unrecognized date " + d1 + ":" + d2);

    const daysArray = new Set();

    const btwDays = daysBetween(d1, d2, d1Inclusive, d2Inclusive);
    const start = d1Inclusive ? 0 : 1;
    for (let i = start; i < btwDays + start; i++) {
        daysArray.add(addDays(d1, i));
    }

    return Array.from(daysArray);
}

function addDays(date, days) {
    const result = new Date(date).getTime() + days * 86400000;
    return new Date(result).toISOString().substring(0, 10);
}

function toDisplayString(period) {
    if (!isPeriod(period)) {
        throw new Error("Unrecognized period " + period);
    }

    const { year, month } = toComponents(period);
    return `${year}-${month}`;
}

function isDateBetween(
    date,
    start,
    end,
    startInclusive = true,
    endInclusive = false
) {
    const startAfter = startInclusive ? isSameOrAfter : isAfter;
    const endBefore = endInclusive ? isSameOrBefore : isBefore;

    return startAfter(date, start) && endBefore(date, end);
}

function toRetailCalendar({
    weekCalculation = "LastDayNearestEOM",
    weekGrouping = "Group445",
    lastDayOfWeek = "Saturday",
    lastMonthOfYear = "January",
    leapYearStrategy = "AddToPenultimateMonth",
    year
}) {
    return new RetailCalendarFactory(
        {
            weekCalculation: WeekCalculation[weekCalculation],
            weekGrouping: WeekGrouping[weekGrouping],
            lastDayOfWeek: LastDayOfWeek[lastDayOfWeek],
            lastMonthOfYear: LastMonthOfYear[lastMonthOfYear],
            leapYearStrategy: LeapYearStrategy[leapYearStrategy]
        },
        year
    );
}

// This enables the devs to pass calConfig as directly from orgConfig i.e.
// { "config": { .... } }
function normCalConf(calConfig) {
    if (!calConfig || !calConfig.config) return calConfig;

    return calConfig.config;
}

function toRetailActgPeriodObj(cal, month, calConfig) {
    calConfig = normCalConf(calConfig);
    if (!calConfig) throw new Error("No calendar configuration provided");

    month.year = cal.year;
    month.startDate = gregorianToShortDate(month.gregorianStartDate);
    month.endDate = gregorianToShortDate(month.gregorianEndDate);
    const strMonth =
        month.monthOfYear < 10
            ? `0${month.monthOfYear}`
            : `${month.monthOfYear}`;
    month.period = `${cal.year}${strMonth}`;
    month.type = "retail";
    month.config = calConfig;
    month.cal = cal;

    return month;
}

function toDefaultActgPeriod(periodDate, calConfig) {
    calConfig = normCalConf(calConfig);

    let month, year, period;
    if (isSimpleDate(periodDate)) {
        periodDate = toPeriod(periodDate);
    }

    if (isPeriod(periodDate)) {
        period =
            typeof periodDate === "number" ? String(periodDate) : periodDate;

        year = period.substring(0, 4);
        month = period.substring(4, 6);
    }

    return {
        type: "default",
        period,
        year,
        startDate: toStartOfMonth(period),
        endDate: toEndOfMonth(period),
        monthOfYear: Number(month),
        config: calConfig
    };
}

function toRetailActgPeriod(date, calConfig) {
    calConfig = normCalConf(calConfig);

    if (isPeriod(date)) {
        let period = typeof date === "number" ? String(date) : date;

        const year = Number(period.substring(0, 4));
        const month = Number(period.substring(4, 6));

        const cal = toRetailCalendar({ year, ...calConfig });
        return toRetailActgPeriodObj(cal, cal.months[month - 1], calConfig);
    } else if (isSimpleDate(date)) {
        const year = Number(date.substring(0, 4));

        let cal = toRetailCalendar({ year, ...calConfig });
        const firstDay = gregorianToShortDate(cal.firstDayOfYear);
        const lastDay = gregorianToShortDate(cal.lastDayOfYear);
        if (date < firstDay || date > lastDay) {
            if (date < firstDay) {
                cal = toRetailCalendar({
                    year: year - 1,
                    ...calConfig
                });
                cal.firstDay = gregorianToShortDate(cal.firstDayOfYear);
                cal.lastDay = gregorianToShortDate(cal.lastDayOfYear);
            } else {
                cal = toRetailCalendar({ year: year + 1, ...calConfig });
                cal.firstDay = gregorianToShortDate(cal.firstDayOfYear);
                cal.lastDay = gregorianToShortDate(cal.firstDayOfYear);
            }
        }

        for (let month of cal.months) {
            const start = gregorianToShortDate(month.gregorianStartDate);
            const end = gregorianToShortDate(month.gregorianEndDate);

            if (date >= start && date <= end) {
                return toRetailActgPeriodObj(cal, month, calConfig);
            }
        }

        throw new Error("Cannot find date in calendar year " + date);
    }

    throw new Error("Unrecognized date " + date);
}

function gregorianToShortDate(d) {
    d = d._isAMomentObject ? new Date(d) : d;

    if (typeof d === "object" && d.toISOString && d.getTime) {
        const pad = function(num) {
            return (num < 10 ? "0" : "") + num;
        };

        return (
            d.getFullYear() +
            "-" +
            pad(d.getMonth() + 1) +
            "-" +
            pad(d.getDate())
        );
    }

    throw new Error("Invalid unrecognized date type!!", d);
}

function toActgPeriod(periodDate, calConfig) {
    calConfig = normCalConf(calConfig);

    if (!isSimpleDate(periodDate) && !isPeriod(periodDate))
        throw new Error(`Invalid period date ${periodDate}`);

    if (isPeriod(periodDate))
        periodDate = calcOrigPeriodLabel(periodDate, calConfig);
    //convert to an original period first

    if (calConfig && calConfig.type === "retail") {
        return new ActgPeriod(toRetailActgPeriod(periodDate, calConfig));
    } else {
        return new ActgPeriod(toDefaultActgPeriod(periodDate, calConfig));
    }
}

function ActgPeriod(periodObj) {
    this.periodObj = periodObj;
    const {
        period,
        year,
        strYear,
        strMonth,
        strQuarter,
        quarterOfYear,
        monthOfYear,
        ...rest
    } = periodObj;
    Object.assign(this, rest);
    this.period_ = period;
    this.year_ = year;

    this.nextPeriod_ = __nextPeriod(this.period_);
    this.effectiveDate = this.endDate;
}

ActgPeriod.prototype.numberOfDays = function() {
    if (this.type === "retail") {
        return this.periodObj.numberOfWeeks * 7;
    } else {
        return numberOfDays(this.periodObj.period);
    }
};

ActgPeriod.prototype.toString = function() {
    return toDisplayString(this.period);
};

ActgPeriod.prototype.toNextPeriod = function() {
    if (this.periodObj.type === "retail") {
        const monthOfYear = this.periodObj.monthOfYear;
        return monthOfYear < 12
            ? new ActgPeriod(
                  toRetailActgPeriodObj(
                      cloneDeep(this.periodObj.cal),
                      cloneDeep(this.periodObj.cal.months[monthOfYear]),
                      this.periodObj.config
                  )
              )
            : new ActgPeriod(
                  toRetailActgPeriod(
                      __nextPeriod(this.periodObj.period),
                      this.periodObj.config
                  )
              );
        //if month of year < 12 then we can do a perf optimization and reuse the existing calendar
    } else {
        return new ActgPeriod(
            toDefaultActgPeriod(
                __nextPeriod(this.periodObj.period),
                this.config
            )
        );
    }
};

ActgPeriod.prototype.toPriorPeriod = function() {
    if (this.periodObj.type === "default") {
        return new ActgPeriod(
            toDefaultActgPeriod(priorPeriod(this.periodObj.period), this.config)
        );
    } else {
        const monthOfYear = this.periodObj.monthOfYear;
        return monthOfYear < 12 && monthOfYear > 1
            ? new ActgPeriod(
                  toRetailActgPeriodObj(
                      cloneDeep(this.periodObj.cal),
                      cloneDeep(this.periodObj.cal.months[monthOfYear - 1 - 1]),
                      this.config
                  )
              )
            : new ActgPeriod(
                  toRetailActgPeriod(
                      priorPeriod(this.periodObj.period),
                      this.config
                  )
              );
        //if month of year < 12 then we can do a perf optimization and reuse the existing calendar
    }
};

ActgPeriod.prototype.toNextYear = function() {
    if (this.periodObj.type === "retail") {
        return new ActgPeriod(
            toRetailActgPeriod(
                nextYearPeriod(this.periodObj.period),
                this.periodObj.config
            )
        );
    } else {
        return new ActgPeriod(
            toDefaultActgPeriod(
                nextYearPeriod(this.periodObj.period),
                this.config
            )
        );
    }
};

ActgPeriod.prototype.toPriorYear = function() {
    if (this.periodObj.type === "retail") {
        return new ActgPeriod(
            toRetailActgPeriod(
                priorYearPeriod(this.periodObj.period),
                this.periodObj.config
            )
        );
    } else {
        return new ActgPeriod(
            toDefaultActgPeriod(
                priorYearPeriod(this.periodObj.period),
                this.config
            )
        );
    }
};

ActgPeriod.prototype.get = function(periodDate) {
    if (!isPeriod(periodDate) && !isSimpleDate(periodDate))
        throw new Error(`Invalid period id ${periodDate}`);

    const calConfig = this.config;
    if (isPeriod(periodDate))
        periodDate = calcOrigPeriodLabel(periodDate, calConfig);
    //convert to an original period first

    if (calConfig?.type === "retail") {
        if (isPeriod(periodDate)) {
            const { year, month } = toComponents(periodDate);
            if (this.periodObj.year === year) {
                const cal = this.periodObj.cal;
                const monthObj = cal.months[month - 1];
                return new ActgPeriod(
                    toRetailActgPeriodObj(cal, monthObj, this.periodObj.config)
                );
            } else {
                return new ActgPeriod(
                    toRetailActgPeriod(periodDate, calConfig)
                );
            }
        } else if (isSimpleDate(periodDate)) {
            return new ActgPeriod(toRetailActgPeriod(periodDate, calConfig));
        } else {
            throw new Error(`Unrecognized date format + ${periodDate}`);
        }
    } //shortcut to

    return new ActgPeriod(toDefaultActgPeriod(periodDate, calConfig));
};

/**
 * Convert back from a period label to the raw label itself
 *
 * @param period the computed period label
 */
function calcOrigPeriodLabel(computedPeriod, calConfig) {
    if (calConfig?.type !== "retail" && calConfig?.lastMonthOfYear) {
        let newMonth =
            Number(computedPeriod.substring(4)) -
            12 +
            (LastMonthOfYear[calConfig.lastMonthOfYear] + 1);

        if (newMonth <= 0) {
            newMonth += 12;
        }

        let year = Number(computedPeriod.substring(0, 4));
        if (newMonth > LastMonthOfYear[calConfig.lastMonthOfYear] + 1) {
            year -= 1;
        }

        if (newMonth < 0 || newMonth > 12) {
            throw new Error(
                `Invalid ${newMonth} from period ${computedPeriod}`
            );
        }
        computedPeriod = `${year}${
            newMonth < 10 ? `0${newMonth}` : `${newMonth}`
        }`;
    }

    if (calConfig?.type === "retail" && calConfig?.fiscalYear === "nextYear") {
        computedPeriod = `${Number(computedPeriod.substring(0, 4)) -
            1}${computedPeriod.substring(4)}`;
    }

    return computedPeriod;
}

/**
 * A wrapper around the period labelling process that uses the config
 * to label the values around a period based on the calendarConfig
 *
 * @param computedPeriod
 * @returns {string}
 */
function calcAdjPeriodLabel(computedPeriod) {
    const calConfig = this.config;

    if (calConfig?.type !== "retail" && calConfig?.lastMonthOfYear) {
        const originalMonth = Number(computedPeriod.substring(4));
        let newMonth =
            originalMonth +
            (12 - (LastMonthOfYear[calConfig.lastMonthOfYear] + 1));

        if (newMonth > 12) {
            newMonth -= 12;
        }

        let year = this.year_;

        if (originalMonth > LastMonthOfYear[calConfig.lastMonthOfYear] + 1) {
            year++;
        }

        if (newMonth < 0 || newMonth > 12) {
            throw new Error(`Bad month ${newMonth}`);
        }

        computedPeriod = `${year}${
            newMonth < 10 ? `0${newMonth}` : `${newMonth}`
        }`;
    }

    if (calConfig?.type === "retail" && calConfig?.fiscalYear === "nextYear") {
        computedPeriod = `${Number(computedPeriod.substring(0, 4)) +
            1}${computedPeriod.substring(4)}`;
    }

    return computedPeriod;
}

Object.defineProperty(ActgPeriod.prototype, "period", {
    get: function period() {
        return calcAdjPeriodLabel.call(this, this.period_);
    },
    set: function period(value) {
        throw new Error("You cannot set the period directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "nextPeriod", {
    get: function nextPeriod() {
        return calcAdjPeriodLabel.call(this, this.nextPeriod_);
    },
    set: function nextPeriod(value) {
        throw new Error("You cannot set the nextPeriod directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "postingPeriod", {
    get: function period() {
        throw new Error("Posting Period is not a valid defined attribute!");
    },
    set: function period(value) {
        throw new Error("Posting Period is not a valid defined attribute!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "year", {
    get: function year() {
        return Number(this.period.substring(0, 4));
    },
    set: function year(value) {
        throw new Error("You cannot set the year directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "strYear", {
    get: function strYear() {
        return String(this.year);
    },
    set: function strYear(value) {
        throw new Error("You cannot set the strYear directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "strMonth", {
    get: function strYear() {
        const p = calcAdjPeriodLabel.call(this, this.period_);
        return p.substring(4);
    },
    set: function strYear(value) {
        throw new Error("You cannot set the strMonth directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "strQuarter", {
    get: function strYear() {
        const p = this.period;
        return toQuarter(p);
    },
    set: function strYear(value) {
        throw new Error("You cannot set the strQuarter directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "quarterOfYear", {
    get: function strYear() {
        const q = this.strQuarter;
        return Number(q.substring(4));
    },
    set: function strYear(value) {
        throw new Error("You cannot set the quarterOfYear directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "firstDayOfYear", {
    get: function strYear() {
        if (this.config?.type === "retail") {
            return gregorianToShortDate(this.cal.firstDayOfYear);
        }

        let month, year;
        if (this.config?.lastMonthOfYear) {
            const calConfig = this.config;
            month =
                (LastMonthOfYear[calConfig.lastMonthOfYear] < 11
                    ? LastMonthOfYear[calConfig.lastMonthOfYear] + 1
                    : 0) + 1;
            year = this.year - 1; // our default is to run a year ahead when we use a different month for the end of fiscal period
            month = Number(month) < 10 ? "0" + Number(month) : month;
        } else {
            month = "01";
            year = this.year_; // our default is to run a year ahead when we use a different month for the end of fiscal period
        }

        return `${year}-${month}-01`;
    },
    set: function strYear(value) {
        throw new Error("You cannot set the firstDayOfYear directly!!");
    }
});

Object.defineProperty(ActgPeriod.prototype, "lastDayOfYear", {
    get: function strYear() {
        if (this.config?.type === "retail") {
            return gregorianToShortDate(this.cal.lastDayOfYear);
        }

        let month, year, days;
        const calConfig = this.config;

        year = this.year;
        if (this.config?.lastMonthOfYear) {
            month = LastMonthOfYear[calConfig.lastMonthOfYear] + 1;
            month = Number(month) < 10 ? "0" + Number(month) : month;
        } else {
            month = "12";
        }

        days = new Date(year, month, 0).getDate();
        return `${year}-${month}-${days}`;
    },
    set: function strYear(value) {
        throw new Error("You cannot set the lastDayOfYear directly!!");
    }
});

export default {
    getDaysInMonth,
    toComponents,
    toDate,
    toPeriodComponents,
    nextYear,
    nextYearPeriod,
    priorPeriod,
    periodsBetween,
    getDays,
    numberOfDays,
    isISODate,
    isPeriod,
    isSimpleDate,
    isInRequiredDateFormat,
    currentDate,
    currentDatePeriod,
    currentTimestamp,
    toStartOfMonth,
    toEndOfMonth,
    addPeriods,
    toPeriod,
    toActgPeriod,
    toPostingPeriod,
    toQuarter,
    toYear,
    inPeriod,
    isBefore,
    isAfter,
    isSameOrBefore,
    isSameOrAfter,
    isSame,
    roundedDaysBetween,
    daysBetween,
    yearsBetween,
    monthsBetween,
    addYear,
    addDays,
    addMonths,
    toPeriodFromComponent,
    toDisplayString,
    toDaysArray,
    isDateBetween,
    toPreviousDay,
    gregorianToShortDate,
    toEndDateOfPreviousMonth,
    toStartDateOfPreviousMonth,
    getDateRegexByDateFormat
};
