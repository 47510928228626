import React from "react";
import { Bar } from "react-chartjs-2";
import { getSSPAnalysis } from "rest/API";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "utils/WebUtils";
import { formatCurrency, getCurrency } from "redux/selectors/data.selector";
import { Paper } from "@material-ui/core";

const canvasStyle = {
    flex: "0 0 auto",
    padding: "4px"
};

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowVerticalLines: false,
    legend: {
        position: "top"
    },
    tooltips: {
        enabled: true,
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFontColor: "#fff",
        bodyFontColor: "#fff",
        xPadding: 10,
        yPadding: 10,
        displayColors: false,
        callbacks: {
            title: (tooltipItems, data) => data.labels[tooltipItems[0].index]
        }
    },
    scales: {
        xAxes: [
            {
                barPercentage: 0.2,
                // categoryPercentage: 0.7,
                borderWidth: 1,
                display: true,
                ticks: {
                    beginAtZero: true
                },
                gridLines: {
                    offsetGridLines: true
                }
            }
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
};

class SSPProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productDetails: []
        };
    }

    componentDidMount = async () => {
        const productId = this.props.data.data.Product_Id;
        const clientId = this.props.data.data.OrgId;
        const result = await getSSPAnalysis(clientId, productId);
        if (result) {
            this.setState({ productDetails: result });
        }
    };

    computeDataValues = () => {
        let data = this.props.data.data;
        const { productDetails } = this.state;
        const dataLabels = [];
        const dataValues = [];

        if (productDetails.length) {
            for (var x = 1; x <= 5; x++) {
                let element = productDetails.find(
                    (item) => item.Bands == `r${x}`
                );
                dataValues.push(element ? element["Count"] : 0);
                dataLabels.push(
                    element
                        ? x == 5
                            ? this.formatPrice(
                                  data[`R${x}_Start`] + "-" + data[`R${x}_End`]
                              )
                            : this.formatPrice(element["Description"])
                        : this.formatPrice(
                              data[`R${x}_Start`] + "-" + data[`R${x}_End`]
                          )
                );
            }
        }

        return { dataLabels, dataValues };
    };

    formatPrice = (price) => {
        const { formatCurr } = this.props;
        let labelArray = [];

        if (price && price.includes("-")) {
            price = price.split("-");
            labelArray.push(
                `${formatCurr(price[0])} - ${formatCurr(price[1])}`
            );
            labelArray.push("");
        }
        return labelArray;
    };

    render() {
        const { dataLabels, dataValues } = this.computeDataValues();
        return (
            <div container>
                <Paper className={canvasStyle}>
                    <div style={{ width: "67pc", height: "25pc" }}>
                        <Bar
                            data={{
                                labels: dataLabels,
                                datasets: [
                                    {
                                        label: "Sales Price Count",
                                        data: dataValues,
                                        backgroundColor: "#59888C",
                                        hoverBackgroundColor: "#456A6D",
                                        borderColor: "#314B4E",
                                        borderWidth: 1
                                    }
                                ]
                            }}
                            options={options}
                        />
                    </div>
                </Paper>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        formatCurr: formatCurrency(state),
        currency: getCurrency(state)
    };
}

export default compose(connect(mapStateToProps))(SSPProductDetails);
