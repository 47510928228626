import React from "react";
import { withStyles } from "@material-ui/core/styles";
import StyledBadge from "../StyledBadge";
import {
    Popover,
    Avatar,
    Typography,
    Box,
    Button,
    Tooltip,
    ListItem,
    ListItemText,
    ListItemIcon,
    List,
    Link
} from "@material-ui/core";

import { ExitToApp, MoreHorizTwoTone as MoreIcon } from "@material-ui/icons";

import { NavLink } from "react-router-dom";
import { compose } from "utils/WebUtils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
import { endImpersonation as removeImpersonation } from "../../rest/API";
import swal from "sweetalert";

import {
    isReadOnlyOrganization,
    currentUser,
    currentOrganization
} from "redux/selectors";
import { UserActions } from "redux/actions";

const styles = (theme) => ({
    navLink: {
        textDecoration: "none",
        marginTop: 4,
        display: "inline-block",
        color: theme.palette.background.link.light
    },
    signOut: {
        justifyContent: "flex-start",
        paddingLeft: 12
    }
});

async function endImpersonation(e) {
    e.stopPropagation();

    const user = await Auth.currentAuthenticatedUser();

    if (user) {
        try {
            await removeImpersonation();

            await swal({
                title: "Ended Impersonation",
                text: "Will refresh in a few seconds",
                timeout: 2000,
                icon: "success"
            });

            setTimeout(() => location.reload(), 500);
        } catch (e) {
            console.error(e, e.stack);
            await swal({
                text: `Could not end impersonation. Try to logout. ${e.toString()}`,
                timeout: 3000,
                icon: "error"
            });
        }
    }
}

function UserAvatar(props) {
    const { currentUser, classes, userTitle } = props;

    if (!userTitle) return null;

    return !currentUser.impersonation ? (
        <Avatar alt={userTitle} className={classes.avatar} />
    ) : (
        <div className={classes.impersonation} onClick={endImpersonation}>
            <StyledBadge
                overlap="circle"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                variant="dot"
            >
                <Avatar className={classes.avatar}>
                    {userTitle.substring(0, 1)}
                </Avatar>
            </StyledBadge>
        </div>
    );
}

function UserMenu(props) {
    const { currentUser, currentOrganization, classes, layout } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => {
        setAnchorEl(null);
        if (
            currentUser.idp === "chargebee-idp" &&
            currentUser.chargebeeIdpType === "ipe"
        ) {
            window.location.href = currentUser.logoutUrl;
            return;
        }
        UserActions.signOut();
    };

    const open = Boolean(anchorEl);

    if (!currentUser) {
        console.log("No user..");
        return null;
    }
    const { isSideNavOpen } = props;
    const familyName =
        currentUser.attributes.family_name != undefined
            ? currentUser.attributes.family_name
            : "";
    const userTitle =
        currentUser &&
        currentUser.attributes &&
        currentUser.attributes.given_name + " " + familyName;

    return (
        <>
            <Box
                w={220}
                display={"flex"}
                flexDirection={"column"}
                onClick={handleClick}
            >
                <List component="nav" disablePadding>
                    <Tooltip
                        title={layout.sidenavOpen ? "" : userTitle}
                        placement="right"
                    >
                        <ListItem
                            id="my-account"
                            style={{ width: "100%" }}
                            button
                            className={classes.listItemText}
                            classes={{
                                root: classes.bottomListItemRootOverrides,
                                gutters: classes.listItemGuttersOverrides
                            }}
                        >
                            <ListItemIcon
                                classes={{
                                    root: classes.bottomListItemIconOverrides
                                }}
                            >
                                <UserAvatar
                                    currentUser={currentUser}
                                    userTitle={userTitle}
                                    classes={classes}
                                />
                            </ListItemIcon>
                            <ListItemText
                                className={classes.orgTitleSidenav}
                                disableTypography
                            >
                                {userTitle}
                            </ListItemText>
                            <ListItemIcon
                                classes={{
                                    root: classes.listItemIconOverrides
                                }}
                            >
                                <MoreIcon className={classes.listIcon} />
                            </ListItemIcon>
                        </ListItem>
                    </Tooltip>
                </List>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{
                    left: 20,
                    top: 20
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    p={1}
                    m={"0 8px 8px"}
                >
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        mb={1}
                    >
                        <Avatar>{userTitle.substring(0, 1)}</Avatar>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            m={"8px 8px 8px 16px"}
                        >
                            <Typography
                                variant={"body1"}
                                style={{ color: "black" }}
                            >
                                {userTitle}
                            </Typography>
                            <Typography variant={"caption"}>
                                {currentUser.attributes &&
                                    currentUser.attributes.email}
                            </Typography>
                            <Typography>
                                <Link
                                    className={classes.navLink}
                                    onClick={() => {
                                        setAnchorEl(null);
                                        if (
                                            currentUser.isChargebeeUser &&
                                            currentOrganization.chargebeeEditProfileUrl
                                        ) {
                                            window.location.href = `${currentOrganization.chargebeeEditProfileUrl}`;
                                        } else {
                                            props.history.push(
                                                `/${currentOrganization.id}/profile`
                                            );
                                        }
                                    }}
                                >
                                    Edit Profile
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={signOut}
                        variant={"text"}
                        className={classes.signOut}
                        startIcon={<ExitToApp size={"small"} />}
                    >
                        Sign out
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

function mapStateToProps(state, props) {
    return {
        currentUser: currentUser(state),
        currentOrganization: currentOrganization(state),
        isReadOnlyOrganization: isReadOnlyOrganization(state)
    };
}

export default compose(
    withRouter,
    withStyles(styles),
    connect(mapStateToProps)
)(UserMenu);
