import tzMoment from "moment-timezone";
//tzMoment.tz.setDefault("UTC");

const _moment = (_date, ...args) => {
    const currentTime = tzMoment().valueOf();
    const beginingOfDayTime = tzMoment().startOf('day').valueOf();
    const _currentDate = process.env.CURRENT_DATE && tzMoment(process.env.CURRENT_DATE).valueOf();
    if (!_date && _currentDate) {
        const timeDelta =  currentTime - beginingOfDayTime
        return tzMoment(_currentDate + timeDelta);
    }
    return tzMoment(_date, ...args)
}


let moment = tzMoment
if (process.env.IS_LOCAL) {
    moment = _moment
    moment.duration = tzMoment.duration
    moment.unix = tzMoment.unix
    moment.utc = tzMoment.utc
    moment.isDate = tzMoment.isDate
}

//set the default timezone
export {
    moment
};
