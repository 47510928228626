import AppEntities from './AppEntities'

import layoutReducer from './reducers/layout.reducer';
import dataReducer from './reducers/data.reducer';
import userReducer from './reducers/user.reducer';
import currentOrganizationReducer from './reducers/currentOrganization.reducer';
import organizationsReducer from './reducers/organizations.reducer';
import reportsReducer from "./reducers/reports.reducer"
import GenericReducer from './GenericReducer';
import organizationUsersReducers from './reducers/organizationUser.reducer'
import taskReducers from './reducers/tasks.reducer'
import soSearchReducers from './reducers/soSearch.reducer'

let referenceDataReducers = {}

Object.keys(AppEntities).forEach(entityName => Object.assign(referenceDataReducers, GenericReducer(entityName, AppEntities[entityName])));

// Combine with other reducers we may add in the future
const staticReducers = {
    layout: layoutReducer,
    data: dataReducer,
    user: userReducer,
    organizations: organizationsReducer,
    organizationsUsers: organizationUsersReducers,
    reports: reportsReducer,
    currentOrganization: currentOrganizationReducer,
    tasks: taskReducers,
    soSearch: soSearchReducers,
    ...referenceDataReducers
};

export default staticReducers;
