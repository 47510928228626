import RemoteEntityReducer from "./RemoteEntityReducer";
import AppEntities, { AppEntityConfig } from './AppEntities'
import { orgId } from "../utils/auth";


const GenericReducer = (entityName, reduxElement) => {

    const config = AppEntityConfig(entityName);

    const generalReducer = (paging, nullValue) => new RemoteEntityReducer((state, action) => {
        if (!action.response && action.status != 'success')
            throw new Error("Cannot reduce with no response");

        if (paging) {
            let { currentPage } = action.params;
            let { result, cursor } = action.response;
            let entityByPageId = state[entityName] || {};
            entityByPageId[currentPage] = result;

            // console.log(entityName + " loaded : " + JSON.stringify({ currentPage, list } ));
            return Object.assign(state, { [entityName]: entityByPageId });
        } else {
            let entity = action.response || nullValue;
            // console.log(entityName + " loaded : " + JSON.stringify(entity));
            return Object.assign(state, { [entityName]: entity });
        }
    }, undefined, (state) => delete state[entityName]);

    const entityReducer = generalReducer(config.paging, []);
    const currentEntityReducer = generalReducer(false, null);

    const currentTaskReducer = new RemoteEntityReducer((state, action) => {
        if (!action.response)
            throw new Error("Cannot reduce with no response");

        let task = action.response;

        task = (task && task.length > 0 && task[0]) || undefined;

        let entity = {
            id: action.params.entityId,
            orgId: action.orgId,
            task
        }

        // console.log(entityName + " loaded : " + JSON.stringify(entity));
        return Object.assign(state, { [entityName]: entity });
    }, undefined, (state) => delete state[entityName]);

    return {
        [entityName]: (state = {}, action) => {
            var newstate = Object.assign({}, state);

            switch (action.type) {
                case 'RESET_ORG':
                    newstate = {}
                    return newstate;
                case reduxElement:
                    return entityReducer.doReduction(newstate, action);
                default:
                    return state;
            }
        },
        [`${entityName}_current`]: (state = {}, action) => {
            var newstate = Object.assign({}, state);

            switch (action.type) {
                case `CURRENT_${reduxElement}`:
                    return currentEntityReducer.doReduction(newstate, action);
                default:
                    return newstate;
            }
        },
        [`${entityName}_current_task`]: (state = {}, action) => {
            var newstate = Object.assign({}, state);

            switch (action.type) {
                case `CURRENT_TASK_${reduxElement}`:
                    return currentTaskReducer.doReduction(newstate, action);
                default:
                    return newstate;
            }
        }
    }
}

export default GenericReducer
