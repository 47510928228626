const { config, version } = require("../../package.json");
let gitDisplayVersion;
try {
    gitDisplayVersion = require("cfg/version.json");
} catch {}

const getEnvironment = () => {
    if (config.backend == "alt") {
        const prod = Object.assign({}.require("./prod/production").default);
        const alt = require("./alt/alternate").default;
        prod.AWS.Auth = alt.AWS.Auth;
        return prod;
    }

    let secrets;
    let env;

    if (config.backend === "prod") {
        secrets = require("./prod/secrets.json");
        env = require("./prod/production").default;
    } else if (config.backend === "qa") {
        secrets = require("./qa/secrets.json");
        env = require("./qa/qa").default;
    } else if (config.backend === "qa-west") {
        secrets = require("./qa-west/secrets.json");
        env = require("./qa-west/qa-west").default;
    } else if (config.backend === "prod-west") {
        secrets = require("./prod-west/secrets.json");
        env = require("./prod-west/prod-west").default;
    } else {
        secrets = require("./dev/secrets.json");
        env = require("./dev/development").default;
    }

    secrets = secrets.profiles[config.serviceProfile];

    return Object.assign(env, secrets);
};

const defaultConfig = getEnvironment();
defaultConfig.backend = config.backend;
defaultConfig.environment = config.backend;
defaultConfig.isCloudDeployment = config.isCloudDeployment;
defaultConfig.version = gitDisplayVersion;
defaultConfig.serviceProfile = config.serviceProfile;
defaultConfig.semver = version;
defaultConfig.wsBaseUrl = `wss://${config.environment}-wsapi.rev-lock.com/${config.serviceProfile}`;

if (!config.isCloudDeployment) {
    defaultConfig.AWS.oauth.redirectSignIn = "http://localhost:3000/";
    defaultConfig.AWS.oauth.redirectSignOut = "http://localhost:3000/signout";
    defaultConfig.wsBaseUrl = `ws://localhost:5100`;
    defaultConfig.microstrategy.mstrWebUrl =
        "http://localhost:8081/MicroStrategy/servlet/";
}

export default defaultConfig;
