import RemoteEntityReducer from "../RemoteEntityReducer";

const entityReducer = new RemoteEntityReducer((state, action) => {

    if (!action.response)
        throw new Error("Cannot reduce with no current organization");

    let currentOrganization = Object.assign({}, action.response);

    return Object.assign(state, { currentOrganization });

});

const main = (state = { }, action) => {
    var newstate = Object.assign({}, state);

    switch (action.type) {
        case 'CURRENT_ORGANIZATION':
            return entityReducer.doReduction(newstate, action);
        default:
            return state;
    }
}

export default main
