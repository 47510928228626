import { Period } from 'revlock-webutils'
import { assertValidPlan } from '../ArrangementUtils'

export default {
	buildPlan: function buildPlan (
		startDate, endDate, planAmount, ssp, retailCalenderConfig) {
		if (!startDate) throw new Error('Need start date for POINT_IN_TIME plan')

		const startActgPeriod = Period.toActgPeriod(startDate, retailCalenderConfig)
		const endActgPeriod = endDate ? Period.toActgPeriod(endDate,
			retailCalenderConfig) : null

		const plan = []
		plan.push({
			actgPeriod: startActgPeriod,
			planAmount,
		})

		// fill the gap periods between startDate and endDate if provided.
		if (!endActgPeriod) return plan

		const missingPeriods = Period.periodsBetween(startActgPeriod.period,
			endActgPeriod.period, false, true)

		let actgPeriod = startActgPeriod
		missingPeriods.forEach(period => {
			actgPeriod = actgPeriod.get(period)

			plan.push({
				actgPeriod,
				planAmount: 0,
			})
		})

		if (ssp.recognizedOn === 'endDate') {
			// make starting point zero
			plan[0].planAmount = 0

			plan[plan.length - 1].planAmount = planAmount
		}

		assertValidPlan(plan)

		return plan
	},
}
