import { Period } from "revlock-webutils";
import shortid from "shortid";
import { assertValidPlan } from "../ArrangementUtils";

export default {
    buildPlan: function buildPlan(
        startDate,
        endDate,
        orderAmount,
        endDateInclusive = false,
        calendarConfig,
        terms
    ) {
        if (Period.isBefore(endDate, startDate)) {
            throw new Error(
                `Plan starts ${startDate} before it ends ${endDate}`
            );
        }

        // STEP1: validate the date and returns a new ActgPeriod object.
        const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig);
        const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig);

        let firstPeriodDays;
        if (startActgPeriod.period === endActgPeriod.period) {
            firstPeriodDays = Period.daysBetween(
                startDate,
                endDate,
                true,
                endDateInclusive
            );
        } else {
            firstPeriodDays = Period.daysBetween(
                startDate,
                startActgPeriod.endDate,
                true,
                true
            );
        }
        //if the start and end date are the same period then all revenue goes to the first period

        const lastPeriodDays =
            startActgPeriod.period === endActgPeriod.period
                ? 0
                : Period.daysBetween(
                      endActgPeriod.startDate,
                      endDate,
                      true,
                      endDateInclusive
                  );

        let totalContractRevenueDays = Period.daysBetween(
            startDate,
            endDate,
            true,
            endDateInclusive
        );
        if (startDate === endDate) totalContractRevenueDays = 1;

        let periods =
            startActgPeriod.period === endActgPeriod.period
                ? [startActgPeriod.period]
                : Period.periodsBetween(
                      startActgPeriod.period,
                      endActgPeriod.period,
                      true,
                      true
                  );
        //between periods

        const endPeriodEndDate = Period.toEndOfMonth(endActgPeriod.period);

        let actgPeriod = startActgPeriod;

        let totalTerms = terms;
        if (!terms) {
            let totalDays = 0;
            for (let period of periods) {
                actgPeriod = actgPeriod.get(period);
                if (actgPeriod.period === startActgPeriod.period) {
                    // all periods expect last
                    if (firstPeriodDays > 30) {
                        totalDays += 30;
                    } else {
                        let actualMonthDays =
                            startActgPeriod.numberOfDays() <= 30
                                ? 30
                                : startActgPeriod.numberOfDays() - 1;
                        let datePart = parseInt(startDate.substr(8, 2)) - 1;
                        totalDays += actualMonthDays - datePart;
                    }
                } else if (actgPeriod.period === endActgPeriod.period) {
                    // last period
                    if (lastPeriodDays > 30) {
                        totalDays += 30;
                    } else if (
                        Period.toStartOfMonth(startDate) == startDate &&
                        endActgPeriod.strMonth == "02" &&
                        endActgPeriod.endDate == endPeriodEndDate
                    ) {
                        // if startDate is start of month and this is feb month end (28, 29 days)
                        // Then take 30 days
                        totalDays += 30;
                    } else {
                        totalDays += lastPeriodDays;
                    }
                } else {
                    totalDays += 30;
                }
            }

            totalTerms = totalDays / 30;
        }

        // if we only have one period and our start and end date periods are same.
        // then totalContractRevenueDays should be 1 and firstPeriodDays should be
        // whole month days and totalTerms would be 1
        if (
            periods.length == 1 &&
            startActgPeriod.period === endActgPeriod.period
        ) {
            totalContractRevenueDays = 1;
            firstPeriodDays = startActgPeriod.numberOfDays();
            totalTerms = 1;
        }

        // start days
        const amountPerTerm = orderAmount / totalTerms;
        if (
            firstPeriodDays === 0 &&
            lastPeriodDays === 0 &&
            totalContractRevenueDays === 1
        ) {
            firstPeriodDays = 1;
        }

        const plan = [];
        let cummulativeTotal = 0;
        let firstMonthPlan = 0;
        for (let period of periods) {
            actgPeriod = actgPeriod.get(period);
            let planAmount = 0;
            if (actgPeriod.period === startActgPeriod.period) {
                let totalDaysInCurrentMonth = startActgPeriod.numberOfDays();
                let planDailyAmount = amountPerTerm / totalDaysInCurrentMonth;
                planAmount = firstPeriodDays * planDailyAmount;
                firstMonthPlan = planAmount;
            } else if (actgPeriod.period === endActgPeriod.period) {
                planAmount = orderAmount - cummulativeTotal;
            } else {
                planAmount = amountPerTerm;
            }

            //calculate the amount and dates for the period.
            cummulativeTotal += planAmount;

            plan.push({
                actgPeriod,
                planAmount
            });
        }

        assertValidPlan(plan);
        return plan;
    }
};
