import RemoteEntityReducer from "../RemoteEntityReducer";


const entityReducer = new RemoteEntityReducer((state, action) => {

    if (!action.response)
        throw new Error("Cannot reduce with no user");

    state.imageStatus = undefined
    state.organizations = action.response

    return Object.assign({}, state);

}, (state, action) => {
    const isSuccess = (action.status == 'update_complete')
    if (!action.reducerType)
        return Object.assign({}, state);
    if (action.reducerType == 'OrgsImages' && action.status == 'update_inprocess') {
        state.imageStatus = action.status
        return Object.assign({}, state)
    }
    if (action.reducerType == 'OrgsImages' && isSuccess) {
        if (action.response) {
            state.imageStatus = action.status
            state.organizations = action.response.map(org => Object.assign({}, org))
        }
        return Object.assign({}, state)
    }

});

const main = (state = { organizations: [] }, action) => {
    var newstate = Object.assign({}, state);

    switch (action.type) {
        case 'ORGANIZATIONS':
            return entityReducer.doReduction(newstate, action);
        case 'ORGANIZATION_INVALIDATE':
            return { organizations: [] }
        case 'UPDATE_ORG_IMAGES':
            newstate = updateOrgImage(newstate, action);
            return newstate
        default:
            return state;
    }
}

const updateOrgImage = (newstate, action) => {
    let organizations = newstate.organizations
    const images = action.value
    organizations = organizations.map(org => {
        const orgImage = images.find(image => image.orgId == org.id)
        if (orgImage)
            org.image = orgImage.image

        org = Object.assign({}, org)
        return org
    })
    // organizations = organizations.map(org => Object.assign({}, org))
    newstate.organizations = organizations
    return newstate
}

export default main
