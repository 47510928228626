import { Utils, Comparator } from 'revlock-webutils';

import shortid from "shortid";

export const prepareScheduleBySalesOrderItemId = (billingSchedule) => {

    const _billingSchedule = Utils.cloneDeep(billingSchedule)

    let scheduleBySalesOrderItemId = {};

    let sorter = Comparator.getComparator(
        ["billingDate"],
        [Comparator.forType('period')]
    )

    if (billingSchedule && _billingSchedule.billingScheduleItem && _billingSchedule.billingScheduleItem.length > 0) {
        _billingSchedule.billingScheduleItem.forEach(billingScheduleItem => {
            let { schedule, total, totalRevenue } = billingScheduleItem;
            let index = 0;

            schedule && Array.isArray(schedule) && schedule.sort(sorter)

            scheduleBySalesOrderItemId[billingScheduleItem.salesOrderItemId] = {
                index,
                schedule,
                total,
                totalRevenue,
                unbilled: 0,
                endingUnearnedRevenue: 0
            }
        });
    }

    return scheduleBySalesOrderItemId;
}


export const adjustForBilling = (itemSchedule, reportingTransaction, ensureZeroUnearnedRevenue,
    currActgPeriod, salesOrderFirstActgPeriod) => {
    const transactionsToReturn = { 'revenue': [], 'billing': [] }

    let revenue = reportingTransaction["Revenue"]

    let beginningUnbilled = itemSchedule.unbilled;

    // Add new booking to unbilled.
    itemSchedule.unbilled += reportingTransaction["New Unbilled"];

    let endingUnbilled = 0;
    let beginningUnearnedRevenue = 0
    let endingUnearnedRevenue = 0
    let cummulativeNewBilling = 0

    // get current effective period
    const effectivePeriod = currActgPeriod.period;

    if (itemSchedule.schedule.length > 0) {

        /**
         * This finds all the billing schedules that are either effective in the current
         * reporting transactions effective actg period or it is an invoice that has a billing
         * date even before the contract was activated for the first time.
         */
        const effectiveSchedules = itemSchedule.schedule.filter(
            sc => {
                const billingPeriod = currActgPeriod.get(sc.billingDate).period
                return (billingPeriod === effectivePeriod ||
                    (billingPeriod < salesOrderFirstActgPeriod  && effectivePeriod === salesOrderFirstActgPeriod))
            })


        if (effectiveSchedules.length > 0) {
            // now iterate on all filtered effective schedules for which we want to generate new transactions
            for(let item of effectiveSchedules){
                // clone reporting transaction so that we can calculate new bookings and new billings
                cummulativeNewBilling += item.amount
                const billingTransaction = Object.assign(Utils.cloneDeep(reportingTransaction), {
                    'Transaction Date': item.billingDate,
                    'New Booking': 0,
                    'New Unbilled': 0,
                    'Ending Backlog': 0,
                    'Beginning Unbilled': 0,
                    'Beginning Unearned Revenue': 0,
                    'New Billing': item.amount,
                    'Ending Unbilled': 0,
                    'Ending Unearned Revenue': 0,
                    'Adjustment To Unearned Revenue': 0,
                    'Beginning Balance': 0,
                    'Revenue': 0,
                })

                transactionsToReturn.billing.push(billingTransaction)

            }
            itemSchedule.unbilled -= cummulativeNewBilling
        }
    }

    endingUnbilled = itemSchedule.unbilled;
    beginningUnearnedRevenue = itemSchedule.endingUnearnedRevenue;
    endingUnearnedRevenue = beginningUnearnedRevenue + cummulativeNewBilling - revenue;

    let adjustmentToUnearnedRevenue = 0;
    if (ensureZeroUnearnedRevenue) {
        adjustmentToUnearnedRevenue = -1 * endingUnearnedRevenue
        endingUnearnedRevenue = 0
    }

    itemSchedule.endingUnearnedRevenue = endingUnearnedRevenue

    let adjusted = {
        "Beginning Unbilled": beginningUnbilled,
        "Beginning Unearned Revenue": beginningUnearnedRevenue,
        "New Billing": 0,
        "Ending Unbilled": endingUnbilled,
        "Ending Unearned Revenue": endingUnearnedRevenue,
        "Adjustment To Unearned Revenue": adjustmentToUnearnedRevenue
    }

    const revenueTransaction = Object.assign(reportingTransaction, adjusted);

    transactionsToReturn.revenue.push(revenueTransaction)

    return transactionsToReturn
}

export function populateBillingSchedule(salesOrder, clientReferenceData) {

    let {  options } = clientReferenceData;

    // By default create the revenue plans for all sales items.
    options = Object.assign({
        createRevenuePlan: true
    }, options)

    if (options.createRevenuePlan && salesOrder.salesOrderItem && salesOrder.salesOrderItem.length > 0) {
        let revenueArrangement = salesOrder.newRevenueArrangement || salesOrder.currentRevenueArrangement;
        if (!revenueArrangement) return;

        let revenueArrangementItemBySoi = Utils.arrayToObject(
            revenueArrangement.revenueArrangementItem,
            item => (item.salesOrderItem.rootId || item.salesOrderItem.id)
        );

        let { billingSchedule } = salesOrder;

        if (!billingSchedule) {
            billingSchedule = {
                id: salesOrder.id,
                billingScheduleItem: []
            }

            salesOrder.billingSchedule = billingSchedule;
        }

        let { billingScheduleItem: billingScheduleItems } = billingSchedule;

        salesOrder.salesOrderItem.forEach(soi => {

            let salesOrderItemId = soi.rootId || soi.id;
            let rai = revenueArrangementItemBySoi[salesOrderItemId];

            if (rai) {
                let billingScheduleItem = billingScheduleItems.find(bsi => bsi.salesOrderItemId === salesOrderItemId);

                // BillingSchedule item doesn't exists, let's create a billingSchedule item
                if (!billingScheduleItem) {
                    billingSchedule.billingScheduleItem.push({
                        id: shortid.generate(),
                        salesOrderId: soi.salesOrderId,
                        salesOrderItemId: soi.rootId ||
                            soi.id,
                        schedule: [],

                    })
                }

                rai.billingSchedulePolicy = 'MANUAL'
            }
        })
    }
}