import React from "react";
import { validate, TypeSpec } from "revlock-entity";
import shortid from "shortid";
import { Utils } from "revlock-webutils";
import AllActions from "../actions";
import store from "../ReduxStore";
import reduxStore from "../ReduxStore";
import * as WebUtils from "../../utils/WebUtils";
import CustomizedSnackbars from "../../components/CustomizedSnackbars";
import AppEntities from "../AppEntities";
import * as API from "rest/API";
import { orgId } from "utils/auth";

export const invalidateAll = () => {
    Object.keys(AppEntities).forEach((entityName) => {
        AllActions[entityName].invalidate();
    });
};

export const resetOrg = () => {
    store.dispatch({
        type: "RESET_ORG",
        status: "success"
    });
};

export const reloadNotifications = () => {
    store.dispatch({
        type: "NOTIFICATION",
        status: "invalid"
    });
};

export const setCookies = (name, value, options) => {
    WebUtils.get("cookies").set(name, value, options);
};

export const removeCookies = (name, options) => {
    WebUtils.get("cookies").remove(name, options);
};

export const unset2 = (name) => {
    store.dispatch({
        type: "UNSET",
        name: name
    });
};

export const setData2 = (name, value) => {
    store.dispatch({
        type: "SET_DATA",
        name: name,
        value: value
    });
};

export const setFlag2 = (name) => {
    store.dispatch({
        type: "SET_DATA",
        name: name
    });
};

export const setFlag = (name, value) => ({
    type: "SET_DATA",
    name: name
});

export const setData = (name, value) => ({
    type: "SET_DATA",
    name: name,
    value: value
});

export const unset = (name) => ({
    type: "UNSET",
    name: name
});

export const addNewSalesOrder = (id) => ({
    type: "NEW_SALES_ORDER",
    id
});

const cleanupOrderBeforePosting = (salesOrder) => {
    delete salesOrder.commissionTransactions;
    delete salesOrder.commissionReportingTransactions;
    delete salesOrder.commissionWaterFall;
    delete salesOrder.reportingTransactions;
    delete salesOrder.transactions;
    delete salesOrder.waterFall;
    delete salesOrder.revenueArrangement;
    delete salesOrder.currentRevenueArrangement;
    delete salesOrder.newRevenueArrangement;
    delete salesOrder.journalEntries;
    delete salesOrder.commissionJournalEntries;
    delete salesOrder.expenseTransactions;
    delete salesOrder.expenseReportingTransactions;
    delete salesOrder.expenseWaterFall;
    delete salesOrder.expenseJournalEntries;
    delete salesOrder.previousVersionSnapshots;
};

export const saveInDirectExpenseItems = async (expenseItems, orgId) => {
    const response = await API.saveInDirectExpenseItems(expenseItems, orgId);

    return response;
};

export const saveExpenseItems = async (expenseItems, orgId) => {
    const response = await API.saveExpenseItems(expenseItems, orgId);

    return response;
};

export const saveSalesOrder = (
    dispatch,
    _salesOrder,
    updates = {},
    callback
) => {
    let salesOrder = Utils.copy(_salesOrder);

    let create = false;
    if (salesOrder.new) {
        create = true;
    }

    salesOrder.salesOrderItem &&
        salesOrder.salesOrderItem.length > 0 &&
        salesOrder.salesOrderItem.forEach((item) => {
            delete item.isNew;
            delete item.errors;
        });

    delete salesOrder.currentRevenueArrangement;
    delete salesOrder.newRevenueArrangement;

    if (!["Active", "Merged"].includes(salesOrder.orderType)) {
        salesOrder.revenueArrangement = [];
    }

    salesOrder.commission &&
        salesOrder.commission.forEach((commission) => {
            commission.salesOrderId = salesOrder.id;

            if (commission.new) {
                commission.id = commission.id || shortid.generate();
                delete commission.new;
            }
        });

    salesOrder.revenueArrangement.forEach((revenueArrangement) => {
        revenueArrangement.salesOrderId = salesOrder.id;
        if (revenueArrangement.new)
            revenueArrangement.id = revenueArrangement.id || shortid.generate();
    });

    let newRevenueArrangement = salesOrder.revenueArrangement.find(
        (revenueArrangement) => revenueArrangement.state == "New"
    );

    salesOrder.salesOrderItem &&
        salesOrder.salesOrderItem.forEach((salesOrderItem) => {
            salesOrderItem.salesOrderId = salesOrder.id;
            if (salesOrderItem.new) {
                salesOrderItem.id = salesOrderItem.id || shortid.generate();
                delete salesOrderItem.new;
            }
        });

    // removing items which were added but user kept it empty
    salesOrder.salesOrderItem = salesOrder.salesOrderItem.filter((soi) => {
        if (soi.product !== undefined) {
            return soi;
        }
    });

    let valid = validate(TypeSpec["SalesOrder"], salesOrder, false);

    if (!valid) {
        toast({
            type: "error",
            message:
                "Error while saving SalesOrder. Failed SalesOrder object validation"
        });
        return valid;
    }

    cleanupOrderBeforePosting(salesOrder);

    if (create) AllActions["salesOrder"].create(callback, salesOrder);
    else AllActions["salesOrder"].update(callback, salesOrder);

    return true;
};

/**
 * Organizations & User Roles
 */
export const selectOrganization = (org) => ({
    type: "SELECT_ORGANIZATION",
    value: org
});

export const updateOrganization = (org) => ({
    type: "UPDATE_ORGANIZATION",
    value: org
});

export const saveProducts = (callback, products) => {
    let _callback = () => {
        store.dispatch({
            type: "STANDALONE_SELLING_PRICE",
            status: "invalid"
        });

        callback && callback();
    };

    AllActions["product"].updateAll(_callback, products);
};

/**
 * Function to load async options
 */
export const loadSearchOptions = async (
    entity,
    inputValue,
    fieldName,
    isCreateable,
    callback,
    currencyCode = false,
    isSspReq = false
) => {
    const params = {
        entity,
        searchTerm: inputValue,
        fieldName,
        ...(currencyCode && {
            currencyCode
        })
    };

    let result = await API.suggestOptions(params, orgId());
    if (isCreateable) {
        // check if inputValue already exists in result then don't create new option
        if (
            result &&
            !result.filter(
                (r) => r.label.toLowerCase() == inputValue.toLowerCase()
            ).length
        ) {
            const toSet = { value: inputValue, label: inputValue };
            result.unshift(toSet);
        }
    }

    //special block to handle createUpdateSsp case
    // display no options when no product returned

    if (isSspReq && result && !result.length) {
        if (fieldName == "name") {
            callback([]);
            return;
        } else {
            result.push({ label: inputValue, value: inputValue });
        }
    }

    if (result) {
        callback(result);
    }
};

export const saveProfessionalServicesLabors = (
    callback,
    professionalServicesLabors
) => {
    professionalServicesLabors.forEach((professionalServicesLabor) => {
        delete professionalServicesLabor.new;
    });
    AllActions["professionalServicesLabor"].updateAll(
        callback,
        professionalServicesLabors
    );
};

/**
 * snackParams : {
 *     type: 'success', // success OR warning OR info OR error
 *     message: 'this is a good message'
 * }
 */
export const toast = (snackbarParams) => {
    reduxStore.dispatch({
        type: "SET_DATA",
        name: "snackbarParams",
        value: Object.assign(snackbarParams, { open: true })
    });
};

export const toastDone = (snackbarParams) => {
    reduxStore.dispatch({
        type: "UNSET",
        name: "snackbarParams"
    });
};

export const showNotification = (context) => {
    const { warningMessage, errorMessage, successMessage } = context;

    let _toastParams = {};

    if (warningMessage) {
        _toastParams.snackBarType = "warning";
        _toastParams.snackBarMessage = warningMessage;
    } else if (errorMessage) {
        _toastParams.snackBarType = "error";
        _toastParams.snackBarMessage = errorMessage;
    } else if (successMessage) {
        _toastParams.snackBarType = "success";
        _toastParams.snackBarMessage = successMessage;
    }

    if (Object.keys(_toastParams).length > 0) {
        _toastParams.snackBarOpen = true;
        _toastParams.snackbarAnchorOrigin = {
            vertical: "top",
            horizontal: "center"
        };

        return <CustomizedSnackbars {..._toastParams} />;
    }
};

export const toastCallback = (successMessage, errorMessage, callback) => {
    return (response, error) => {
        if (error) {
            errorMessage &&
                toast({
                    type: "error",
                    message: error.errorMessage || `${errorMessage.message}`
                });
        } else {
            successMessage &&
                toast({
                    type: "success",
                    message: successMessage
                });
        }

        if (callback) callback(response, error);
    };
};

export const validateDeliveryLog = (deliveryLog, revenueArrangementItem) => {
    let { salesOrderItem, plan } = revenueArrangementItem;

    let error;

    // 1. Validate that all entries are between start and end date.
    let logForOutOfServicePeriod = false;
    for (var i = 0; i < deliveryLog.length; i++) {
        if (
            !Utils.inPeriodRange(
                deliveryLog[i].period,
                plan[0].period,
                plan[plan.length - 1].period
            )
        ) {
            logForOutOfServicePeriod = true;
            break;
        }
    }
    error = logForOutOfServicePeriod
        ? "Found atleast one delivery log for period out of service dates."
        : error;

    if (!error) {
        // 3. Validate that cummulative hours are not greater than current item quantity.
        let { quantity } = salesOrderItem;
        let isValid = true;
        if (quantity == 1) {
            deliveryLog.forEach(
                (log) => (isValid = isValid && log.percentComplete <= 1)
            );
            error = !isValid
                ? "Percent complete can not be greater than 100%."
                : error;
        } else if (quantity > 1) {
            deliveryLog.forEach(
                (log) => (isValid = isValid && log.unitsDelivered <= quantity)
            );
            error = !isValid
                ? "Units delivered can not exceed units sold. "
                : error;
        }
    }

    return error;
};

export const validateBillingSchedule = (
    billingSchedule,
    revenueArrangementItem
) => {
    let error;

    let { extendedSalePrice } = revenueArrangementItem;

    let agg = billingSchedule.reduce(
        (agg, element) => (agg += element.amount),
        0
    );

    if (agg > extendedSalePrice) {
        error = `Invalid Schedule. Sum of all billed amount [${Utils.formatCurrency(
            agg
        )}] does not equal sale price. [${Utils.formatCurrency(
            extendedSalePrice
        )}]`;
    }

    return error;
};

export const saveProfessionalServiceDelivery = (_salesOrder, callback) => {
    let salesOrder = Utils.copy(_salesOrder);

    cleanupOrderBeforePosting(salesOrder);

    let valid = validate(TypeSpec["SalesOrder"], salesOrder, false);

    if (!valid) {
        toast({
            type: "error",
            message:
                "Error while saving SalesOrder. Failed SalesOrder object validation"
        });
        return valid;
    }

    let _toastCallback = toastCallback(
        "Successfully updated professional service delivery log.!",
        "There was an error while saving professional service delivery log!",
        callback
    );

    AllActions["professionalServiceDelivery"].update(
        _toastCallback,
        salesOrder.professionalServiceDelivery
    );

    return true;
};

export const saveInvoices = (_salesOrder, callback) => {
    let salesOrder = Utils.copy(_salesOrder);

    cleanupOrderBeforePosting(salesOrder);

    let valid = validate(TypeSpec["SalesOrder"], salesOrder, false);

    if (!valid) {
        toast({
            type: "error",
            message:
                "Error while saving SalesOrder. Failed SalesOrder object validation"
        });
        return valid;
    }

    AllActions["billingSchedule"].update(callback, salesOrder);

    return true;
};

export const setTabIndex = (tabId, index) => setData2(`tab_${tabId}`, index);
