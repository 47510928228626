import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import config from '../../cfg/config';
import { connect } from 'react-redux';
import { compose } from 'utils/WebUtils';
import { doMstrLogout } from '../../redux/selectors/data.selector';
import { setData2 } from '../../redux/actions/data.actions';

const mstr = config.microstrategy;

class MstrLogout extends Component {

    componentDidMount() {
        if (this.props.doMstrLogout)
            setTimeout(() => setData2('doMstrLogout', false), 5000);
    }

    render() {

        if (!this.props.doMstrLogout) {
            return null
        }

        let webLogout = mstr.mstrWebUrl + "mstrWeb?evt=3008&src=mstrWeb.3008"
        return <div style={{ display: 'none', position: 'absolute' }}>
            <iframe src={webLogout} width={10} height={10}></iframe>
        </div>
    }
}

const mapStateToProps = (state, props) => {
    const _doMstrLogout = doMstrLogout(state)
    return {
        doMstrLogout: _doMstrLogout
    };
};

export default compose(
    connect(mapStateToProps),
)(MstrLogout);
