import _ from 'lodash';
import { Utils } from 'revlock-webutils'

export function sortExpenseAccountingRules(expenseAccountingRules) {

    expenseAccountingRules.forEach(rule => {
        if (Object.keys(rule.expenseAttributes).length > 0) {
            rule.sortKey = Object.values(rule.expenseAttributes).join(":=:")
        } else {
            rule.sortKey = "NOKEY"
        }
    })

    const sortedExpenseAccountingRules = _.sortBy(expenseAccountingRules, ['sortKey'])

    sortedExpenseAccountingRules.forEach(rule => delete rule.sortKey)

    return sortedExpenseAccountingRules
}

export function selectExpenseRule(clientReferenceData, expenseItem, sspType = 'Direct') {

    let { orgConfig, standaloneSellingPrice } = clientReferenceData

    if (!standaloneSellingPrice) {
        return
    }

    const expenseAccountingRules = standaloneSellingPrice.filter(ssp => ssp.sspType == sspType)

    if (expenseAccountingRules && expenseAccountingRules.length > 0) {

        const sspFieldsConfig = orgConfig.find(cfg => cfg.id === 'properties/ssp-fields')

        const sortedExpenseAccountingRules = sortExpenseAccountingRules(expenseAccountingRules)

        const expenseRule = sortedExpenseAccountingRules.find(rule => {
            return expenseRuleMatch(
                sspFieldsConfig,
                rule,
                expenseItem
            )
        })

        return expenseRule;
    }
}

export const expenseSelectorProductFields = [
    { name: 'productCode', displayName: 'Product Code', get: ({ product }) => product.code }
]

let productFields = Utils.arrayToObject(expenseSelectorProductFields, obj => obj.name, obj => obj.get);

const expenseRuleMatch = (sspFieldsConfig, rule, expenseItem, raItem) => {

    let args = { product: { code: expenseItem.productCode } };

    let customAttributes = Object.assign({},
        expenseItem.attributes
    );

    let response = sspFieldsConfig.value.reduce((result, field) => {
        let match = false;
        let sspFieldValue = rule.expenseAttributes[field];

        if (sspFieldValue != undefined) {
            // STEP 1: Product Code matched with Product Code in expense rule
            if (productFields[field]) {
                match = _.isEqual(productFields[field](args),
                    sspFieldValue
                );
            } else {
                // STEP 2: Product attributes match with expense rule attributes
                match = _.isEqual(customAttributes[field],
                    sspFieldValue
                );
            }

            if (match) {
                // attributes were matched successfully, let's now match expense code
                if (rule.expenseCode == expenseItem.expenseCode) {
                    match = true
                } else {
                    match = false
                }
            } else {
                // this situation will only happen when the rule we are checking for the item
                // isn't matched at all
            }
        } else {
            // STEP 3: Fall back, no product code no custom field at expense item 
            // as well as at expense rule level
            // just match expense code and return
            if (rule.expenseCode == expenseItem.expenseCode) {
                match = true
            }
        }

        return result && match
    }, true)

    return response
}
