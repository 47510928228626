const { config } = require("../../../package.json");

const alias = (() => {
    return config.serviceProfile ? `/${config.serviceProfile}` : "";
})();

function getApiEndpoint() {
    return (
        (config["isCloudDeployment"] &&
            `https://qa-api.rev-lock.com${alias}`) ||
        "http://localhost:3001"
    );
}

const baseRedirectUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return "https://qa.rev-lock.com";
    } else if (window.location.origin.match(/localhost/)) {
        return "http://localhost:3000";
    } else {
        return "https://qa.revrec.stagingcb.com";
    }
})();

const baseReportUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return "https://qa-reports.rev-lock.com";
    } else {
        return "https://qa-reports.revrec.stagingcb.com";
    }
})();

export default {
    microstrategy: {
        mstrWebUrl: `${baseReportUrl}/MicroStrategy/servlet/`,
        project: "QA_RL",
        server: "qa-mstr.cluster.rev-lock.local"
    },
    hotGlue: {
        env_id: "qa.hotglue.rev-lock.com"
    },
    AWS: {
        API: {
            endpoints: [
                {
                    name: "revlock_api",
                    endpoint: getApiEndpoint()
                },
                {
                    name: "revlock_public_api",
                    endpoint: getApiEndpoint(),
                    custom_header: async () => {
                        return {
                            "x-api-key":
                                "uO5UebKfgDcIlZJh3ec11WX1ScvVhIR8kNoP4Jhe"
                        };
                    }
                }
            ]
        },
        Auth: {
            identityPoolId: "us-east-1:99b004bd-d10b-4384-928a-5ad351ba917f",
            region: "us-east-1",
            userPoolId: "us-east-1_B2k7YxB19",
            userPoolWebClientId: "5n87fc6r53id2nmd0levuddqdj",
            mandatorySignIn: true
        },
        Storage: {
            bucket: "qa-userfiles",
            region: "us-east-1",
            hotglueBucket: "qa-hotgluefiles"
        },
        Analytics: {
            disabled: true
        },
        oauth: {
            domain: "qa.auth.rev-lock.com",
            clientId:
                "1092057117597-naiv1sargrqsilc5n3l6pbef2nclsk16.apps.googleusercontent.com",
            redirectSignOut: `${baseRedirectUrl}/signout`,
            redirectSignIn: `${baseRedirectUrl}/`,
            scope: ["email", "profile", "openid"],
            responseType: "code"
        }
    },
    analytics: {
        trackingId: "UA-127158128-3",
        debug: true
    },
    keycloakConfig: {
        url: "https://idp-broker.devcb.in/",
        redirectUri: `https://qa-${config.serviceProfile}-sso.stagingcb.com/`,
        realm: "master",
        clientId: "revrec-app",
        idpHint: "revrec-idp",
        redirectSignoutUri: `https://qa.auth.rev-lock.com/logout?client_id=6f50lh83rprtp3pl1sof5edsdn&logout_uri=https%3A%2F%2Fqa-${config.serviceProfile}-sso.stagingcb.com%2F`
    }
};
