import {createSelector} from 'reselect';
import UserActions from '../actions/user.actions'
import {getUser, hasEditRole} from '../../utils/auth'

export const userState = (state) => state.user && state.user;
export const isFetchingUser = (state) => {
    return state.user && state.user.isFetching;
}

// private selectors
const organizationsState = (state) => state && state.organizations;
const organizationsUsersState = state => state && state.organizationsUsers
const syncState = state => state && state.sync

export const currentOrganizationState = (state) => state && state.currentOrganization;

export const currentUser = createSelector(
    [userState],
    (userState) => {

        if (!userState.user) {
            return undefined
        } else
            return userState.user
    }
)

export const getOrgsWithImages = createSelector(
    [currentUser, organizationsState], (currentUser, organizationsState) => {

        if ((organizationsState.organizations && organizationsState.organizations.length == 0) || !currentUser)
            return undefined

        const orgs = organizationsState.organizations
        const hasImages = orgs && orgs.map(org => org.image != undefined).includes(true) || false
        const loadedImages = organizationsState.imageStatus == 'update_complete' || organizationsState.imageStatus == 'update_inprocess'

        if (!hasImages && !loadedImages) {
            UserActions.getOrganizationImage(organizationsState.organizations)
            return undefined
        }
        if (hasImages && loadedImages)
            return organizationsState.organizations

        return undefined
    }
)

export const userOrganizations = createSelector(
    [currentUser, userState, organizationsState],
    (currentUser, userState, organizationsState) => {
        if (!currentUser)
            return undefined

        if (!currentUser.orgs)
            currentUser.orgs = []

        if (!UserActions.fetchUserOrganizations(organizationsState, () => {
            return organizationsState.lastUpdated != undefined && organizationsState.lastUpdated >= userState.lastUpdated;
        }, currentUser.orgs))
            return undefined;

        return organizationsState.organizations
    }
)

export const currentOrganization = createSelector(
    [currentOrganizationState, userOrganizations, organizationsState],
    (currentOrganizationState, userOrganizations, organizationsState) => {
        if (!userOrganizations)
            return undefined

        if (currentOrganizationState.lastUpdated != undefined && currentOrganizationState.lastUpdated >= organizationsState.lastUpdated) {
            const currentOrganization = currentOrganizationState.currentOrganization

            const currentPeriod = currentOrganization && currentOrganization.currentPeriod;
            currentOrganization.isRestatementMode = !currentPeriod ? true : false
    
            return currentOrganization;
        }

        return undefined;
    }
)

export const isReadOnlyOrganization = createSelector(
    [currentOrganization],
    (currentOrganization) => {
        if (!currentOrganization)
            return false

        currentOrganization.isReadOnly = currentOrganization.isReadOnly || false;
        if (!hasEditRole()) {
            currentOrganization.isReadOnly = true
        }

        return currentOrganization.isReadOnly;
    }
)

export const getOrganizationsUsersState = createSelector(
    [currentOrganization, organizationsUsersState],
    (currentOrganization, organizationsUsersState) => {
        if (!currentOrganization)
            return undefined

        if (!UserActions.fetchCurrentOrganzationUsers(organizationsUsersState, () => {
            return organizationsUsersState.users != undefined && organizationsUsersState.orgId == currentOrganization.id;
        }, currentOrganization.id))
            return undefined;


        return organizationsUsersState
    }
)

export const getOrganizationUserLicenses = createSelector([getOrganizationsUsersState],
    (organizationsUsersState) => {
        if (!organizationsUsersState)
            return undefined;
        return organizationsUsersState.userLicenses;
    }
)

export const getOrganizationUsers = createSelector([getOrganizationsUsersState],
    (organizationsUsersState) => {
        if (!organizationsUsersState)
            return undefined;
        return organizationsUsersState.users;
    }
)


export const getSyncStatus = createSelector(
    [currentOrganization, syncState],
    (currentOrganization, syncState) => {
        if (!currentOrganization || !syncState.jobs)
            return undefined

        const type = 'Salesforce_v1'
        const jobs = syncState.jobs.find(job => job.type == type)
        const hasSalesForceIntegration = currentOrganization.integrations && currentOrganization.integrations.find(integ => integ.type == type)
        if (jobs && hasSalesForceIntegration) {
            if (!UserActions.fetchSync(syncState, () => {
                    return syncState.syncStatus != undefined
                        && syncState.orgId == currentOrganization.id
                        && jobs != undefined;
                }, {orgId: currentOrganization.id, jobId: jobs.id}
            ))
                return undefined;

            return syncState.syncStatus

        } else
            return undefined

    }
)

export const loadingOrg = createSelector(
    [currentOrganizationState, organizationsState],
    (currentOrganizationState, organizationsState) => {

        let loadingOrg = organizationsState.isFetching;
        loadingOrg = loadingOrg || currentOrganizationState.isFetching

        return loadingOrg;
    }
)
