import RemoteEntityReducer from "../RemoteEntityReducer";

const entityReducer = new RemoteEntityReducer((state, action) => {
    if (!action.user) throw new Error("Cannot reduce with no user");

    const { user } = action;

    return Object.assign(state, { user });
});

const main = (state = {}, action) => {
    const newstate = Object.assign({}, state);

    switch (action.type) {
        case "USER":
            return entityReducer.doReduction(newstate, action);
        case "NO_USER":
            return {};

        case "SET_TOUR":
            newstate.user.data = { tours: ["welcome_tour"] };
            return newstate;

        default:
            return state;
    }
};

export default main;
