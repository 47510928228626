import _ from 'lodash';

import { Codes } from './ErrorCode';
import * as AccountingUtils from './AccountingUtils';
import {Utils} from 'revlock-webutils'

export function selectSSP(clientReferenceData, item, salesOrder) {

    let { sspSelectors, orgConfig, standaloneSellingPrice } = clientReferenceData

    const _standaloneSellingPrice = standaloneSellingPrice && standaloneSellingPrice.filter(ssp => !['Direct', 'Indirect'].includes(ssp.sspType))

    if (_standaloneSellingPrice && _standaloneSellingPrice.length > 0) {

        _standaloneSellingPrice.sort(
            (a, b) =>
                a.sspSelectorId == b.sspSelectorId
                    ? 0
                    : (!a.sspSelectorId || (a.sspSelectorId < b.sspSelectorId)
                        ? 1
                        : -1))

        const ssp = _standaloneSellingPrice.find(ssp => {

            if (!ssp.sspSelectorId) {
                let sspFieldsConfig = orgConfig.find(cfg => cfg.id === 'properties/ssp-fields')

                if (!sspFieldsConfig) return false;

                return sspMatch(
                    sspFieldsConfig,
                    ssp,
                    item,
                    salesOrder
                )
            }

            // Legacy way, used for first two client's.
            // If you are using this in future, RevLock implementation is getting complex.
            const template = sspSelectors && sspSelectors.find(sspSelector => sspSelector.id == ssp.sspSelectorId);
            try {
                const result = template && AccountingUtils.evaluateExpression(template.expression, {
                    revenueArrangementItem: item,
                    standaloneSellingPrice: ssp,
                    salesOrder
                }) || false;

                return result;
            } catch (e) {
                throw { code: Codes.SSP_1, data: { salesOrder, salesOrderItem: item.salesOrderItem, expression: template.expression, exception: e } }
            }
        })

        item.ssp = ssp;
    }
}

export const sspSelectorProductFields = [
    { name: 'term', displayName: 'Term', get: ({ product }) => product.term },
    { name: 'productCode', displayName: 'Product Code', get: ({ product }) => product.code },
    { name: 'pricebookId', displayName: 'Price Book Id', get: ({ product }) => product.pricebookId },
    { name: 'productId', displayName: 'Product Id', get: ({ product }) => product.id }
]

let productFields = Utils.arrayToObject(sspSelectorProductFields, obj => obj.name, obj => obj.get);

const sspMatch = (sspFieldsConfig, standaloneSellingPrice, revenueArrangementItem, salesOrder) => {
    let { customer } = salesOrder;
    let { product } = revenueArrangementItem.salesOrderItem;

    let args = { customer, product, revenueArrangementItem, salesOrder };

    let customAttributes = Object.assign({},
        undefined,

        // Include order level attribute for SSP as well.
        salesOrder.orderAttributes,

        customer.attributes,
        product.attributes,
        revenueArrangementItem.attributes,
        revenueArrangementItem.salesOrderItem.attributes
    );

    return sspFieldsConfig.value.reduce((result, field) => {

        let match = false;
        let sspFieldValue = standaloneSellingPrice.attributes[field];
        if (productFields[field]) {
            match = _.isEqual(productFields[field](args),
                sspFieldValue
            );
        } else {
            match = _.isEqual(customAttributes[field],
                sspFieldValue
            );
        }

        return result && match
    }, true)
}
