import { Period } from 'revlock-webutils'
import { assertValidPlan } from '../ArrangementUtils'

export default {
	buildPlan: function buildPlan (startDate, endDate, originalBooking,
		endDateInclusive = false, calendarConfig) {
		if (Period.isBefore(endDate, startDate)) {
			throw new Error(`Plan starts ${startDate} before it ends ${endDate}`)
		}

		const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig)
		const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig)

		let firstPeriodDays
		if (startActgPeriod.period === endActgPeriod.period) {
			firstPeriodDays = Period.daysBetween(startDate, endDate, true,
				endDateInclusive)
		} else {
			firstPeriodDays = Period.daysBetween(startDate, startActgPeriod.endDate,
				true, true)
		}
		//if the start and end date are the same period then all revenue goes to the first period

		const lastPeriodDays = (startActgPeriod.period === endActgPeriod.period)
			? 0
			: Period.daysBetween(endActgPeriod.startDate, endDate, true,
				endDateInclusive)

		let periods = (startActgPeriod.period === endActgPeriod.period)
			? [startActgPeriod.period]
			: Period.periodsBetween(startActgPeriod.period, endActgPeriod.period,
				true, true)
		//between periods

		let totalContractRevenueDays = (startDate === endDate)
			? 1
			: Period.daysBetween(startDate, endDate, true, endDateInclusive)

		const planRate = originalBooking / totalContractRevenueDays
		if (firstPeriodDays === 0 && lastPeriodDays === 0 &&
			totalContractRevenueDays === 1) {
			firstPeriodDays = 1
		}

		const plan = []
		let actgPeriod = startActgPeriod
		for (let period of periods) {
			actgPeriod = actgPeriod.get(period)

			let planAmount = 0
			if (actgPeriod.period === startActgPeriod.period) {
				planAmount = firstPeriodDays * planRate
			} else if (actgPeriod.period === endActgPeriod.period) {
				planAmount = lastPeriodDays * planRate
			} else {
				planAmount = actgPeriod.numberOfDays(period) * planRate
			}

			//calculate the amount and dates for the period.

			plan.push({
				actgPeriod,
				planAmount,
			})
		}

		assertValidPlan(plan)
		return plan

	}
}
