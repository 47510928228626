import { SecurityUtils } from "revlock-webutils";
import store from "../redux/ReduxStore";
import { setData2 } from "../redux/actions/data.actions";
import { moment } from "revlock-webutils";
import { userOrganizations } from "../redux/selectors/user.selector";
import { AccountingUtils } from "revlock-accounting";
import { Auth } from "aws-amplify";
import config from "cfg/config";
import Keycloak from "keycloak-js";

const { keycloakConfig } = config;
const keycloak = new Keycloak({
    url: keycloakConfig.url,
    realm: keycloakConfig.realm,
    clientId: keycloakConfig.clientId
});

export async function initKeycloak() {
    try {
        // authenticating via keycloak if not already authenticated
        const authenticated =
            keycloak.authenticated ||
            (await keycloak.init({
                checkLoginIframe: false
            }));
        console.info(
            `User is ${authenticated ? "authenticated" : "not authenticated"}`
        );

        return keycloak;
    } catch (exc) {
        console.log("Got exception at kk init ", exc, exc.stack);
        throw exc;
    }
}

export async function loginKeycloak() {
    try {
        const { keycloakConfig } = config;
        const { idpHint, redirectUri } = keycloakConfig;
        const authenticated =
            keycloak.authenticated ||
            (await keycloak.init({
                checkLoginIframe: false
            }));
        console.info(
            `User is ${authenticated ? "authenticated" : "not authenticated"}`
        );

        if (!authenticated) {
            await keycloak.login({
                idpHint,
                redirectUri: `${redirectUri}?cbssoredirect=true`
            });
        }

        return authenticated;
    } catch (exc) {
        console.log("Got exception at kk login ", exc, exc.stack);
        throw exc;
    }
}

export async function signOut(params = { global: true }) {
    try {
        if (keycloak && keycloak.authenticated) {
            await signOutKeycloak();
        }

        await Auth.signOut(params);
    } catch (exc) {
        if (exc.name === "PasswordResetRequiredException") {
            console.log("Password Rest caught");
        }

        console.log("Got exception", exc, exc.stack);
    }
}

export async function signOutKeycloak() {
    try {
        const { keycloakConfig } = config;
        const { redirectUri } = keycloakConfig;

        // TODO Cognito isn't logging Out, the below explicit signout is
        // for signing out the One Time Pass to logout aws cognito
        await Auth.signOut({ global: false });

        const logoutUri = await keycloak.createLogoutUrl();
        console.log(`Generated logout URI - ${logoutUri}`);

        await keycloak.logout({
            redirectUri: `${redirectUri}?signout=true`
        });
    } catch (exc) {
        console.log("Got exception at signOut", exc, exc.stack);
    }
}

export function getUserLicenses() {
    let state = store.getState();
    let userLicenses =
        state &&
        state.organizationsUsers &&
        state.organizationsUsers.userLicenses;
    return userLicenses;
}

export function getProdOrg() {
    let orgs = userOrganizations(store.getState());
    orgs = orgs && orgs.filter((o) => !AccountingUtils.isTestId(o.id));
    const currentOrg = getOrg();
    return orgs.length > 0
        ? orgs[
              orgs.findIndex(
                  (o) => o.index == AccountingUtils.toProdId(currentOrg.id)
              )
          ]
        : null;
}

export function getOrg() {
    let state = store.getState();
    let org =
        state &&
        state.currentOrganization &&
        state.currentOrganization.currentOrganization;
    return org;
}

export function orgId() {
    let org = getOrg();
    return org && org.id;
}

export function getUser() {
    let state = store.getState();
    let user = state && state.user && state.user.user;
    return user;
}

export function getAllPlans() {
    return SecurityUtils.allPlans();
}

export function getPlansForUpgrade() {
    const activePlan = getPlan();
    let plans = Object.values(getAllPlans());

    if (activePlan.isTrial) {
        plans[0].selected = true;
        return { plans, isTrial: true };
    }

    const nPlan = getNextPlan();
    plans = plans.filter((p) => p.level <= nPlan.level); //filter out other plans.
    const currentPlan = plans.find((p) => p.name == activePlan.name);
    const nextPlan = plans.find((p) => p.name == nPlan.name);

    currentPlan.current = true;
    nextPlan.selected = true;

    return { plans, isTrial: false, currentPlan, nextPlan };
}

export function getNextPlan() {
    const activePlan = getPlan();
    const plans = Object.values(getAllPlans());

    const nextLevel = Number(activePlan.level) + 1;
    const nextPlan = plans.find((p) => p.level === nextLevel);
    return nextPlan ? nextPlan : activePlan;
}

export function getPlan() {
    let org = getProdOrg();

    if (!org) {
        org = getOrg();
    }

    return SecurityUtils.getPlan(org);
}

export function getTrial(activePlan) {
    if (activePlan && activePlan.trial) {
        const trialDate = activePlan.trialStartedOn
            ? activePlan.trialStartedOn
            : activePlan.addedOn;
        const trialPeriod = activePlan.trialPeriod
            ? activePlan.trialPeriod
            : 30;
        const days = moment().diff(moment(trialDate), "days");
        return { isTrial: true, daysRemaining: trialPeriod - days };
    }

    return { isTrial: false };
}

export function allRoles(filterToAvailable = false) {
    let userLicenses = getUserLicenses();

    let roles = SecurityUtils.allRoles();
    if (filterToAvailable)
        if (
            userLicenses &&
            userLicenses.activeAdvancedSeats >= userLicenses.maxAdvancedSeats
        )
            roles = roles.filter((r) => r != "Advanced");

    return roles;
}

export function hasRole(role) {
    let org = getOrg();
    let user = getUser();

    if (!org || !user) {
        return false;
    }

    return SecurityUtils.hasRole(org.id, role, user);
}

export function hasLicense({ type, name }, superAdminOverride = false) {
    if (type === "plan") {
        const activePlan = getPlan();
        const plan = getAllPlans()[name];

        if (plan.level <= activePlan.level || activePlan.trial) {
            return true;
        } else if (
            superAdminOverride &&
            SecurityUtils.getSuperAdminLevel(getUser()) >=
                SecurityUtils.SUPER_ADMIN_LEVEL_3
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        console.warn(`Unrecognized license type [${type}]`);

        return false;
    }
}

export async function licensedExecute({ type, name }, func) {
    if (
        hasLicense({ type, name }) ||
        SecurityUtils.getSuperAdminLevel(getUser()) >=
            SecurityUtils.SUPER_ADMIN_LEVEL_3
    ) {
        await func();
    } else {
        setData2("showUpgradeDialog", "plan");
    }
}

export function isImpersonatedUser() {
    let user = getUser();
    if (user && user.impersonation) {
        return user.impersonation;
    }
    return false;
}

export function hasEditRole() {
    let org = getOrg();
    let user = getUser();

    if (!org || !user) {
        return false;
    }

    let canEdit = false;
    if (user) {
        ["Admin", "Analyst", "Controller"].forEach((role) => {
            canEdit = canEdit || hasRole(role);
        });
    }

    return canEdit;
}

export function isChargebeeUser() {
    // function checks if the user is a chargebee user or not.
    let user = getUser();
    if (!user && !user.attributes && !user.attributes.email) {
        return false;
    }

    const userEmail = user.attributes.email;
    let isChargebeeUser =
        userEmail.includes("@chargebee.com") || userEmail.includes("usyyid");
    return isChargebeeUser;
}
