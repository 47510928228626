
import { lightBlue } from '@material-ui/core/colors';

const styles = theme => ({
    portalDashboardPageWrapper: {
        minHeight: '100%',
        boxSizing: 'border-box',
        padding: '2em'
    },
    portalWidgetHeading: {
        textTransform: 'uppercase',
        paddingLeft: 12,
        borderLeftWidth: 2,
        borderLeftStyle: 'solid',
        position: 'relative',
        '&:after': {
            content: '""',
            width: 2,
            height: '0%',
            position: 'absolute',
            bottom: 0,
            left: -2,
            transition: 'height .5s'
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '25vh',
        cursor: 'pointer',
        height: '20vh',
        margin: '1em'
    },
    cardSelected: {
        maxWidth: 400,
        cursor: 'pointer',
        backgroundColor: lightBlue[500]
    },
    media: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    mediaContainer: {
        position: 'relative',
        width: '100%',
        height: '120px'
    },
    mediaGray: {
        maxWidth: '100%',
        filter: 'grayscale(100 %)'
    },
    avatar: {
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    orgListAvatar: {
        minWidth: theme.spacing(6),
    },
    orgAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    orgIcon: {
        width: 32,
        height: 32,
    },
    hr: {
        border: 'none',
        height: '10px',
        marginTop: 0,
        borderBottom: '1px solid #c2c2c2',
        // 'box-shadow': '0 5px 5px -5px #333',
        margin: '-50px auto 10px'
    },
    header: {

    },
    imgCardOverlay: {
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        padding: "1.25rem"
    },
    checkRoot: {
        backgroundColor: 'rgb(0,0,0,0.1)'
    },
    button: {
        cursor: 'pointer',
        color: lightBlue[500],
        paddingBottom: '.5em',
        paddingRight: '0.5em',
        zIndex:100,
    },
    ddTitle: {
        color: '#222',
        whiteSpace: 'nowrap',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        textTransform: 'none',
    },
    menuItemTitle: {
        fontSize:'0.9rem'
    },
    menuColor:{
        color: '#757575',
        marginRight: '-28px'
    },
    divider:{
        backgroundColor: '#e8e3e3'
    },
    activeChip: {
		height: '22px',
		fontWeight: 500,
		fontSize: '11px',
		borderRadius: '11px',
		// borderColor: theme.palette.background.green[600],
		color: theme.palette.background.green[600],
		backgroundColor: theme.palette.background.green[100],
	},
	errorChip: {
		height: '22px',
		fontWeight: 500,
        fontSize: '11px',
		borderRadius: '11px',
		// borderColor: theme.palette.background.red[600],
		color: theme.palette.background.orange[500],
		backgroundColor: theme.palette.background.orange[100],
	},
    infoChip:{
        height: '22px',
		fontWeight: 500,
		fontSize: '11px',
		borderRadius: '11px',
		// borderColor: theme.palette.background.orange[600],
		color: theme.palette.background.primary[600],
		backgroundColor: theme.palette.background.primary[100],
    }
});

export default styles
