import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForward from "@material-ui/icons/ArrowForward";
import swal from "sweetalert";

import Typography from "@material-ui/core/Typography";

import * as PlgAPI from "rest/PlgAPI";
import * as WebUtils from "utils/WebUtils";
import { SecurityUtils } from "revlock-webutils";
import { contactSales } from "rest/API";

const bannerStyle = makeStyles((theme) => ({
    root: {
        color: "white",
        backgroundColor: theme.palette.background.button.main,
        borderColor: theme.palette.background.button.main
    },
    message: {
        display: "flex",
        alignItems: "flex-end"
    },
    learnMoreLink: {
        marginLeft: theme.spacing(2),
        color: "white",
        fontSize: 15,
        fontWeight: "bold",
        display: "flex",
        alignItems: "flex-end"
    }
}));

const PLGBanner = ({
    userMessages,
    learnMore,
    callExpert,
    handleBannerClose
}) => {
    const classes = bannerStyle();

    if (!userMessages) return null;

    const bannerMessage = userMessages["banner"]["Message"];

    return (
        <Alert
            icon={false}
            classes={{ root: classes.root, message: classes.message }}
            onClose={handleBannerClose}
        >
            {bannerMessage}
            <a className={classes.learnMoreLink} href="#" onClick={learnMore}>
                See what you&#39;re missing <ArrowForward fontSize="small" />
            </a>
        </Alert>
    );
};

const DialogTitle = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}))((props) => {
    const { title, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant={"h4"}>{title}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        border: 0
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "flex-start"
    }
}))(MuiDialogActions);

export default function PLGModal({ currentOrganization, currentUser }) {
    const [open, setOpen] = React.useState(true);
    const [userMessages, setUserMessages] = React.useState(null);
    const [learnMore, setLearnMore] = React.useState(null);

    const getUserMessages = async () => {
        try {
            const response = await PlgAPI.getMessageForUser(
                currentOrganization.id
            );

            console.log("search response:", response);

            if (response && Object.keys(response).length !== 0) {
                setUserMessages(response);
            } else {
                setUserMessages(undefined);
            }
        } catch (e) {
            console.log("failed!!", e, e.stack);

            WebUtils.showAlert({
                text:
                    e.message ||
                    e.errorMessage ||
                    e.text ||
                    "Unable to get user  messages!",
                title: "Error",
                buttons: "Close",
                icon: "error"
            });
        }
    };

    React.useEffect(() => {
        if (currentOrganization && currentUser) {
            // PLG is only enabled for rev-essential.
            if (
                currentOrganization.license &&
                currentOrganization.license.name === "rev-essential"
            ) {
                getUserMessages();
            }
        }
    }, [currentOrganization, currentUser]);

    const callExpert = async () => {
        const allPlans = SecurityUtils.allPlans();
        const upgradeTo = allPlans[currentOrganization.license.name].nextPlan;

        try {
            await contactSales(currentOrganization.id, "plan", upgradeTo);

            await swal({
                title: `Thank you!!`,
                icon: "success",
                text: `Our RevRec teammate will contact you shortly.`
            });

            await PlgAPI.plgUserAction(
                currentOrganization.id,
                "contact-sales",
                userMessages.features.map((F) => F["Feature"])
            );

            await getUserMessages();

            setOpen(false);
            setLearnMore(false);
        } catch (err) {
            await swal({
                title: "Error",
                icon: "error",
                text: `Something didn't quite work ${err.errorMessage}`,
                error: true,
                timer: 2000,
                buttons: false
            });
        }
    };

    const handleBannerClose = () => {
        async function doClose() {
            try {
                await PlgAPI.plgUserAction(
                    currentOrganization.id,
                    "close-banner",
                    userMessages.features.map((F) => F["Feature"])
                );

                getUserMessages();
            } catch (err) {
                await swal({
                    title: "Error",
                    icon: "error",
                    text: `Something didn't quite work ${err.errorMessage}`,
                    error: true,
                    timer: 2000,
                    buttons: false
                });
            }
        }

        doClose();
    };

    const handleModalClose = () => {
        async function doClose() {
            if (userMessages["status"] === "Show Banner" && learnMore) {
                // Do nothing as user is closing modal that was opened from banner.
                setLearnMore(false);
                setOpen(false);
                return;
            }

            try {
                await PlgAPI.plgUserAction(
                    currentOrganization.id,
                    "close-popup",
                    userMessages.features.map((F) => F["Feature"])
                );

                getUserMessages();
            } catch (err) {
                await swal({
                    title: "Error",
                    icon: "error",
                    text: `Something didn't quite work ${err.errorMessage}`,
                    error: true,
                    timer: 2000,
                    buttons: false
                });
            }

            setOpen(false);
        }

        doClose();
    };

    if (!userMessages) return null;

    const children = [];

    if (userMessages["status"] === "Show Banner") {
        children.push(
            <PLGBanner
                userMessages={userMessages}
                learnMore={() => {
                    setLearnMore(true);
                    setOpen(true);
                }}
                handleBannerClose={handleBannerClose}
                callExpert={callExpert}
            />
        );
    }

    if (
        userMessages["status"] == "Show Modal" ||
        (userMessages["status"] == "Show Banner" && learnMore)
    ) {
        children.push(
            <Dialog
                onClose={handleModalClose}
                maxWidth={false}
                fullWidth={false}
                aria-labelledby="customized-dialog-title"
                open={open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    title={userMessages.features[0]["Header"]}
                    style={{
                        padding: "24px 26px 0px 26px"
                    }}
                    onClose={handleModalClose}
                />

                <div style={{ maxWidth: "700px" }}>
                    <DialogContent
                        style={{
                            padding: "24px 26px 0px 26px"
                        }}
                        dividers
                    >
                        {userMessages.features.map((feature) => {
                            return (
                                <>
                                    <Typography
                                        style={{
                                            color: "inherit",
                                            fontWeight: "600"
                                        }}
                                        gutterBottom
                                        variant={"body1"}
                                    >
                                        <a
                                            style={{
                                                color: "#2d6a8a",
                                                paddingLeft: "2px",
                                                "font-size": "15px"
                                            }}
                                            size="medium"
                                            variant="outlined"
                                            color="primary"
                                            target="_blank}"
                                            href={feature["Link"]}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        feature[
                                                            "DisplayContent"
                                                        ]
                                                }}
                                            />
                                        </a>
                                    </Typography>
                                    <Typography
                                        align={"justify"}
                                        style={{
                                            "font-size": "13px",
                                            marginBottom: "10px"
                                        }}
                                        gutterBottom
                                        variant={"body1"}
                                    >
                                        {feature["Message"]}
                                    </Typography>
                                </>
                            );
                        })}
                    </DialogContent>
                </div>
                <DialogActions>
                    <Button
                        style={{ margin: "15px 0px 16px 17px" }}
                        autoFocus
                        onClick={callExpert}
                        color="primary"
                        variant="contained"
                    >
                        Talk to an Expert
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return children;
}
