const logoImage = "https://cdn.revrec.chargebee.com/images/chargebee_logo.svg";

export { logoImage };

export const globalStyles = (theme) => ({
    buttonRoot: {
        display: "flex",
        alignItems: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    root: {
        // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        borderRadius: 3,
        // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: "white",
        height: 48,
        padding: "0 30px"
    },
    signUpContainer: {
        marginTop: 68,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
            maxWidth: 480,
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: 640,
            marginBottom: theme.spacing(4)
        }
    },
    card: {
        margin: 8,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(1)
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(2)
        }
    },
    background: {
        minHeight: "100vh",
        height: "100%",
        // background: 'url(/chargebee-icon-black.png) no-repeat center center rgba(0,0,0,.05)',
        backgroundSize: 300
    },
    ["login-actions"]: {
        justifyContent: "space-between"
    },
    ["lock-actions"]: {
        justifyContent: "space-between"
    },
    ["signup-content"]: {
        // textAlign: 'center'
    },
    logo: {
        height: 60,
        marginBottom: 10,
        [theme.breakpoints.down("md")]: {
            height: 60
        }
    },
    ["signup- logo"]: {
        height: 54,
        marginBottom: 10
    },

    error: {
        fontSize: "12px",
        color: "#de3618"
    },
    resendButton: {
        marginLeft: 16,
        backgroundColor: "#de3618",
        color: "white"
    },
    resendDisabled: {
        backgroundColor: "grey"
    },
    mainMessage: {
        fontWeight: 400
    }
});
