import Amplify from 'aws-amplify';
import { getCookie }  from 'rest/API';
import awsConfig from './cfg/aws-amplify';
import { ensureOrgProfile } from 'utils/WebUtils';

Amplify.configure(awsConfig);
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

const ensureProfile = async function () {

    // Check of orgid in the location
    let orgId = undefined
    if (
        !window.location.pathname.endsWith('index.html') 
        && !window.location.pathname.endsWith('signout')
        && !window.location.search.startsWith('?code')
    ) {

        // If upgrade redirect cookie is set, you are on the correct profile already.
        const upgradeRedirect = getCookie('upgradeRedirect')

        if (!upgradeRedirect) {
            // All URL's apart from index.html has convension /{orgId}/page-name
            orgId = window.location.pathname.replace("/", "").split("/")[0]

            if (!orgId) {
                orgId = getCookie('orgId')
            }

            if (orgId) {
                await ensureOrgProfile(orgId)
            }
        }
    }

    require('./start')
}

ensureProfile()