import RecognitionRule from "./reference/RecognitionRule";
import { getRevenueArrangementItemBySalesOrderItem } from "./RevenueArrangementHelper";
import { updateRatablePlan } from "./plan/ApportionedRatablePlan";
import { Utils, Period } from "revlock-webutils";
import { updatePSPlan } from "./ProfessionalServiceHelper";

const ratableItemTermination = ({
    terminationDate,
    revenueArrangementItem,
    endDateInclusive
}) => {
    revenueArrangementItem.terminationDate = terminationDate;
    // Change item delivery end date to termination date regardless.

    if (revenueArrangementItem.productRevenue == 0) {
        revenueArrangementItem.terminatedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
        return;
    } // No revenue to be recognized on this item.

    // Check if the item is fully recognized, terminated revenue equals actual allocation revenue
    const fullyRecognized = endDateInclusive
        ? Period.isSameOrAfter(
              terminationDate,
              revenueArrangementItem.deliveryEndDate
          )
        : Period.isAfter(
              terminationDate,
              revenueArrangementItem.deliveryEndDate
          );
    if (fullyRecognized) {
        revenueArrangementItem.terminatedProductRevenue =
            revenueArrangementItem.productRevenue;
        return;
    }

    // If item service start date is in the future, terminated revenue equals zero.
    const zeroRecognized = Period.isAfter(
        revenueArrangementItem.deliveryStartDate,
        terminationDate
    );
    if (zeroRecognized) {
        revenueArrangementItem.terminatedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
        return;
    }

    const newPlan = [];
    let terminatedProductRevenue = 0;

    let terminated = false;

    // Now let find prorate revenue allocation.
    for (let index = 0; index < revenueArrangementItem.plan.length; index++) {
        const element = revenueArrangementItem.plan[index];

        if (terminated) {
            // already terminated, update plan element to recognize no revenue.

            element.planAmount = 0;
            newPlan.push(Object.assign({}, element));
            continue;
        }

        let { actgPeriod } = element;

        terminated = Period.isDateBetween(
            terminationDate,
            actgPeriod.startDate,
            actgPeriod.endDate,
            true,
            true
        );
        // This is the period when we terminate the arrangement and contract.
        if (terminated) {
            let start = actgPeriod.startDate;

            if (index === 0) {
                start = revenueArrangementItem.deliveryStartDate;
            } // terminated in first month, startdate is first day of service

            let end = actgPeriod.endDate;

            if (index === revenueArrangementItem.plan.length - 1) {
                end = revenueArrangementItem.deliveryEndDate;

                if (!endDateInclusive) {
                    end = Period.addDays(end, -1);
                    // convert end to inclusive date as it's not.
                }
            } // Terminated in last month, enddate is last day of service

            const periodDays = Period.daysBetween(start, end, true, true);
            // Original Plan: total period days of service in this month.

            const terminatedPeriodDays = Period.daysBetween(
                start,
                terminationDate,
                true,
                true
            );
            // Terminated Plan: total period days of service in this month.

            element.planAmount =
                (element.planAmount * terminatedPeriodDays) / periodDays;
            // Now let's prorate this month.
        }

        newPlan.push(Object.assign({}, element));
        terminatedProductRevenue += element.planAmount;
    }

    revenueArrangementItem.plan = newPlan;
    revenueArrangementItem.terminatedProductRevenue = terminatedProductRevenue;
    // Preserve all the hard work that we just did.
};

const getProfessionalServiceItemBySalesOrderItem = (
    professionalServiceDelivery,
    salesOrderItem
) => {
    if (professionalServiceDelivery) {
        return professionalServiceDelivery.soiProfessionalServiceDelivery.find(
            (psd) => {
                let itemId = salesOrderItem.rootId || salesOrderItem.id;
                if (psd.salesOrderItemId === itemId) return psd;
            }
        );
    }
};

const proportionalPerformanceItemTermination = ({
    salesOrder,
    salesOrderItem,
    revenueArrangement,
    revenueArrangementItem,
    endDateInclusive,
    terminationDate,
    calendarConfig,
    orgConfig
}) => {
    revenueArrangementItem.terminationDate = terminationDate;
    revenueArrangementItem.terminationDateInclusive = endDateInclusive;

    const professionalServiceDelivery = salesOrder.professionalServiceDelivery;
    const soiPSD = getProfessionalServiceItemBySalesOrderItem(
        professionalServiceDelivery,
        salesOrderItem
    );

    if (!soiPSD) {
        revenueArrangementItem.terminatedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
        return;
    }

    if (soiPSD) {
        revenueArrangementItem.plan = Utils.copy(
            revenueArrangementItem.originalPlan
        );
        updatePSPlan(
            revenueArrangementItem,
            salesOrder,
            revenueArrangement,
            undefined,
            true,
            calendarConfig,
            undefined,
            orgConfig
        );
    }

    return;
};

const pointInTimeItemTermination = ({
    revenueArrangementItem,
    terminationDate,
    endDateInclusive
}) => {
    revenueArrangementItem.terminationDate = terminationDate;

    let deliveryDate = revenueArrangementItem.deliveryStartDate;
    if (revenueArrangementItem.ssp.recognizedOn === "endDate") {
        deliveryDate = revenueArrangementItem.deliveryEndDate;

        if (!endDateInclusive) {
            deliveryDate = Period.addDays(end, -1);
            // convert end to insclusive date as it's not.
        }
    }

    const recognized = Period.isSameOrAfter(terminationDate, deliveryDate);

    if (!recognized) {
        revenueArrangementItem.terminatedProductRevenue = 0;
        updateRatablePlan(revenueArrangementItem.plan, 0);
    } else {
        revenueArrangementItem.terminatedProductRevenue =
            revenueArrangementItem.productRevenue;
    }
};

const TERMINATION_RULES = {
    [RecognitionRule.POINT_IN_TIME]: pointInTimeItemTermination,
    [RecognitionRule.RATABLE]: ratableItemTermination,
    [RecognitionRule.PROPORTIONAL_PERFORMANCE]: proportionalPerformanceItemTermination
};

const applyTerminationRule = (params) => {
    const { salesOrder, salesOrderItem, revenueArrangement, logger } = params;

    const revenueArrangementItem = getRevenueArrangementItemBySalesOrderItem(
        revenueArrangement,
        salesOrderItem
    );

    if (!revenueArrangementItem) {
        logger.warn(
            `Revenue arrangement item not found for sales order ${salesOrder.id} and sales order item ${salesOrderItem.id} (product ${salesOrderItem.productId})`
        );
        return;
    }

    const recognitionRule =
        revenueArrangementItem && revenueArrangementItem.recognitionRule.id;

    TERMINATION_RULES[recognitionRule]({
        ...params,
        revenueArrangement,
        revenueArrangementItem
    });
};

export const changeUnitsSold = (salesOrderItem, quantityDelivered) => {
    salesOrderItem.quantity = Number(quantityDelivered);

    salesOrderItem.extendedSalePrice =
        salesOrderItem.salePrice * salesOrderItem.quantity;

    return {
        unitsSold: salesOrderItem.quantity,
        unitPrice: salesOrderItem.salePrice,
        extendedSalePrice: salesOrderItem.extendedSalePrice
    };
};

export const changeUnitPrice = (salesOrderItem, salePrice) => {
    salesOrderItem.salePrice = Number(salePrice);
    salesOrderItem.extendedSalePrice = salesOrderItem.quantity * salePrice;

    return {
        unitsSold: salesOrderItem.quantity,
        unitPrice: salesOrderItem.salePrice,
        extendedSalePrice: salesOrderItem.extendedSalePrice
    };
};

export const addTerminationPenaltyItem = (
    terminationPenalityFees,
    salesOrder,
    terminationDate
) => {
    const firstItem = salesOrder.salesOrderItem[0];
    const itemAttributes = Utils.cloneDeep(firstItem.attributes);

    Object.keys(itemAttributes).forEach((key) => {
        if (key === "productType") {
            itemAttributes.productType = "Terminate";
        } else {
            delete itemAttributes[key];
        }
    });

    // Add a new item in the sales order with quantity 1 and sale price as termination penalityFees
    const product = {
        id: "termination",
        code: "TERMINATE",
        name: "Termination Penality"
    };
    const item = {
        salesOrderId: salesOrder.id,
        id: `terminateItem`,
        rootId: "terminateItem",
        productId: "termination",
        product: product,
        quantity: 1,
        salePrice: Number(terminationPenalityFees),
        listPrice: 0,
        startDate: Utils.formatDate(terminationDate),
        endDate: Utils.formatDate(terminationDate),
        dereferencedStartDate: "manualDate",
        dereferencedEndDate: "manualDate",
        isActive: true,
        attributes: itemAttributes,
        standaloneSellingPrice: Number(terminationPenalityFees)
    };

    salesOrder.salesOrderItem.push(item);
};

export const terminateSalesOrder = (
    salesOrder,
    organization,
    terminationDate,
    penaltyAmount = 0,
    doProration = true,
    calendarConfig,
    endDateInclusive,
    orgConfig,
    logger
) => {
    for (let salesOrderItem of salesOrder.salesOrderItem) {
        applyTerminationRule({
            salesOrder,
            revenueArrangement: salesOrder.newRevenueArrangement,
            salesOrderItem,
            terminationDate,
            doProration,
            calendarConfig,
            endDateInclusive,
            orgConfig,
            logger
        });
    }

    if (penaltyAmount != 0) {
        addTerminationPenaltyItem(penaltyAmount, salesOrder, terminationDate);
    }

    return salesOrder;
};
