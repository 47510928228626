import React from "react";
import { Utils } from "revlock-webutils";

export function ImgIcon(props) {
    const { name, width = 70 } = props;
    return (
        <img
            src={`https://cdn.revrec.chargebee.com/${name}.png`}
            style={{ width }}
        />
    );
}

export function A(props) {
    const { href, children } = props;
    return (
        <>
            {" "}
            <a
                style={{
                    textDecoration: "underline",
                    textDecorationColor: "#012a38"
                }}
                onClick={() => Utils.showPopup({ url: href })}
            >
                {children}
            </a>{" "}
        </>
    );
}
