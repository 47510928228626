import React from 'react';

import { Grid, Typography, Button, Dialog, IconButton, Slide } from '@material-ui/core';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { withRouter } from 'react-router-dom';
import { compose } from 'utils/WebUtils';
import { markSeenTour } from "utils/Crisp";
import { welcomeTour } from './TourConfig';
import { getPlan } from "utils/auth";

import { setData2 } from 'redux/actions';

import { connect } from 'react-redux';
import { currentOrganization, currentUser } from "redux/selectors";
import { ArrowBack as Prev, ArrowForward as Next } from "@material-ui/icons";

import {ReactComponent as Welcome1} from './images/welcome1.svg'
import {ReactComponent as Welcome2} from './images/welcome2.svg'
import {ReactComponent as Welcome3} from './images/welcome3.svg'

import * as PlgAPI from "rest/PlgAPI";

const items = [ <Welcome1/>, <Welcome2/>, <Welcome3/> ];

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    tourFooter: {
        display: 'flex',
        padding: '16px 20px',
    },
    // btn:{
    //     color: theme.palette.background.primary['600']
    // }
});

const Transition = React.forwardRef(function Transistepstion(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1)
    },
}))(MuiDialogActions);

function TourWindow(props) {
    const { currentOrganization, currentUser, open, history, classes } = props;
    const [tours, setTours] = React.useState();
    const [index, setIndex] = React.useState(0);

    const pills = React.useRef();

    React.useEffect(() => {
        if (currentOrganization) {
            const tours =
                welcomeTour(currentOrganization, currentUser, history);
            setTours(tours);
        }

    }, [currentOrganization, currentUser])

    async function handleClose() {
        setTimeout(() => {
            // User just completed tour, let's give 'em a break. No PLG for a short time.
            PlgAPI.disablePlg(async () => {
                await markSeenTour(currentOrganization.id, undefined, history)
                setData2('showWelcomeTour', false);
            })
        }, 100);

        
    };

    const go = (dir) => {
        if (dir == 'prev') {
            const value = index > 1 ? Number(index) - 1 : 0
            setIndex(value)
        }
        if (dir == 'next') {
            const value = index < tours.length - 1 ? index + 1 : tours.length - 1
            setIndex(value)
        }
    };

    if (!tours || !open || !currentOrganization)
        return null;

    return (<Dialog
        onClose={handleClose}
        open={open}
        TransitionComponent={Transition}
        maxWidth={'md'}
        disableEnforceFocus={true}>
        <DialogTitle onClose={handleClose}>
        </DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={6}>
                    <Grid item xs={12} >
                        {items[index]}
                    </Grid>
                </Grid>
                {tours[index].content}
            </Grid>
        </DialogContent>
        <DialogActions className={classes.tourFooter}>
            {index !== 0 &&
                <Button color="primary" size="normal" onClick={() => go('prev')} >
                    <Prev />&nbsp;&nbsp;Prev
                </Button>
            }
            <div style={{ flex: 1 }} />
            {index !== tours.length - 1 &&
                <Button color="primary" size="normal" onClick={() => go('next')} className={classes.btn}>
                    Next&nbsp;&nbsp;<Next />
                </Button>
            }
            { index === tours.length -1 &&
              <Button color="primary" size="normal" onClick={handleClose} className={classes.btn}>
                  {getPlan().name == 'rev-essential' ? 'Go to QuickView Reports' : 'Done'}&nbsp;&nbsp;<Next />
              </Button>
            }
        </DialogActions>
    </Dialog>
    );
}


function mapStateToProps(state) {
    const toReturn = {
        currentOrganization: currentOrganization(state),
        currentUser: currentUser(state)
    };
    return toReturn;
}

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps)
)(TourWindow);
