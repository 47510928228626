// Revenue Arrangement Item Type
export default {
    // unchanged items are normal, new or without any prospective modifications
    UNCHANGED: 1,
    // for a prospective modification, frozen type will be the item before the modification date where the revenue is frozen
    // unrecognized type will be the item with the future revenue which is after the modification date
    FROZEN: 2,
    SPLIT_FROZEN: 3,
    SPLIT_UNRECOGNIZED: 4,
    isFrozen(itemType) {
        return itemType == this.FROZEN || itemType == this.SPLIT_FROZEN;
    }
};
