const {config} = require('../../../package.json');

function getApiEndpoint() {
    return (config['isCloudDeployment'] && "https://dev-api.rev-lock.com") || "http://localhost:3001"
}

export default {
    AWS: {
        "authenticationFlowType": "USER_PASSWORD_AUTH",
        Auth: {
            identityPoolId: "us-west-1:3524871b-2989-4e4b-9780-b2e55beadca3",
            region: "us-west-1",
            userPoolId: "us-west-1_5G1zQCgPv",
            userPoolWebClientId: "48sk2k79ghobveg28t660nunrm",
            mandatorySignIn: true
        }
    }
}
