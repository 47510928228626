import { Utils } from "revlock-webutils";

const allStrategies = ["boundary", "min", "max", "midpoint"];

export default {
    RANGE: {
        name: "RANGE",
        label: "Dollar Amount (Range)",
        format: ({ min, max }) => Utils.formatCurrencyRange(min, max),
        isValid: ({ min, max, type, strategy }) => {
            let ret =
                (min <= max &&
                    type === "RANGE" &&
                    allStrategies.includes(strategy) &&
                    true) ||
                false;
            return ret;
        }
    },
    AS_IS: {
        name: "AS_IS",
        label: "Sale Price",
        format: () => "Use Sale Price",
        isValid: ({ type }) => type === "AS_IS"
    },

    LIST_PRICE: {
        name: "LIST_PRICE",
        label: "List Price",
        format: () => "Use List Price",
        isValid: ({ type }) => type === "LIST_PRICE"
    },

    DISCOUNT_RANGE: {
        name: "DISCOUNT_RANGE",
        label: "Discount",
        format: ({ min, max }) =>
            `${Utils.formatPercentRange(min * 100, max * 100)} (Discount)`,
        isValid: ({ min, max, type, strategy }) => {
            let ret =
                (min <= max &&
                    -1 <= min &&
                    min <= 1 &&
                    -1 <= max &&
                    max <= 1 &&
                    type === "DISCOUNT_RANGE" &&
                    allStrategies.includes(strategy) &&
                    true) ||
                false;

            return ret;
        }
    },

    PERCENT_NET_RANGE: {
        name: "PERCENT_NET_RANGE",
        label: "Apportioned Percent Net",
        format: ({ min, max }) =>
            `${Utils.formatPercentRange(min * 100, max * 100)} (Percent-net)`,
        isValid: ({ min, max, type, strategy }) => {
            return (
                (min <= max &&
                    0 <= min &&
                    min <= 1 &&
                    0 <= max &&
                    max <= 1 &&
                    type === "PERCENT_NET_RANGE" &&
                    allStrategies.includes(strategy) &&
                    true) ||
                false
            );
        }
    },

    SIMPLE_PERCENT_NET_RANGE: {
        name: "SIMPLE_PERCENT_NET_RANGE",
        label: "Simple Percent Net",
        format: ({ min, max }) =>
            `${Utils.formatPercentRange(min * 100, max * 100)} (Percent-net)`,
        isValid: ({ min, max, type, strategy }) => {
            return (
                (min <= max &&
                    0 <= min &&
                    min <= 1 &&
                    0 <= max &&
                    max <= 1 &&
                    type === "SIMPLE_PERCENT_NET_RANGE" &&
                    allStrategies.includes(strategy) &&
                    true) ||
                false
            );
        }
    },

    RESIDUAL: {
        name: "RESIDUAL",
        label: "Residual",
        format: () => "RESIDUAL",
        isValid: ({ type }) => type === "RESIDUAL"
    },

    NUMERIC: {
        name: "NUMERIC",
        label: "Dollar Amount",
        format: (amount) => Utils.formatCurrency(amount),
        isValid: (amount) => (isNaN(amount) ? false : true)
    },
    STANDALONE: {
        name: "STANDALONE",
        label: "Standalone",
        format: () => "Standalone",
        isValid: ({ type }) => type === "STANDALONE"
    }
};
