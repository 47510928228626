const { config } = require("../../../package.json");

const alias = (() => {
    return config.serviceProfile ? `/${config.serviceProfile}` : "";
})();

function getApiEndpoint() {
    return (
        (config["isCloudDeployment"] &&
            `https://dev-api.rev-lock.com${alias}`) ||
        "http://localhost:3001"
    );
}

function getRedirectUri() {
    return (
        (config["isCloudDeployment"] &&
            `https://dev-${config.serviceProfile}-sso.revrec.devcb.in/`) ||
        `http://localhost:3000${alias}`
    );
}

function getRedirectSignoutUri() {
    return (
        (config["isCloudDeployment"] &&
            `https://dev.auth.rev-lock.com/logout?client_id=3gsqkq3q84eucsnv6nn37643k6&logout_uri=https%3A%2F%2Fdev-${config.serviceProfile}-sso.revrec.devcb.in%2F`) ||
        `https://dev.auth.rev-lock.com/logout?client_id=3gsqkq3q84eucsnv6nn37643k6&logout_uri=http%3A%2F%2Flocalhost%3A3000%2Fa%3Fcbsso%3Dtrue`
    );
}

const baseRedirectUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return `https://dev-${config.serviceProfile}.rev-lock.com`;
    } else {
        return `https://dev-${config.serviceProfile}.revrec.devcb.in`;
    }
})();

const baseReportUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return "https://dev-reports.rev-lock.com";
    } else {
        return "https://dev-reports.revrec.devcb.in";
    }
})();

export default {
    microstrategy: {
        mstrWebUrl: `${baseReportUrl}/MicroStrategy/servlet/`,
        project: "Dev_RL_A",
        server: "dev-mstr.cluster.rev-lock.local"
    },
    hotGlue: {
        env_id: "dev.hotglue.rev-lock.com"
    },
    AWS: {
        API: {
            endpoints: [
                {
                    name: "revlock_api",
                    endpoint: getApiEndpoint()
                },
                {
                    name: "revlock_public_api",
                    endpoint: getApiEndpoint(),
                    custom_header: async () => {
                        return {
                            "x-api-key":
                                "AwhpvUNsUJ7MLnfVyhXa30hwGaiBPDy9rGb4yGbc"
                        };
                    }
                }
            ]
        },
        Auth: {
            identityPoolId: "us-east-1:62490b3c-81d4-4df5-8352-e0250a08d68a",
            region: "us-east-1",
            userPoolId: "us-east-1_TPjvx0Q4A",
            userPoolWebClientId: "5a13rk9eks6cibsebc1cv5j2ll",
            mandatorySignIn: true
        },
        Storage: {
            bucket: "dev-a-userfiles",
            region: "us-east-1",
            hotglueBucket: "dev-hotgluefiles"
        },
        Analytics: {
            disabled: true
        },
        oauth: {
            domain: "dev.auth.rev-lock.com",
            clientId:
                "1092057117597-1nosea2ifka99fshkiqo4a4iajmjucq4.apps.googleusercontent.com",
            redirectSignOut: `${baseRedirectUrl}/signout`,
            redirectSignIn: `${baseRedirectUrl}/`,
            scope: ["email", "profile", "openid"],
            responseType: "code"
        }
    },
    keycloakConfig: {
        url: "https://idp-broker.devcb.in/",
        redirectUri: getRedirectUri(),
        realm: "master",
        clientId: "revrec-app",
        idpHint: "revrec-idp",
        redirectSignoutUri: getRedirectSignoutUri()
    },
    development: true
};
