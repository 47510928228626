import AppEntities from './AppEntities'
import GenericActions from './GenericActions';
const actions = {}

Object.keys(AppEntities).forEach(entityName => actions[entityName] = GenericActions(entityName));

actions.newEntity = (entityName) => {
    actions[entityName] = GenericActions(entityName)
}

export const ProductActions = actions['product'];
export const CustomerActions = actions['customer'];
export const SyncActions = actions['sync'];
export const CustomerContractsActions = actions['customerContracts'];
export const JournalAccountActions = actions['journalAccount'];
export const SalesOrderActions = actions['salesOrder'];
export const RevenueSummaryActions = actions['revenueSummary'];
export const CommissionSummaryActions = actions['commissionSummary'];
export const OrgConfigActions = actions['orgConfig'];
export const SSPActions = actions['standaloneSellingPrice'];
export const MissingStandaloneSellingPriceActions = actions['missingStandaloneSellingPrice'];

export {default as UserActions} from 'redux/actions/user.actions'


export * from 'redux/actions/data.actions.js';
export * from 'redux/actions/layout.actions';
export * from 'redux/actions/reports.actions';
export * from 'redux/actions/sales.actions';


export default actions;
