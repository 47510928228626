const EntityDefaults = {
    Customer: [{ id: "_0", name: "UNKNOWN" }],
    Product: [
        { id: "_0", code: "unknown", name: "UNKNOWN" },
        { id: "Expense", code: "EXPENSE", name: "Product Expense" },
        {
            id: "termination",
            code: "TERMINATE",
            name: "Termination Penality",
            recurring: false
        },
        { id: "Invoice", code: "Invoice", name: "Product Invoice" },
        { id: "SVC", code: "SVC", name: "Service Delivery" }
    ],
    SalesPerson: [{ id: "_0", name: "DEFAULT" }],
    OrgConfig: [
        { id: "reporting/fields", value: ["customer", "product"] },
        {
            id: "mrr/fields",
            value: ["customer"]
        },

        {
            id: "properties/default-revenue-policy/RATABLE",
            value: "END_OF_MONTH"
        },
        {
            id: "properties/default-revenue-policy/POINT_IN_TIME",
            value: "END_OF_FIRST_MONTH"
        },
        {
            id: "properties/default-revenue-policy/PROPORTIONAL_PERFORMANCE",
            value: "END_OF_LAST_MONTH"
        },
        {
            id: "properties/default-revenue-policy/PERCENT_OF_COMPLETE",
            value: "END_OF_LAST_MONTH"
        },

        {
            id: "properties/default-commission-policy/OVER_TIME",
            value: "END_OF_MONTH"
        },
        {
            id: "properties/default-commission-policy/AS_INCURRED",
            value: "END_OF_FIRST_MONTH"
        },

        {
            id: "properties/default-billing-policy/RATABLE",
            value: "START_OF_FIRST_MONTH"
        },
        {
            id: "properties/default-billing-policy/POINT_IN_TIME",
            value: "START_OF_FIRST_MONTH"
        },
        {
            id: "properties/default-billing-policy/PROPORTIONAL_PERFORMANCE",
            value: "AS_INCURRED"
        },
        {
            id: "properties/default-billing-policy/PERCENT_OF_COMPLETE",
            value: "AS_INCURRED"
        },
        {
            id: "properties/default-billing-policy/QUANTITY_DELIVERED",
            value: "AS_INCURRED"
        },

        { id: "properties/enableBetaFeatures", value: false },
        { id: "properties/legacyPSDSystem", value: false },
        { id: "properties/legacyBillingScheduleSystem", value: false },
        { id: "properties/hotGlueEnabled", value: false },
        { id: "properties/deprecated/hotGlueEnabled", value: false },
        { id: "properties/billRemainingAmountOnExpiry", value: false },
        { id: "properties/autoDiscoverModification", value: true },
        {
            id: "properties/postJournalEntries/transactionTypes",
            value: [
                "COM_DEFERRAL",
                "COM_EXPENSE",
                "EXP_DEFERRAL",
                "EXP_RECOGNIZED",
                "REV_DEFERRAL",
                "REVENUE",
                "DREV_RECLASS_UR",
                "UR_REVERSAL",
                "FX_GAIN_LOSS"
            ]
        },
        {
            id: "properties/postJournalEntries/customerName",
            value: "c.Customer_Name"
        },
        {
            id: "properties/postJournalEntries/executeOnAccountingClose",
            value: false
        },
        { id: "properties/mrr-method", value: "mrr-by-day" },
        { id: "properties/advancedSecurity", value: false },
        { id: "properties/journal-mapping-fields", value: ["accountType"] },
        { id: "properties/journal-mapping-fields/tax", value: ["accountType"] },
        {
            id: "customFields/attributeMapping/tax",
            value: {
                SLCustomField01: {
                    name: "tax_name",
                    displayName: "Tax Name",
                    sourceEntity: "tax",
                    isAccountingCategory: true
                },
                SLCustomField02: {
                    name: "tax_juris_name",
                    displayName: "Tax Juris Name",
                    sourceEntity: "tax",
                    isAccountingCategory: true
                }
            }
        },
        { id: "properties/postJournalEntries/level_of_details", value: {} },
        { id: "properties/mstr_language", value: "English (United States)" },
        { id: "properties/mstr_legacy_client", value: false },
        { id: "properties/mstr_analytics_enabled", value: false },
        { id: "properties/billing/by-product-code", value: false },
        { id: "properties/ssp-fields", value: ["productCode"] },
        // Current supported values are 'salesOrder' and 'customer'
        { id: "properties/unearned_by", value: "salesOrder" },
        { id: "properties/advanced-ssp", value: false },
        { id: "properties/multi-currency-enabled", value: false },
        { id: "properties/billing/by-contract", value: false },
        { id: "properties/ratable_plan_type", value: "default" },
        { id: "properties/delayedRevenueForActiveContractsOnly", value: true },
        { id: "properties/expense-enabled", value: false },
        { id: "properties/expense/reasoncodes", value: [] },
        {
            id: "sync/integration",
            value: {
                "XL_v1.1": {
                    tables: [
                        "Commissions",
                        "OrderDetails",
                        "Product",
                        "Customer",
                        "BillingSchedule",
                        "JournalAccounts",
                        "PendingData",
                        "ProfessionalServiceDelivery",
                        "Cost",
                        "Terminations",
                        "ReconSummary_Invoice",
                        "ReconDetails_Invoice",
                        "ReconCustomerInvoices"
                    ]
                }
            }
        },
        { id: "integration/invoice_period_as_contract_term", value: false },
        { id: "properties/wh_export_enabled", value: false },
        {
            id: "properties/wh_export_files",
            value: [
                "Control",
                "DeferredRevenueBalance",
                "Expenses",
                "Invoices",
                "Journals",
                "Orders",
                "Revenue",
                "UnearnedRevenueRollforward"
            ]
        },
        { id: "properties/period_export_enabled", value: false },
        {
            id: "properties/period_export_files",
            value: [
                "Control",
                "DeferredRevenueBalance",
                "Expenses",
                "Invoices",
                "Journals",
                "Orders",
                "Revenue",
                "UnearnedRevenueRollforward"
            ]
        },
        { id: "properties/taxEnabled", value: false },
        {
            id: "properties/service-delivery/percentage-incremental",
            value: false
        },
        { id: "properties/recognize-by-transaction-date", value: false },
        { id: "properties/manualAdjustmentsEnabled", value: false },
        { id: "properties/reporting/useTransactionCurrency", value: false },
        { id: "properties/deferred_by_item", value: false },
        { id: "properties/enable_prior_period_adj", value: false },
        {
            id: "properties/track-pending-collectability/level",
            value: "customer"
        }
    ]
};

export const defaultEntities = EntityDefaults;

export function ensureDefaults(clientId, referenceData) {
    let updates = {};

    Object.keys(referenceData).forEach((entityName) => {
        if (EntityDefaults[entityName]) {
            let entities = referenceData[entityName];
            let newEntities = [];
            let defaultEntities = EntityDefaults[entityName];

            defaultEntities.forEach((entity) => {
                let existingEntity = entities.find(
                    (_entity) => _entity.id == entity.id
                );
                if (!existingEntity) {
                    newEntities.push(entity);
                }
            });

            if (newEntities.length > 0) {
                updates[entityName] = newEntities;
                entities.push(...newEntities);
            }
        }
    });

    return updates;
}
