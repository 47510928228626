export default {
    A_R: ["Unbilled Receivable"],
    UNEARN_REV: [
        "Bad Debt Expense",
        "Contra Revenue",
        "Deferred Revenue",
        "Revenue",
        "Revenue PPA",
        "Realized FX Gain Loss"
    ],
    EXPENSE: [
        "Commission Accumulated Amortization",
        "Commission Amortization",
        "Contra Commission Expense",
        "Deferred Commission",
        "Deferred Contra Commission Expense",
        "Deferred Expense",
        "Expense Clearing",
        "Expense Recognized"
    ],
    TAX: ["Tax Payable", "Cash Tax"]
};
