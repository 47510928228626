const { config } = require("../../../package.json");

const alias = (() => {
    return config.serviceProfile ? `/${config.serviceProfile}` : "";
})();

function getApiEndpoint() {
    if (config["isCloudDeployment"]) {
        if (window.location.origin.match(/rev-lock.com/)) {
            return `https://prod.api.rev-lock.com${alias}`;
        } else {
            return `https://api.revrec.chargebee.com${alias}`;
        }
    } else {
        return "http://localhost:3001";
    }
}

const baseRedirectUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return "https://app.rev-lock.com";
    } else {
        return "https://app.revrec.chargebee.com";
    }
})();

const baseReportUrl = (() => {
    if (window.location.origin.match(/rev-lock.com/)) {
        return "https://prod-reports.rev-lock.com";
    } else {
        return "https://prod-reports.revrec.chargebee.com";
    }
})();

export default {
    microstrategy: {
        mstrWebUrl: `${baseReportUrl}/MicroStrategy/servlet/`,
        project: "Prod_RL_B",
        server: `prod-mstr.cluster.rev-lock.local`
    },
    hotGlue: {
        env_id: "prod.hotglue.rev-lock.com"
    },
    AWS: {
        API: {
            endpoints: [
                {
                    name: "revlock_api",
                    endpoint: getApiEndpoint()
                },
                {
                    name: "revlock_public_api",
                    endpoint: getApiEndpoint(),
                    custom_header: async () => {
                        return {
                            "x-api-key":
                                "C6zNfde03c1OqCQNFsfYIa2SfmyImgIn8DqwZYvL"
                        };
                    }
                }
            ]
        },
        Auth: {
            identityPoolId: "us-east-1:d0f08abf-65f1-40a0-abbe-e1d918f51cd8",
            region: "us-east-1",
            userPoolId: "us-east-1_Fdy2b5mSk",
            userPoolWebClientId: "1qg6sm77h56bieuo4uqljiqvl1",
            mandatorySignIn: true
        },
        Storage: {
            bucket: "revlockprod-userfiles-mobilehub-615352475",
            region: "us-east-1",
            hotglueBucket: "prod-hotgluefiles"
        },
        Analytics: {
            disabled: true
        },
        oauth: {
            domain: "revrec.auth.us-east-1.amazoncognito.com",
            clientId:
                "1092057117597-5m0ml54tve0hha8v79pccob3l6320sno.apps.googleusercontent.com",
            redirectSignOut: `${baseRedirectUrl}/signout`,
            redirectSignIn: `${baseRedirectUrl}/`,
            scope: ["email", "profile", "openid"],
            responseType: "code"
        }
    },
    analytics: {
        trackingId: "UA-127158128-2",
        debug: false
    },
    keycloakConfig: {
        url: "https://idp-broker.devcb.in/",
        redirectUri: `https://prod-${config.serviceProfile}-sso.rev-lock.com/`,
        realm: "master",
        clientId: "revrec-app",
        idpHint: "revrec-idp",
        redirectSignoutUri: `https://prod.auth.rev-lock.com/logout?client_id=6f50lh83rprtp3pl1sof5edsdn&logout_uri=https%3A%2F%2Fprod-${config.serviceProfile}-sso.rev-lock.com%2F`
    }
};
