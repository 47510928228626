import { Period } from 'revlock-webutils'
import shortid from 'shortid'
import { assertValidPlan } from '../ArrangementUtils'

export default {
	buildPlan: function buildPlan (
		startDate, endDate, originalBooking, calendarConfig) {
		if (Period.isBefore(endDate, startDate)) {
			throw new Error(`Plan starts ${startDate} before it ends ${endDate}`)
		}

		const startActgPeriod = Period.toActgPeriod(startDate, calendarConfig)
		const endActgPeriod = Period.toActgPeriod(endDate, calendarConfig)

		// last month is not full whack it, otherwise it's inclusive.
		const endDateEndOfMonth = Period.toEndOfMonth(endDate)

		let endPeriod
		if ((endActgPeriod.period === startActgPeriod.period)) {
			endPeriod = startActgPeriod.period
		} else if (endDate < endDateEndOfMonth) {
			endPeriod = Period.priorPeriod(endActgPeriod.period)
		} else {
			endPeriod = endActgPeriod.period
		}

		let periods = (startActgPeriod.period === endPeriod)
			? [startActgPeriod.period]
			: Period.periodsBetween(startActgPeriod.period, endPeriod, true, true)
		//between periods

		let totalContractRevenueMonths = periods.length
		if (totalContractRevenueMonths === 0) {
			totalContractRevenueMonths = 1
		}

		const planRate = originalBooking / totalContractRevenueMonths

		const plan = []
		let cummulativeTotal = 0

		let actgPeriod = startActgPeriod
		for (let period of periods) {
			actgPeriod = actgPeriod.get(period)

			let planAmount = planRate
			//calculate the amount for the period.

			cummulativeTotal += planAmount

			plan.push({
				actgPeriod,
				planAmount,
			})
		}

		assertValidPlan(plan)
		return plan

	},
}
