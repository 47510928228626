import React from 'react';
import { Collapse, IconButton, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";

const buttonStyles = {
    alignSelf: 'flex-start',
    textTransform: 'None',
    fontSize: '14px',
    backgroundColor: 'transparent',
}

const detailsBox = {
    overflowX: 'scroll',
    border: '1px solid #f4f5f7',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5',
    padding: '5px',
    textAlign: 'left',
    maxHeight: '150px',
}

const useStyles = makeStyles(theme => ({
    root: {
        textDecoration: 'underline',
        '&:hover svg': {
            fill: theme.palette.secondary.main
        }
    }
}))

const ErrorDetailsBox = ({ details }) => {

    const classes = useStyles()

    const [toggleShowDetails, setToggleShowDetails] = React.useState(false);
    const handleShowDetailsClick = () => setToggleShowDetails(!toggleShowDetails)

    return (
        <Box display='flex' flexDirection="column" justifyContent='flex-end'>
            <IconButton
                className={classes.root}
                style={buttonStyles}
                color="secondary"
                onClick={handleShowDetailsClick}
            >
                Show Details {toggleShowDetails ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <Collapse in={toggleShowDetails} timeout="auto" unmountOnExit>
                <pre style={detailsBox}>{details}</pre>
            </Collapse>
        </Box>
    );
}


export default ErrorDetailsBox;