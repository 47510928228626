import { createSelector } from "reselect";
import UserActions from "../actions/user.actions";
import { currentUser, currentOrganization, currentOrganizationState } from "./user.selector";

const taskState = (state) => state.tasks;

export const getTasks = createSelector(
  [currentUser, currentOrganization, currentOrganizationState, taskState],
  (currentUser, currentOrganization, currentOrganizationState, taskState) => {
    if (!currentUser || !currentUser.attributes || !currentOrganization)
      return undefined;

    if (!UserActions.fetchAllTasks(taskState, () => {
      return taskState.tasks != undefined && taskState.lastUpdated >= currentOrganizationState.lastUpdated;
    }, { orgId: currentOrganization.id, userId: currentUser.attributes && currentUser.attributes.email}))
      return undefined;

    return taskState.tasks;
  }
);
