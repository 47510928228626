import { buildExpenseRuleDistinctiveKey } from "./SalesOrderVisitor";
import _ from "lodash";
import { Period, Utils, Comparator } from "revlock-webutils";
import shortid from "shortid";

import RecognitionRule from "./reference/RecognitionRule";
import ExpenseRecognitionRule from "./reference/ExpenseRecognitionRule";
import ModificationType from "./reference/ModificationType";
import PointInTimePlan from "./plan/PointInTimePlan";
import { Codes } from "../../build/core/ErrorCode";
import { updateRatablePlan } from "./plan/ApportionedRatablePlan";

import { selectExpenseRule } from "./ExpenseRuleSelectorHelper";
import {
    buildPlan,
    getAutoCreateSSP,
    prefillZeroAtStart
} from "./ArrangementUtils";
import { isDiscountProduct } from "./AccountingUtils";
import ItemType from "./reference/ItemType";
import { AccountingUtils } from "../Accounting";

const defaultDirectExpenseRule = (id, expenseAttributes, expenseCode) => {
    return {
        sspType: "Direct",
        expenseAttributes: expenseAttributes,
        expenseCode: expenseCode,
        reAllocateExpense: true,
        expenseTracksRevenue: true,
        id: id
    };
};

const defaultInDirectExpenseRule = (id, expenseItem) => {
    const expenseCode = expenseItem.expenseCode;

    let expenseAmortizationMethod = ExpenseRecognitionRule.AS_INCURRED;

    if (
        expenseItem.amortizationTerm != undefined ||
        (expenseItem.amortizationEndDate != undefined &&
            Utils.isDate(expenseItem.amortizationEndDate) &&
            expenseItem.amortizationEndDate !=
                expenseItem.amortizationStartDate)
    ) {
        expenseAmortizationMethod = ExpenseRecognitionRule.OVER_TIME;
    }

    return {
        sspType: "Indirect",
        expenseAttributes: {},
        expenseCode: expenseCode,
        expenseAmortizationMethod: expenseAmortizationMethod,
        id: id
    };
};

export function buildDefaultExpensePlan(
    expenseItem,
    raItem,
    salesOrder,
    orgConfig
) {
    const d = orgConfig.find((config) => config.id === "properties/calendar");
    const calendarConfig = d && d.value && d.value.config;

    switch (expenseItem.expenseRecognitionRule.id) {
        case ExpenseRecognitionRule.AS_INCURRED:
            expenseItem.plan = PointInTimePlan.buildPlan(
                expenseItem.deliveryStartDate,
                expenseItem.deliveryEndDate,
                expenseItem.allocatedExpense,
                expenseItem.expenseRule,
                calendarConfig
            );
            break;
        default:
            expenseItem.plan = buildPlan(
                {
                    deliveryStartDate: expenseItem.deliveryStartDate,
                    deliveryEndDate: expenseItem.deliveryEndDate
                },
                salesOrder,
                orgConfig,
                expenseItem.allocatedExpense,
                calendarConfig
            );
    }

    prefillZeroAtStart(
        expenseItem.plan,
        {
            revenueArrangement: [
                {
                    effectiveDate: expenseItem.expenseDate
                }
            ]
        },
        calendarConfig
    );
}

export function populateExpenseDeliveryDates(
    expenseItem,
    expenseRule,
    rai,
    errors,
    endDateInclusive,
    contractTermEnabled,
    raiItemsGroupedByProductCode
) {
    const overrideDeliveryDates = (
        amortizationStartDate,
        amortizationEndDate,
        term,
        expenseItem,
        sourceRuleId,
        targetRuleId
    ) => {
        let deliveryStartDate;
        let deliveryEndDate;
        if (expenseItem.amortizationStartDate != undefined) {
            if (expenseItem.amortizationStartDate == "Expense Date") {
                amortizationStartDate = expenseItem.expenseDate;
            } else {
                amortizationStartDate = expenseItem.amortizationStartDate;
            }
        }

        if (expenseItem.amortizationEndDate != undefined) {
            if (expenseItem.amortizationEndDate == "Expense Date") {
                amortizationEndDate = expenseItem.expenseDate;
            } else {
                amortizationEndDate = expenseItem.amortizationEndDate;
            }
        }

        if (expenseItem.amortizationTerm != undefined) {
            amortizationEndDate = undefined;
            term = expenseItem.amortizationTerm;
        }

        deliveryStartDate = amortizationStartDate;

        // STEP 3: We have found the dates at this point, let's populate them now.
        if (sourceRuleId != targetRuleId) {
            // RATABLE
            if (term != undefined && amortizationEndDate == undefined) {
                let endDate = Utils.addMonths(amortizationStartDate, term);

                if (endDateInclusive) endDate = Utils.addDays(endDate, -1);

                deliveryEndDate = Utils.formatDate(endDate);
            } else {
                deliveryEndDate = amortizationEndDate;
            }
        } else {
            deliveryEndDate = amortizationStartDate;
        }

        return { deliveryStartDate, deliveryEndDate };
    };

    let amortizationStartDate;
    let amortizationEndDate;
    let term;
    // NOTE: SSP is going to tell us which date we need to use for start and end
    if (expenseRule.expenseTracksRevenue == true) {
        amortizationStartDate = rai.deliveryStartDate;
        amortizationEndDate = rai.deliveryEndDate;

        // Here we need to pick the start date end date and term of the sales order item
        const { deliveryStartDate, deliveryEndDate } = overrideDeliveryDates(
            amortizationStartDate,
            amortizationEndDate,
            term,
            expenseItem,
            rai.ssp.recognitionRuleId,
            RecognitionRule.POINT_IN_TIME
        );

        // final dates to use post de-referencing for plan
        expenseItem.deliveryStartDate = deliveryStartDate;
        expenseItem.deliveryEndDate = deliveryEndDate;
    } else {
        // expense rule is not tracking revenue, check if we have amortization dates and term defined on
        // expense rule. If not then we have explicit dates / term defined. If yes, dereference them.
        // if no dates found on expense item, it's an error.

        // NOTE: expense rule can only have Expense Date / Amortization Start Date

        // Expense Item dates takes priority over what is defined in the Expense Rule

        // STEP 1. Populate dates based off of expense rule.
        if (expenseRule.amortizationStartDate !== undefined) {
            // expense rule is telling us which date to pick for amortization.
            if (expenseRule.amortizationStartDate === "Expense Date") {
                amortizationStartDate = expenseItem.expenseDate;
            } else if (
                expenseRule.amortizationStartDate === "Amortization Start Date"
            ) {
                amortizationStartDate = expenseItem.amortizationStartDate;
            }
        }

        if (expenseRule.amortizationEndDate !== undefined) {
            // expense rule is telling us which end date to pick.
            if (expenseRule.amortizationEndDate === "Expense Date") {
                amortizationEndDate = expenseItem.expenseDate;
            } else if (
                expenseRule.amortizationEndDate === "Amortization Start Date"
            ) {
                amortizationEndDate = expenseItem.amortizationEndDate;
            }
        }

        if (expenseRule.amortizationTerm != undefined) {
            // expense rule is telling us what is the term
            amortizationEndDate = undefined;
            term = expenseRule.amortizationTerm;
        }

        // STEP 2. Override dates based off of what is defined in the expense item.
        const { deliveryStartDate, deliveryEndDate } = overrideDeliveryDates(
            amortizationStartDate,
            amortizationEndDate,
            term,
            expenseItem,
            expenseRule.expenseAmortizationMethod,
            ExpenseRecognitionRule.AS_INCURRED
        );

        expenseItem.deliveryStartDate = deliveryStartDate;
        expenseItem.deliveryEndDate = deliveryEndDate;
    }

    // no start date item is inactive
    // no end date default end date to start date.

    if (!expenseItem.deliveryStartDate) {
        // this is an error
        expenseItem.deliveryStartDate = expenseItem.expenseDate;
    }

    if (!expenseItem.deliveryEndDate) {
        expenseItem.deliveryEndDate = expenseItem.deliveryStartDate;
    }

    if (
        raiItemsGroupedByProductCode &&
        contractTermEnabled &&
        expenseItem.productCode
    ) {
        // we have contract terms enabled and have product code in the expense too
        // we need to pick min start date and max end date from all the sales order items that have same product code
        // as expense.

        if (raiItemsGroupedByProductCode[expenseItem.productCode]) {
            // we found the product
            const raiItems =
                raiItemsGroupedByProductCode[expenseItem.productCode];
            let minDeliveryStartDate = raiItems[0].deliveryStartDate;
            let maxDeliveryEndDate = raiItems[0].deliveryEndDate;
            for (const _item of raiItems) {
                if (_item.deliveryStartDate < minDeliveryStartDate) {
                    minDeliveryStartDate = _item.deliveryStartDate;
                }

                if (_item.deliveryEndDate > maxDeliveryEndDate) {
                    maxDeliveryEndDate = _item.deliveryEndDate;
                }
            }

            expenseItem.deliveryStartDate = minDeliveryStartDate;
            expenseItem.deliveryEndDate = maxDeliveryEndDate;
        }
    }
}

export const getExpenseItemUniqueKey = (expenseItem) => {
    return `${expenseItem.expenseCode}_${expenseItem.referenceNumber}_${expenseItem.productCode}_${expenseItem.class}_${expenseItem.department}_${expenseItem.location}`;
};

export function processExpense(
    clientReferenceData,
    salesOrder,
    errors,
    orgConfig,
    options
) {
    if (
        !salesOrder.expenseItems ||
        (salesOrder.expenseItems && salesOrder.expenseItems.length == 0)
    ) {
        // we don't have expense to process
        return;
    }

    const { buildExpenseRuleDistinctiveKey } = require("./SalesOrderVisitor");

    let sspFieldsConfig = orgConfig.find(
        (cfg) => cfg.id === "properties/ssp-fields"
    );
    let sspFields = (sspFieldsConfig && sspFieldsConfig.value) || [];

    const getExpenseItemUniqKey = (item) => {
        return `${item.salesOrderItemRootId || item.salesOrderItemId}_${
            item.referenceNumber
        }_${item.productCode}_${item.expenseCode}_${item.class}_${
            item.department
        }_${item.location}`;
    };

    const buildExpenseRuleId = (item) => {
        let id = `${item.expenseCode}`;

        if (item.productCode) {
            id += `_${item.productCode}`;
        }

        return id;
    };

    const newDirectExpenseRules = [];
    const newInDirectExpenseRules = [];

    const getDirectExpenseRule = (raItem, raiExpenseItem) => {
        const salesOrderItemSSP = raItem.ssp;
        const attributes = salesOrderItemSSP.attributes;
        const ruleId = buildExpenseRuleId(raiExpenseItem);
        const newRule = defaultDirectExpenseRule(
            ruleId,
            attributes,
            raiExpenseItem.expenseCode
        );

        if (newDirectExpenseRules.length) {
            const distinctiveKey = buildExpenseRuleDistinctiveKey(
                newRule,
                sspFields
            );
            newDirectExpenseRules.forEach(
                (rule) =>
                    (rule.distinctiveKey = buildExpenseRuleDistinctiveKey(
                        rule,
                        sspFields
                    ))
            );

            const groupedByDistinctiveKey = _.groupBy(
                newDirectExpenseRules,
                (r) => r.distinctiveKey
            );

            if (Object.keys(groupedByDistinctiveKey).includes(distinctiveKey)) {
                // we already created the ssp, let's return this ssp now
                return groupedByDistinctiveKey[distinctiveKey][0];
            }
        }

        // new ssp created
        newDirectExpenseRules.push(newRule);
        return newRule;
    };

    const getInDirectExpenseRule = (raiExpenseItem) => {
        const ruleId = buildExpenseRuleId(raiExpenseItem);
        const newRule = defaultInDirectExpenseRule(ruleId, raiExpenseItem);

        if (newInDirectExpenseRules.length) {
            const distinctiveKey = buildExpenseRuleDistinctiveKey(
                newRule,
                sspFields
            );
            newInDirectExpenseRules.forEach(
                (rule) =>
                    (rule.distinctiveKey = buildExpenseRuleDistinctiveKey(
                        rule,
                        sspFields
                    ))
            );

            const groupedByDistinctiveKey = _.groupBy(
                newInDirectExpenseRules,
                (r) => r.distinctiveKey
            );

            if (Object.keys(groupedByDistinctiveKey).includes(distinctiveKey)) {
                // we already created the ssp, let's return this ssp now
                return groupedByDistinctiveKey[distinctiveKey][0];
            }
        }

        // new ssp created
        newInDirectExpenseRules.push(newRule);
        return newRule;
    };

    const checkIfExpenseIsAlreadyAssignedToProductBefore = (
        expenseItem,
        revenueArrangement
    ) => {
        const expenseKey = `${expenseItem.expenseCode}_${expenseItem.referenceNumber}_${expenseItem.productCode}_${expenseItem.class}_${expenseItem.department}_${expenseItem.location}`;

        let _expenseItem;
        for (const rai of revenueArrangement.revenueArrangementItem) {
            if (
                rai.expenseItems &&
                rai.expenseItems.length &&
                !ItemType.isFrozen(rai.itemType)
            ) {
                _expenseItem = rai.expenseItems.find((expItem) => {
                    const expItemKey = `${expItem.expenseCode}_${expItem.referenceNumber}_${expItem.productCode}_${expItem.class}_${expItem.department}_${expItem.location}`;

                    if (expItemKey == expenseKey) {
                        return expItem;
                    }
                });

                if (_expenseItem) {
                    // we have found the matching item
                    break;
                }
            }
        }

        return _expenseItem?.expenseAmount === expenseItem.expenseAmount;
    };

    // we have expense items
    for (const revenueArrangement of salesOrder.revenueArrangement) {
        const contractTermConfig = orgConfig.find(
            (config) => config.id == "properties/expense_for_contract_term"
        );
        const contractTermValue =
            (contractTermConfig && contractTermConfig.value) || false;

        let raiItemsGroupedByProductCode;

        if (contractTermValue) {
            raiItemsGroupedByProductCode = Utils.groupByOnArray(
                revenueArrangement.revenueArrangementItem,
                (_) => _.salesOrderItem.product.code
            );
        }

        for (const raItem of revenueArrangement.revenueArrangementItem) {
            const { salesOrderItem, itemType } = raItem;

            if (ItemType.isFrozen(itemType)) {
                continue;
            }

            for (const expenseItem of revenueArrangement.expenseItems) {
                // STEP 0: Get expense item from raItem
                let raiExpenseItem = undefined;
                let newRaiExpenseItem = false;
                if (raItem.expenseItems.length) {
                    // this would only be returned on day2
                    // this should never return on day1
                    const _raiExpenseItem = raItem.expenseItems.find(
                        (expItem) => {
                            if (
                                expItem.expenseCode ==
                                    expenseItem.expenseCode &&
                                expItem.productCode ==
                                    expenseItem.productCode &&
                                expItem.class == expenseItem.class &&
                                expItem.department == expenseItem.department &&
                                expItem.location == expenseItem.location &&
                                expItem.referenceNumber ==
                                    expenseItem.referenceNumber
                            ) {
                                return expItem;
                            }
                        }
                    );

                    raiExpenseItem = Utils.cloneDeep(_raiExpenseItem);
                } else {
                    raItem.expenseItems = [];
                }

                if (!raiExpenseItem) {
                    if (expenseItem.salesOrderItemId) {
                        // this expense is sent for particular sales order item
                        // so assign it to that item and that item only
                        const salesOrderItemId =
                            salesOrderItem.rootId || salesOrderItem.id;
                        if (expenseItem.salesOrderItemId != salesOrderItemId) {
                            continue;
                        }
                    } else {
                        // expense is not sent for any sales order item.
                    }
                    raiExpenseItem = Utils.cloneDeep(expenseItem);
                    if (
                        revenueArrangement.modificationType ==
                            ModificationType.PROSPECTIVE &&
                        raiExpenseItem.unrecognizedExpenseAmount != null
                    ) {
                        raiExpenseItem.expenseAmount =
                            raiExpenseItem.unrecognizedExpenseAmount;
                    }
                    raiExpenseItem.id = `expense-${shortid.generate()}`;
                    raiExpenseItem.salesOrderItemId = salesOrderItem.id;
                    raiExpenseItem.salesOrderItemRootId = salesOrderItem.rootId;
                    newRaiExpenseItem = true;
                }

                // STEP 1: select expense rule
                if (!raiExpenseItem.expenseRule) {
                    let expenseRule = selectExpenseRule(
                        clientReferenceData,
                        expenseItem,
                        expenseItem.expenseType == "Direct"
                            ? "Direct"
                            : "Indirect"
                    );

                    // no ssp check if auto is enabled
                    // yes, create default with expense tracks revenue = true and reAllocate = true
                    const autoCreateSSP = getAutoCreateSSP(orgConfig);
                    if (!expenseRule && autoCreateSSP == true) {
                        if (raiExpenseItem.expenseType == "Direct") {
                            expenseRule = getDirectExpenseRule(
                                raItem,
                                raiExpenseItem
                            );
                        } else {
                            // Indirect expense
                            expenseRule = getInDirectExpenseRule(
                                raiExpenseItem
                            );
                        }

                        // Save the auto created SSP to be saved later.
                        if (!salesOrder.autoCreatedSSP)
                            salesOrder.autoCreatedSSP = {};
                        if (!salesOrder.autoCreatedSSP[expenseRule.id]) {
                            salesOrder.autoCreatedSSP[
                                expenseRule.id
                            ] = expenseRule;
                        }
                    }

                    if (expenseRule) {
                        raiExpenseItem.expenseRule = Utils.cloneDeep(
                            expenseRule
                        );
                        // STEP 1.a: Expense Tracks Revenue is enabled
                        //      - copy expense amortization method from raiExpenseItem.ssp
                        //      - copy delivery stuff from raiExpenseItem.ssp
                        // STEP 1.b: Expense Tracks Revenue is disabled
                        //       - Use Amortization Method from raiExpenseItem.expenseRule
                        //       - Use delivery stuff from raiExpenseItem.expenseRule
                        if (expenseRule.expenseTracksRevenue == true) {
                            if (
                                raItem.ssp.recognitionRuleId ==
                                RecognitionRule.POINT_IN_TIME
                            ) {
                                raiExpenseItem.expenseRule.expenseAmortizationMethod =
                                    ExpenseRecognitionRule.AS_INCURRED;
                            } else {
                                raiExpenseItem.expenseRule.expenseAmortizationMethod =
                                    ExpenseRecognitionRule.OVER_TIME;
                            }
                        }
                    }
                }

                if (!raiExpenseItem.expenseRule) {
                    // TODO: ERROR condition, we can't find expense rule.
                    errors.add(Codes.EXPENSE_7, {
                        expenseItem: raiExpenseItem
                    });
                    console.error(
                        `Expense rule not found for ${expenseItem}, ${raItem}, ${raiExpenseItem}`
                    );
                    continue;
                }

                // STEP 2.a: reAllocate expense is enabled, do reAllocation, otherwise no reallocation assign the full amount the matching item.
                if (raiExpenseItem.expenseRule.reAllocateExpense) {
                    let totalExpense = 0;
                    revenueArrangement.expenseItems.forEach((ex) => {
                        if (!ex.isDeleted) {
                            // this expense was removed so no need to add this.
                            totalExpense += ex.expenseAmount;
                        }
                    });

                    // reAllocation is enabled
                    raiExpenseItem.allocatedExpense =
                        raItem.relativeSalePricePercent *
                        raiExpenseItem.expenseAmount;

                    if (expenseItem.salesOrderItemId) {
                        // unfortunately the way we used to calculate allocatedCommission was
                        // to sum all commission expenses for all items and then apply proportion

                        // let's check if we already have assigned something to this sales order item
                        // multiple expenses coming for same item scenario
                        let alreadyAllocatedExpenseForSalesOrderItem = 0;
                        if (raItem.expenseItems.length) {
                            // we have found expense
                            // let's subtract the amount we have already allocated to this item
                            raItem.expenseItems.forEach((ex) => {
                                const salesOrderItemId =
                                    salesOrderItem.rootId || salesOrderItem.id;
                                const expSalesOrderItemId =
                                    ex.salesOrderItemRootId ||
                                    ex.salesOrderItemId;
                                // make sure it's not the same we are iterating right now.
                                if (
                                    salesOrderItemId == expSalesOrderItemId &&
                                    ex.referenceNumber !=
                                        raItem.referenceNumber &&
                                    !ex.isDeleted
                                ) {
                                    alreadyAllocatedExpenseForSalesOrderItem +=
                                        ex.allocatedExpense;
                                }
                            });
                        }
                        raiExpenseItem.allocatedExpense =
                            raItem.relativeSalePricePercent * totalExpense;

                        if (alreadyAllocatedExpenseForSalesOrderItem) {
                            raiExpenseItem.allocatedExpense -= alreadyAllocatedExpenseForSalesOrderItem;
                        }
                    }
                } else {
                    // reAllocation is disabled
                    if (
                        expenseItem.expenseType == "Direct" &&
                        expenseItem.productCode &&
                        expenseItem.productCode != salesOrderItem.product.code
                    ) {
                        if (
                            checkIfExpenseIsAlreadyAssignedToProductBefore(
                                raiExpenseItem,
                                revenueArrangement
                            )
                        ) {
                            // Seems like we have multiple items in the current rai
                            // since we have already assigned expense to the product, no to raise this error
                            // for this rai.salesOrderItem
                            continue;
                        }

                        errors.add(Codes.EXPENSE_6, {
                            expenseItem: raiExpenseItem
                        });
                        continue;
                    } else {
                        let isExpenseAlreadyAssigned = checkIfExpenseIsAlreadyAssignedToProductBefore(
                            raiExpenseItem,
                            revenueArrangement
                        );

                        if (!isExpenseAlreadyAssigned) {
                            // only assigning if we haven't previously assigned to the same product
                            // or the expense amount has changed
                            raiExpenseItem.allocatedExpense =
                                raiExpenseItem.expenseAmount;
                        }
                    }
                }

                // STEP 2.b: Discount Item don't do any processing just return
                if (
                    isDiscountProduct(salesOrderItem.product) &&
                    (raItem.relativeSalePricePercent === undefined ||
                        raItem.relativeSalePricePercent === 0)
                ) {
                    raiExpenseItem.allocatedExpense = 0;
                }

                // STEP 2.c If this sales order item has been deleted, change the allocated expense to 0
                if (salesOrderItem.isDeleted) {
                    raiExpenseItem.allocatedExpense = 0;
                }

                // STEP 2.d expense item has been deleted, change the allocated expense to 0
                if (raiExpenseItem.isDeleted) {
                    raiExpenseItem.allocatedExpense = 0;
                }

                // STEP 3: Populate Delivery Dates
                if (raItem.isActive) {
                    // only populate when this raItem is active

                    const d = orgConfig.find(
                        (config) =>
                            config.id == "properties/ratable_end_date_inclusive"
                    );
                    const endDateInclusive = (d && d.value) || false;

                    populateExpenseDeliveryDates(
                        raiExpenseItem,
                        raiExpenseItem.expenseRule,
                        raItem,
                        errors,
                        endDateInclusive,
                        contractTermValue,
                        raiItemsGroupedByProductCode
                    );

                    if (
                        raiExpenseItem.deliveryStartDate == undefined &&
                        raiExpenseItem.expenseType == "Indirect" &&
                        raiExpenseItem.expenseRule.expenseAmortizationMethod ==
                            ExpenseRecognitionRule.AS_INCURRED
                    ) {
                        raiExpenseItem.deliveryStartDate =
                            raiExpenseItem.expenseDate;
                    }
                }

                if (
                    !raiExpenseItem.expenseRecognitionRule &&
                    raiExpenseItem.expenseRule &&
                    raiExpenseItem.expenseRule.expenseAmortizationMethod
                ) {
                    const ruleKey = Object.keys(ExpenseRecognitionRule).find(
                        (ruleKey) => {
                            const value = ExpenseRecognitionRule[ruleKey];
                            if (
                                value ==
                                raiExpenseItem.expenseRule
                                    .expenseAmortizationMethod
                            ) {
                                return true;
                            }
                        }
                    );

                    raiExpenseItem.expenseRecognitionRule = {
                        id:
                            raiExpenseItem.expenseRule
                                .expenseAmortizationMethod,
                        name: ruleKey
                    };
                    raiExpenseItem.expenseRecognitionRuleId =
                        raiExpenseItem.expenseRule.expenseAmortizationMethod;
                }

                if (
                    !isNaN(raiExpenseItem.allocatedExpense) && // must have allocated some expense to create a plan
                    options.createRevenuePlan &&
                    ((revenueArrangement.state == "New" &&
                        raiExpenseItem.expenseRecognitionRule) ||
                        !raiExpenseItem.plan ||
                        raiExpenseItem.plan.length == 0)
                ) {
                    buildDefaultExpensePlan(
                        raiExpenseItem,
                        raItem,
                        salesOrder,
                        orgConfig
                    );
                }

                // STEP 5: expenseItems.push()
                if (newRaiExpenseItem) {
                    raItem.expenseItems.push(raiExpenseItem);
                } else {
                    // we have previously added this item, no need to do anything :)
                }
            }
        }
    }

    // Here we update any expense amortization plan for with the parent arrangement item has Proportional Performance Rule.
    // We ony support proportional performance for expense where expense tracks revenue is true.
    const updateProportionalPerformancePlans = () => {
        for (const revenueArrangement of salesOrder.revenueArrangement) {
            for (const raItem of revenueArrangement.revenueArrangementItem) {
                if (!raItem.expenseItems) continue;

                for (const raiExpenseItem of raItem.expenseItems) {
                    if (
                        raItem.plan &&
                        raItem.recognitionRule.id ===
                            RecognitionRule.PROPORTIONAL_PERFORMANCE &&
                        !ItemType.isFrozen(raItem.itemType) &&
                        raiExpenseItem.expenseRule.expenseTracksRevenue == true
                    ) {
                        raiExpenseItem.plan = Utils.cloneDeep(raItem.plan);

                        updateRatablePlan(
                            raiExpenseItem.plan,
                            raiExpenseItem.allocatedExpense || 0.0
                        );
                    }
                }
            }
        }
    };

    const updateSalesOrderExpenseItemId = () => {
        for (const revenueArrangement of salesOrder.revenueArrangement) {
            for (const raItem of revenueArrangement.revenueArrangementItem) {
                if (raItem.expenseItems && raItem.expenseItems.length) {
                    for (const raiExpenseItem of raItem.expenseItems) {
                        raiExpenseItem.salesOrderExpenseItemId = getExpenseItemUniqKey(
                            raiExpenseItem
                        );
                    }
                }
            }
        }
    };

    updateProportionalPerformancePlans();

    updateSalesOrderExpenseItemId();
}
