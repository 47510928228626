import React from 'react'
import store from "./ReduxStore";
import { toastCallback } from "./actions/data.actions"
import * as WebUtils from 'utils/WebUtils';

export const RETRY_WAIT = 500;

const expiredTokenHandler = async () => {
    // handle expired token here.
};

export default class Updater {

    constructor(actionType, remoteAction, reducerType, errorHandler) {
        this.actionType = actionType;
        this.remoteAction = remoteAction;
        this.reducerType = reducerType;
        this.errorHandler = errorHandler;
    }


    /**
     * Sends and update using the AWS api.
     *
     * @param update parameters
     * @return TRUE Update process started successfully.
     *         FALSE Updated can not be sent.
     *
     */
    doUpdate(doInvalidate, callback, ...updateParams) {
        this.performAsyncUpdate(RETRY_WAIT, doInvalidate, true, callback, ...updateParams);
    }

    // Silently update the entity without doing any redux changes.
    doUpdateSilent(doInvalidate, callback, ...updateParams) {
        this.performAsyncUpdate(RETRY_WAIT, doInvalidate, false, callback, ...updateParams);
    }

    async performAsyncUpdate(wait, doInvalidate, updateRedux, callback, ...updateParams) {

        const params = (updateParams) ? updateParams[0] : undefined;

        try {
            updateRedux && store.dispatch({
                type: this.actionType,
                status: 'update_inprocess',
                reducerType: this.reducerType,
                params
            });

            var response =
                await this.doTimedRemoteCall(wait, ...updateParams);

            updateRedux && store.dispatch({
                type: this.actionType,
                status: 'update_complete',
                params,
                reducerType: this.reducerType,
                response
            });
            
            updateRedux && doInvalidate && store.dispatch({
                type: this.actionType,
                status: 'invalid',
            });

            // if (updateRedux) {
            //     store.dispatch({ type: this.actionType, status: 'success' })
            // }
        
            if (callback)
                callback(response, undefined);

        } catch (e) {
            let error = (e && e.response && e.response) || e;
            if (error && error.code == 403) {
                try {
                    await expiredTokenHandler();
                    const e = new Error("Handled failed authentication");
                    e.code = 500;

                    updateRedux && store.dispatch(this.errorAction(e));
                }
                catch (_e) {
                    updateRedux && store.dispatch(this.errorAction(_e));
                }
            } else if (this.errorHandler) {
                try {
                    await this.errorHandler(...updateParams);
                }
                catch (_e) {
                    updateRedux && store.dispatch(this.errorAction(_e));
                }
            } else {
                if (!callback) {
                    const callback = toastCallback(undefined, { message: (error.errorMessage && error.errorMessage) || (error && error.data && error.data.errorMessage) || 'There was an error processing your requrest, please contant administrator.' });
                    this.showErrorNotification(callback)
                }

                updateRedux && store.dispatch(this.errorAction(error));
            }
            if (callback) {
                callback(undefined, error);
            } else if (error && error.code === 500) {
                await WebUtils.showError({
                    title:  error.title || 'Error',
                    text: error.errorMessage,
                    icon: 'error',
                    error: true,
                    closeOnEsc: false,
                    closeOnClickOutside: false,
                    details: error.details
                });
            } else {
                console.log("Shoot.. something is not working on our servers. Maybe try later?");
            }
        }
    }

    async doTimedRemoteCall(wait, ...updateParams) {
        return new Promise((resolve, reject) =>
            setTimeout(() => {
                try {
                    resolve(this.remoteAction(store.getState(), ...updateParams))
                } catch (err) {
                    reject(err);
                }
            }, wait));
    }

    errorAction(error) {
        return {
            type: this.actionType,
            status: "error",
            error
        }
    }

    showErrorNotification(callback) {
        callback(undefined, { message: "Something bad happened!" })
    }
}
