import {
    generateWaterFall,
    generateTransactions,
    getTransactionsForReporting
} from "./RevenueWaterFallHelper";

import {
    generateExpenseTransactions,
    generateExpenseJournalReportData,
    generateExpenseWaterFall,
    getTransactionsForExpenseReporting
} from "./ExpenseWaterFallHelper";

import { populateRevenueArrangements } from "./RevenueArrangementHelper";
import { populateBillingSchedule } from "./BillingScheduleHelper";
import { Collection, Codes } from "./ErrorCode";
import { Utils, moment } from "revlock-webutils";
import _ from "lodash";
import shortid from "shortid";
import * as SalesOrderTerminateHelper from "./SalesOrderTerminateHelper";
import { AccountingUtils } from "../Accounting";

export const buildExpenseRuleDistinctiveKey = (entity, sspFields) => {
    let distinctiveKey = "";

    Object.keys(entity.expenseAttributes).forEach((key) => {
        if (sspFields.includes(key)) {
            distinctiveKey += `${entity.expenseAttributes[key] || ""}`;
        }
    });

    distinctiveKey += entity.expenseCode;

    return distinctiveKey;
};

export const combineNewAndExistingExpenseItemsAndMarkDeleted = (
    existingExpenseItems,
    newExpenseItems
) => {
    const combined = [...newExpenseItems];

    const existingGroupedBy = _.groupBy(existingExpenseItems, (e) => {
        return `${e.expenseCode}_${e.referenceNumber}_${e.productCode}_${e.class}_${e.department}_${e.location}`;
    });
    const newGroupedBy = _.groupBy(newExpenseItems, (e) => {
        return `${e.expenseCode}_${e.referenceNumber}_${e.productCode}_${e.class}_${e.department}_${e.location}`;
    });

    const newKeys = Object.keys(newGroupedBy);
    const existingKeys = Object.keys(existingGroupedBy);

    existingKeys.forEach((ekey) => {
        if (newKeys.includes(ekey)) {
            // we have an update on this
        } else {
            // this item has been deleted, let's mark delete
            const deleted = existingGroupedBy[ekey][0];
            deleted.isDeleted = true;
            deleted.id = `deleted-${shortid.generate()}`;
            combined.push(deleted);
        }
    });

    return combined;
};

export function fetchBusinessKeyValues(salesOrder, soi, businessKey) {
    const salesOrderObject = (salesOrder, salesOrderItem) => [
        salesOrderItem,
        salesOrderItem.attributes,
        salesOrder.orderAttributes,
        salesOrderItem.product,
        salesOrderItem.product.attributes,
        salesOrder.customer.attributes,
        salesOrder.customer
    ];

    let picker = Utils.pickFromObject(businessKey);

    salesOrder.customer.customerName = salesOrder.customer.name;

    // Product code is an interal field that client can choose to use as business key.
    // So setting it to what it should be.
    soi.product.productCode = soi.product.code;

    let businessKeyValues = picker(salesOrderObject(salesOrder, soi));

    // delete temporary product code attribute from product object.
    delete soi.product.productCode;
    delete salesOrder.customer.customerName;

    return businessKeyValues;
}

export function getDereferencedDateValue(
    dereferencedKey,
    salesOrder,
    salesOrderItem,
    currentState,
    isServiceEndDate
) {
    let dereferencedVal = undefined;
    if (salesOrder.orderAttributes) {
        let dereferencedObject = salesOrder.orderAttributes[dereferencedKey];
        dereferencedVal = dereferencedObject;
    }

    if (!dereferencedVal) {
        dereferencedVal = salesOrder[dereferencedKey];
    }

    if (!dereferencedVal && salesOrderItem && salesOrderItem.attributes) {
        dereferencedVal = salesOrderItem.attributes[dereferencedKey];
    }

    if (
        salesOrderItem &&
        [
            "Min Start Date",
            "Max Start Date",
            "Min End Date",
            "Max End Date",
            "Absolute Date"
        ].includes(dereferencedKey)
    ) {
        dereferencedVal = isServiceEndDate
            ? salesOrderItem.dereferencedEndDateValue
            : salesOrderItem.dereferencedStartDateValue;
    }

    return dereferencedVal;
}

export function validate(salesOrder, clientReferenceData, errors) {
    if (typeof salesOrder != "object") throw new Error("Bad sales order");

    const { products } = clientReferenceData;

    if (Array.isArray(salesOrder))
        throw new Error("Can only validate a single sales order");

    if (!salesOrder.orderDate) errors.add(Codes.TRAN_02, { salesOrder });

    if (salesOrder.salesOrderItem && salesOrder.salesOrderItem.length == 0)
        errors.add(Codes.TRAN_09, { salesOrder });

    let duplicateItems = {};

    salesOrder.salesOrderItem.forEach((salesOrderItem) => {
        //Check to see if client has mistakenly provided same order item id
        let duplicateItem = salesOrder.salesOrderItem.filter(
            (item) => item.id == salesOrderItem.id
        );

        if (duplicateItem && duplicateItem.length > 1) {
            let itemKey = duplicateItem[0].rootId || duplicateItem[0].id;
            let hasDuplicateItem = Object.keys(duplicateItems).filter(
                (key) => key == itemKey
            );
            if (!(hasDuplicateItem.length > 0)) {
                duplicateItems[itemKey] = { salesOrder, salesOrderItem };
            }
        }

        if (
            salesOrderItem.listPrice == null &&
            !salesOrderItem.product &&
            salesOrderItem.product &&
            !salesOrderItem.product.listPrice
        )
            errors.add(Codes.TRAN_07, { salesOrder, salesOrderItem });

        if (
            salesOrderItem.salePrice == undefined ||
            salesOrderItem.salePrice == null ||
            !Utils.isNumber(salesOrderItem.salePrice)
        )
            errors.add(Codes.TRAN_06, { salesOrder, salesOrderItem });

        if (products && !salesOrderItem.product) {
            const product = products.find(
                (prod) => prod.id == salesOrderItem.productId
            );
            if (!product)
                errors.add(Codes.REF_01, { salesOrder, salesOrderItem });
        }
    });
}

function moveAwayPassThrough(salesOrder, logger) {
    const passThroughSalesOrderItem = [];
    let salesOrderItem = [];

    let isAllPassThroughProducts = true;
    let hasDiscount = false;
    let totalPassThroughAmount = 0;

    salesOrder.salesOrderItem.forEach((soi) => {
        if (soi.product.passThrough) {
            soi.passThrough = true;
            soi.extendedSalePrice = soi.quantity * soi.salePrice;
            totalPassThroughAmount += soi.extendedSalePrice;

            passThroughSalesOrderItem.push(soi);
        } else {
            if (AccountingUtils.isDiscountProduct(soi.product)) {
                // This check is to ensure if discount item is marked as pass through initial because all the item are marked as pass through,
                // then later it was changed. so we need to mark the discount as not pass through.
                soi.passThrough = false;
                hasDiscount = true;
            } else {
                isAllPassThroughProducts = false;
            }
            salesOrderItem.push(soi);
        }
    });

    // Removing Discount if all the product a
    if (isAllPassThroughProducts && hasDiscount) {
        salesOrderItem.forEach((soi) => {
            soi.passThrough = true;
            soi.extendedSalePrice = soi.quantity * soi.salePrice;
            totalPassThroughAmount += soi.extendedSalePrice;
            passThroughSalesOrderItem.push(soi);
        });
        salesOrderItem = [];
    }

    salesOrder.totalPassThroughAmount = totalPassThroughAmount;

    if (passThroughSalesOrderItem.length) {
        salesOrder.salesOrderItem = salesOrderItem;
        salesOrder.passThroughSalesOrderItem = passThroughSalesOrderItem;
    }

    if (passThroughSalesOrderItem.length)
        logger.info(
            `${
                passThroughSalesOrderItem.length
            } sales order items are pass through items. ${passThroughSalesOrderItem.map(
                (item) => item.product.code
            )}`
        );
}

function bringBackPassThroughProducts(salesOrder) {
    if (
        salesOrder.passThroughSalesOrderItem &&
        salesOrder.passThroughSalesOrderItem.length
    ) {
        salesOrder.salesOrderItem.push(...salesOrder.passThroughSalesOrderItem);
        delete salesOrder.passThroughSalesOrderItem;
    }
}

export function visit(
    salesOrder,
    clientReferenceData,
    errorCollector,
    logger = console,
    isApiCall = false
) {
    // this will cause the runtime context errors to be associated
    // with this sales order and as a side effect will save them during postUpsert
    salesOrder.errors = [];
    let errors = new Collection(salesOrder.errors);

    //merging expense errors into so.errors
    if (salesOrder.expenseErrors) {
        salesOrder.expenseErrors.errors.forEach((err) =>
            salesOrder.errors.push(err)
        );
    }

    if (
        salesOrder.entitySubType === "Invoice" ||
        salesOrder.entitySubType === "SVC"
    )
        return;

    try {
        validate(salesOrder, clientReferenceData, errors);

        let { options, organization, orgConfig } = clientReferenceData;

        moveAwayPassThrough(salesOrder, logger);

        // By default transactions are generated.
        options = Object.assign(
            {
                generateTransactions: true
            },
            options
        );

        let totalBooking = 0;

        salesOrder.salesOrderItem.forEach((salesOrderItem) => {
            // Assuming here that discount is already applied to salePrice
            salesOrderItem.actualSalePrice =
                salesOrderItem.salePrice /* * (1 - salesOrderItem.discount) */;
            salesOrderItem.extendedSalePrice =
                salesOrderItem.quantity * salesOrderItem.actualSalePrice;

            totalBooking += salesOrderItem.extendedSalePrice;

            if (salesOrder.commissionTerm) {
                // Overriding item level commission term if user has provided commissionTerm at order level.
                salesOrderItem.commissionTerm = salesOrder.commissionTerm;
            }
        });

        // Booking for which revenue accounting will be done.
        salesOrder.totalBooking = totalBooking;
        salesOrder.totalContractValue =
            totalBooking + salesOrder.totalPassThroughAmount;
        salesOrder.taxPercent = 0.05;

        populateRevenueArrangements(
            salesOrder,
            clientReferenceData,
            errors,
            isApiCall
        );

        let hasSSP = true;
        salesOrder.revenueArrangement.forEach((revenueArrangement, raIndex) => {
            revenueArrangement.revenueArrangementItem.forEach((item) => {
                if (!item.ssp && hasSSP) {
                    hasSSP = false;
                }
            });
        });

        if (hasSSP) {
            // populated expense in populateRevenueArrangements
            populateBillingSchedule(salesOrder, clientReferenceData);

            if (
                salesOrder.terminationDate &&
                salesOrder.newRevenueArrangement
            ) {
                // Order has a termination date and we just created a new arrangement.
                // We now apply termination logic to this new arrangement.

                logger.info(
                    `Terminate sales order '${salesOrder.id}'. Termination date = ${salesOrder.terminationDate}`
                );

                const d1 = orgConfig.find(
                    (config) => config.id === "properties/calendar"
                );
                const calendarConfig = d1 && d1.value && d1.value.config;

                const d2 = orgConfig.find(
                    (config) =>
                        config.id == "properties/ratable_end_date_inclusive"
                );
                const endDateInclusive = (d2 && d2.value) || false;

                SalesOrderTerminateHelper.terminateSalesOrder(
                    salesOrder,
                    organization,
                    salesOrder.terminationDate,
                    salesOrder.terminationPenalty,
                    true, // always prorate
                    calendarConfig,
                    endDateInclusive,
                    orgConfig,
                    logger
                );
            }

            if (
                options.generateTransactions &&
                salesOrder.currentRevenueArrangement
            ) {
                const d = orgConfig.find(
                    (config) => config.id === "properties/calendar"
                );
                const calendarConfig = d && d.value && d.value.config;

                generateTransactions(
                    salesOrder,
                    orgConfig && Utils.getOrgConfigByKey(orgConfig, false),
                    calendarConfig
                );
                generateWaterFall(salesOrder);
                getTransactionsForReporting(
                    salesOrder,
                    orgConfig && Utils.getOrgConfigByKey(orgConfig, false),
                    calendarConfig
                );

                if (salesOrder.expenseItems && salesOrder.expenseItems.length) {
                    generateExpenseTransactions(salesOrder);
                    generateExpenseWaterFall(salesOrder);
                    getTransactionsForExpenseReporting(salesOrder);
                }
            }

            let revenueArrangement =
                salesOrder.currentRevenueArrangement ||
                salesOrder.newRevenueArrangement;

            let orderEndDate =
                revenueArrangement &&
                revenueArrangement.revenueArrangementItem[0].deliveryStartDate;
            salesOrder.endDate =
                revenueArrangement &&
                revenueArrangement.revenueArrangementItem.reduce(
                    (_endDate, item) =>
                        moment(item.deliveryEndDate).isAfter(moment(_endDate))
                            ? item.deliveryEndDate
                            : _endDate,
                    orderEndDate
                );
        }

        bringBackPassThroughProducts(salesOrder);
    } catch (e) {
        if (e.code && e.data) errors.add(e.code, e.data);
        else throw e;
    }

    if (errorCollector && errorCollector.errors) {
        errorCollector.errors.push(...errors.errors);
    }

    salesOrder.visited = true;
    return salesOrder;
}

export const _generateTransactions = generateTransactions;
