const { config } = require("../../../package.json");

const alias = (() => {
    return config.serviceProfile ? `/${config.serviceProfile}` : "";
})();

function getApiEndpoint() {
    return (
        (config["isCloudDeployment"] &&
            `https://prod-west.api.rev-lock.com${alias}`) ||
        "http://localhost:3001"
    );
}

const mstrWebUrl = (() => {
    return config.serviceProfile
        ? `https://prod-west-reports-${config.serviceProfile}.rev-lock.com`
        : "https://prod-west-reports.rev-lock.com";
})();

export default {
    microstrategy: {
        mstrWebUrl: `${mstrWebUrl}/MicroStrategy/servlet/`,
        project: "Prod_RL_B",
        server: `prod-mstr.cluster.rev-lock.local`
    },
    hotGlue: {
        env_id: "prod.hotglue.rev-lock.com"
    },
    AWS: {
        API: {
            endpoints: [
                {
                    name: "revlock_api",
                    endpoint: getApiEndpoint()
                },
                {
                    name: "revlock_public_api",
                    endpoint: getApiEndpoint(),
                    custom_header: async () => {
                        return {
                            "x-api-key":
                                "yPkQuc05Fc7ekRIPwqtt1mJxuYdqJOa2zhCRmT4e"
                        };
                    }
                }
            ]
        },
        Auth: {
            identityPoolId: "us-west-2:2a721cda-ec7c-4db2-be33-a17f99c34aaa",
            region: "us-west-2",
            userPoolId: "us-west-2_aLt5bCAoU",
            userPoolWebClientId: "5ee101f1aci9g44o1vsdu4ph2o",
            mandatorySignIn: true
        },
        Storage: {
            bucket: "prod-west-userfiles",
            region: "us-west-2",
            hotglueBucket: "prod-hotgluefiles"
        },
        Analytics: {
            disabled: true
        },
        oauth: {
            domain: "prod-west.auth.rev-lock.com",
            clientId:
                "1092057117597-5m0ml54tve0hha8v79pccob3l6320sno.apps.googleusercontent.com",
            redirectSignout: "https://prod-west.app.rev-lock.com/signout",
            redirectSignIn: "https://prod-west.app.rev-lock.com/",
            scope: ["email", "profile", "openid"],
            responseType: "code"
        }
    },
    analytics: {
        trackingId: "UA-127158128-2",
        debug: false
    },
    keycloakConfig: {
        url: "https://idp-broker.devcb.in/",
        redirectUri: `https://prod-west-${config.serviceProfile}-sso.rev-lock.com/`,
        realm: "master",
        clientId: "revrec-app",
        idpHint: "revrec-idp"
    }
};
