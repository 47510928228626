import _ from "lodash";
import cloneDeep from "lodash.clonedeep";
import { moment as Moment } from "./moment";
import shortid from "shortid";
import swal from "sweetalert";
import omitDeep from "omit-deep-lodash";
import { Buffer } from "buffer";

const sqlString = require("sqlstring");
const testIdPrefix = "9";

const validIdRegExp = /^[\w\-\:\. ]*$/;
const validIdRegExp_extended = /^[\w\-\:\. \(\)]*$/;
const csvEscapeRegExp = /\\|[^\\]\\[^\\]|[^\\]\\$/g;

const simpleDateRegExp = new RegExp(
    /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
);
const periodRegExp = new RegExp(/^\d{6}$/);
const base64RegExp = new RegExp(
    /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/
);

const isSimpleDate = (date) =>
    typeof date === "string" && simpleDateRegExp.test(date);
const isPeriod = (date) => typeof date === "string" && periodRegExp.test(date);

const isTest = process.env.IS_TEST == "true";
const DEFAULT_PRECISION = isTest ? 4 : 20;
const base62Digits =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
class Utils {
    static PERIOD_FORMAT = "YYYYMM";
    static PLAN_PERIOD_FORMAT = "YYYY-MM";
    static DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

    constructor() {}

    static comparators = {
        period: (a, b) => (a == b ? 0 : a < b ? -1 : 1),
        number: (a, b) => (a == b ? 0 : a < b ? -1 : 1),
        string: (a, b) => (a == b ? 0 : a < b ? -1 : 1)
    };

    /**
     * Abbreviates the string if required.
     * @param str The string to abbreviate.
     * @param len The maximum length for the string.
     * @returns {*}
     */
    static abbreviate(str, maxLength) {
        if (str && str.length > maxLength) {
            if (str.length > 3) {
                return str.substring(0, maxLength - 3) + "...";
            } else {
                return str.substring(0, maxLength);
            }
        }

        return str;
    }

    /**
     * Strips any HTML tags within the string.
     * @param str The string to strip HTML tags from.
     * @returns {*}
     */
    static stripHTML(str) {
        if (!str || str === null || str === "") {
            return str;
        } else {
            const html = str.toString();

            // Regular expression to identify HTML tags in
            // the input string. Replacing the identified
            // HTML tag with a null string.
            return html.replace(/(<([^>]+)>)/gi, "");
        }
    }

    /**
     * Convert a given http string to a domain name
     * @param website
     * @returns {*}
     */
    static toDomain(website) {
        return (
            website &&
            website
                .replace("http://", "")
                .replace("https://", "")
                .replace("www.", "")
                .split(/[/?#]/)[0]
        );
    }

    static comparator(columns, sortCols, sortTypes) {
        let sortColIndexes = sortCols.map((sortCol) =>
            columns.indexOf(sortCol)
        );

        return function(a, b) {
            let toReturn = sortColIndexes.reduce((result, softColIndex, i) => {
                if (result != 0) return result;

                return Utils.comparators[sortTypes[i]](
                    a[softColIndex],
                    b[softColIndex]
                );
            }, 0);

            return toReturn;
        };
    }

    static definedKeys(objParams) {
        const obj = Object.assign({}, objParams);
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined) {
                delete obj[key];
            }
        });

        return Object.keys(obj);
    }

    static deIdentify(str) {
        if (str && typeof str === "string")
            return str.substring(0, 1) + "*".repeat(str.length - 1);
    }

    static showPopup({ url, title, w, h }) {
        // Fixes dual-screen position
        // Most browsers      Firefox
        const dualScreenLeft =
            window.screenLeft != undefined
                ? window.screenLeft
                : window.screen.left;
        const dualScreenTop =
            window.screenTop != undefined
                ? window.screenTop
                : window.screen.top;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : window.screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;

        w = w || width * 0.75;
        h = h || height * 0.75;

        const top = height / 2 - h / 2 + dualScreenTop;
        const left = width / 2 - w / 2 + dualScreenLeft;

        const newWindow = window.open(
            url,
            title,
            "scrollbars=yes, width=" +
                w +
                ", height=" +
                h +
                ", top=" +
                top +
                ", left=" +
                left
        );
        if (!newWindow)
            swal("Error", "Unable to start. Are pop-ups disabled?", "error");
        // Puts focus on the newWindow
        else if (newWindow.focus) {
            newWindow.focus();
        }
    }

    static isTestId(id) {
        return id.toString().startsWith(testIdPrefix);
    }

    static encode(data) {
        return encodeURIComponent(data).replace(/[-!'()*]/g, (c) => {
            return "%" + c.charCodeAt(0).toString(16);
        });
    }

    static isEncoded(id) {
        if (id === undefined || id === null || typeof id !== "string")
            return false;

        if (id.startsWith("enc:")) {
            const versionIndexId = id.lastIndexOf(":ver:");

            return versionIndexId === -1
                ? Utils.isBase64Encoded(id.substring(4).replaceAll("~", "/"))
                : Utils.isBase64Encoded(
                      id
                          .substring(0, versionIndexId)
                          .substring(4)
                          .replaceAll("~", "/")
                  );
        }
        return false;
    }

    static encodeId(id, force = false, type = "utf-8") {
        if (id === undefined || id === null) return id;

        if (id.toString().startsWith("enc:")) return id;
        //if you are already encoded don't try to do it again

        const encodedId = Buffer.from(id, type)
            .toString("base64")
            .replaceAll("/", "~");
        return Utils.isValidId(id) && !force ? id : `enc:${encodedId}`;
    }

    static decodeId(id) {
        if (id === undefined || id === null) return id;

        if (id.toString().startsWith("enc:")) {
            const base64ID = id.substring(4).replaceAll("~", "/");
            const versionIndexId = base64ID.lastIndexOf(":ver:");

            if (versionIndexId !== -1) {
                const encodedId = base64ID.substring(0, versionIndexId);
                return Utils.isBase64Encoded(encodedId)
                    ? `${Buffer.from(
                          encodedId,
                          "base64"
                      ).toString()}${base64ID.substring(versionIndexId)}`
                    : id;
            }

            return Buffer.from(base64ID, "base64").toString();
        }

        return id;
    }

    static isBase64Encoded(str) {
        return base64RegExp.test(str);
    }

    static decode(encodedStr) {
        return decodeURIComponent(encodedStr);
    }

    static isValidId(id) {
        if (process.env.EXTENDED_CHARSET_FOR_ID) {
            return validIdRegExp_extended.test(id);
        } else {
            return validIdRegExp.test(id);
        }
    }

    static excelEscape(value) {
        if (
            value &&
            (value.toString().startsWith("=") ||
                value.toString().startsWith("@") ||
                value.toString().startsWith("#") ||
                value.toString().startsWith("+") ||
                value.toString().startsWith("-"))
        ) {
            return "'" + value;
        }

        return value;
    }

    static csvSqlEscapeId(sqlOrderId) {
        if (
            sqlOrderId &&
            typeof sqlOrderId === "string" &&
            csvEscapeRegExp.test(sqlOrderId)
        ) {
            return sqlOrderId.replaceAll(
                csvEscapeRegExp,
                (match, offset, src) => match.replaceAll("\\", "\\\\")
            );
        }
        return sqlOrderId;
    }

    static sqlEscapeId(sqlOrderId, escapeWildcards = false) {
        if (Array.isArray(sqlOrderId)) {
            return sqlOrderId.map((id) => Utils.sqlEscapeId(id));
        }

        if (typeof sqlOrderId === "string") {
            let escapedValue = sqlOrderId;

            if (escapeWildcards) {
                // First escape any embedded escape characters as the
                // caller wants a \ to be a literal in this case.
                escapedValue = escapedValue.replaceAll("\\", "\\\\");
            }

            escapedValue = sqlString.escape(escapedValue);

            if (escapeWildcards) {
                // In MySQL % means zero or more of any character and
                // _ means 0 or 1 of any character.  If they are in the
                // string to escape then they are meant to be literals
                // and not wildcards so escape these so they are treated
                // as literals.
                escapedValue = escapedValue.replaceAll("%", "\\%");
                escapedValue = escapedValue.replaceAll("_", "\\_");
            }

            return escapedValue;
        }

        return sqlOrderId;
    }

    static removeSpecialCharsId(id) {
        return (id && id.replaceAll(/\\$/g, "")) || "NA";
    }

    static getUniqueKeysFromObjects(obj1, obj2) {
        const toReturn = [...new Set(Object.keys(obj1))];
        toReturn.push(...new Set(Object.keys(obj2)));

        return new Set(toReturn);
    }

    static getBusinessKeyString(attributes, keys) {
        let businessKey = "";
        keys.forEach((key, index) => {
            if (attributes[key]) {
                businessKey += attributes[key] + "_";
            }
        });

        if (businessKey[businessKey.length - 1] == "_") {
            businessKey = businessKey.slice(0, businessKey.length - 1);
        }

        return businessKey;
    }

    static getCommulativeHoursForExistingPS(
        order,
        psdData,
        businessKeys,
        periodKey,
        hoursKey
    ) {
        let toReturn = [];
        order[
            "professionalServiceDelivery"
        ].soiProfessionalServiceDelivery.forEach((psSoi) => {
            let item = order.salesOrderItem.filter(
                (soi) => soi.rootId == psSoi.salesOrderItemId
            );

            if (item && item.length > 0) {
                item.forEach((soi) => {
                    let businessKey = Utils.getBusinessKeyString(
                        soi.attributes,
                        businessKeys
                    );
                    let psd = psdData[businessKey];
                    if (psd) {
                        psd = Utils.groupByPSDPeriod(psd, periodKey);
                        let deliveryLogByPeriod = Utils.groupByOnArray(
                            psSoi.deliveryLog,
                            (dl) => dl["period"]
                        );

                        let uniquePeriods = Utils.getUniqueKeysFromObjects(
                            psd,
                            deliveryLogByPeriod
                        );
                        let sortedPeriods = Utils.sortByPeriod(
                            Array.from(uniquePeriods)
                        );
                        let previousPeriodHours = 0;
                        sortedPeriods.forEach((period) => {
                            if (psd[period]) {
                                let toPush = {
                                    "Order Number":
                                        order["Order Number"] || order.id,
                                    "Order Item Number": soi.rootId,
                                    "Units Delivered": 0,
                                    Period: period
                                };

                                if (deliveryLogByPeriod[period]) {
                                    let previousHours = 0;

                                    deliveryLogByPeriod[period].forEach(
                                        (dl) => {
                                            previousHours += dl.unitsDelivered;
                                        }
                                    );

                                    toPush["Units Delivered"] += previousHours;
                                }

                                psd[period].forEach((ps) => {
                                    toPush["Units Delivered"] += Number(
                                        ps[hoursKey]
                                    );
                                });

                                toPush[
                                    "Units Delivered"
                                ] += previousPeriodHours;

                                previousPeriodHours = toPush["Units Delivered"];

                                toReturn.push(toPush);
                            } else {
                                deliveryLogByPeriod[period].forEach((dl) => {
                                    previousPeriodHours = dl.unitsDelivered;
                                });
                            }
                        });
                    }
                });
            }
        });

        return toReturn;
    }

    static sortByDateKey(obj, dateFormat) {
        let sortedKeys = Object.keys(obj).sort(function(period1, period2) {
            return Moment(period1, dateFormat).diff(
                Moment(period2, dateFormat)
            );
        });

        let toReturn = {};
        sortedKeys.forEach((key) => {
            toReturn[key] = obj[key];
        });

        return toReturn;
    }

    static sortByPeriod(periodsArray) {
        return periodsArray.sort(function(period1, period2) {
            return Moment(period1, Utils.PERIOD_FORMAT).diff(
                Moment(period2, Utils.PERIOD_FORMAT)
            );
        });
    }

    static groupByPSDPeriod(psd, key) {
        psd.forEach((ps) => {
            ps[key] =
                !Utils.isValidPeriodFormat(ps[key]) ||
                typeof ps[key] == "object"
                    ? Utils.formatToAccountingPeriod(ps[key])
                    : ps[key];
        });

        return Utils.groupByOnArray(psd, (psd) => psd[key]);
    }

    static getCommulativeHoursForNewPS(
        psd,
        orderNumber,
        itemId,
        periodKey,
        hoursKey
    ) {
        let toReturn = {
            "Order Number": orderNumber,
            "Order Item Number": itemId,
            "Units Delivered": 0
        };

        psd.forEach((ps) => {
            toReturn["Period"] = ps[periodKey];
            toReturn["Units Delivered"] += Number(ps[hoursKey]);
        });

        return toReturn;
    }

    static groupByOnArray(data = [], groupByFunction) {
        return _.groupBy(data, groupByFunction);
    }

    static pickFromObject = (keys, objectMapper) => (objects) => {
        // If there is an object mappper
        // than enrich the data upfront.
        if (objectMapper) {
            let _objects = [];
            if (Array.isArray(objects)) {
                objects.forEach((obj) => _objects.push(...objectMapper(obj)));
            } else {
                _objects.push(...objectMapper(objects));
            }

            objects = _objects;
        }

        if (Array.isArray(objects)) {
            // Reversing the order here so fist item gets precedence over second and so on.
            const toReturn = objects.reverse().reduce((result, obj) => {
                Object.assign(result, _.pick(obj, keys));
                return result;
            }, {});

            return toReturn;
        } else {
            objects = (objectMapper && objectMapper(objects)) || objects;
            // Input is actually not an array.
            let obj = objects;
            return _.pick(obj, keys);
        }
    };

    static createCompositeKey = (keys, objectMapper) => {
        let picker = Utils.pickFromObject(keys, objectMapper);

        return (objects) => {
            return JSON.stringify(picker(objects));
        };
    };

    static removeElementFromArray(array, rmFunction) {
        return _.remove(array, rmFunction);
    }

    static removeAttributeFromObject(obj, attributes) {
        return _.omit(obj, attributes);
    }

    static isBlank(value) {
        return (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value);
    }

    static isEmail(email) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRex.test(email)) {
            return true;
        }
        return false;
    }

    static getObjectOfRequiredAttributes(obj, requiredAttributes) {
        const keysToRemove = [];
        Object.keys(obj).forEach((key) => {
            if (!requiredAttributes.includes(key)) {
                keysToRemove.push(key);
            }
        });

        return this.removeAttributeFromObject(obj, keysToRemove);
    }

    static groupBy(data, key, groupByFunction) {
        //E.g [{ "SALES_ORDERS": [ key: value ] }]
        let byId = {};
        data.forEach((d) => {
            Object.keys(d).map((dKey) => {
                if (dKey == key) {
                    byId = Utils.groupByOnArray(d[key], groupByFunction);
                }
            });
        });
        return byId;
    }

    static resolveObjectByKey(data, key) {
        let toReturn;
        Object.values(data).map((D) => {
            Object.keys(D).map((Dkey) => {
                if (Dkey == key) {
                    toReturn = D[Dkey];
                }
            });
        });
        return toReturn;
    }

    static resolveChildren(parent, pKey, children, getId) {
        let toReturn = [];
        parent.forEach((P) => {
            Object.keys(P).map((key) => {
                if (key == pKey) {
                    Object.values(P[key]).map((pVal) => {
                        let id = getId(pVal);
                        Object.keys(children).forEach((_key) => {
                            let value = children[_key][id];
                            if (value) {
                                pVal[_key] = value;
                                toReturn.push(pVal);
                            }
                        });
                    });
                }
            });
        });

        return toReturn;
    }

    static getAddress(street, state, city, postalCode) {
        let address = "";

        if (street) address += street;

        if (state) address += "," + state;

        if (city) address += "," + city;

        if (postalCode) address += "," + postalCode;

        return address;
    }

    static _omitDeep(obj, keysToRemove) {
        if (keysToRemove.length > 0) {
            obj = omitDeep(obj, keysToRemove);
        }

        return obj;
    }

    static isEqual(obj1, obj2, keysToRemove = []) {
        obj1 = Utils._omitDeep(obj1, keysToRemove);
        obj2 = Utils._omitDeep(obj2, keysToRemove);
        return _.isEqual(obj1, obj2);
    }

    static groupDataByBusinessKey(object, businessKeys, objectKey = undefined) {
        return Utils.groupByOnArray(object, function(obj) {
            let toReturn = "";
            const value = (objectKey && obj[objectKey]) || obj;
            businessKeys.forEach((col) => {
                if (value[col]) {
                    toReturn =
                        toReturn != ""
                            ? `${toReturn}.${value[col]}`
                            : `${value[col]}`;
                }
            });
            return toReturn;
        });
    }

    static agg(
        data,
        aggregateBy,
        attributes,
        beginningFacts,
        endingFacts,
        restOfFacts,
        getters = {}
    ) {
        if (!Array.isArray(beginningFacts)) {
            beginningFacts = [beginningFacts];
        }

        if (!Array.isArray(endingFacts)) {
            endingFacts = [endingFacts];
        }

        var groups = _.groupBy(data, function(value) {
            let toReturn = "";
            aggregateBy.forEach(
                (col) => (toReturn = `${toReturn}.${value[col]}`)
            );
            return toReturn;
        });

        const defaultGet = (col) => (item) => (item[col] && item[col]) || 0;

        const sum = (col, group) => {
            return group.reduce((result, item) => {
                let get = getters[col] || defaultGet(col);
                return result + get(item);
            }, 0);
        };

        return _.map(groups, function(group) {
            let record = {};

            attributes.forEach((col) => {
                if (col) {
                    record[col] = group[0][col];
                }
            });

            beginningFacts.forEach((beginningFact) => {
                let get = getters[beginningFact] || defaultGet(beginningFact);
                record[beginningFact] = get(group[0]);
            });

            restOfFacts.forEach((col) => (record[col] = sum(col, group)));
            endingFacts.forEach((endingFact) => {
                let get = getters[endingFact] || defaultGet(endingFact);
                record[endingFact] = get(group[group.length - 1]);
            });

            return record;
        });
    }

    static getUserIdFromAsyncContext(asyncContext) {
        const context = asyncContext.getContext();
        const userId = context && context.get("userId");
        if (context && userId) return userId;
        return "system";
    }

    static parseQueryString(queryString) {
        if (!queryString || queryString.indexOf("?") == -1)
            throw new Error(
                "Invalid query string specified [" + queryString + "]"
            );

        var values = queryString.split("?")[1].split("&");
        var queryParams = {};
        values.forEach((val) => {
            var valueSplit = val.split("=");

            const key = valueSplit[0];
            const value = valueSplit[1];
            queryParams[key] = value;
        });

        return queryParams;
    }

    static capitalize(text) {
        return `${text[0].toUpperCase()}${text.slice(1)}`;
    }

    static truncate(str, length) {
        if (!str) return "";

        var dots = str.length > length ? "..." : "";
        return str.substring(0, length) + dots;
    }

    static getDayStatus(date) {
        let today = Moment(Utils.now_str());
        let currentMoment = Moment(date);

        return today.diff(currentMoment, "days");
    }

    static getDayStatusString(date) {
        let today = Moment(Utils.now_str());
        let currentMoment = Moment(date);

        let dayStatus = today.diff(currentMoment, "days");
        if (dayStatus == 0) {
            dayStatus = "Today";
        } else {
            dayStatus = dayStatus + "d ago";
        }

        return dayStatus;
    }

    static getRelativeTime(date) {
        let today = Moment(Utils.now());
        let currentMoment = Moment(date);
        let diff = today.diff(currentMoment, "milliseconds");
        let status = "";
        diff = parseInt(diff / 1000);

        if (diff > 86400) {
            //300000/86400=3.52742
            let d = parseInt(diff / 86400);
            status = d + ` day${d > 1 ? "s" : ""} ago`;
            diff = diff % 86400; //3.523546-3   300000-(300000/86400)*86400=259000
        } else if (diff > 3600) {
            let h = parseInt(diff / 3600);
            status = h + ` hour${h > 1 ? "s" : ""} ago`;
            diff = diff % 3600;
        } else if (diff > 60) {
            let m = parseInt(diff / 60);
            status = m + ` min${m > 1 ? "s" : ""} ago`;
        } else {
            status = "Now";
        }
        return status;
    }

    static isValidDateFormat(date, format = Utils.DEFAULT_DATE_FORMAT) {
        return Moment(date, format, true).isValid();
    }

    static isValidPeriodFormat(period) {
        return Moment(period, Utils.PERIOD_FORMAT, true).isValid();
    }

    static daysTo(date) {
        return Moment(date).diff(Moment(), "days") + 1;
    }

    static daysSince(date) {
        return Moment().diff(Moment(date), "days");
    }

    static formatWeekHeader(date) {
        return (
            Moment(date).format("MM/DD") +
            " - " +
            Moment(date)
                .add(6, "days")
                .format("MM/DD")
        );
    }

    static formatWeekHeaderShort(date) {
        return "Week of " + Moment(date).format("MMM Do, YYYY");
    }

    static formatShortNow() {
        return Moment().format("dddd, MMM Do, YYYY");
    }

    static formatLongDate(date) {
        return Moment(date).format("dddd, MMM DD, YYYY");
    }

    static formatLongStandardDate(date) {
        return Moment(date).format("DD MMM, YYYY");
    }

    static formatShortDate(date) {
        return Moment(date).format("MM/DD/YY");
    }

    static formatDate(date, dateFormat) {
        if (isSimpleDate(date)) return date;

        if (date === "Invalid date") return null;

        if (dateFormat) {
            // REV-9973 We are now supporting multiple date formats
            return Moment(date, dateFormat).format(Utils.DEFAULT_DATE_FORMAT);
        }

        return Moment(new Date(date)).format(Utils.DEFAULT_DATE_FORMAT);
    }

    static formatDateForExcel(date) {
        return Moment(date).format("MM/DD/YYYY");
    }

    static toISOString(date) {
        return Moment(date).toISOString();
    }

    static formatDayOfWeek(date) {
        return Moment(date).format("dddd");
    }

    static formatShortestDate(date) {
        return Moment(date).format("MM/DD");
    }

    static formatToAccountingPeriod(date) {
        if (isSimpleDate(date))
            return `${date.substring(0, 4)}${date.substring(5, 7)}`;

        return Moment(date).format(Utils.PERIOD_FORMAT);
    }

    //
    //
    // New Time Methods
    //
    //

    static isBefore(d1, d2) {
        if (
            (isSimpleDate(d1) && isSimpleDate(d2)) ||
            (isPeriod(d1) && isPeriod(d2))
        )
            return d1 < d2;
        return Moment(d1).isBefore(Moment(d2));
    }

    static isAfter(d1, d2) {
        if (
            (isSimpleDate(d1) && isSimpleDate(d2)) ||
            (isPeriod(d1) && isPeriod(d2))
        )
            return d1 > d2;
        return Moment(d1).isAfter(Moment(d2));
    }

    static isSameOrBefore(d1, d2) {
        if (
            (isSimpleDate(d1) && isSimpleDate(d2)) ||
            (isPeriod(d1) && isPeriod(d2))
        )
            return d1 <= d2;
        return Moment(d1).isSameOrBefore(Moment(d2));
    }

    static isSameOrAfter(d1, d2) {
        if (
            (isSimpleDate(d1) && isSimpleDate(d2)) ||
            (isPeriod(d1) && isPeriod(d2))
        )
            return d1 >= d2;
        return Moment(d1).isSameOrAfter(Moment(d2));
    }

    static isSame(d1, d2) {
        if (
            (isSimpleDate(d1) && isSimpleDate(d2)) ||
            (isPeriod(d1) && isPeriod(d2))
        )
            return d1 === d2;
        return Moment(d1, Utils.PERIOD_FORMAT).isSame(
            Moment(d2, Utils.PERIOD_FORMAT)
        );
    }

    //
    // End New Time Methods
    //
    //

    static formatShortestDateWithYear(date) {
        if (isSimpleDate(date))
            return `${date.substring(0, 4)}${date.substring(5, 7)}`;

        return Moment(date).format(Utils.PERIOD_FORMAT);
    }

    static addPeriods(period, N, format = Utils.PERIOD_FORMAT) {
        return Moment(period, format)
            .add(N, "months")
            .format(format);
    }

    static addQuarters(quarter, N) {
        return Moment(quarter, "YYYYQ")
            .add(N, "quarters")
            .format("YYYYQ");
    }

    static addYears(year, N) {
        return Moment(year, "YYYY")
            .add(N, "years")
            .format("YYYY");
    }

    static getPriorQuarter(currentQuarter) {
        return Utils.addQuarters(currentQuarter, -1);
    }

    static getPriorYear(currentYear) {
        return Utils.addYears(currentYear, -1);
    }

    static priorPeriod(period) {
        return Utils.addPeriods(period, -1);
    }

    static nextPeriod(period, format = Utils.PERIOD_FORMAT) {
        return Utils.addPeriods(period, 1, format);
    }

    static periodsBetween(
        period1,
        period2,
        p1inclusive = false,
        p2inclusive = false,
        format = Utils.PERIOD_FORMAT
    ) {
        let toReturn = [];

        if (period1 == period2) {
            if (p1inclusive) toReturn.push(period1);
            if (p2inclusive) toReturn.push(Utils.nextPeriod(period2, format));
            return toReturn;
        }

        let currentPeriod = Utils.nextPeriod(period1, format);

        if (p1inclusive) toReturn.push(period1);

        while (currentPeriod != period2) {
            toReturn.push(currentPeriod);
            currentPeriod = Utils.nextPeriod(currentPeriod, format);
        }

        if (p2inclusive) toReturn.push(period2);

        return toReturn;
    }

    static inPeriodRange(period, min, max) {
        let _period = Moment(period, Utils.PERIOD_FORMAT);
        let _min = Moment(min, Utils.PERIOD_FORMAT);
        let _max = Moment(max, Utils.PERIOD_FORMAT);

        return _min.isSameOrBefore(_period) && _period.isSameOrBefore(_max);
    }

    static formatPeriodForDisplay(date, format = "YYYY-MM") {
        return Moment(date, Utils.PERIOD_FORMAT).format(format);
    }

    static getMonthFromPeriod(date) {
        return Moment(date, Utils.PERIOD_FORMAT).format("MMM");
    }

    static getYear(date, format) {
        return Moment(date, format).format("YYYY");
    }

    static getPeriod(dateParts) {
        if (dateParts.month) {
            dateParts.month--;
        }
        return Moment(dateParts).format(Utils.PERIOD_FORMAT);
    }

    static getYearFromPeriod(period) {
        return Utils.formatDateCustom(
            Moment(period, Utils.PERIOD_FORMAT),
            "YYYY"
        );
    }

    static getQuarter(period) {
        return Utils.formatDateCustom(
            Moment(period, Utils.PERIOD_FORMAT),
            "YYYYQ"
        );
    }

    static getQuarterDesc(period) {
        return Utils.formatDateCustom(
            Moment(period, Utils.PERIOD_FORMAT),
            "YYYY - [Q]Q"
        );
    }

    static getCurrentPeriod() {
        return Utils.formatDateCustom(Utils.currentDate(), Utils.PERIOD_FORMAT);
    }

    static formatDateCustom(date, format) {
        return Moment(date).format(format);
    }

    static getDateFromPeriod(period, endOfMonth = false) {
        let _date = Moment(period, Utils.PERIOD_FORMAT);
        if (endOfMonth) {
            _date = _date.endOf("month");
        }

        return _date.format(Utils.DEFAULT_DATE_FORMAT);
    }

    static startOfMonth(date, format) {
        return Moment(date, format).startOf("month");
    }

    static endOfMonth(date, format) {
        return Moment(date, format).endOf("month");
    }

    static endOfQuarter(date, format) {
        return Moment(date, format).endOf("quarter");
    }

    static startOfQuarter(date, format) {
        return Moment(date, format).startOf("quarter");
    }

    static nthDayOfMonth(date, n) {
        return Moment(date)
            .startOf("month")
            .add(n - 1 || 0, "days");
    }

    /**
     * Convert a date into a weekId
     * @param date yyyy-MM-dd formatted string for date
     * @returns weekId
     */
    static toWeekId(date) {
        if (!date) return null;

        var d = new Date(date); //date.substring(0, 4), date.substring(5, 7) - 1, date.substring(8)
        d.setUTCDate(d.getUTCDate() - d.getUTCDay());
        return d.toISOString().slice(0, 10);
    }

    /**
     * Convert the date to a short firm date in UTC format for e.g., 2016-12-09
     * @param date
     * @returns {null}
     */
    static toShortForm(date) {
        "use strict";
        if (!date) return null;

        if (date && Object.prototype.toString.call(date) != "[object Date]")
            throw new Error("Invalid type for object date [" + date + "]");

        return date.toISOString().slice(0, 10);
    }

    static currentTimeStamp() {
        return new Date().toISOString();
    }

    static currentTimeStampInt() {
        return Date.now();
    }

    /**
     * Right now..
     * @returns {string}
     */
    static currentDate() {
        "use strict";
        const isDate = (s) =>
            s &&
            s.match(/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/);
        const currentDate =
            process.env.CURRENT_DATE && isDate(process.env.CURRENT_DATE)
                ? Moment(new Date(process.env.CURRENT_DATE))
                : Moment();
        return currentDate;
    }

    /**
     * Right now..
     * @returns {string}
     */
    static now() {
        "use strict";
        return Utils.currentDate();
    }

    static now_str() {
        return Utils.currentDate()
            .toISOString()
            .slice(0, 10);
    }

    /**
     * Get the current week id
     * @returns {*|weekId}
     */
    static thisWeekId() {
        "use strict";
        return Utils.toWeekId(
            Utils.currentDate()
                .toISOString()
                .slice(0, 10)
        );
    }

    /**
     * Get the current period id
     * @returns {*}
     */
    static thisPeriodId() {
        "use strict";
        return Utils.thisWeekId(); //@todo: right now the weekid and the period id are the same.
    }

    /**
     * Figure out the weekId for some future week.
     *
     * @param date
     * @param weekCount week in future.
     * @returns weekId for future week that is {weekCount} out from this week.
     */
    static futureWeekId(date, weekCount) {
        "use strict";

        if (!date) return null;

        return Moment(date)
            .add(weekCount, "week")
            .toISOString()
            .slice(0, 10);
    }

    static addMonthsAsDuration(date, months) {
        if (months % 1 == 0) {
            return Utils.addMonths(date, months);
        }
        // if month is a floating (decimal) value
        const termToDuration = Moment.duration(months, "months");
        const ms = termToDuration.asMilliseconds();
        return Moment(date)
            .add(ms, "ms")
            .toISOString()
            .slice(0, 10);
    }

    /**
     * Figure out the weekId for some future week.
     *
     * @param date
     * @param months in future.
     */
    static addMonths(date, monthCount) {
        "use strict";

        if (!date) return null;

        return Moment(date)
            .add(monthCount, "month")
            .toISOString()
            ?.slice(0, 10);
    }

    static addDays(date, dayCount) {
        if (!date) return null;

        return Moment(date)
            .add(dayCount, "days")
            .toISOString()
            .slice(0, 10);
    }

    static eightWeeksAgo(from) {
        const today = Moment(from);
        return Utils.toShortForm(new Date(today.subtract(8, "weeks")));
    }

    static getSpecificWeekDay(date, weekCount, plus = 0) {
        if (!date) return null;

        var d = new Date(Utils.toWeekId(date));
        d.setUTCDate(d.getUTCDate() - d.getUTCDay() - 7 * weekCount);
        d.toISOString().slice(0, 10);
        let newDate = Moment(d)
            .add(plus, "days")
            .format(Utils.DEFAULT_DATE_FORMAT);
        return newDate;
    }

    /**
     * Figure out what the previous week id is..
     *
     * @param date
     * @param weekCount
     * @returns {*}
     */
    static previousWeekId(date, weekCount = 1) {
        if (!date) return null;

        var d = new Date(Utils.toWeekId(date));
        d.setUTCDate(d.getUTCDate() - d.getUTCDay() - 7 * weekCount);

        return d.toISOString().slice(0, 10);
    }

    static isDateBetween(sourceDate, fromDate, toDate, inclusive = true) {
        // https://momentjs.com/docs/#/query/is-between/
        if (
            inclusive &&
            Moment(sourceDate).isBetween(fromDate, toDate, undefined, "[]")
        ) {
            return true;
        } else if (
            !inclusive &&
            Moment(sourceDate).isBetween(fromDate, toDate)
        ) {
            return true;
        }

        return false;
    }

    /**
     * Calculate number of weeks between two dates.
     * @param _data1 yyyy-mm-dd date
     * @param _data2 yyyy-mm-dd date
     */
    static weeksBetween(_date1, _date2) {
        return Moment(_date2).diff(_date1, "weeks");
    }

    /**
     * Figure out the next week id is..
     *
     * @param date
     * @returns {*}
     */
    static nextWeekId(date) {
        "use strict";
        if (!date) return null;

        var d = new Date(date);
        d.setUTCDate(d.getUTCDate() + 7);
        return d.toISOString().slice(0, 10);
    }

    /**
     * Returns week name for providing week id number, e.g. sunday for 1
     * @param weekId
     */
    static getWeeksName(weekId) {
        let days = [
            "",
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        return days[weekId];
    }

    static formatCurrency(money, n, x, sign, currency) {
        "use strict";
        if (n === undefined) {
            n = 2;
        }
        if (x == undefined) {
            x = 3;
        }

        if (isNaN(money)) return "-";

        const isNegative = money < 0;
        money = Math.abs(money);

        var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
        return (
            (isNegative ? "-" : sign ? "+" : "") +
            (currency ? currency : "$") +
            parseFloat(money)
                .toFixed(Math.max(0, ~~n))
                .replace(new RegExp(re, "g"), "$&,")
        );
    }

    static formatCurrencyRange(min, max) {
        return `${Utils.formatCurrency(min)} - ${Utils.formatCurrency(max)}`;
    }

    static formatPercentage(number, sign) {
        return (
            (sign && number > 0 ? "+" : "") +
            Math.round(number * 100) / 100 +
            "%"
        );
    }

    static formatPercentRange(min, max) {
        return `${Utils.formatPercentage(min)} - ${Utils.formatPercentage(
            max
        )}`;
    }

    static formatPhoneNumber(s) {
        var s2 = ("" + s).replace(/\D/g, "");
        var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
        return !m ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
    }

    static parseCurrency(currency) {
        return Number(currency.replace(/[^0-9\.]+/g, ""));
    }

    static parseAmount(number, precision = DEFAULT_PRECISION) {
        return parseFloat(number.toFixed(precision));
    }

    /**
     * The cache is considered expired after 1 hour
     *
     * @param lastUpdatedOn
     * @returns {boolean}
     */
    static cacheExpired(lastUpdatedOn) {
        return lastUpdatedOn
            ? Utils.now() - lastUpdatedOn > 60 * 60 * 1000
            : false;
    }

    /**
     * Deep merge two objects.
     * @param target
     * @param ...sources
     */
    static mergeDeep(target, ...sources) {
        return _mergeDeep(target, ...sources);
    }

    /**
     * Clone value object into a new deep copy
     *
     * @param value
     * @param customizer
     * @param thisArg
     * @returns cloned value
     */
    static cloneDeep(value, customizer, thisArg) {
        return cloneDeep(value, customizer, thisArg);
    }

    static getYearDiff(start, end, round) {
        let years = Moment(end).diff(Moment(start), "years", true);

        if (round) years = Math.round(years);

        return years;
    }

    static getMonthDiff(start, end, round) {
        let months = Moment(end).diff(Moment(start), "months", true);

        if (round) months = Math.round(months);

        return months;
    }

    static getTermFromPeriods(start, end) {
        return Moment(end, Utils.PERIOD_FORMAT).diff(
            Moment(start, Utils.PERIOD_FORMAT),
            "months",
            true
        );
    }

    static getDayDuration(start, end) {
        const _endDate = Moment(end)
            .add(1, "days")
            .endOf("day");
        const _startDate = Moment(start).startOf("day");
        const duration = _endDate.diff(_startDate, "days");
        return Math.round(duration);
    }

    static dateDiffIgnoreLeapDay(start, end) {
        var startDate = Moment(start);
        var endDate = Moment(end).add(1, "days");
        var diff = endDate.diff(startDate, "days");
        for (var year = startDate.year(); year <= endDate.year(); year++) {
            var date = Moment(year + "-02-29");
            if (date.isBetween(startDate, endDate) && date.isLeapYear()) {
                diff -= 1;
            }
        }
        return diff;
    }

    static getDayDiff(start, end) {
        let days = Moment(end).diff(Moment(start), "days", true);

        return Math.round(days);
    }

    static daysLeftInMonth(date, inclusive) {
        return Utils.daysLeft(date, inclusive, "month");
    }

    static daysLeftInQuarter(date, inclusive) {
        return Utils.daysLeft(date, inclusive, "quarter");
    }

    static daysLeftInYear(date, inclusive) {
        return Utils.daysLeft(date, inclusive, "year");
    }

    static daysLeft(date, inclusive, diffType = "month") {
        let endOfPeriod = Moment(date).endOf(diffType);
        return (
            Utils.getDayDiff(Moment(date), endOfPeriod) - (inclusive ? 0 : 1)
        );
    }

    static dayOfMonth(date, inclusive) {
        return Moment(date).date() - (inclusive ? 0 : 1);
    }

    static getProgressStatus(progress) {
        const duration = Moment.duration(
            Moment(progress.endTime).diff(Moment())
        );
        const timeDiff = duration.asHours();

        let status = {
            hasWarrning: false,
            hasExpired: false,
            hideProgress: false
        };

        if (timeDiff < 0 && progress.status != "In Progress") {
            status.hasWarrning = true;
        }

        if (
            timeDiff <= -2 ||
            (progress.currentStatus && progress.currentStatus == "Error")
        ) {
            status.hasExpired = true;
        }

        if (timeDiff <= -24) {
            status.hideProgress = true;
        }

        return status;
    }

    static dayOfQuarter(date, inclusive) {
        return (
            Utils.getDayDiff(Moment(date).startOf("quarter"), Moment(date)) +
            1 -
            (inclusive ? 0 : 1)
        );
    }

    static dayOfYear(date, inclusive) {
        return (
            Utils.getDayDiff(Moment(date).startOf("year"), Moment(date)) +
            1 -
            (inclusive ? 0 : 1)
        );
    }

    /**
     * Get time difference in minutes.
     *
     * @param startTime
     * @param endTime
     */
    static getDiffInMinutes(startTime, endTime) {
        // time difference in ms
        let timeDiff = endTime - startTime;

        // strip the ms
        timeDiff /= 1000;

        // get seconds (Original had 'round' which incorrectly counts 0:28, 0:29, 1:30 ... 1:59, 1:0)
        let seconds = Math.round(timeDiff % 60);

        // remove seconds from the date
        timeDiff = Math.floor(timeDiff / 60);

        // get minutes
        let minutes = Math.round(timeDiff % 60);

        return minutes;
    }

    /**
     * Determine if this object has any of it's own keys..
     *
     * @param obj
     * @returns true if it has keys..
     */
    static objHasKeys(obj) {
        let hasKeys = false;
        for (let i in obj) {
            hasKeys = true;
            break;
        }

        return hasKeys;
    }

    static isNumeric(str) {
        if (typeof str == "number") return Utils.isNumber(str);

        if (typeof str != "string") return false; // we only process strings!

        return (
            !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ); // ...and ensure strings of whitespace fail
    }

    static isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    static isPriorDate(d) {
        return this.isDate(d) && this.daysSince(d) > 1;
    }

    static isPhone(phone) {
        return phone.length >= 11;
    }

    static isDomain(value) {
        const pattern = new RegExp(
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})"
        );
        return !!pattern.test(value);
    }

    static isDate(d) {
        try {
            // TODO: use moment.isDate instead of following two lines
            const timestamp = Date.parse(d);
            return isNaN(timestamp) == false;
        } catch (error) {
            return false;
        }
    }

    /**
     * Add the given weeks to the given date
     *
     * @param weeks
     * @returns {*}
     */
    static addWeeksTo(date = Utils.currentDate(), weeks) {
        return Moment(date).add(weeks, "weeks");
    }

    /**
     * Add the given years to the given date
     *
     * @param years
     * @returns {*}
     */
    static addYearsTo(date = Utils.currentDate(), years) {
        return Moment(date).add(years, "years");
    }

    static getAbsoluteMonths(date) {
        return date.month() + 1 + date.year() * 12;
    }

    static getAbsoluteQuarters(date) {
        return Math.ceil(Utils.getAbsoluteMonths(Moment(date)) / 4);
    }

    /**
     * Convert string to title case
     * ALABAMA IS => Alabama Is
     * @param str
     * @returns {*}
     */
    static toTitleCase(str) {
        if (str == undefined || str == null) return undefined;

        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    /**
     * Convert a snake case string to title case
     * is_closed => Is Closed
     * @param str
     * @returns {*}
     */
    static toTitleCaseFromSnakeCase(str) {
        const titleCase = str.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
            c ? c.toUpperCase() : " " + d.toUpperCase()
        );

        return titleCase;
    }

    /**
     * Shorten a string and add ellipsis
     *
     * @param str
     * @param length
     * @returns {*|string}
     */
    static shortString(str, length = 50) {
        return (
            str &&
            str
                .substring(0, length)
                .concat((str.length == length && " ") || "...")
        );
    }

    static getD3CirclePath(cx, cy, r) {
        return `M${cx - r},${cy}a${r},${r} 0 1,0 ${r *
            2},0a${r},${r} 0 1,0 -${r * 2},0`;
    }

    /**
     * Computes the schedule and other details for the saving goal
     *
     * @param pastFundings
     * @param fundingAmount
     * @param targetAmount
     * @returns Forcasted savings schedule.
     */
    static computeSchedule(pastFundings, fundingAmount, targetAmount) {
        const schedule = [];

        let toDateFunding = 0;
        let outstanding = targetAmount;
        let weekNumber = 0;

        pastFundings = pastFundings.slice();
        pastFundings = pastFundings.sort((f1, f2) => {
            return f1.fundingPeriodId < f2.fundingPeriodId
                ? -1
                : f1.fundingPeriodId > f2.fundingPeriodId
                ? 1
                : 0;
        });

        let lastPeriod = Utils.previousWeekId(Utils.thisWeekId());
        pastFundings = pastFundings.map((funding, index) => {
            toDateFunding += funding.fundAmount;
            outstanding -= funding.fundAmount;

            let output = {
                fundAmount: funding.fundAmount,
                fundingPeriodId: funding.fundingPeriodId,
                toDateFunding: toDateFunding,
                weekNumber: weekNumber++
            };

            lastPeriod = output.fundingPeriodId && output.fundingPeriodId;

            schedule.push(Object.assign({}, output));

            return output;
        });

        lastPeriod =
            lastPeriod == null || !lastPeriod
                ? Utils.previousWeekId(Utils.thisWeekId())
                : lastPeriod;

        const totalFunded = toDateFunding;

        let nextPeriod = Moment(lastPeriod)
            .add(7, "days")
            .format(Utils.DEFAULT_DATE_FORMAT);
        let toFund = -1;

        do {
            toFund = outstanding < fundingAmount ? outstanding : fundingAmount;
            toDateFunding += toFund;
            schedule.push({
                fundAmount: toFund,
                fundingPeriodId: nextPeriod,
                toDateFunding: toDateFunding,
                weekNumber: weekNumber++
            });

            nextPeriod = Moment(nextPeriod)
                .add(7, "days")
                .format(Utils.DEFAULT_DATE_FORMAT);
            outstanding -= toFund;
        } while (outstanding > 0);

        return {
            pastFundings,
            schedule,
            totalFunded,
            weeksToGo: schedule.length - pastFundings.length,
            progress: 1 - (targetAmount - totalFunded) / targetAmount
        };
    }

    /**
     * Determine the savings if you added a monthly prepayment amount above the interest accumulated
     * that month
     *
     * @param outstanding
     * @param annualRate
     * @param monthlyPrepaymentAmount
     * @returns interestSaved with the prepay as a dollar value
     */
    static computeSavingsWithPrepay(
        outstanding,
        annualRate,
        monthlyPrepaymentAmount
    ) {
        const s1 = Utils.buildCardSchedule(outstanding, annualRate);
        const s2 = Utils.buildCardSchedule(
            outstanding,
            annualRate,
            monthlyPrepaymentAmount
        );

        const toReturn = {
            estimatedSaving: s1.totalInterestPaid - s2.totalInterestPaid,
            payoffWeeks: s2.monthsToPayoff * 4,
            estInterest: s1.totalInterestPaid,
            withBuckitInterest: s2.totalInterestPaid,
            scheduleNoPrepay: s1.schedule,
            scheduleWithPrepay: s2.schedule,
            fasterPayoff: (s2.monthsToPayoff - s1.monthsToPayoff) * 4
        };

        // console.log("#1 : " + JSON.stringify(toReturn, null, 4));

        return toReturn;
    }

    /**
     * Build a credit card schedule
     *
     * @param outstanding
     * @param annualRate
     * @param monthlyPrepaymentAmount
     * @returns {{totalInterestPaid: number, schedule: Array, monthsToPayoff: number}}
     */
    static buildCardSchedule(outstanding, annualRate, fixedPaymentAmount) {
        const schedule = [];

        let month = 0;
        let accumInterest = 0.0;
        let terminated = false;
        schedule.push({
            month,
            outstanding,
            interest: 0.0,
            accumInterest,
            principal: 0.0,
            payment: 0.0
        });
        do {
            let interest = (outstanding * annualRate) / 1200.0;
            let minPrincipalPayment = 0.01 * outstanding;
            let minPayment = Math.max(interest + minPrincipalPayment, 25.0);

            let payment = fixedPaymentAmount ? fixedPaymentAmount : minPayment;
            //deal with continued minimal payments
            if (payment > outstanding + interest)
                payment = outstanding + interest;

            outstanding -= payment - interest;
            accumInterest += interest;

            month++;

            schedule.push({
                month,
                outstanding,
                interest,
                payment,
                principal: payment - interest,
                accumInterest
            });

            if (schedule.length > 20 * 12) {
                terminated = true;
                break;
            }
            //terminate if you are running forever..
        } while (outstanding > 0);

        const totalInterestPaid = schedule.reduce(
            (totalInterestPaid, schedule) =>
                totalInterestPaid + schedule.interest,
            0.0
        );

        return {
            totalInterestPaid,
            schedule,
            monthsToPayoff: schedule.length - 1,
            terminated
        };
    }

    static toTitleCase(str) {
        return str.replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    static capitalizeFirstLetter(str) {
        if (str) {
            str = String(str);
        }
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    }

    static parseBillingPeriod(asOfPeriod) {
        if (!asOfPeriod) {
            throw Utils.error("Missing asOfPeriod");
        }

        var period = asOfPeriod.split(" ");

        return {
            start: Moment(period[0]).toDate(),
            end: Moment(period[2]).toDate()
        };
    }

    static formatBillingPeriod(billingPeriod) {
        return (
            Utils.formatDate(billingPeriod.start) +
            " - " +
            Utils.formatDate(billingPeriod.end)
        );
    }

    static calculateBillingPeriod(asOfPeriod, diff) {
        if (!asOfPeriod) {
            throw Utils.error("Missing asOfPeriod");
        }

        var period;
        try {
            period = Utils.parseBillingPeriod(asOfPeriod);
        } catch (e) {
            throw Utils.error("Invalid billing period [" + asOfPeriod + "]", e);
        }

        var newPeriod = {};

        newPeriod.start = Moment(period.start)
            .add(diff, "months")
            .format(Utils.DEFAULT_DATE_FORMAT);
        newPeriod.end = Moment(period.end)
            .add(diff, "months")
            .format(Utils.DEFAULT_DATE_FORMAT);

        return Utils.formatBillingPeriod(newPeriod);
    }

    static isNumberSame(num1, num2) {
        if (num1 == undefined && num2 == undefined) return true;

        num1 = Number(num1);
        num2 = Number(num2);
        return (
            _.isNumber(num1) == _.isNumber(num2) &&
            _.isFinite(num1) == _.isFinite(num2) &&
            Math.abs(num1 - num2) < 0.01
        );
    }

    static range(lowEnd, highEnd) {
        var list = [];
        for (var i = lowEnd; i <= highEnd; i++) {
            list.push(i);
        }

        return list;
    }

    /**
     * @description Throws an error.
     * @param {*} errorOptions options for error object such as
     * type, subCode, errorMessage and code.
     */
    static throwError(errorOptions) {
        const {
            type = "HttpResponse",
            subCode = "UNKNOWN_ERROR",
            errorMessage = "It's not you, it's us. This is our fault.",
            code = 500
        } = errorOptions;
        let error = {};
        throw Object.assign(error, {
            type,
            subCode,
            errorMessage,
            code,
            details: errorOptions.details || null
        });
    }

    // Keep the stack trace of original error and rethrow.
    static error(message, error, log) {
        if (!log) {
            log = { error: (str) => console.log(str) };
        }

        const e = new Error(message);

        if (error) {
            log.error(
                message +
                    ", Error :" +
                    error.toString() +
                    ", Full Error :" +
                    JSON.stringify(error)
            );
            log.error(error.stack);

            e.original = error;
            e.stack =
                e.stack
                    .split("\n")
                    .slice(0, 2)
                    .join("\n") +
                "\n" +
                error.stack;
        } else {
            log.error("Error : " + message);
        }

        return e;
    }

    static emptyPromise(val = null) {
        return new Promise((resolve) => {
            resolve(val);
        });
    }

    static copy(aObject) {
        if (!aObject || typeof aObject !== "object") {
            return aObject;
        }

        if (true) return cloneDeep(aObject);

        var bObject, v, k;
        bObject = Array.isArray(aObject) ? [] : {};
        for (k in aObject) {
            v = aObject[k];
            bObject[k] = typeof v === "object" ? Utils.copy(v) : v;
        }
        return bObject;
    }

    static getOrgConfigByKey(orgConfigArray, legacy = true) {
        const orgConfigurations = {};
        if (orgConfigArray && orgConfigArray.length > 0) {
            orgConfigArray.forEach((_config) => {
                let id = legacy
                    ? isNaN(_config.id) &&
                      _config.id.toString().indexOf("ac/") == -1
                        ? _config.id.replace(/^.+\//, "")
                        : _config.id
                    : _config.id;
                orgConfigurations[id] = _config;
            });
        }

        return orgConfigurations;
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive)
     * Using Math.round() will give you a non-uniform distribution!
     */
    static random(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static randomNumbers(count, min, max) {
        let toReturn = Array.apply(0, Array(count)).map((i) =>
            Utils.random(min, max)
        );
        console.log("r => " + JSON.stringify(toReturn));
        return toReturn;
    }

    static randomFixedInteger(length) {
        return Math.floor(
            Math.pow(10, length - 1) +
                Math.random() *
                    (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
        );
    }

    static filterObjectKeyKeepStructure(attr, obj) {
        obj = Utils.copy(obj);
        _filterObjectKeyKeepStructure(attr, obj);
        return obj;
    }

    static pathToName(path) {
        return path.substring(path.lastIndexOf("/") + 1, path.length);
    }

    // removes the extension as well
    static pathToSimpleName(path) {
        const fileName = Utils.pathToName(path);
        return fileName.substring(0, fileName.lastIndexOf("."));
    }

    static getFileExtension(filename) {
        return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
    }

    static getPath(path) {
        return path.substring(0, path.lastIndexOf("/") + 1);
    }

    static getFolderNameFromPath(path) {
        let _path = this.getPath(path);
        const toReturn = _path.replace(/.$/, "").split("/");
        return toReturn[toReturn.length - 1];
    }

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
    }

    static convertToNormalCaseFromCamelCase(stringToConvert) {
        return (
            stringToConvert
                // insert a space before all caps
                .replace(/([A-Z])/g, " $1")
                // uppercase the first character
                .replace(/^./, function(str) {
                    return str.toUpperCase();
                })
        );
    }

    static convertToAllCapsFromCamelCase(stringToConvert) {
        return (
            stringToConvert
                // insert a space before all caps
                .replace(/([A-Z])/g, "_$1")
                // uppercase the first character
                .toUpperCase()
        );
    }

    /* Utility function to run sales order data in slices. */
    static async runSlice(params) {
        let {
            data = [],
            consumeSlicedData,
            sliceParams = {
                max: 50,
                start: 0,
                end: 50
            },
            batchNumber = 0
        } = params;

        do {
            const { start, end, max } = sliceParams;

            const slicedData = data.slice(start, end);

            await consumeSlicedData(
                Object.assign(
                    {},
                    params,
                    { data: slicedData },
                    { sliceParams, batchNumber }
                )
            );

            sliceParams.start += max;
            sliceParams.end += max;
            sliceParams.end =
                sliceParams.end <= data.length ? sliceParams.end : data.length;
            batchNumber = batchNumber + 1;
        } while (sliceParams.start < data.length);
    }

    static arrayToObject = (
        array,
        keyProvider = (obj) => obj,
        valueProvider = (obj) => obj
    ) => {
        if (!array) {
            return [];
        }

        return array.reduce((results, element, index) => {
            results[keyProvider(element, index)] = valueProvider(element);
            return results;
        }, {});
    };

    static objectToArray = (
        obj,
        arrayElement = (name, value) => ({
            name,
            value
        })
    ) => {
        return Object.keys(obj).map((key, index) => {
            return arrayElement(key, obj[key], index);
        });
    };

    static inverseObject(_obj) {
        let toReturn = {};
        Object.keys(_obj).forEach((key) => (toReturn[_obj[key]] = key));
        return toReturn;
    }

    static approxeq = function(v1 = 0, v2 = 0, epsilon = 0.01) {
        return Math.abs(v1 - v2) < epsilon;
    };

    static hasDuplicates = (array) => {
        return new Set(array).size !== array.length;
    };

    static sortByStringPeriod = (array, periodKey) => {
        return array.sort(function(a1, a2) {
            if (a2[periodKey] < a1[periodKey]) {
                return 1;
            } else if (a2[periodKey] > a1[periodKey]) {
                return -1;
            }
            return 0;
        });
    };

    static toSheetData(tableData) {
        let rows = [];

        tableData.forEach((row, index) => {
            if (index == 0) {
                rows.push(Object.keys(tableData[0]));
            }

            rows.push(rows[0].map((key) => row[key]));
        });

        return rows;
    }

    static resetRuntime(object) {
        object.runtime = {
            id: shortid.generate()
        };

        object.runtime.links = [object];
    }

    static shareRuntime(object1, object2) {
        if (!object1.runtime || !object2.runtime) return false;

        return object1.runtime.id === object2.runtime.id;
    }

    static linkRuntime(source, target) {
        if (!source.runtime) return;

        target.runtime = source.runtime;
        source.runtime.links.push(target);
    }

    static disposeRuntime(object) {
        object.runtime.links.forEach((link) => delete link.runtime);
    }

    static copyAttr(source, target, fields, override = true) {
        if (fields && fields.length > 0) {
            fields.forEach((key) => {
                if (override) target[key] = source[key];
                else target[key] = target[key] || source[key];
            });
        }
        return target;
    }

    // Returns if a value is a function
    static isFunction(value) {
        return typeof value === "function";
    }

    // Returns if a value is an object
    static isObject(value) {
        return value && typeof value === "object";
    }

    // Returns if a value is really a number
    static isNumber(value) {
        return typeof value === "number" && isFinite(value);
    }

    // Returns if a value is a string
    static isString(value) {
        return typeof value === "string" || value instanceof String;
    }

    static randomDate(start, end) {
        let _start = moment(start).valueOf();
        let _end = moment(end).valueOf();

        return moment(_start + Math.random() * (_end - _start));
    }

    static prune(object) {
        return _.omitBy(object, (value) => !value && !Utils.isNumber(value));
    }

    static isEmpty(value) {
        return _.isNil(value) || (Utils.isString(value) && value.length === 0);
    }

    static trim = (value) => (Utils.isString(value) && value.trim()) || value;

    static round = _.round;
    static roundToNearestHalf = (num) => Math.round(num * 2) / 2;
    static roundToNearestQuarter = (num) => Math.round(num * 4) / 4;

    static toBoolean = (value) => {
        if (value === null || value === undefined) return false;
        return value === true || value.trim().toLowerCase() === "true";
    };

    static toNumber = (value) => {
        if (value == undefined || value == null) return undefined;

        if (typeof value == "string") {
            if (value.trim() === "-") {
                return 0.0;
            }
            value = value.replace(/,/g, "");
        }

        if (typeof value == "number") return value;

        return parseFloat(value);
    };

    static toAmount = (value) => {
        const num = Utils.toNumber(value);
        if (num == null) return null;

        return Utils.parseAmount(num);
    };

    static toSearchKey = (key, searchFieldName, fieldValue) =>
        `${key}:${Utils.forKeyAndValue(searchFieldName, fieldValue)}`;

    static forKeyAndValue = (searchFieldName, fieldValue) => {
        return (
            `for${Utils.cap(Utils.safe(searchFieldName))}` +
            ((fieldValue &&
                `:${Utils.safe(fieldValue)}:${Utils.searchValue(
                    fieldValue
                )}`) ||
                "")
        );
    };

    static cap = (str) => {
        return `${str[0].toUpperCase()}${str.slice(1)}`;
    };

    static safe = (str) => {
        // return typeof str === 'string' ? str.replace(/[ ]/g, '_') : str
        return String(str);
    };

    static searchValue = (str) => {
        return typeof str === "string"
            ? str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
            : str;
    };

    static getKeyComponents = (str) => {
        let parts = str.split(":");

        // remove lower case value at the end, only there for searching
        if (parts[4]) parts.pop();

        // convert forProductFamily to productFamilu
        let fieldName = parts[3].replace(/^for/, "");
        fieldName = `${fieldName[0].toLowerCase()}${fieldName.slice(1)}`;

        // Create result
        let result = {
            clientId: parts[1],
            entity: parts[2],
            fieldName,
            value: parts.splice(4).join(":")
        };
        return result;
    };

    static chunkArrayInGroups(arr, size) {
        var myArray = [];
        for (var i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i + size));
        }
        return myArray;
    }

    static bucketizeArrayInGroups(groups, maxSize) {
        const buckets = [];
        const keys = Object.keys(groups);
        let bucket = [];
        for (const key of keys) {
            const arr = groups[key];
            if (bucket.length + arr.length > maxSize) {
                buckets.push(bucket);
                bucket = [...arr];
            } else {
                bucket.push(...arr);
            }
        }
        if (!_.isEmpty(bucket)) {
            buckets.push(bucket);
        }
        return buckets;
    }

    // keep can be first or last
    // Do not expect the result to be sorted even if the input array was sorted.
    static dropDuplicates(arr, getter, keep = "first") {
        if (!getter) {
            throw Utils.error(
                `No getter provided when removing duplicates from array.`
            );
        }

        var toReturn = {};

        arr.forEach((element) => {
            let value = getter(element);
            if (!toReturn[value] || keep === "last") {
                toReturn[value] = element;
            }
        });

        return Object.values(toReturn);
    }

    static iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/;

    static parseISO8601Duration(iso8601Duration) {
        var matches = iso8601Duration.match(Utils.iso8601DurationRegex);

        return {
            sign: matches[1] === undefined ? "+" : "-",
            years: matches[2] === undefined ? 0 : matches[2],
            months: matches[3] === undefined ? 0 : matches[3],
            weeks: matches[4] === undefined ? 0 : matches[4],
            days: matches[5] === undefined ? 0 : matches[5],
            hours: matches[6] === undefined ? 0 : matches[6],
            minutes: matches[7] === undefined ? 0 : matches[7],
            seconds: matches[8] === undefined ? 0 : matches[8]
        };
    }

    static iso860toSeconds(iso8601Duration) {
        const {
            sign,
            years,
            months,
            weeks,
            days,
            hours,
            minutes,
            seconds
        } = Utils.parseISO8601Duration(iso8601Duration);

        return (
            Number(`${sign}1`) *
            (years * 60 * 60 * 24 * 365 +
                months * 60 * 60 * 24 * 30 +
                weeks * 60 * 60 * 24 * 7 +
                days * 60 * 60 * 24 +
                hours * 60 * 60 +
                minutes * 60 +
                seconds)
        );
    }

    static sleep = (ms = 1000) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    static convertToBase62 = (number) => {
        let base62 = "";
        let remainder;
        while (number > 0) {
            remainder = number % 62;
            base62 = `${base62Digits[remainder]}${base62}`;
            number = Math.floor(number / 62);
        }
        return base62;
    };

    // https://en.wikipedia.org/wiki/Pairing_function
    // Optimized with base64 so take less characters in my id.
    static pair = (x, y) =>
        Utils.convertToBase62((1 / 2) * (x + y) * (x + y + 1) * y);
}

function _filterObjectKeyKeepStructure(attr, obj) {
    let id = obj.id;
    let returned = Object.keys(obj).map((key) => {
        if (key == attr) {
            if (id) obj.id = id;
            return true;
        } else if (typeof obj[key] == "object") {
            let result = _filterObjectKeyKeepStructure(attr, obj[key]);
            if (!result) key != id && delete obj[key];
            return result;
        } else {
            key != id && delete obj[key];
            return false;
        }
    });

    let found = returned.reduce((result, _bool) => {
        result = result || _bool;
        return result;
    }, false);

    return found;
}

function _mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (_isObject(target) && _isObject(source)) {
        for (const key in source) {
            if (_isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                _mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return _mergeDeep(target, ...sources);
}

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
function _isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

module.exports = Utils;
