import ExpenseRecognitionRule from "./reference/ExpenseRecognitionRule";
import AccountType from "./reference/AccountType";
import ComponentType from "./reference/ComponentType";
import ModificationType from "./reference/ModificationType";
import RecognitionRule from "./reference/RecognitionRule";
import TransactionType from "./reference/TransactionType";

export {
    ExpenseRecognitionRule,
    AccountType,
    ComponentType,
    ModificationType,
    RecognitionRule,
    TransactionType
}

/**
 * Returns a sorted array of all the account types in the system.
 * @param {*} forComponentTypes If specified then filter the account types
 * by the supplied component types.
 * @returns A sorted array of all the account types in the system.
 */
export const getAccountTypes = (forComponentTypes = []) => {
    const types = [];

    for (const key of Object.keys(AccountType)) {
        if (!forComponentTypes ||
            forComponentTypes.length == 0 ||
            forComponentTypes.includes(key)) {
            // Add in this component types set of account types.
            types.push(...AccountType[key]);
        }
    }

    types.sort();

    return types;
}

/**
 * Returns an array of journal posting types not including the legacy revenue
 * and expense related component types that the software already is built for.
 * These component types have records that will be stored in the OrderTransactions
 * table.
 * @returns An array of journal posting types not including the legacy revenue
 * and expense related component types that the software already is built for.
 */
export const getTransactionalJournalPostingTypes = () => {
    const types = [];

    for (const ct of Object.keys(ComponentType)) {
        if (ComponentType[ct] != ComponentType.ACCOUNT_RECEIVABLE &&
            ComponentType[ct] != ComponentType.UNEARNED_REVENUE &&
            ComponentType[ct] != ComponentType.EXPENSE) {
            // These 3 are legacy and the product has these already built into the
            // posting generation.
            types.push(ct);
        }
    }

    return types;
}

/**
 * Returns the ComponentType object or undefined if not found.
 * @param {*} name The name of the component type object to return.
 * @returns The ComponentType object or undefined if not found.
 */
 export const getComponentType = (name) => {
    for (const ct of Object.keys(ComponentType)) {
        if (ct == name || ComponentType[ct].name == name) {
            return ComponentType[ct];
        }
    }

    return undefined;
}

/**
 * Returns the TransactionType object or undefined if not found.
 * @param {*} name The name of the transaction type object to return.
 * @returns The TransactionType object or undefined if not found.
 */
 export const getTransactionType = (name) => {
    for (const tt of Object.keys(TransactionType)) {
        if (tt == name || TransactionType[tt].name == name) {
            return TransactionType[tt];
        }
    }

    return undefined;
}
